(function(Love) {

    Love.Views.SocialUpdateScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.SocialUpdateScreenView',

        defaults: function() {

            return {

                activateFirstDestination: true,
                availableDestinations: _.sortBy(Love.session.get('currentSite').get('destinations'), function(dest) { return dest.type + dest.accountName; }),
                date: null,
                socialUpdateId: null
            };
        },

        events: {

            'click .common-destinations .common-destination': '_handleDestinationClick',
            'click .common-destinations .common-destination-add': '_handleDestinationAddClick',

            'click .action-buttons-container [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .action-buttons-container [data-action="delete"]:not(.disabled)': '_handleDeleteClick',
            'click .action-buttons-container [data-action="schedule"]:not(.disabled)': '_handleScheduleClick',
            'click .action-buttons-container [data-action="unschedule"]:not(.disabled)': '_handleScheduleClick',

            'click .base-sidebar [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .base-sidebar [data-action="delete"]:not(.disabled)': '_handleDeleteClick',
            'click .base-sidebar [data-action="schedule"]:not(.disabled)': '_handleScheduleClick',
            'click .base-sidebar [data-action="unschedule"]:not(.disabled)': '_handleScheduleClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_checkExistingBackup');

            this.editModel = new Love.Models.SocialUpdateModel();

            // The view state contains information relevant to this view and any sub views.
            // We use a standard Backbone model, since it's not part of the API but (change) events are useful.

            this.viewState = new Backbone.Model({

                changedByUser: false,
                disableSaving: false
            });

            if (this.options.socialUpdateId)
                this.editModel.set('id', this.options.socialUpdateId);

            if (this.options.date && this.options.date.isValid() && this.options.date.isAfter(moment()))
                this.editModel.set('scheduledOnUtc', this.options.date.clone().utc());

            if (this.options.activateFirstDestination && !this.editModel.get('destination').id) {

                if (this.options.availableDestinations.length > 0)
                    this.editModel.set('destination', this.options.availableDestinations[0]);
            }
        },

        render: function() {

            // Remove any existing event listeners in case of re-rendering.

            this.stopListening(this.viewState, 'change:disableSaving');

            this.stopListening(this.editModel, 'change', this.editModel.saveClientBackup);
            this.stopListening(this.editModel, 'changedByUser');

            // Recreate the subviews, to ensure their event handlers are also removed.

            if (this.editorView) this.editorView.close();

            if (this.sidebarView) {

                this.stopListening(this.sidebarView, 'toggle');

                this.sidebarView.close();
            }

            this.listenTo(this.viewState, 'change:disableSaving', _.bind(function() {this._setSavingEnabled(!this.viewState.get('disableSaving'));}, this));

            this.editorView = this.addSubView(new Love.Views.SocialUpdateEditorView({editModel: this.editModel, viewState: this.viewState}));
            this.sidebarView = this.addSubView(new Love.Views.SocialUpdateSidebarView({editModel: this.editModel, viewState: this.viewState}));

            if (this.editModel.isNew()) {

                this.viewState.set('isEditable', _.indexOf(['idle', 'scheduled'], this.editModel.get('status')) > -1);

                this._checkScheduledOn();
                this._renderModel();

                _.defer(this._checkExistingBackup);
            }
            else {

                var self = this;
                this.listenToOnce(this.editModel, 'sync', function() {

                    this.viewState.set('isEditable', _.indexOf(['idle', 'scheduled'], this.editModel.get('status')) > -1);

                    self._checkScheduledOn();
                    self._renderModel();

                    _.defer(self._checkExistingBackup);
                });

                this.editModel.fetch({

                    error: function(model, response, options) {

                        Love.router.navigate('error/404', {trigger: true});
                    }
                });
            }

            this.listenTo(Love.appView, 'navigate', _.bind(function(e) {

                if (this._hasUnsavedUserChanges())
                    e.unsavedChanges = true;

            }, this));

            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _checkExistingBackup: function() {

            // Check if a backup for changes exists in the local storage.

            var entry = this.editModel.getClientBackup();

            if (entry) {

                var self = this;
                var popup = new Love.Views.CommonQuestionPopupView({

                    callbacks: {

                        onNo: this.editModel.removeClientBackup,
                        onYes: function() {

                            self.editModel.set(self.editModel.parse(entry.backup));
                            self.editModel.trigger('changedByUser', self.editModel);

                            self._renderModel();
                        }
                    },
                    data: {

                        title: 'Restore edits?',
                        text: 'A backup of local changes was made at ' + entry.updatedAt.format('ddd, DD MMM YYYY, HH:mm') + '. Do you want to restore it?'
                    }
                });

                popup.showPopup();
            }
        },

        _checkScheduledOn: function() {

            // Check if the scheduled on date is in the future.

            var scheduledOn = this.editModel.get('scheduledOnUtc');

            if (scheduledOn && scheduledOn.isBefore(moment())) {

                this.editModel.set('scheduledOnUtcIsImmediately', true);
            }
        },

        _handleDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        Love.Helpers.Tracking.track('Social update screen - delete', {

                            model: self.editModel.id
                        });

                        self.editModel.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                self.viewState.set('changedByUser', false);
                                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/social', {trigger: true});
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete social update?',
                    text: 'Do you really want to delete this social update? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleDestinationClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('[data-id]');
            var id = element.attr('data-id');
            var destination = _.find(this.options.availableDestinations, function(obj) {return obj.id === id;});

            if (this.editModel.get('destination') !== destination) {

                this.editModel.set('destination', destination);
                this.editModel.set('destinationId', destination.id);

                this.$('.common-destination').removeClass('active');
                element.addClass('active');
            }
        },

        _handleDestinationAddClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/settings', {trigger: true});
        },

        _handleSaveClick: function(e, programmatically) {

            if (e && e.preventDefault) e.preventDefault();

            if (!programmatically)
                Love.Helpers.Tracking.track('Social update screen - save', {

                    model: this.editModel.id
                });

            Love.Helpers.Loading.showLoading(true);

            var result = this._saveUpdateModel();

            $.when(result).always(function() { Love.Helpers.Loading.showLoading(false);});
        },

        _handleScheduleClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            Love.Helpers.Tracking.track('Social update screen - schedule', {

                model: this.editModel.id
            });

            this.editModel.set({

                status: (this.editModel.get('status') === 'scheduled') ? 'idle' : 'scheduled'
            });

            this._handleSaveClick(e, true);
        },

        _hasUnsavedUserChanges: function() {

            return (this.editModel && (this.editModel.isNew() || this.editModel.changedAttributesJSON(this.editModel.serverAttributes)) && this.viewState.get('changedByUser'));
        },

        _renderDestinations: function() {

            _.each(this.options.availableDestinations, function(model) {

                if (!this.viewState.get('isEditable') && model.id !== this.editModel.get('destination').id)
                    return;

                this.$('.common-destinations .common-destination-add').first().before(_.template(Love.Templates.social_update_destination(_.extend({

                    active: model.id === this.editModel.get('destination').id

                }, model))));

            }, this);

            this.performLazyLoadImages();
        },

        _renderModel: function() {

            var self = this;

            $(window).scrollTo(0, 'normal');

            var context = _.extend(_.clone(this.editModel.attributes), this.viewState.attributes);
            this.$el.html(_.template(Love.Templates.social_update_screen)(context));

            this._renderDestinations();

            this.editorView.setElement(this.$('.editor-container')).render();
            this.sidebarView.setElement(this.$('.base-sidebar-container')).render();

            this._updateScheduleButton();

            this.listenTo(this.editModel, 'change', this.editModel.saveClientBackup);
            this.listenTo(this.editModel, 'change:scheduledOnUtc', _.bind(this._updateScheduleButton, this));
            this.listenTo(this.editModel, 'change:scheduledOnUtcIsImmediately', _.bind(this._updateScheduleButton, this));

            this.listenTo(this.editModel, 'changedByUser', function(e) {

                self.viewState.set('changedByUser', true);
            });

            this.listenTo(this.sidebarView, 'toggle', function(e) {

                self.performDotDotDotUpdate();
                self.performLazyLoadImagesResize(true);
            });
        },

        _saveUpdateModel: function() {

            this._setSavingEnabled(false);
            this._showFeedback(false);

            var wasNew = this.editModel.isNew();

            var result = this.editModel.save(this.editModel.attributes, {

                wait: true,
                success: _.bind(function(model, response, options) {

                    if (wasNew) {

                        // If it was a new social update, update the url.

                        Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/social/' + model.id, {

                            trigger: false,
                            replace: true
                        });
                    }

                    // Any changes by the user have now been saved.

                    this.viewState.set('changedByUser', false);

                    // After saving, for both new and old social updates, the update model attributes are updated.
                    // This raises a change event which in turn saves a copy of the model to the local storage.
                    // Therefore, we have to remove the client backup here.

                    this.editModel.removeClientBackup();

                    this._renderModel();
                    this._setSavingEnabled(true);

                }, this),
                error: _.bind(function(model, response, options) {

                    this._setSavingEnabled(true);
                    this._showFeedback(true);

                }, this)
            });

            if (!result) {

                this._setSavingEnabled(true); // Validation failed.
                this._showFeedback(true, "You didn't provide all necessary values. Please check again!", this.editModel.validate(this.editModel.attributes));
            }

            return result;
        },

        _setSavingEnabled: function(enabled) {

            if (enabled) {

                this.$('.action-buttons-container [data-action="save"]').removeClass('disabled');
                this.$('.base-sidebar [data-action="save"]').removeClass('disabled');
            }
            else {

                this.$('.action-buttons-container [data-action="save"]').addClass('disabled');
                this.$('.base-sidebar [data-action="save"]').addClass('disabled');
            }
        },

        _showFeedback: function(show, message, errors) {

            if (show) {

                if (!message)
                    message = 'Oops! Something went wrong.';

                this.$('.editor-feedback').text(message);

                this.$('[data-model-field]').removeClass('feedback-error');

                _.each(errors, _.bind(function(message, field) {

                    this.$('[data-model-field="' + field + '"]').addClass('feedback-error');

                }, this));
            }

            this.$('.editor-feedback').toggleClass('hidden', !show);
        },

        _updateScheduleButton: function() {

            // Display the schedule/unschedule button depending on the current status.

            var isScheduled = (this.editModel.get('status') === 'scheduled');

            this.$('.action-buttons-container [data-action="schedule"]').toggleClass('hidden', isScheduled);
            this.$('.action-buttons-container [data-action="unschedule"]').toggleClass('hidden', !isScheduled);

            this.$('.base-sidebar [data-action="schedule"]').toggleClass('hidden', isScheduled);
            this.$('.base-sidebar [data-action="unschedule"]').toggleClass('hidden', !isScheduled);

            // Change the schedule button's text if the update is to be posted immediately.

            var shouldBeScheduled = this.editModel.get('scheduledOnUtc') && !this.editModel.get('scheduledOnUtcIsImmediately');
            var text = shouldBeScheduled ? 'Schedule' : 'Post now';

            this.$('.action-buttons-container [data-action="schedule"]').attr('title', text);
            this.$('.action-buttons-container [data-action="schedule"] span').text(text);

            this.$('.base-sidebar [data-action="schedule"]').attr('title', text);
            this.$('.base-sidebar [data-action="schedule"] span').text(text);
        }
    });

})(Love);