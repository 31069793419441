(function(Love) {

    Love.Views.CommonDatePickerView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonDatePickerView',

        defaults: function() {

            return {

                callbacks: {},
                canSelectInPast: true,
                date: moment(),
                dateTimeRange: {

                    canLastAllDay: false,
                    isRange: false,
                    lastsAllDay: false,
                    secondValue: null
                }
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            if (!this.options.date || !this.options.date.isValid())
                this.options.date = moment();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_date_picker));

            var picker = new Pikaday({

                firstDay: 1,
                minDate: this.options.canSelectInPast ? null : moment().toDate(),
                onDraw: _.bind(this._showDateRange, this),
                onSelect: _.bind(function(date) {

                    date = moment(date);

                    if (this.options.dateTimeRange.secondValue) {

                        var diff = this.options.dateTimeRange.secondValue.diff(this.options.date);
                        var isRangeStart = this.options.date.isBefore(this.options.dateTimeRange.secondValue);

                        this.options.date.year(date.year());
                        this.options.date.dayOfYear(date.dayOfYear());

                        if (isRangeStart && this.options.dateTimeRange.secondValue) {

                            if (this.options.dateTimeRange.secondValue.isAfter(date))
                                this.options.dateTimeRange.secondValue = this.options.date.clone().add(diff, 'milliseconds');
                            else
                                this.options.dateTimeRange.secondValue = this.options.date.clone().add(diff, 'milliseconds');
                        }
                        else if (!isRangeStart && this.options.dateTimeRange.secondValue) {

                            if (date.isBefore(this.options.dateTimeRange.secondValue, 'day'))
                                this.options.dateTimeRange.secondValue.dayOfYear(date.dayOfYear());
                            else if (date.isSame(this.options.dateTimeRange.secondValue, 'day'))
                                this.options.dateTimeRange.secondValue.dayOfYear(date.dayOfYear());
                        }
                    }
                    else {

                        this.options.date.year(date.year());
                        this.options.date.dayOfYear(date.dayOfYear());
                    }

                    if (this.options.callbacks.onChange)
                        this.options.callbacks.onChange(this.options.date, this.options.dateTimeRange.secondValue);

                    if (this.options.dateTimeRange.secondValue)
                        this._showDateRange();

                }, this),
                defaultDate: this.options.date.clone().toDate(),
                setDefaultDate: true
            });

            // Pikaday registers a non-optional event listener to the whole document. This can easily cause errors.

            document.removeEventListener('keydown', picker._onKeyChange);

            this.$('.common-date-picker').html(picker.el);

            if (this.options.dateTimeRange.secondValue)
                this._showDateRange();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _showDateRange: function() {

            if (this.options.dateTimeRange && this.options.dateTimeRange.isRange && this.options.dateTimeRange.secondValue) {

                var monthText = this.$('.pika-select-month :selected').text();
                var yearText = this.$('.pika-select-year :selected').text();
                var shownMonth = moment(monthText + ' ' + yearText);
                var dayOfMonth;

                if (this.options.dateTimeRange.secondValue.isValid() && !(this.options.date.isSame(this.options.dateTimeRange.secondValue, 'day'))) {

                    if (shownMonth.isValid()) {

                        var isRangeStart = this.options.date.isBefore(this.options.dateTimeRange.secondValue, 'day');
                        var isRangeEnd = this.options.date.isAfter(this.options.dateTimeRange.secondValue, 'day');
                        var isShown = this.options.date.isSame(shownMonth, 'month');
                        var secondValueShown = this.options.dateTimeRange.secondValue.isSame(shownMonth, 'month');

                        for (dayOfMonth = 1; dayOfMonth < shownMonth.daysInMonth() + 1; dayOfMonth++) {

                            var day = shownMonth.clone().date(dayOfMonth);

                            this.$('.common-date-picker').find('td[data-day="' + dayOfMonth + '"]').removeClass('is-between-selected');
                            this.$('.common-date-picker').find('td[data-day="' + dayOfMonth + '"]').removeClass('is-start-date');
                            this.$('.common-date-picker').find('td[data-day="' + dayOfMonth + '"]').removeClass('is-end-date');

                            var isBetween = isRangeStart ? day.isBetween(this.options.date, this.options.dateTimeRange.secondValue, 'day') : day.isBetween(this.options.dateTimeRange.secondValue, this.options.date, 'day');

                            if (isBetween)
                                this.$('.common-date-picker').find('td[data-day="' + dayOfMonth + '"]').addClass('is-between-selected');
                        }

                        if (isRangeStart && isShown)
                            this.$('.common-date-picker').find('td[data-day="' + this.options.date.date() + '"]').addClass('is-start-date');
                        else if (isRangeEnd && isShown)
                            this.$('.common-date-picker').find('td[data-day="' + this.options.date.date() + '"]').addClass('is-end-date');

                        if (isRangeStart && secondValueShown)
                            this.$('.common-date-picker').find('td[data-day="' + this.options.dateTimeRange.secondValue.date() + '"]').addClass('is-end-date');
                        else if (isRangeEnd && secondValueShown)
                            this.$('.common-date-picker').find('td[data-day="' + this.options.dateTimeRange.secondValue.date() + '"]').addClass('is-start-date');
                    }
                }
                else if (this.options.dateTimeRange.secondValue.isValid() && (this.options.date.isSame(this.options.dateTimeRange.secondValue, 'day'))) {

                    for (dayOfMonth = 1; dayOfMonth < shownMonth.daysInMonth() + 1; dayOfMonth++) {

                        this.$('.common-date-picker').find('td[data-day="' + dayOfMonth + '"]').removeClass('is-between-selected');
                        this.$('.common-date-picker').find('td[data-day="' + dayOfMonth + '"]').removeClass('is-start-date');
                        this.$('.common-date-picker').find('td[data-day="' + dayOfMonth + '"]').removeClass('is-end-date');
                    }
                }
            }
        }
    });

})(Love);