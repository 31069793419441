(function(Love) {

    Love.Views.CommonNewRemixTopPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonNewRemixTopPopupView',

        className: 'base-popup popup-list-menu',
        id: '',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    items: null,
                    showMore: false,
                    type: ''
                }
            };
        },

        events: {

            'click [data-action="add"]': '_handleAdd',
            'click [data-action="more"]': '_handleMore'
        },

        initialize: function(options) {

            Love.Views.BasePopupView.prototype.initialize.call(this, options);

            if (!this.options.data.items)
                this.options.data.items = new Love.Collections.BookmarkCollection();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_new_remix_top)({

                items: this.options.data.items.map(function(item) { return item.attributes; }),
                showMore: this.options.data.showMore
            }));

            if (this.options.data.type === 'images')
                this.$('.popup-title').text('Newest images');

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleAdd: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm) {

                var model = this.options.data.items.findWhere({id: $(e.target).closest('[data-id]').attr('data-id')});
                this.options.callbacks.onConfirm({items: [{type: 'bookmark', model: model}]});
            }

            this.close();
        },

        _handleMore: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var popup;

            switch (this.options.data.type) {

                case 'widget': {

                    popup = new Love.Views.CommonContentNewWidgetRemixPopupView({

                        callbacks: {

                            onConfirm: this.options.callbacks.onConfirm
                        }
                    });

                    popup.showPopup();
                    break;
                }
                case 'images': {

                    popup = new Love.Views.CommonNewRemixImagePopupView({

                        callbacks: {

                            onConfirm: this.options.callbacks.onConfirm
                        }
                    });

                    popup.showPopup();
                    break;
                }
            }

            this.close();
        }
    });

})(Love);