(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'calendar': {

            'flairs': {

                // Based on IcoMoon free pack.

                'airplane': 'flight, airplane, airport, aircraft',
                'briefcase': 'work, working',
                'gift': 'gift, birthday, celebration',
                'mug': 'drink, drinks',
                'spoon-knife': 'lunch, lunches, luncheon, lunching, dinner, dinners, restaurant, restaurants, Family meal'
            },

            /*
             'flairsGoogle': {

             // Based on Googles custom icons.

             'art': 'painting, art workshop, sketching workshop, drawing workshop',
             'badminton': 'badminton',
             'baseball': 'baseball',
             'basketball': 'basketball',
             'bbq': 'bbq, barbecue, barbeque',
             'beer': 'beer, beers, Oktoberfest, October Fest, Octoberfest',
             'billiard': 'billiard',
             'bookclub': 'book club, reading',
             'bowling': 'bowling',
             'breakfast': 'breakfast, breakfasts, brunch, brunches',
             'camping': 'camping',
             'chinesenewyear': 'chinese new year, chinese new years, chinese new year\'s',
             'cinema': 'cinema, movies',
             'clean': 'cleaning, clean the house, clean the apartment, clean house, tidy up, vacuum clean, vacuum cleaning',
             'code': 'learn to code, coding time, hackathon, Rails Girls, Railsgirls, Hour of Code, Codecademy, Computer Science, Programming in Python, Web Programming, Programming in Java, Web Development',
             'coffee': 'coffee, coffees',
             'concert': 'concert, gig, concerts, gigs',
             'cycling': 'bicycle, cycling, bike, bicycles, bikes, Biking',
             'dancing': 'dance, dancing, dances',
             'dentist': 'dentist, dentistry, dental',
             'dinner': 'dinner, dinners, restaurant, restaurants, Family meal',
             'drinks': 'cocktail, drinks, cocktails',
             'golf': 'golf',
             'graduation': 'graduation',
             'gym': 'gym, workout, workouts',
             'haircut': 'haircut, hair',
             'halloween': 'halloween, helloween, hallowe\'en, Allhalloween, All Hallows\' Eve, All Saints\' Eve',
             'hiking': 'hiking, hike, hikes',
             'kayaking': 'kayaking',
             'learninstrument': 'piano, singing, music Class, choir practice, flute, orchestra, oboe, clarinet, saxophone, cornett, trumpet, contrabass, cello, trombone, tuba, music ensemble, string quartett, guitar lesson, classical music, choir',
             'learnlanguage': 'French Course, German Course, English Course, French Class, German Class, English Class, Practice French, Practice German, Practice English',
             'lunch': 'lunch, lunches, luncheon',
             'manicure': 'manicure, pedicure, manicures, pedicures',
             'massage': 'massage, back rub, backrub, massages',
             'newyear': 'new year, new year\'s, new years',
             'oilchange': 'oil change, car service',
             'pingpong': 'ping pong, table tennis, ping-pong, pingpong',
             'planmyday': 'plan week, plan quarter, plan day, plan vacation, week planning, vacation planning',
             'pride': 'dyke march, christopher street day, gay parade, gay pride, gayglers, gaygler, lesbian march, lesbian parade, lesbian pride, euro pride, europride, world pride, worldpride',
             'reachout': 'reach out to, write letter, send invitations',
             'read': 'reading, newspaper',
             'repair': 'fridge repair, handyman, electrician, DIY',
             'running': 'jog, jogging, running, jogs, runs',
             'sailing': 'sail, sailing, boat cruise, sailboat',
             'santa': 'Santa Claus, Father Christmas',
             'skiing': 'skiing, ski, skis, Snowboarding, snowshoeing, snow shoe, snow boarding',
             'soccer': 'soccer',
             'swimming': 'swim, swimming, swims',
             'tennis': 'tennis',
             'thanksgiving': 'thanksgiving',
             'violin': 'violin, violins',
             'walk': 'going for a walk, walking',
             'wedding': 'wedding, wedding eve, wedding-eve party, weddings',
             'xmas': 'christmas, xmas, x-mas',
             'xmasmeal': 'christmas dinner, christmas lunch, christmas brunch, christmas luncheon, xmas lunch, xmas luncheon, x-mas dinner, x-mas lunch, x-mas brunch, x-mas luncheon, christmas eve dinner, christmas eve lunch, christmas eve brunch, christmas eve luncheon, xmas eve dinner, xmas eve lunch, xmas eve brunch, xmas eve luncheon, x-mas eve dinner, x-mas eve lunch, x-mas eve brunch, x-mas eve luncheon',
             'xmasparty': 'christmas party, xmas party, x-mas party, christmas eve party, xmas eve party, x-mas eve party',
             'yoga': 'yoga'
             },
             */

            'sidebar': {

                'authors': 'Showing posts by <em>{{value}}</em>.',
                'authors_all': 'Showing posts by <em>all authors</em>.',
                'authors_plural': 'Showing posts by <em>{{count}} authors</em>.',
                'calendars': '<em>{{value}}</em> is displayed.',
                'calendars_all': '<em>All calendars</em> are displayed.',
                'calendars_plural': '<em>{{count}} calendars</em> are displayed.',
                'categories': '<em>{{value}}</em> is displayed.',
                'categories_all': '<em>All categories</em> are displayed.',
                'categories_plural': '<em>{{count}} categories</em> are displayed.',
                'storylines': '<em>{{value}}</em> is displayed.',
                'storylines_all': '<em>All storylines</em> are displayed.',
                'storylines_plural': '<em>{{count}} storylines</em> are displayed.',
                'types': 'This schedule shows <em>{{value}}</em>.',
                'types_all': 'This schedule shows <em>all types</em>.',
                'types_plural': 'This schedule shows <em>{{count}} types</em>.'
            }
        }
    });

})(Love);