(function(Love) {

    Love.Views.CalendarEventInstagramPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarEventInstagramPopupView',

        className: 'base-popup popup-calendar popup-calendar-event popup-clean popup-size-auto',
        id: 'popup-calendar-event-instagram',

        defaultsForPopupType: function() {

            return {

                data: {

                    calendarEventModel: {}
                },
                enableClickInterception: false
            };
        },

        events: {

            'click [data-action="view"]': '_handleViewClick'
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.calendar_popups_event_instagram)(this.options.data.calendarEventModel.attributes));

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleViewClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var context = this.options.data.calendarEventModel.get('content');

            if (!_.isEmpty(context.info.externalURL)) {

                this.close();

                var win = window.open(context.info.externalURL, '_blank');
                win.focus();
            }
        }
    });

})(Love);