(function(Love) {

    Love.Views.CommonDateTimePickerView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonDateTimePickerView',

        defaults: function() {

            return {

                callbacks: {},
                canSelectInPast: true,
                date: moment(),
                dateTimeRange: {

                    canLastAllDay: false,
                    isRange: false,
                    lastsAllDay: false,
                    secondValue: null
                },
                resetText: 'Reset',
                showClose: true,
                showReset: false
            };
        },

        events: {

            'click [data-action="close"]': '_handleCloseClick',
            'click [data-action="reset"]': '_handleResetClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            if (!this.options.date || !this.options.date.isValid())
                this.options.date = moment();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_datetime_picker)(this.options));

            this.datePickerView = this.addSubView(new Love.Views.CommonDatePickerView({

                callbacks: {

                    onChange: _.bind(function(date, dateTimeRange) {

                        this.options.date.year(date.year());
                        this.options.date.dayOfYear(date.dayOfYear());

                        if (this.options.callbacks.onChange)
                            this.options.callbacks.onChange(this.options.date, this.options.dateTimeRange);

                    }, this)
                },
                date: this.options.date,
                dateTimeRange: this.options.dateTimeRange,
                canSelectInPast: this.options.canSelectInPast

            }));
            this.datePickerView.setElement('.common-date-picker-container').render();

            this.timePickerView = this.addSubView(new Love.Views.CommonTimePickerView2({

                callbacks: {

                    onChange: _.bind(function(time, dateTimeRange) {

                        this.options.date.hours(time.hours());
                        this.options.date.minutes(time.minutes());
                        this.options.date.seconds(time.seconds());
                        this.options.date.milliseconds(time.milliseconds());

                        if (this.options.dateTimeRange.isRange) {

                            this.options.dateTimeRange.secondValue.dayOfYear(dateTimeRange.secondValue.dayOfYear());
                            this.options.dateTimeRange.secondValue.hours(dateTimeRange.secondValue.hours());
                            this.options.dateTimeRange.secondValue.minutes(dateTimeRange.secondValue.minutes());
                            this.options.dateTimeRange.secondValue.seconds(dateTimeRange.secondValue.seconds());
                            this.options.dateTimeRange.secondValue.milliseconds(dateTimeRange.secondValue.milliseconds());
                        }

                        if (this.options.dateTimeRange.canLastAllDay)
                            this.options.dateTimeRange.lastsAllDay = dateTimeRange.lastsAllDay;

                        if (this.options.callbacks.onChange)
                            this.options.callbacks.onChange(this.options.date, this.options.dateTimeRange);

                    }, this)
                },
                dateTimeRange: this.options.dateTimeRange,
                time: this.options.date
            }));
            this.timePickerView.setElement(this.$('.common-time-picker-container')).render();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _handleCloseClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onClose)
                this.options.callbacks.onClose(this.options.date, this.options.dateTimeRange);
        },

        _handleResetClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onReset)
                this.options.callbacks.onReset();
        }
    });

})(Love);