(function(Love) {

    Love.Views.CommonEditImagePopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonEditImagePopupView',

        className: 'base-popup popup-centered',
        id: 'popup-common-edit-image',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    addImageMode: false,
                    imageModel: new Love.Models.ImageModel()
                }
            };
        },

        events: {

            'click [data-action="confirm"]': '_handleConfirm',
            'click [data-action="close"]': '_handleClose'
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_edit_image)(_.extend({

                addImageMode: this.options.data.addImageMode

            }, this.options.data.imageModel.attributes)));

            this.imageUploadView = this.addSubView(new Love.Views.CommonImageUploadView({

                callbacks: {

                    onChange: _.bind(this._handleImageChange, this)
                },
                imageModel: this.options.data.imageModel,
                parentPopupView: this
            }));
            this.imageUploadView.setElement(this.$('.common-image-upload-container')).render();

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var imageModel = this.options.data.imageModel;
            var urlValue = this.$('.common-image-upload input[name="url"]').val();

            if (_.isEmpty(imageModel.getImageView()) && !_.isEmpty(urlValue)) {

                imageModel.set({

                    imageSource: 'url',
                    imageUpload: {},
                    imageURL: urlValue
                });
            }

            imageModel.set({

                imageLink: this.$('input[name="link"]').val(),
                imageTitle: this.$('input[name="title"]').val(),
                imageCopyright: this.$('input[name="copyright"]').val(),
                imageCopyrightUrl: this.$('input[name="copyrightUrl"]').val()
            });

            this.close();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm(imageModel);
        },

        _handleImageChange: function(imageModel) {

            imageModel.set({

                imageLink: this.$('input[name="link"]').val(),
                imageTitle: this.$('input[name="title"]').val(),
                imageCopyright: this.$('input[name="copyright"]').val(),
                imageCopyrightUrl: this.$('input[name="copyrightUrl"]').val()
            });

            this.options.data.imageModel = imageModel;
        }
    });

})(Love);