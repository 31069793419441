(function(Love) {

    Love.Enums.ContentSetOffsets = {

        'Last item': 0,
        '1st to last': 1,
        '2nd to last': 2,
        '3rd to last': 3,
        '4th to last': 4,
        '5th to last': 5,
        '6th to last': 6,
        '7th to last': 7,
        '8th to last': 8,
        '9th to last': 9
    };

})(Love);