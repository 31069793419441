(function(Love) {

    Love.Views.DashboardPopularRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.DashboardPopularRowView',

        defaults: function() {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true,
                layoutBlock: true,
                selectable: false,
                selected: false
            });
        },

        renderItem: function() {

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    externalUrl: this.options.item.getExternalUrl(),
                    isLoading: _.isEmpty(this.options.item.id)
                })
            });

            this.$el.html(_.template(Love.Templates.dashboard_modules_popular_row)(context));

            this.listenTo(this, 'remix', function(e) {

                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/new/remix/url/' + encodeURIComponent(this.options.item.getExternalUrl()), {trigger: true});
            });
        }
    });

})(Love);