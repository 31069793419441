(function(Love) {

    Love.Views.CommonContentContentWidgetView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentContentWidgetView',

        defaults: function() {

            return {

                settings: null
            };
        },

        events: {

            'input [name="type"]': '_handleTypeChange',
            'input [name="type_id"]': '_handleTypeIdChange',
            'input [name="type_offset"]': '_handleTypeOffsetChange'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'hasContent');
        },

        render: function() {

            $.when(Love.session.get('currentSite').getData('pages')).done(_.bind(function(pages) {

                this.$el.html(_.template(Love.Templates.common_content_widgets_content)(_.extend(_.clone(this.options.settings), {

                    viewOptions: {

                        pages: pages.map(function(value) {

                            return _.extend(_.clone(value.attributes), {

                                title: value.get('revisions')[value.getDefaultRevision()].title
                            });
                        })
                    }
                })));

                this.trigger('rendered');

            }, this));

            return Love.Views.BaseView.prototype.render.call(this, true); // We'll trigger the rendered event later.
        },

        hasContent: function() {

            return true;
        },

        _handleTypeChange: function() {

            this.options.settings.type = this.$('[name="type"]').val();
            this._raiseChanged();

            this.render();
        },

        _handleTypeIdChange: function() {

            this.options.settings.type_id = this.$('[name="type_id"]').val();
            this._raiseChanged();
        },

        _handleTypeOffsetChange: function() {

            this.options.settings.type_offset = this.$('[name="type_offset"]').val();
            this._raiseChanged();
        },

        _raiseChanged: function() {

            this.trigger('changed', {settings: this.options.settings});
        }
    });

})(Love);