(function(Love) {

    Love.Helpers.Tabs = {

        initTabs: function(element, changeHandler) {

            if (element.length > 0) {

                element.find('[data-open-tab]').on('click', function(e) {

                    if (e && e.preventDefault) e.preventDefault();
                    Love.Helpers.Tabs.setActiveTab($(this), changeHandler);
                });
            }
        },

        setActiveTab: function(newTab, changeHandler) {

            var tabs = newTab.closest('.tabs');
            var current = tabs.find('[data-tab].active').attr('data-tab');

            if (tabs.length > 0) {

                tabs.find('[data-open-tab]').closest('li').removeClass('active');
                $(newTab).closest('li').addClass('active');

                var tabPane = newTab.attr('data-open-tab');

                if (tabPane) {

                    tabs.find('[data-tab]').removeClass('active');
                    tabs.find('[data-tab="' + tabPane + '"]').addClass('active');
                }

                if (changeHandler)
                    changeHandler({

                        oldTab: current,
                        newTab: tabPane
                    });
            }
        },

        unloadTabs: function(element) {

            element.find('[data-open-tab]').off('click');
        }
    };

})(Love);