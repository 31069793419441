(function(Love) {

    Love.Views.CommonLocationPicker = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonLocationPicker',

        defaults: function() {

            return {

                apiKey: 'AIzaSyCx25Up6NGJJjWKq6PDUAg9dbHv9iuMlf8',
                enableControls: true,
                locationString: '',
                locationStringGiven: false,
                position: {lat: null, lng: null, isDefault: false},
                positionGiven: true,
                useInput: true
            };
        },

        defaultPosition: function() {

            return {

                lat: 52.364862, // Mobypicture latitude.
                lng: 4.902297, // Mobypicture longtitude.
                isDefault: true
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.options.locationStringGiven = !_.isEmpty(this.options.locationString);
            this.options.positionGiven = (this.options.position && !_.isNull(this.options.position.lat) && !_.isNull(this.options.position.lng));

            if (!this.options.positionGiven) {

                this.options.position = this.defaultPosition();
                //this.options.positionGiven = true;
            }

            this.geocoder = new google.maps.Geocoder();
            this.latLng = new google.maps.LatLng(this.options.position.lat, this.options.position.lng);

            this.map = null;
            this.marker = null;
            this.autocomplete = null;
            this.service = null;
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_location_picker)());

            if (!this.options.useInput)
                this.$('.google-map-inputs').remove();

            this._initMap();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            /*google.maps.event.removeListener(this.marker, 'dragend', function() {
             this._getAddressFromPosition(self.marker.getPosition());
             });*/

            if (this.autocomplete) {

                try {

                    if ($(this.autocomplete.gm_accessors_.place.Rd.gm_accessors_.input.Rd.ta))
                        $(this.autocomplete.gm_accessors_.place.Rd.gm_accessors_.input.Rd.ta).remove(); //TODO: Sort of a hack, removes autocomplete container on closing popup
                }
                catch (ex) {

                    console.log(ex);
                }
            }
        },

        setLocation: function(location) {

            if (_.isString(location))
                return this.setLocationFromString(location);

            this.marker.setPosition(location);
            this.latLng = location;
            this.marker.setVisible(false);
            this.map.setCenter(location);

            this.options.position = location;
            this.options.position.isDefault = false;

            this.options.positionGiven = true;
        },

        setLocationFromString: function(locationString, _updateAddressInput) {

            if (_updateAddressInput)
                this._updateAddressInput(locationString);

            if (_.isEmpty(locationString)) return;

            var self = this;
            this.service.textSearch({query: locationString}, function(results, status) {

                if (status === google.maps.places.PlacesServiceStatus.OK) {

                    var place = results[0];
                    self._markPlace(place);
                }
            });

            this.options.locationString = locationString;
            this.options.locationStringGiven = true;
        },

        _initMap: function() {

            var self = this;

            var mapOptions = {

                center: this.latLng,
                clickableIcons: false,
                mapTypeControl: false,
                navigationControl: false,
                scrollwheel: false,
                streetViewControl: false,
                zoom: 15,
                zoomControl: this.options.enableControls ? true : false
            };

            this.map = new google.maps.Map(this.$('.google-map')[0], mapOptions);

            var markerOptions = {

                draggable: this.options.enableControls ? true : false,
                map: this.map,
                position: this.latLng,
                title: this.options.enableControls ? 'Drag me!' : ''
            };

            this.marker = new google.maps.Marker(markerOptions);
            this.marker.setVisible(false);

            if (navigator.geolocation && !this.options.locationStringGiven && !this.options.positionGiven) { // Only if the browser supports checking the users location and there's no position.

                navigator.geolocation.getCurrentPosition(function(position) {

                    if (self.options.locationStringGiven || self.options.positionGiven) return; // The position might have been set in the mean time.

                    self.latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

                    self.map.setCenter(self.latLng);
                    self.marker.setPosition(self.latLng);
                    self.marker.setVisible(true);

                }, function() {});
            }

            google.maps.event.addListener(this.marker, 'dragend', function() {

                self._getAddressFromPosition(self.marker.getPosition());
            });

            google.maps.event.addListener(this.map, 'click', function(event) {

                self.marker.setPosition(event.latLng);
                self.marker.setVisible(true);
                self._getAddressFromPosition(event.latLng);
            });

            this.autocomplete = new google.maps.places.Autocomplete(this.$('input[name="address"]')[0]);
            this.autocomplete.bindTo('bounds', this.map);
            this.autocomplete.addListener('place_changed', function() {self._autoComplete(self);});

            this.service = new google.maps.places.PlacesService(this.map);

            if (this.options.locationStringGiven)
                this.setLocationFromString(this.options.locationString);

            if (this.options.positionGiven)
                this._getAddressFromPosition(this.marker.getPosition(), this.options.position.isDefault);

            google.maps.event.trigger(this.map, 'resize');
        },

        _autoComplete: function(self) {

            self.marker.setVisible(false);

            var place = self.autocomplete.getPlace();

            if (!place.geometry) {

                //First try for an address.

                self.geocoder.geocode({'address': self.$('input[name="address"]').val()}, function(results, status) {

                    if (status == google.maps.GeocoderStatus.OK) {

                        place = results[0];

                        var lat = place.geometry.location.lat(),
                            lng = place.geometry.location.lng();

                        self.latLng = new google.maps.LatLng(lat, lng);

                        self._markPlace(place);
                        self._raiseLocationChanged(self.latLng, self._getAddressFieldsFromReverseGeoResponse(place));

                    }
                    else {

                        var result = self._useFirstResult(self);

                        // Last resort; incomplete data.

                        if (!result) {

                            self.service.textSearch({query: self.$('input[name="address"]').val()}, function(results, status) {

                                if (status === google.maps.places.PlacesServiceStatus.OK) {

                                    var place = results[0];

                                    self._markPlace(place);
                                    self._raiseLocationChanged(self.latLng, self._getAddressFieldsFromReverseGeoResponse(place));
                                }
                            });
                        }
                    }
                });

                return;
            }

            // If the place has a geometry, then present it on a map.

            if (place.geometry.viewport)
                self.map.fitBounds(place.geometry.viewport);
            else
                self.map.setZoom(15);

            self._markPlace(place);
            self._raiseLocationChanged(self.latLng, self._getAddressFieldsFromReverseGeoResponse(place));
        },

        _getAddressFromPosition: function(position, noTrigger) {

            var self = this;
            this.geocoder.geocode({latLng: position}, function(responses) {

                if (responses && responses.length > 0) {

                    if (!noTrigger || _.isEmpty(self.$('input[name="address"]').val()))
                        self._updateAddressInput(responses[0].formatted_address);

                    if (!noTrigger)
                        self._raiseLocationChanged(position, self._getAddressFieldsFromReverseGeoResponse(responses[0]));
                }
                else {

                    self._updateAddressInput('');

                    if (!noTrigger)
                        self._raiseLocationChanged(position, null);
                }
            });
        },

        _getAddressFieldsFromReverseGeoResponse: function(place) {

            var result = {

                number: '',
                street: '',
                zip: '',
                city: '',
                country: '',
                venue: ''
            };

            if (!place)
                return result;

            // Get street, zip code, city, country and possible venue.

            if (place.address_components) {

                place.address_components.forEach(function(el) {

                    if (el.types.indexOf('street_number') !== -1)
                        result.number = el.long_name;

                    if (el.types.indexOf('route') !== -1)
                        result.street = el.long_name;

                    if (el.types.indexOf('locality') !== -1)
                        result.city = el.long_name;

                    if (el.types.indexOf('country') !== -1)
                        result.country = el.long_name;

                    if (el.types.indexOf('postal_code') !== -1)
                        result.zip = el.long_name;
                });

                if (result.number)
                    result.street += ' ' + result.number;

                if (place.name && place.name !== result.street)
                    result.venue = place.name;
            }

            // No street found?

            if (!result.street) {

                // Get street from formatted address.

                if (place.formatted_address)
                    result.street = place.formatted_address.substr(0, place.formatted_address.indexOf(','));
            }

            return result;
        },

        _markPlace: function(place) {

            this.latLng = place.geometry.location;

            this.map.setCenter(place.geometry.location);

            this.marker.setPosition(place.geometry.location);
            this.marker.setVisible(true);
        },

        _raiseLocationChanged: function(latLng, address) {

            this.trigger('locationChanged', {

                latLng: latLng,
                address: address
            });
        },

        _updateAddressInput: function(address) {

            this.$('input[name="address"]').toggleClass('placeholder', _.isEmpty(address));
            this.$('input[name="address"]').val(address);
        },

        _useFirstResult: function(self) {

            $('.pac-container').hide();

            var firstResult = $('.pac-container .pac-item:first').text();
            var place = null;

            this.geocoder.geocode({'address': firstResult}, function(results, status) {

                if (status == google.maps.GeocoderStatus.OK) {

                    place = results[0];

                    var lat = place.geometry.location.lat(),
                        lng = place.geometry.location.lng();

                    //placeName = results[0].address_components[0].long_name;

                    self.latLng = new google.maps.LatLng(lat, lng);

                    self._markPlace(place);
                    self._raiseLocationChanged(self.latLng, self._getAddressFieldsFromReverseGeoResponse(place));

                    //self.$('input[name="address"]').val(firstResult);
                }
            });

            return place;
        }
    });

})(Love);