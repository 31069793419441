(function(Love) {

    Love.Views.BaseSidebarView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.BaseSidebarView',

        defaults: function() {

            return {

                becomeSticky: true,
                isOpen: true
            };
        },

        events: {

            'click .sidebar-image': '_handleSidebarImageClick',
            'click .sidebar-row:not(.sidebar-toggle) a': '_handleSidebarClick',
            'click .sidebar-row': '_handleSidebarRowClick',
            'click .sidebar-button, .button': '_handleSidebarClick',
            'click [data-action="toggle-dropdown"]': '_handleSidebarDropdownToggle',
            'click .sidebar-dropdown ul [data-value]': '_handleSidebarDropdownListClick',
            'click .sidebar-toggle': '_handleSidebarToggle',
            'dragover .sidebar-image:not(.common-image-upload-container)': function(e) {e.preventDefault();},
            'drop .sidebar-image:not(.common-image-upload-container)': '_handleDrop'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this._handleScroll = _.bind(this._handleScroll, this);
            this._handleWindowResize = _.bind(this._handleWindowResize, this);
        },

        render: function(dontTrigger) {

            // The base sidebar has no template to render. It's up to its children.

            if (this.options.isOpen)
                this.openSidebar();

            if (this._isMobile())
                this.closeSidebar(); // Close the sidebar by default on mobile.

            $('#common-header [data-menu-item="menu-sidebar-toggle"]').removeClass('hidden');

            $(window).off('scroll', this._handleScroll);
            $(window).on('scroll', this._handleScroll);

            $(window).off('resize', this._handleWindowResize);
            $(window).on('resize', this._handleWindowResize);

            this._unregisterCustomEvents();
            this._registerCustomEvents();

            return Love.Views.BaseView.prototype.render.call(this, dontTrigger);
        },

        onClose: function() {

            // Undelegate custom events when removing the view.

            $(window).off('scroll', this._handleScroll);
            $(window).off('resize', this._handleWindowResize);

            this._unregisterCustomEvents();
        },

        closeSidebar: function() {

            this.$('.base-sidebar').closest('.base-sidebar-container').removeClass('open');

            // Close any dropdowns in the sidebar.

            this._closeDropdowns();

            // Re-enable background scrolling.

            if (this._isMobile()) {

                Love.Helpers.Scrolling.enableScroll($(window));
                Love.Helpers.Scrolling.enableScroll($('#page-content *:not(.base-sidebar-container)'));
            }
        },

        closeSidebarDropdown: function(dropdownElement) {

            this.toggleDropdown(dropdownElement, false);
        },

        getDropdownListItems: function(listElement) {

            var items = listElement.find('li');

            return _.map(items, function(value) {

                value = $(value);

                return {

                    id: value.attr('data-id'),
                    value: value.attr('data-value'),
                    selected: value.hasClass('selected')
                };
            });
        },

        openSidebar: function() {

            this.$('.base-sidebar').closest('.base-sidebar-container').addClass('open');

            this.performDotDotDotUpdate();
            this.performLazyLoadImagesResize();

            if (this._isMobile()) {

                Love.Helpers.Scrolling.disableScroll($(window));
                Love.Helpers.Scrolling.disableScroll($('#page-content *:not(.base-sidebar-container)'));
            }
        },

        openSidebarDropdown: function(dropdownElement) {

            this.toggleDropdown(dropdownElement, true);
        },

        toggle: function() {

            if (this._isOpen())
                this.closeSidebar();
            else
                this.openSidebar();

            this.trigger('toggle', {isOpen: this._isOpen()});
        },

        toggleDropdown: function(dropdownElement, forceOpen) {

            // Open the sidebar if it wasn't opened yet.

            this.openSidebar();

            // Close any other dropdowns.

            var toggler = $(dropdownElement).closest('.sidebar-row').find('.sidebar-dropdown-toggle');
            var dropdown = $(dropdownElement).closest('.sidebar-row').find('.sidebar-dropdown');

            this.$('.sidebar-dropdown-toggle').not(toggler).removeClass('open');
            this.$('.sidebar-dropdown').not(dropdown).removeClass('open');

            // Toggle the nearest dropdown menu.

            toggler.toggleClass('open', forceOpen);
            dropdown.toggleClass('open', forceOpen);

            // Bring it into view.

            $(dropdownElement).closest('.sidebar-row')[0].scrollIntoView(false);

            // Raise an event indicating the toggled dropdown menu.

            this.trigger('toggleDropdown', {

                element: dropdown,
                isOpen: dropdown.hasClass('open')
            });
        },

        _closeDropdowns: function() {

            this.$('.sidebar-dropdown-toggle').removeClass('open');
            this.$('.sidebar-dropdown').removeClass('open');
        },

        _handleDragEnter: function(e) {

            var feedback = this.$('.sidebar-image:not(.common-image-upload-container) .hover-feedback');

            feedback.css('opacity', 1);

            feedback.find('.icon').removeClass('icon-image');
            feedback.find('.icon').removeClass(Love.Helpers.DragDrop.getHeartsIconClasses().join(' '));

            var amount = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            feedback.find('.icon').addClass(Love.Helpers.DragDrop.getHeartsIconClass(amount));

            var display = Love.t('common:dragdrop.feedback_drop', {count: amount});

            feedback.find('.text').text(display);
        },

        _handleDragLeave: function(e) {

            var feedback = this.$('.sidebar-image:not(.common-image-upload-container) .hover-feedback');

            feedback.css('opacity', 0);

            _.delay(_.bind(function() {

                // Execute this code after the opacity transition has been completed.

                feedback.find('.text').text('Nice cover image!');

                feedback.find('.icon').removeClass(Love.Helpers.DragDrop.getHeartsIconClasses().join(' '));
                feedback.find('.icon').addClass('icon-image');

            }, this), Love.Constants.CSS_COMMON_MIXIN_TRANSITION_MILD);
        },

        _handleDragEnterImage: function(e) {

            var display = Love.t('common:dragdrop.feedback_drop_close', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.sidebar-image:not(.common-image-upload-container) .hover-feedback .text').text(display);
        },

        _handleDragLeaveImage: function(e) {

            var display = Love.t('common:dragdrop.feedback_drop', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.sidebar-image:not(.common-image-upload-container) .hover-feedback .text').text(display);
        },

        _handleDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault(); // Default is to open as link on drop.

            var count = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            if (count > 0) {

                var self = this;
                var promises = [];
                var images = [];

                _.each(Love.Helpers.DragDrop.getDataItems(e.originalEvent), function(item, index) {

                    var dataType = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent, index);

                    if (dataType === 'image') {

                        var reader = new FileReader();
                        var deferred = $.Deferred();

                        reader.addEventListener('load', function() {

                            var imageModel = new Love.Models.ImageModel({

                                imageSource: 'url',
                                imageURL: reader.result
                            });

                            images.push(imageModel);
                            deferred.resolve();

                        }, false);

                        reader.addEventListener('abort', function() { deferred.reject(); });
                        reader.addEventListener('error', function() { deferred.reject(); });

                        promises[index] = deferred.promise();
                        reader.readAsDataURL(item.getAsFile());
                    }

                }, this);

                $.whenAll.apply($, promises).always(function() {

                    self.trigger('imagesDropped', {

                        target: $(e.target).closest('.sidebar-image'),
                        images: images
                    });
                });
            }
        },

        _handleScroll: function() {

            if (this.options.becomeSticky) {

                var containerPosition = this.$('.base-sidebar').closest('.base-sidebar-container').offset().top;
                var windowHeight = $(window).height();
                var headerHeight = $('#common-header').height();
                var contentHeight = windowHeight - headerHeight;

                var shouldBeSticky = ($(window).scrollTop() > containerPosition - headerHeight + 10); // + 10 offset voor vreemde bug die alleen Bram had.

                // We cannot make something sticky if it's larger than the view area. Otherwise it would have to be scrollable as well.

                shouldBeSticky = (shouldBeSticky && this.$('.base-sidebar').height() <= contentHeight);

                if (shouldBeSticky) {

                    if (!this.$('.base-sidebar').hasClass('sticky')) {

                        this.$('.base-sidebar').addClass('sticky');
                        this.$('.base-sidebar').css('top', headerHeight);
                    }
                }
                else
                    this.$('.base-sidebar').removeClass('sticky');
            }
        },

        _handleSidebarClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (!this._isOpen())
                this._handleSidebarToggle(e);
        },

        _handleSidebarDropdownListClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            // Checkbox lists need toggling, radio and image lists need uniqueness.

            if (element.closest('ul').attr('data-mode') === 'checkboxes') {

                element.toggleClass('selected');
                element.find('.icon').toggleClass('icon-checkbox-checked icon-checkbox-unchecked');
            }
            else {

                element.closest('ul').find('li').removeClass('selected');
                element.closest('ul').find('.radio .icon').removeClass('icon-radio-checked').addClass('icon-radio-unchecked');
                element.addClass('selected');
                element.find('.radio .icon').removeClass('icon-radio-unchecked').addClass('icon-radio-checked');
            }
        },

        _handleSidebarDropdownToggle: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.toggleDropdown($(e.target));
        },

        _handleSidebarImageClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            // Open the sidebar if it wasn't opened yet.

            this.$('.base-sidebar').closest('.base-sidebar-container').addClass('open');
        },

        _handleSidebarRowClick: function(e) {

            // Try to focus on any input that wants that.

            $(e.target).closest('.sidebar-row').find('.sidebar-wants-focus:first').focus();
        },

        _handleSidebarToggle: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.toggle();
        },

        _handleWindowResize: function() {

            this._handleScroll();
        },

        _isMobile: function() {

            return ($(window).width() <= 1024); // Breakpoint matches views/base/sidebar.scss.
        },

        _isOpen: function() {

            return this.$('.base-sidebar').closest('.base-sidebar-container').hasClass('open');
        },

        _registerCustomEvents: function() {

            _.bindAll(this, '_handleDragEnter', '_handleDragEnterImage');
            _.bindAll(this, '_handleDragLeave', '_handleDragLeaveImage');

            Love.Helpers.DragDrop.registerEnterLeaveEvents($('body'), this.cid, this._handleDragEnter, this._handleDragLeave);
            Love.Helpers.DragDrop.registerEnterLeaveEvents(this.$('.sidebar-image:not(.common-image-upload-container)'), this.cid, this._handleDragEnterImage, this._handleDragLeaveImage);
        },

        _unregisterCustomEvents: function() {

            Love.Helpers.DragDrop.unregisterEnterLeaveEvents($('body'), this.cid);
            Love.Helpers.DragDrop.unregisterEnterLeaveEvents(this.$('.sidebar-image:not(.common-image-upload-container)'), this.cid);
        }
    });

})(Love);