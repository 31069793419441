(function (Love) {

    Love.Views.DashboardScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.DashboardScreenView',

        initialize: function (options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            //this.analyticsViewsView = this.addSubView(new Love.Views.DashboardAnalyticsViewsModuleView());
            this.analyticsViewsTotalView = this.addSubView(new Love.Views.DashboardAnalyticsViewsTotalModuleView());
            this.calendarView = this.addSubView(new Love.Views.DashboardCalendarModuleView());
            this.contentListsView = this.addSubView(new Love.Views.DashboardContentListsModuleView());
            //this.mentionsView = this.addSubView(new Love.Views.DashboardMentionsModuleView());
            this.newsView = this.addSubView(new Love.Views.DashboardNewsModuleView());
            //this.popularView = this.addSubView(new Love.Views.DashboardPopularModuleView());
            //this.remixView = this.addSubView(new Love.Views.DashboardRemixModuleView());
            //this.socialView = this.addSubView(new Love.Views.DashboardSocialModuleView());
            this.upcomingView = this.addSubView(new Love.Views.DashboardUpcomingModuleView());
        },

        render: function () {

            this.$el.html(_.template(Love.Templates.dashboard_screen));

            //this.analyticsViewsView.setElement(this.$('.module-container.analytics-views')).render();
            this.analyticsViewsTotalView.setElement(this.$('.module-container.analytics-views-total')).render();
            this.calendarView.setElement(this.$('.module-container.calendar')).render();
            this.contentListsView.setElement(this.$('.module-container.content-lists')).render();
            //this.mentionsView.setElement(this.$('.module-container.mentions')).render();
            this.newsView.setElement(this.$('.module-container.news')).render();
            //this.popularView.setElement(this.$('.module-container.popular')).render();
            //this.remixView.setElement(this.$('.module-container.remix')).render();
            //this.socialView.setElement(this.$('.module-container.social')).render();
            this.upcomingView.setElement(this.$('.module-container.upcoming')).render();

            var self = this;

            // Keep the dates between the calendar and upcoming views synchronized.

            this.stopListening(this.calendarView, 'dateChanged');
            this.listenTo(this.calendarView, 'dateChanged', function (e) {

                if (self.upcomingView) {

                    self.upcomingView.options.date = e.newDate.clone().add(1, 'days');
                    self.upcomingView.render();
                }
            });

            this.stopListening(this.upcomingView, 'dateChanged');
            this.listenTo(this.upcomingView, 'dateChanged', function (e) {

                if (self.calendarView) {

                    self.calendarView.options.date = e.newDate.clone().add(-1, 'days');
                    self.calendarView.render();
                }
            });

            return Love.Views.BaseScreenView.prototype.render.call(this);
        }
    });

})(Love);