(function(Love) {

    Love.Views.PostEditorView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.PostEditorView',

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.editModel = this.options.editModel;
            this.viewState = this.options.viewState;

            this._widgetViews = [];

            _.bindAll(this,

                '_clearExistingFragments',
                '_deleteExistingFragment',
                '_insertFragment',
                '_handleRevisionsChanged',
                '_moveExistingFragment',
                '_renderRevisionFragments',
                '_renderAllFragments',
                '_renderAllToolbars',
                '_renderExistingFragment'
            );
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.posts_post_editor));
            this._renderRevisionFragments(this.editModel, this.editModel.get('revisions')[this.viewState.get('activeRevision')], {});

            this.stopListening(this.editModel, 'change:revisions', this._handleRevisionsChanged);
            this.listenTo(this.editModel, 'change:revisions', this._handleRevisionsChanged);

            this.stopListening(this.viewState, 'change:activeRevision', this.render);
            this.listenTo(this.viewState, 'change:activeRevision', this.render);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _clearExistingFragments: function() {

            _.each(this._widgetViews, function(view) {

                view.close();
                view = null;
            });

            this._widgetViews = [];
            this.$('.post-editor').empty();
        },

        _deleteExistingFragment: function(index) {

            this._widgetViews[index].close();
            this._widgetViews[index].remove();
            this._widgetViews.splice(index, 1);
        },

        _insertFragment: function(model, revision, index) {

            var fragment = revision.fragments[index];

            var widgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                fragment: fragment,
                isNew: true,
                totalWidgets: this.editModel.get('revisions')[this.viewState.get('activeRevision')].fragments.length
            }));
            this._widgetViews.splice(index, 0, widgetView);

            if (index > 0)
                this.$('.post-editor .widget-container').eq(index - 1).after(widgetView.render().$el);
            else
                this.$('.post-editor').prepend(widgetView.render().$el);

            this._registerWidgetEvents(widgetView);
        },

        _handleRevisionsChanged: function(model, value, options) {

            options = options || {};

            if (options.action === 'deleteFragment' || options.action === 'insertFragment' || options.action === 'moveFragment') {

                // Render the changed fragments.

                this._renderRevisionFragments(model, value[this.viewState.get('activeRevision')], options);
            }
        },

        _moveExistingFragment: function(oldIndex, newIndex) {

            // We can't simply move the rendered html. At least this doesn't work for Tweet embeds. So we create a new view.

            var widgetView = this._widgetViews[oldIndex];

            this._deleteExistingFragment(oldIndex);

            widgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                fragment: widgetView.options.fragment,
                totalWidgets: this.editModel.get('revisions')[this.viewState.get('activeRevision')].fragments.length
            }));
            this._widgetViews.splice(newIndex, 0, widgetView);

            if (newIndex > 0)
                this.$('.post-editor .widget-container').eq(newIndex - 1).after(widgetView.render().$el);
            else
                this.$('.post-editor').prepend(widgetView.render().$el);

            this._registerWidgetEvents(widgetView);
        },

        _registerWidgetEvents: function(view) {

            this.listenTo(view, 'addExtraWidget', _.bind(function(e) {

                this.editModel.insertFragment(this.editModel.get('revisions')[this.viewState.get('activeRevision')], e.type, e.settings, e.index + 1);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));

            this.listenTo(view, 'changed', _.bind(function(e) {

                this.editModel.updateFragmentSettings(this.editModel.get('revisions')[this.viewState.get('activeRevision')], e.index, e.settings);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));

            this.listenTo(view.widgetView, 'changing', _.bind(function(e) {

                this.viewState.set('disableSaving', e.isChanging);

            }, this));

            this.listenTo(view, 'delete', _.bind(function(e) {

                this.editModel.deleteFragment(this.editModel.get('revisions')[this.viewState.get('activeRevision')], e.index);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));

            this.listenTo(view, 'moveDown', _.bind(function(e) {

                this.editModel.moveFragmentDown(this.editModel.get('revisions')[this.viewState.get('activeRevision')], e.index);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));

            this.listenTo(view, 'moveUp', _.bind(function(e) {

                this.editModel.moveFragmentUp(this.editModel.get('revisions')[this.viewState.get('activeRevision')], e.index);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));
        },

        _renderRevisionFragments: function(model, revision, options) {

            // Activate the first widget by default.

            var activateIndex = (options.index > -1) ? options.index : 0;

            // A widget might have been added or removed. We need to maintain the scroll position...

            var scrollPosition = $(window).scrollTop();

            switch (options.action) {

                case 'deleteFragment': {
                    this._deleteExistingFragment(options.index);
                    this._renderAllToolbars();
                    break;
                }
                case 'insertFragment': {
                    this._insertFragment(model, revision, options.index);
                    this._renderAllToolbars();
                    break;
                }
                case 'moveFragment': {
                    this._moveExistingFragment(options.oldIndex, options.index);
                    this._renderAllToolbars();
                    break;
                }
                case 'settingsFragment': {
                    this._renderExistingFragment(options.index);
                    break;
                }
                default: {
                    this._clearExistingFragments();
                    this._renderAllFragments(model, revision);
                    break;
                }
            }

            // Activate the widget if it matches the changed index or the first widget, if no changed index (see above).

            if (this._widgetViews[activateIndex])
                this._widgetViews[activateIndex].setActive();

            if (options.index > -1) {

                // ... unless we have a changed index. In that case, set the scroll position to the changed widget.

                var scrollToIndex = options.index;

                if (options.action === 'deleteFragment') {

                    // If the last fragment was deleted, we scroll up to the previous widget.
                    // We can check for the length of the _widgetViews array, since the widget was already removed from it.

                    if (options.index === this._widgetViews.length)
                        scrollToIndex = options.index - 1;
                }

                var element = this.$('.post-editor .widget-container').eq(scrollToIndex);

                if (element.length > 0) {

                    var headerHeight = $('#common-header').height();
                    var viewHeight = $(window).height();
                    var widgetHeight = element.height();

                    scrollPosition = headerHeight + element.offset().top - (viewHeight / 2) + (widgetHeight / 2);
                }
            }

            $(window).scrollTo(scrollPosition, 'normal');
        },

        _renderAllFragments: function(model, revision) {

            _.each(revision.fragments, function(fragment, index) {

                var widgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                    fragment: fragment,
                    totalWidgets: this.editModel.get('revisions')[this.viewState.get('activeRevision')].fragments.length
                }));
                this._widgetViews.push(widgetView);

                this.$('.post-editor').append(widgetView.render().$el);

                this._registerWidgetEvents(widgetView);

                //this.$('.post-editor').append('<div>');
                //widgetView.setElement(this.$('.post-editor div:last')[0]).render(); // Set the element to the actual instance, not just a div:last selector.

            }, this);

            var widgetView = this.addSubView(new Love.Views.CommonContentWidgetNewView());
            this.$('.post-editor').append(widgetView.render().$el);

            this.listenTo(widgetView, 'addExtraWidget', _.bind(function(e) {

                this.editModel.addFragment(this.editModel.get('revisions')[this.viewState.get('activeRevision')], e.type, e.settings);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));
        },

        _renderAllToolbars: function() {

            // After widgets have been moved / inserted / deleted, we need to re-render most if not all toolbars
            // to reflect changes in possibility to move widgets, etc.

            _.each(this._widgetViews, _.bind(function(view) {view.renderToolbars(this.editModel.get('revisions')[this.viewState.get('activeRevision')].fragments.length);}, this));
        },

        _renderExistingFragment: function(index) {

            this._widgetViews[index].render();
        }
    });

})(Love);