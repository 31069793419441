(function(Love) {

    Love.Helpers.LocalStorage = {

        getInteger: function(key) {

            if (!this.isSupported()) return null;

            var value = window.localStorage.getItem(key);
            return (value !== null) ? parseInt(value) : null;
        },

        getObject: function(key) {

            if (!this.isSupported()) return null;

            var value = window.localStorage.getItem(key);
            return (value !== null) ? JSON.parse(value) : null;
        },

        isSupported: function() {

            var supported = (typeof(Storage) !== 'undefined');
            var localStorage;

            try {

                localStorage = window.localStorage;
            }
            catch (ex) {

                console.log(ex);
                supported = false;
            }

            return supported;
        },

        removeObject: function(key) {

            if (!this.isSupported()) return;
            window.localStorage.removeItem(key);
        },

        setItem: function(key, value) {

            if (!this.isSupported()) return;

            try {

                window.localStorage.setItem(key, value);
            }
            catch (ex) {

                console.log(ex);
            }
        },

        setObject: function(key, value) {

            if (!this.isSupported()) return;

            try {

                window.localStorage.setItem(key, JSON.stringify(value));
            }
            catch (ex) {

                console.log(ex);
            }
        }
    };

})(Love);