(function (Love) {

    Love.Views.CommonTimePickerView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonTimePickerView',

        defaults: function () {

            return {

                callbacks: {},
                time: moment()
            };
        },

        events: {

            'click .hours-dial > .time-interval': 'selectHour',
            'click .minutes-dial > .time-interval': 'selectMinutes',
            'click .picked-hours': function () {this.switchMode('hours');},
            'click .picked-minutes': function () {this.switchMode('minutes');}
        },

        initialize: function (options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            if (!this.options.time || !this.options.time.isValid())
                this.options.time = moment();

            this.mode = 'hours';

            // Clock size

            this.dialRadius = 100;
            this.outerRadius = 80;
            this.innerRadius = 54;
            this.tickRadius = 13;
            this.diameter = this.dialRadius * 2;
            this.duration = 150; //ms
            this.checkSvgSupport();
        },

        render: function () {

            this.$el.html(_.template(Love.Templates.common_time_picker));
            this.renderTimeIntervals();

            this.$('.minutes-dial').css({'opacity': 0, 'visibility': 'hidden'});
            this.$('.hours-dial').css({'opacity': 1, 'visibility': 'visible'});

            this.updateDisplay();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        checkSvgSupport: function () {

            // Can I use inline svg ? //TODO: Van https://weareoutman.github.io/clockpicker/ is dit nodig?

            var svgNS = 'http://www.w3.org/2000/svg';
            this.svgSupported = 'SVGAngle' in window && (function () {

                    var supported;
                    var el = document.createElement('div');
                    el.innerHTML = '<svg/>';
                    supported = (el.firstChild && el.firstChild.namespaceURI) == svgNS;
                    el.innerHTML = '';
                    return supported;

                })();
        },

        renderTimeIntervals: function () {

            var timeIntervals = this.$('.time-interval');

            _.each(timeIntervals, function (timeInterval, index) {

                var radian = index / 6 * Math.PI;
                var inner = $(timeInterval).hasClass('inner');
                var radius = inner ? this.innerRadius : this.outerRadius;

                if (!inner)
                    $(timeInterval).css('font-size', '120%');

                $(timeInterval).css({

                    left: this.dialRadius + Math.sin(radian) * radius - this.tickRadius,
                    top: this.dialRadius - Math.cos(radian) * radius - this.tickRadius
                });

            }, this);
        },

        selectHour: function (e) {

            var hours = $(e.target).html();
            this.options.time.hours(hours);

            if (this.svgSupported)
                this.updateSvgDisplay();

            this.switchMode('minutes');
        },

        selectMinutes: function (e) {

            var minutes = $(e.target).html();
            this.options.time.minutes(minutes);
            this.updateDisplay();

            if (this.options.callbacks.onChange)
                this.options.callbacks.onChange(this.options.time);
        },

        switchMode: function (mode) {

            var hourDial = this.$('.hours-dial');
            var minuteDial = this.$('.minutes-dial');
            var svg = this.$('.clock-svg');
            var duration = this.duration;
            var self = this;

            if (mode === 'hours') {

                this.mode = 'hours';

                hourDial.animate({'opacity': 1}, {

                    duration: duration * 2, complete: function () {

                        hourDial.css({'visibility': 'visible'});
                        self.updateDisplay();
                    }
                });

                svg.animate({'opacity': 0}, {duration: duration});

                minuteDial.animate({'opacity': 0}, {

                    duration: duration, complete: function () {

                        minuteDial.css({'visibility': 'hidden'});
                    }
                });

                //hourDial.removeClass('hidden');
                //minuteDial.addClass('hidden');

            }
            else if (mode === 'minutes') {

                this.mode = 'minutes';
                //this.updateDisplay();

                hourDial.animate({'opacity': 0}, {

                    duration: duration, complete: function () {

                        hourDial.css({'visibility': 'hidden'});
                    }
                });

                svg.animate({'opacity': 0}, {duration: duration});

                minuteDial.animate({'opacity': 1}, {

                    duration: duration * 2, complete: function () {

                        minuteDial.css({'visibility': 'visible'});
                        self.updateDisplay();
                    }
                });

                //hourDial.addClass('hidden');
                //minuteDial.removeClass('hidden');
            }
        },

        updateDisplay: function () {

            var hourDisplay = this.$('.picked-hours');
            var minuteDisplay = this.$('.picked-minutes');

            hourDisplay.html(this.options.time.format('HH'));
            minuteDisplay.html(this.options.time.format('mm'));

            if (this.svgSupported)
                this.updateSvgDisplay();
        },

        updateSvgDisplay: function () {

            // Draw clock hands and others
            var svg = this.$('.clock-svg');
            svg.css({'width': this.diameter, 'height': this.diameter, 'opacity': 1});
            var g = this.$('svg.clock-svg g');
            g.attr('transform', 'translate(' + this.dialRadius + ',' + this.dialRadius + ')');
            var innerDot = svg.find('circle.inner-dot');
            var line = svg.find('line');
            innerDot.attr('cx', 0);
            innerDot.attr('cy', 0);
            innerDot.attr('r', 2);

            line.attr('x1', 0);
            line.attr('y1', 0);

            var selectedCircle = svg.find('circle.selected-circle');
            selectedCircle.attr('r', this.tickRadius);

            var index, radius;
            if (this.mode === 'hours') {
                index = this.options.time.hours() % 12;
                var inner = false;
                if (this.options.time.hours() === 0 || this.options.time.hours() > 12)
                    inner = true;
                radius = inner ? this.innerRadius : this.outerRadius;
            } else {
                index = this.options.time.minutes() / 5;
                radius = this.outerRadius * 0.8;
            }
            var radian = index / 6 * Math.PI;
            var cx = Math.sin(radian) * radius;
            var cy = -Math.cos(radian) * radius;

            line.attr('x2', cx);
            line.attr('y2', cy);
            selectedCircle.attr('cx', cx);
            selectedCircle.attr('cy', cy);
        }
    });

})(Love);