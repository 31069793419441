(function(Love) {

    Love.Views.CommonTimePickerView2 = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonTimePickerView2',

        defaults: function() {

            return {

                callbacks: {},
                dateTimeRange: {

                    canLastAllDay: false,
                    isRange: false,
                    lastsAllDay: false,
                    secondValue: null
                },
                time: moment()
            };
        },

        events: {

            'click [data-action="reset"]': '_handleReset',
            'click .hours [data-action="up"]': '_handleHoursUp',
            'click .hours [data-action="down"]': '_handleHoursDown',
            'click .minutes [data-action="up"]': '_handleMinutesUp',
            'click .minutes [data-action="down"]': '_handleMinutesDown',
            'change input[name="hours"]': '_handleHoursChange',
            'change input[name="minutes"]': '_handleMinutesChange',
            'change input[name="all-day"]': '_handleAllDayChange'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            if (!this.options.time || !this.options.time.isValid())
                this.options.time = moment();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_time_picker_2)(this.options));

            this.$('input[name="all-day"]').prop('checked', this.options.dateTimeRange.lastsAllDay);
            this._renderTimePicker();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _handleAllDayChange: function() {

            this.options.dateTimeRange.lastsAllDay = this.$('input[name="all-day"]').prop('checked');

            this._renderTimePicker();

            if (this.options.dateTimeRange.lastsAllDay) {

                if (this.options.dateTimeRange.secondValue.isAfter(this.options.time)) {

                    this.options.time.hours(0);
                    this.options.time.minutes(0);
                    this.options.time.seconds(0);
                    this.options.time.milliseconds(0);

                    this.options.dateTimeRange.secondValue.hours(23);
                    this.options.dateTimeRange.secondValue.minutes(59);
                    this.options.dateTimeRange.secondValue.seconds(59);
                    this.options.dateTimeRange.secondValue.milliseconds(0);
                }
                else {

                    this.options.time.hours(23);
                    this.options.time.minutes(59);
                    this.options.time.seconds(59);
                    this.options.time.milliseconds(0);

                    this.options.dateTimeRange.secondValue.hours(0);
                    this.options.dateTimeRange.secondValue.minutes(0);
                    this.options.dateTimeRange.secondValue.seconds(0);
                    this.options.dateTimeRange.secondValue.milliseconds(0);
                }
            }
            else {

                var hours = parseInt(this.$('input[name="hours"]').val());
                var minutes = parseInt(this.$('input[name="minutes"]').val());

                this.options.time.hours(hours);
                this.options.time.minutes(minutes);
            }

            if (this.options.callbacks.onChange)
                this.options.callbacks.onChange(this.options.time, this.options.dateTimeRange);
        },

        _handleHoursChange: function() {

            var value = parseInt(this.$('input[name="hours"]').val());
            this._setHours(value, 0);
        },

        _handleHoursDown: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var value = parseInt(this.$('input[name="hours"]').val());
            this._setHours(value, -1);
        },

        _handleHoursUp: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var value = parseInt(this.$('input[name="hours"]').val());
            this._setHours(value, 1);
        },

        _handleMinutesChange: function() {

            var value = parseInt(this.$('input[name="minutes"]').val());
            this._setMinutes(value, 0);
        },

        _handleMinutesDown: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var value = parseInt(this.$('input[name="minutes"]').val());
            this._setMinutes(value, -1);
        },

        _handleMinutesUp: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var value = parseInt(this.$('input[name="minutes"]').val());
            this._setMinutes(value, 1);
        },

        _handleReset: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var now = moment();
            this._handleTimeChange(now.hours(), now.minutes());
        },

        _handleTimeChange: function(hours, minutes) {

            if (!_.isUndefined(hours) && !_.isUndefined(minutes)) {

                this.$('input[name="hours"]').val(Love.Helpers.Language.addLeadingZeros(hours, 2));
                this.$('input[name="minutes"]').val(Love.Helpers.Language.addLeadingZeros(minutes, 2));
            }
            else {

                hours = parseInt(this.$('input[name="hours"]').val());
                minutes = parseInt(this.$('input[name="minutes"]').val());
            }

            this.options.time.hours(hours);
            this.options.time.minutes(minutes);

            if (this.options.callbacks.onChange)
                this.options.callbacks.onChange(this.options.time, this.options.dateTimeRange);
        },

        _setHours: function(value, change) {

            if (isNaN(value)) value = 0;
            value = value + change;

            if (value > 23) value = 0;
            else if (value < 0) value = 23;

            this.$('input[name="hours"]').val(Love.Helpers.Language.addLeadingZeros(value, 2));
            this._handleTimeChange();
        },

        _setMinutes: function(value, change) {

            if (isNaN(value)) value = 0;
            value = value + change;

            if (value > 59) value = 0;
            else if (value < 0) value = 59;

            this.$('input[name="minutes"]').val(Love.Helpers.Language.addLeadingZeros(value, 2));
            this._handleTimeChange();
        },

        _renderTimePicker: function() {

            if (this.options.dateTimeRange.lastsAllDay)
                this.$('.time-picker-container').addClass('hidden');
            else
                this.$('.time-picker-container').removeClass('hidden');
        }
    });

})(Love);