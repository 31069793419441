(function(Love) {

    Love.Helpers.Loading = {

        _references: [],

        finishFadeInLoading: function(selector) {

            $(selector).animate({opacity: 1});
        },

        showLoading: function(show, id, selector) {

            var global = !selector || selector.length < 1;

            var index = id;
            if (!index) index = '';

            if (!this._references[index])
                this._references[index] = 0;

            if (show) {

                this._references[index]++;

                if (global)
                    Love.appView.showLoading(true);
                else
                    Love.appView.showLoading(true, selector);
            }
            else {

                this._references[index]--;

                if (this._references[index] < 1) {

                    this._references[index] = 0;

                    if (global)
                        Love.appView.showLoading(false);
                    else
                        Love.appView.showLoading(false, selector);
                }
            }
        },

        startFadeInLoading: function(selector) {

            $(selector).css('opacity', 0);
        }
    };

})(Love);