(function(Love) {

    Love.Views.CommonContentNewLanguagePopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonContentNewLanguagePopupView',

        className: 'base-popup popup-centered popup-size-auto',
        id: 'popup-common-content-new-language',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    except: [],
                    languages: Love.Helpers.Language.getLanguages()
                },
                enableLightbox: true
            };
        },

        events: {

            'change [name="copy-from"]': '_handleCopyFromChange',
            'click [data-action="close"]': '_handleClose',
            'click [data-action="confirm"]': '_handleConfirm'
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_popups_new_language)(this.options.data));

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.close();
        },

        _handleCopyFromChange: function(e) {

            var copy = this.$('[name="copy-from"]').prop('checked');
            this.$('[name="copy-from-language"]').prop('disabled', !copy);
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm({

                    copy: this.$('[name="copy-from"]').prop('checked'),
                    copyFrom: this.$('[name="copy-from-language"]').val(),
                    language: this.$('[name="new-language"]').val()
                });

            this.close();
        }
    });

})(Love);