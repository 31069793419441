(function(Love) {

    Love.Models.ImageModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.ImageModel',

        defaults: function() {

            return {

                imageLink: '',
                imageCopyright: '',
                imageCopyrightUrl: '',
                imageSource: 'upload',
                imageTitle: '',
                imageUpload: {

                    cropRegion: this.defaultsCropRegion(), // Will currently only be saved for blog post ogImages.
                    id: '',
                    files: [],
                    focusPoint: this.defaultsFocusPoint(), // Will currently only be saved for blog post cover images.
                    mediaSizeHeight: '',
                    mediaSizeWidth: '',
                    mediaType: 'image',
                    title: ''
                },
                imageUploadID: '',
                imageURL: ''
            };
        },

        defaultsCropRegion: function() {

            return {

                canvas: {

                    left: null,
                    top: null,
                    width: null,
                    height: null,
                    naturalWidth: null,
                    naturalHeight: null
                },
                cropBox: {

                    left: null,
                    top: null,
                    width: null,
                    height: null
                }
            };
        },

        defaultsFocusPoint: function() {

            return {x: 50, y: 50};
        },

        initialize: function(options) {

            if (options) this.set(options);
        },

        parse: function(response, options) {

            var contents = (response.response) ? response.response : response;
            return contents;
        },

        isUploaded: function() {

            return this.get('imageSource') === 'upload';
        },

        getAttrFromMediaAttr: function(data) {

            // Sometimes the server gives null for these two, but it doesn't accept that in reply.
            // So we always set a value client side.

            if (data.cropRegion === null) data.cropRegion = this.defaultsCropRegion();
            if (data.focusPoint === null) data.cropRegion = this.defaultsFocusPoint();

            return _.extend(_.clone(this.attributes), {

                imageLink: '',
                imageSource: 'upload',
                imageTitle: data.title,
                imageUpload: _.extend(_.clone(this.get('imageUpload')), data),
                imageUploadID: data.id,
                imageURL: ''
            });
        },

        getCanvasData: function(callback) {

            var image = new Image();
            var url = this.getImageView();

            image.onload = function() {

                var canvas = document.createElement('canvas');

                canvas.width = this.width;
                canvas.height = this.height;

                var ctx = canvas.getContext('2d');
                ctx.drawImage(this, 0, 0);

                canvas.toBlob(callback);
            };

            if (url.indexOf('data:image/') !== 0) // At least Safari doesn't support using a crossOrigin attribute for local data URIs. This is probably according to specs.
                image.crossOrigin = 'anonymous';

            image.src = Love.Helpers.Proxy.getProxyUrl(url);
        },

        getCroppedArea: function() {

            if (this.get('imageUpload') && this.get('imageUpload').cropRegion)
                return this.get('imageUpload').cropRegion;
            else
                return this.defaultsCropRegion();
        },

        getFocusPoint: function() {

            if (this.get('imageUpload') && this.get('imageUpload').focusPoint)
                return this.get('imageUpload').focusPoint;
            else
                return this.defaultsFocusPoint();
        },

        getImageView: function() {

            var url = '';

            if (this.get('imageSource') === 'url')
                url = this.get('imageURL');

            else if (this.get('imageSource') === 'upload' && this.get('imageUpload').files[0])
                url = this.get('imageUpload').files[0].url;

            return url;
        },

        hasDefaultCropRegion: function() {

            return _.isEqual(this.getCroppedArea(), this.defaultsCropRegion());
        },

        hasImage: function() {

            if (this.get('imageSource') === 'url')
                return !_.isEmpty(this.get('imageURL'));

            else if (this.get('imageSource') === 'upload')
                return (this.get('imageUpload').files[0] && !_.isEmpty(this.get('imageUpload').files[0].url));

            return false;
        },

        setCroppedArea: function(data) {

            if (this.get('imageUpload')) {

                this.get('imageUpload').cropRegion = data;
                this.trigger('change');
            }
        },

        setFocusPoint: function(percPoint) {

            if (this.get('imageUpload')) {

                this.get('imageUpload').focusPoint = percPoint;
                this.trigger('change');
            }
        },

        uploadFile: function(file, options) {

            if (!file) throw new Error('File must have a value.');

            options = _.extend({

                title: ''

            }, options);

            var self = this;
            var result = $.Deferred();

            var performUpload = function(uploadFile) {

                var formData = new FormData();

                formData.append('media', uploadFile);
                formData.append('title', options.title);

                $.ajax({

                        url: '/api/1.0/site/' + Love.session.get('currentSite').id + '/cms/media.json',
                        data: formData,
                        type: 'POST',
                        contentType: false,
                        processData: false
                    })
                    .done(function(data, textStatus, jqXHR) {

                        data.response = self.getAttrFromMediaAttr(data.response);

                        self.serverAttributes = self.parse(Love.Helpers.General.deepCloneJSON(data.response));
                        self.set(self.parse(data.response));

                        result.resolve();
                    })
                    .fail(function() {result.reject();});
            };

            loadImage(file, function(canvas) {

                if (canvas && canvas.type !== 'error') {

                    canvas.toBlob(function(blob) {

                        blob = Love.Helpers.General.blobToFile(blob, file.name, file.lastModifiedDate);
                        performUpload(blob);

                    }, file.type, 1);
                }
                else
                    result.reject();
            }, {

                // The API stores 1280 as the largest image. Therefore, it doesn't make much sense to send larger images.
                // By resizing images client side here, we decrease the risk of sending to large images (> 15 MB) which result in an upload error.

                maxHeight: 1280,
                maxWidth: 1280,
                orientation: true
            });

            return result;
        },

        uploadUrl: function(url, options) {

            if (!url) throw new Error('Url must have a value.');

            // We have to use the proxy to prevent CORS errors. We can also handle orientation and resizing there to speed things up.

            url = Love.Helpers.Proxy.getProxyUrl(url, [

                {name: 'image_correct_orientation', value: true},
                {name: 'image_max_height', value: 1280},
                {name: 'image_max_width', value: 1280}
            ]);

            return this.uploadFile(url, options); // For now this works, as the implementation of uploadFile() also supports URLs vis loadImage.
        }
    });

})(Love);