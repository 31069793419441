(function(Love) {

    Love.Models.SiteModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.SiteModel',

        defaults: function() {

            return {

                authors: [],
                blogs: [],
                calendars: [],
                destinations: [],
                googleCalendars: [],
                id: '',
                imageUrl: '',
                nowPlatformId: '', // Meant for reference and hacks, not for API calls!
                storylines: [],
                streams: [],
                title: '',
                url: ''
            };
        },

        url: function() { return 'api/1.0/site/' + this.id + '.json'; },

        initialize: function(options) {

            if (options) this.set(options);

            _.bindAll(this, 'getData', 'requireData');
        },

        parse: function(response, options) {

            var contents = (response.response) ? response.response : response;

            // TODO RENS: currently, the API returns null for empty arrays. This is unexpected, so until Bram fixes this we replace the undefined values by empty arrays.

            contents.authors = contents.authors || [];
            contents.blogs = contents.blogs || [];
            contents.calendars = contents.calendars || [];

            contents.destinations = contents.destinations ? _.map(contents.destinations, function(dest) {

                dest = new Love.Models.DestinationModel(dest, {parse: true});
                return dest.attributes;

            }) : [];

            contents.googleCalendars = contents.googleCalendars || [];
            contents.storylines = contents.storylines || [];
            contents.streams = _.map(contents.streams || [], function(value) {

                value.id = String(value.id);
                return value;
            });

            if (response.streams) {

                // Use the latest Moby as site image, if available.

                var moby = this._getMobyFromStreams(response.streams);

                if (moby)
                    contents.imageUrl = moby.get('info').media.thumbnails.square;
            }

            if (contents.imageUrl === 'http://placehold.it/150x150')
                contents.imageUrl = '';

            return contents;
        },

        getData: function(type, refresh) {

            if (!_.isString(type) || _.isEmpty(type)) return;
            return _.bind(this._loveDataFunctions[type], this)(refresh);
        },

        getLatestMoby: function() {

            return this._getMobyFromStreams(this.get('streams'));
        },

        getUsageAmount: function() {

            return Love.Helpers.LocalStorageSession.getInteger('sites_site_usage_amount_' + this.id) || 0;
        },

        incrementUsageAmount: function() {

            var amount = this.getUsageAmount();
            Love.Helpers.LocalStorageSession.setItem('sites_site_usage_amount_' + this.id, amount + 1);
        },

        requireData: function(type, refresh) {

            if (!_.isString(type) || _.isEmpty(type)) return;
            _.bind(this._loveDataFunctions[type], this)(refresh);
        },

        _getMobyFromStreams: function(streams) {

            if (streams) {

                var brandStream = _.findWhere(streams, {isBrandStream: true});

                if (brandStream && brandStream.lastMobypictureMedia)
                    return new Love.Models.MediaModel(brandStream.lastMobypictureMedia);
            }

            return null;
        },

        _loveData: {

            bookmarksLatest: null,
            bookmarksLatestPromise: null,
            bookmarksLatestImages: null,
            bookmarksLatestImagesPromise: null,
            pages: null,
            pagesPromise: null
        },

        _loveDataFunctions: {

            // Check if the required data is available already. If not, start fetching it.

            bookmarksLatest: function(refresh) {

                if (!this._loveData.bookmarksLatestPromise || refresh)
                    this._loveData.bookmarksLatestPromise = $.Deferred();

                var deferred = this._loveData.bookmarksLatestPromise;

                if (!this._loveData.bookmarksLatest || refresh) {

                    this._loveData.bookmarksLatest = new Love.Collections.BookmarkCollection(null, {state: {pageSize: 3}});
                    this._loveData.bookmarksLatest.getFirstPage({

                        success: function(collection, response, options) { deferred.resolve(collection); },
                        error: function(collection, response, options) { deferred.reject(); }
                    });
                }

                return deferred;
            },

            bookmarksLatestImages: function(refresh) {

                if (!this._loveData.bookmarksLatestImagesPromise || refresh)
                    this._loveData.bookmarksLatestImagesPromise = $.Deferred();

                var deferred = this._loveData.bookmarksLatestImagesPromise;

                if (!this._loveData.bookmarksLatestImages || refresh) {

                    this._loveData.bookmarksLatestImages = new Love.Collections.BookmarkCollection(null, {state: {pageSize: 50}}); // TODO: use 50 for client side filtering hack...
                    this._loveData.bookmarksLatestImages.getFirstPage({

                        success: function(collection, response, options) {

                            // TODO RENS: we have to filter client side, as the API doesn't yet support bookmark filtering.

                            collection = new Love.Collections.BookmarkCollection(_.first(collection.filter(function(model) {

                                return (model.get('type') === 'image');

                            }), 3));

                            deferred.resolve(collection);
                        },
                        error: function(collection, response, options) { deferred.reject(); }
                    });
                }

                return deferred;
            },

            pages: function(refresh) {

                if (!this._loveData.pagesPromise || refresh)
                    this._loveData.pagesPromise = $.Deferred();

                var deferred = this._loveData.pagesPromise;

                if (!this._loveData.pages || refresh) {

                    this._loveData.pages = new Love.Collections.PageCollection();
                    this._loveData.pages.fetch({

                        success: function(collection, response, options) { deferred.resolve(collection); },
                        error: function(collection, response, options) { deferred.reject(); }
                    });
                }

                return deferred;
            }
        }
    });

    Love.Collections.SiteCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.SiteCollection',

        model: Love.Models.SiteModel,

        url: 'api/1.0/account/sites.json',

        parse: function(response, options) {

            return response.response;
        }
    });

})(Love);