(function (Love) {

    Love.Models.BlogModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.BlogModel',

        defaults: function() {

            return {

                id: '',
                title: ''
            };
        },

        initialize: function (options) {

            if (options) this.set(options);
        }
    });

    Love.Collections.BlogCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.BlogCollection',

        model: Love.Models.BlogModel,
        
        url: function () {return "api/1.0/site/" + Love.session.get('currentSiteId') + "/blogs.json";},

        parse: function (response, options) {

            var blogs = [];

            $.each(response.response, function (i, value) {

                if (value.class === "cms\\blog")
                    blogs.push(value);
            });

            return blogs;
        }
    });

})(Love);