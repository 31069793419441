(function(Love) {

    Love.Views.HighlightedContentSetEditorView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.HighlightedContentSetEditorView',

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.editModel = this.options.editModel;
            this.viewState = this.options.viewState;

            this._widgetViews = [];

            _.bindAll(this,

                '_clearExistingFragments',
                '_deleteExistingFragment',
                '_handleContentsChanged',
                '_moveExistingFragment',
                '_renderFragments',
                '_renderAllFragments',
                '_renderAllToolbars',
                '_renderExistingFragment'
            );
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.highlighted_content_set_editor));
            this._renderFragments(this.editModel, {});

            this.listenTo(this.editModel, 'change:contents', this._handleContentsChanged);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _clearExistingFragments: function() {

            _.each(this._widgetViews, function(view) {

                view.close();
                view = null;
            });

            this._widgetViews = [];
            this.$('.highlighted-content-set-editor').empty();
        },

        _deleteExistingFragment: function(index) {

            this._widgetViews[index].close();
            this._widgetViews[index].remove();
            this._widgetViews.splice(index, 1);
        },

        _handleContentsChanged: function(model, value, options) {

            options = options || {};

            if (options.action === 'moveFragment' || options.action === 'amount') {

                // Render the changed fragments.

                this._renderFragments(model, options);
            }
        },

        _moveExistingFragment: function(oldIndex, newIndex) {

            // We can't simply move the rendered html. At least this doesn't work for Tweet embeds. So we create a new view.

            var widgetView = this._widgetViews[oldIndex];

            this._deleteExistingFragment(oldIndex);

            widgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                enableDelete: false,
                enableToolbarLine: false,
                fragment: widgetView.options.fragment,
                totalWidgets: this.editModel.get('contents').length
            }));
            this._widgetViews.splice(newIndex, 0, widgetView);

            if (newIndex > 0)
                this.$('.highlighted-content-set-editor .widget-container').eq(newIndex - 1).after(widgetView.render().$el);
            else
                this.$('.highlighted-content-set-editor').prepend(widgetView.render().$el);

            this._registerWidgetEvents(widgetView);
        },

        _registerWidgetEvents: function(view) {

            this.listenTo(view, 'changed', _.bind(function(e) {

                this.editModel.updateFragmentSettings(e.index, e.settings);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));

            this.listenTo(view.widgetView, 'changing', _.bind(function(e) {

                this.viewState.set('disableSaving', e.isChanging);

            }, this));

            this.listenTo(view, 'moveDown', _.bind(function(e) {

                this.editModel.moveFragmentDown(e.index);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));

            this.listenTo(view, 'moveUp', _.bind(function(e) {

                this.editModel.moveFragmentUp(e.index);
                this.editModel.trigger('changedByUser', this.editModel);

            }, this));
        },

        _renderFragments: function(model, options) {

            // Activate the first widget by default.

            var activateIndex = (options.index > -1) ? options.index : 0;

            // A widget might have been added or removed. We need to maintain the scroll position ...

            var scrollPosition = $(window).scrollTop();

            switch (options.action) {

                case 'moveFragment': {
                    this._moveExistingFragment(options.oldIndex, options.index);
                    this._renderAllToolbars();
                    break;
                }
                case 'settingsFragment': {
                    this._renderExistingFragment(options.index);
                    break;
                }
                default: {
                    this._clearExistingFragments();
                    this._renderAllFragments(model);
                    break;
                }
            }

            // Activate the widget if it matches the changed index or the first widget, if no changed index (see above).

            if (this._widgetViews[activateIndex])
                this._widgetViews[activateIndex].setActive();

            if (options.index > -1) {

                // ... unless we have a changed index. In that case, set the scroll position to the changed widget.

                var scrollToIndex = options.index;
                var element = this.$('.highlighted-content-set-editor .widget-container').eq(scrollToIndex);

                if (element.length > 0) {

                    var headerHeight = $('#common-header').height();
                    var viewHeight = $(window).height();
                    var widgetHeight = element.height();

                    scrollPosition = headerHeight + element.offset().top - (viewHeight / 2) + (widgetHeight / 2);
                }
            }

            $(window).scrollTo(scrollPosition, 'normal');
        },

        _renderAllFragments: function(model) {

            _.each(model.get('contents'), function(fragment, index) {

                var widgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                    enableDelete: false,
                    enableToolbarLine: false,
                    fragment: fragment,
                    totalWidgets: this.editModel.get('contents').length
                }));
                this._widgetViews.push(widgetView);

                this.$('.highlighted-content-set-editor').append(widgetView.render().$el);

                this._registerWidgetEvents(widgetView);

                //this.$('.highlighted-content-set-editor').append('<div>');
                //widgetView.setElement(this.$('.page-editor div:last')[0]).render(); // Set the element to the actual instance, not just a div:last selector.

            }, this);
        },

        _renderAllToolbars: function() {

            // After widgets have been moved / inserted / deleted, we need to re-render most if not all toolbars
            // to reflect changes in possibility to move widgets, etc.

            _.each(this._widgetViews, _.bind(function(view) {view.renderToolbars(this.editModel.get('contents').length);}, this));
        },

        _renderExistingFragment: function(index) {

            this._widgetViews[index].render();
        }
    });

})(Love);