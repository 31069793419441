(function (Love) {

    Love.Helpers.Scrolling = {

        /**
         * Disable all scrolling behaviour for an element.
         *
         * @param element
         */
        disableScroll: function (element) {

            $(element).off('scroll', this._handler);

            _.each($(element), function(el) {

                var current = $(el).scrollTop();
                $(el).on('scroll', {oldPosition: current}, this._handler);

            }, this);
        },

        /**
         * Enable all scrolling behaviour for an element.
         * @param element
         */
        enableScroll: function (element) {

            $(element).off('scroll', this._handler);
        },

        getScrollbarWidth: function() {

            var $scroll = document.createElement('div');

            $scroll.className = 'scrollbar-measure';
            document.body.appendChild($scroll);

            var scrollbarWidth = $scroll.offsetWidth - $scroll.clientWidth;

            document.body.removeChild($scroll);

            return scrollbarWidth;
        },

        _handler: function (e) {

            if (e.data && typeof(e.data.oldPosition) !== 'undefined') // Can be 0.
                $(this).scrollTop(e.data.oldPosition);
        }
    };

})(Love);