(function (Love) {

    Backbone.sync = _.wrap(Backbone.sync, function (func, method, model, options) {

        if (!options) options = {};

        if (options.abortPendingByIds)
            Love.Helpers.AjaxPool.abortPendingByIds(options.abortPendingByIds);

        var xhr = func(method, model, options).always(function () {

            if (xhr && xhr.status === 0 && model.trigger)
                model.trigger('syncAborted');

            Love.Helpers.AjaxPool.cleanUp(); // Cleanup the pool of active AJAX requests.
        });

        Love.Helpers.AjaxPool.add({

            method: method,
            model: model,
            options: options,
            requestId: options.requestId,
            xhr: xhr
        });

        return xhr;
    });

})(Love);