(function(Love) {

    Love.Views.CalendarSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.CalendarSidebarView',

        defaults: function() {

            return {

                selectByDefault: false
            };
        },

        filterModelDefaults: function() {

            return {

                authors: [],
                calendars: [],
                categories: [],
                search: '',
                storylines: [],
                types: []
            };
        },

        events: function() {

            return _.extend({}, Love.Views.BaseSidebarView.prototype.events, {

                'change input[name="search"]': '_handleSearchChange',
                'click [data-type="authors"] [data-value]': '_handleAuthorSelect',
                'click [data-type="calendars"] [data-value]': '_handleCalendarSelect',
                'click [data-type="categories"] [data-value]': '_handleCategorySelect',
                'click [data-type="storylines"] [data-value]': '_handleStorylineSelect',
                'click [data-type="types"] [data-value]': '_handleTypeSelect'
            });
        },

        initialize: function(options) {

            Love.Views.BaseSidebarView.prototype.initialize.call(this, options);

            this._filterModel = this.filterModelDefaults();

            this.types = new Love.Collections.ContentTypeCollection();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.calendar_sidebar));

            this.renderStats(true);
            this.updateStatsText();

            this._renderAuthors();
            this._renderCalendars();
            this._renderCategories();
            this._renderStorylines();
            this._renderTypes();

            this._updateAuthorsText();
            this._updateCalendarsText();
            this._updateCategoriesText();
            this._updateStorylinesText();
            this._updateTypesText();

            return Love.Views.BaseSidebarView.prototype.render.call(this);
        },

        getFilterModel: function() {

            return (this._filterModel) ? this._filterModel : this.filterModelDefaults();
        },

        renderStats: function(placeholders, eventCollection) {

            var hasEvents = eventCollection && eventCollection.length > 0;

            if (placeholders) {

                this.$('[data-type="stats"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_statistics)({

                    items: [

                        {name: "0 items total", show: true, placeholder: true},
                        {name: "0 items total", show: true, placeholder: true},
                        {name: "0 items total", show: true, placeholder: true}
                    ]
                }));
            }
            else if (hasEvents) {

                var stats = eventCollection.getStatistics();

                this.$('[data-type="stats"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_statistics)({

                    items: [

                        {name: eventCollection.length + " items total", show: true}, // TODO RENS: i18next plural/non-plural
                        {icon: 'ttl-moby', name: stats.totalMobys + " Moby's", show: (stats.totalMobys > 0)},
                        {icon: 'icon-twitter', name: stats.totalTweets + " Tweets ", show: (stats.totalTweets > 0)},
                        {icon: 'ttl-ttl-logo', name: stats.totalPosts + " blog posts ", show: (stats.totalPosts > 0)},
                        {icon: 'icon-calendar', name: stats.totalEvents + " events", show: (stats.totalEvents > 0)},
                        {
                            icon: 'icon-calendar',
                            name: stats.totalGoogleEvents + " Google Calendar events",
                            show: (stats.totalGoogleEvents > 0)
                        },

                        {icon: 'icon-none', name: "", show: (stats.totalMobys > 0 || stats.totalPosts > 0)},

                        {
                            icon: 'ttl-moby',
                            name: Love.Helpers.Language.getViewsEtcText(stats.totalViewsMobys) + " total Moby views",
                            show: (stats.totalMobys > 1)
                        },
                        {
                            icon: 'ttl-moby',
                            name: Love.Helpers.Language.getViewsEtcText(Math.round(stats.totalViewsMobys / stats.totalMobys)) + " average views per Moby",
                            show: (stats.totalMobys > 1)
                        },
                        {
                            icon: 'ttl-moby',
                            name: Love.Helpers.Language.getViewsEtcText(stats.totalViewsMobys) + " views for this Moby",
                            show: (stats.totalMobys === 1)
                        },
                        {
                            icon: 'icon-eye',
                            name: Love.Helpers.Language.getViewsEtcText(stats.totalViewsPosts) + " total blog post views",
                            show: (stats.totalPosts > 1)
                        },
                        {
                            icon: 'icon-eye',
                            name: Love.Helpers.Language.getViewsEtcText(Math.round(stats.totalViewsPosts / stats.totalPosts)) + " average views per blog post",
                            show: (stats.totalPosts > 1)
                        },
                        {
                            icon: 'icon-eye',
                            name: Love.Helpers.Language.getViewsEtcText(stats.totalViewsPosts) + "  views for this blog post",
                            show: (stats.totalPosts === 1)
                        }
                    ]
                }));
            }
            else {

                this.$('[data-type="stats"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_statistics)({

                    items: [{
                        name: 'No statistics are available. Try adding events or removing some filters!',
                        show: true
                    }]
                }));
            }
        },

        updateStatsText: function(descriptionText) {

            var display = 'Stats of ' + descriptionText + '.';
            if (_.isEmpty(descriptionText)) display = 'Stats of current view.';

            this.$('[data-type="stats"] .sidebar-row-content span').html(display);
        },

        _renderAuthors: function() {

            this.$('[data-type="authors"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(Love.session.get('currentSite').get('authors'), function(value, index, list) {

                    var displayEmail = (_.indexOf(_.pluck(list.slice(0, index), 'name'), value.name) > -1);

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.name + ((displayEmail) ? ' (' + value.id + ')' : ''),
                        imageSource: (value.imageUrl) ? value.imageUrl : "http://placekitten.com/g/50/50"
                    };

                }, this),
                displayNew: false,
                emptyText: 'No authors :('
            }));
        },

        _renderCalendars: function() {

            // TODO RENS: hier ook een model voor maken? Beetje zoals bij content types?

            var calendars = [];

            // Add a pseudo calendar filter for all items without a calendar (content such as Tweets, Moby's).

            calendars.push({

                id: -1,
                name: 'TTL content'
            });

            // Add platform calendars.

            if (Love.session.get('currentSite').has('calendars'))
                _.each(Love.session.get('currentSite').get('calendars'), function(cal) {

                    // TODO: name = title kan weg als Bram er net als bij Google Calendars name van maakt. Maar wil ie dat?

                    calendars.push(_.defaults(_.clone(cal), {name: cal.title}));
                });

            // Add user Google Calendars.

            if (Love.session.has('googleCalendars'))
                _.each(Love.session.get('googleCalendars'), function(gCal) {

                    var value = _.clone(gCal);

                    value.id = 'gcal_' + value.id;
                    value.name = value.name + ' (Google, user)';

                    calendars.push(value);
                });

            // Add site Google Calendars.

            if (Love.session.get('currentSite').has('googleCalendars'))
                _.each(Love.session.get('currentSite').get('googleCalendars'), function(gCal) {

                    var value = _.clone(gCal);

                    value.id = 'gcal_' + value.id;
                    value.name = value.name + ' (Google, platform)';

                    calendars.push(value);
                });

            this.$('[data-type="calendars"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(calendars, function(value) {

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.name
                    };

                }, this),
                displayNew: false,
                emptyText: 'No calendars :('
            }));
        },

        _renderCategories: function() {

            this.$('[data-type="categories"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(Love.session.get('currentSite').get('blogs'), function(value) {

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: false,
                emptyText: 'No categories :('
            }));
        },

        _renderStorylines: function() {

            this.$('[data-type="storylines"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(Love.session.get('currentSite').get('storylines'), function(value) {

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: false,
                emptyText: 'No storylines :('
            }));
        },

        _renderTypes: function() {

            this.$('[data-type="types"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: this.types.map(function(value) {

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.get('title')
                    };

                }, this),
                displayNew: false,
                emptyText: 'No content types :('
            }));
        },

        _handleAuthorSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="authors"] [data-value]').closest('ul'));

            this._filterModel.authors = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateAuthorsText();
        },

        _handleCalendarSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="calendars"] [data-value]').closest('ul'));

            this._filterModel.calendars = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateCalendarsText();
        },

        _handleCategorySelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="categories"] [data-value]').closest('ul'));

            this._filterModel.categories = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateCategoriesText();
        },

        _handleSearchChange: function(e) {

            this._filterModel.search = $(this.$('[data-type="search"] input[name="search"]')).val();
            this.trigger('filtersChanged', {filters: this._filterModel});
        },

        _handleStorylineSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="storylines"] [data-value]').closest('ul'));

            this._filterModel.storylines = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateStorylinesText();
        },

        _handleTypeSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="types"] [data-value]').closest('ul'));

            this._filterModel.types = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateTypesText();
        },

        _updateAuthorsText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="authors"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('calendar:sidebar.authors_all');

            else
                display = Love.t('calendar:sidebar.authors', {

                    value: (this._filterModel.authors[0]) ? this._filterModel.authors[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="authors"] .sidebar-row-content span').html(display);
        },

        _updateCalendarsText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="calendars"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('calendar:sidebar.calendars_all');

            else
                display = Love.t('calendar:sidebar.calendars', {

                    value: (this._filterModel.calendars[0]) ? this._filterModel.calendars[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="calendars"] .sidebar-row-content span').html(display);
        },

        _updateCategoriesText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="categories"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('calendar:sidebar.categories_all');

            else
                display = Love.t('calendar:sidebar.categories', {

                    value: (this._filterModel.categories[0]) ? this._filterModel.categories[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="categories"] .sidebar-row-content span').html(display);
        },

        _updateStorylinesText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="storylines"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('calendar:sidebar.storylines_all');

            else
                display = Love.t('calendar:sidebar.storylines', {

                    value: (this._filterModel.storylines[0]) ? this._filterModel.storylines[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="storylines"] .sidebar-row-content span').html(display);
        },

        _updateTypesText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="types"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('calendar:sidebar.types_all');

            else
                display = Love.t('calendar:sidebar.types', {

                    value: (this._filterModel.types[0]) ? this._filterModel.types[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="types"] .sidebar-row-content span').html(display);
        }
    });

})(Love);