(function(Love) {

    Love.Views.DashboardContentListsModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardContentListsModuleView',

        defaults: function() {

            return {

                itemsToLoad: 5,
                view: 'bookmarks',
                views: ['bookmarks', 'social', 'popular', 'mentions']
            };
        },

        events: {

            'click .common-tab-slider [data-action="current"]': '_handleTabCurrentClick',
            'click .common-tab-slider [data-action="next"]': '_handleTabNextClick',
            'click .common-tab-slider [data-action="previous"]': '_handleTabPreviousClick',
            'click [data-action="create"]': '_handleCreateClick',
            'click [data-action="help"]': '_handleHelpClick',
            'click .common-content-list-toolbar-remix [data-action="remove"]': '_handleToolbarRemoveClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this._bookmarks = {

                fetchOptions: {requestId: this.getAjaxAbortId(), abortPendingByIds: [this.getAjaxAbortId()]},
                finalNotificationShown: false,
                goToUrl: 'site/' + Love.session.get('currentSiteId') + '/remix',
                items: new Love.Collections.BookmarkCollection(null, {

                    mode: 'infinite',
                    state: {pageSize: this.options.itemsToLoad}
                }),
                itemViews: [],
                notification: null,
                scrollPosition: 0,
                selectedItems: [],
                title: 'Bookmarks',
                waypoints: []
            };

            this._mentions = {

                fetchOptions: {

                    data: {

                        mediaType: '',
                        streamIDs: _.pluck(_.where(Love.session.get('currentSite').get('streams'), {isFanStream: true}), 'id')
                    },
                    traditional: true,
                    requestId: this.getAjaxAbortId(),
                    abortPendingByIds: [this.getAjaxAbortId()]
                },
                finalNotificationShown: false,
                items: new Love.Collections.MediaCollection(null, {

                    mode: 'infinite',
                    state: {pageSize: this.options.itemsToLoad}
                }),
                itemViews: [],
                notification: null,
                scrollPosition: 0,
                selectedItems: [],
                title: 'My mentions',
                waypoints: []
            };

            this._popular = {

                fetchOptions: {

                    data: {

                        period: 'lastMonth',
                        section: 'platform',
                        sectionId: Love.session.get('currentSite').get('nowPlatformId')
                    },
                    traditional: true,
                    requestId: this.getAjaxAbortId(),
                    abortPendingByIds: [this.getAjaxAbortId()]
                },
                finalNotificationShown: false,
                items: new Love.Collections.PopularContentCollection(null, {

                    mode: 'infinite',
                    state: {pageSize: this.options.itemsToLoad}
                }),
                itemViews: [],
                notification: null,
                scrollPosition: 0,
                selectedItems: [],
                title: 'Popular',
                waypoints: []
            };

            this._social = {

                fetchOptions: {

                    data: {

                        mediaType: '',
                        streamIDs: _.pluck(_.where(Love.session.get('currentSite').get('streams'), {isBrandStream: true}), 'id')
                    },
                    traditional: true,
                    requestId: this.getAjaxAbortId(),
                    abortPendingByIds: [this.getAjaxAbortId()]
                },
                finalNotificationShown: false,
                goToUrl: 'site/' + Love.session.get('currentSiteId') + '/social',
                items: new Love.Collections.MediaCollection(null, {

                    mode: 'infinite',
                    state: {pageSize: this.options.itemsToLoad}
                }),
                itemViews: [],
                notification: null,
                scrollPosition: 0,
                selectedItems: [],
                title: 'Social updates',
                waypoints: []
            };
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.dashboard_modules_content_lists)(this.options));

            this.listenTo(this._bookmarks.items, 'sync', _.bind(function() {this._renderItems('bookmarks'); }, this));
            this.listenTo(this._mentions.items, 'sync', _.bind(function() {this._renderItems('mentions'); }, this));
            this.listenTo(this._popular.items, 'sync', _.bind(function() {this._renderItems('popular'); }, this));
            this.listenTo(this._social.items, 'sync', _.bind(function() {this._renderItems('social'); }, this));

            var viewObj = this._getViewObject(this.options.view);
            viewObj.items.getFirstPage(viewObj.fetchOptions);

            _.defer(_.bind(function() {

                // Deferred to 'ensure' the header has been rendered.

                this.listenTo(Love.appView.headerView, 'create', _.bind(function(e) {

                    var selected = this._getSelectedItems();
                    selected.reverse();

                    if (e.type === 'post' && selected.length > 0) {

                        e.preventDefault = true;
                        this._remixItems(selected);
                    }

                }, this));

            }, this));

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this._clearWaypoints();

            this.$('.common-content-list-toolbar-remix .items [data-item-id]').off('mouseenter');
            this.$('.common-content-list-toolbar-remix .items [data-item-id]').off('mouseleave');
        },

        _activateView: function(view) {

            var viewObj = this._getViewObject(this.options.view); // Old view.
            viewObj.scrollPosition = this.$('.module-content').scrollTop();

            this._clearWaypoints(this.options.view);
            this._closeNotification(this.options.view, true);

            this.options.view = view;
            viewObj = this._getViewObject(view); // New view.

            this.$('.common-tab-slider span').text(viewObj.title);

            this.$('.common-content-list:not(.' + view + ')').addClass('hidden');
            this.$('.common-content-list.' + view).removeClass('hidden');

            this.$('.module-content').scrollTo(viewObj.scrollPosition);

            if (viewObj.items.state.currentPage === 0)
                viewObj.items.getFirstPage(viewObj.fetchOptions);
            else
                this._registerWaypoints(view);
        },

        _clearWaypoints: function(view) {

            var doClear = function(viewObj) {

                _.each(viewObj.waypoints, function(point) { point.destroy(); });
                viewObj.waypoints = [];
            };

            switch (view) {

                case 'bookmarks':
                    doClear(this._bookmarks);
                    break;
                case 'mentions':
                    doClear(this._mentions);
                    break;
                case 'popular':
                    doClear(this._popular);
                    break;
                case 'social':
                    doClear(this._social);
                    break;
                default:
                    doClear(this._bookmarks);
                    doClear(this._mentions);
                    doClear(this._popular);
                    doClear(this._social);
                    break;
            }
        },

        _closeNotification: function(view, noFade) {

            var doClose = function(viewObj) {

                if (viewObj.notification) {

                    if (!noFade)
                        viewObj.notification.closeFade('slow');
                    else
                        viewObj.notification.close();
                }
            };

            switch (view) {

                case 'bookmarks':
                    doClose(this._bookmarks);
                    break;
                case 'mentions':
                    doClose(this._mentions);
                    break;
                case 'popular':
                    doClose(this._popular);
                    break;
                case 'social':
                    doClose(this._social);
                    break;
                default:
                    doClose(this._bookmarks);
                    doClose(this._mentions);
                    doClose(this._popular);
                    doClose(this._social);
                    break;
            }
        },

        _getSelectedItems: function() {

            var selectedItems = _.map(_.filter(this._bookmarks.itemViews, _.bind(function(view) {

                return (_.indexOf(this._bookmarks.selectedItems, view.getItem().id) > -1);

            }, this)), function(view) {return {type: 'bookmark', view: 'bookmarks', model: view.getItem()};});

            selectedItems = selectedItems.concat(_.map(_.filter(this._mentions.itemViews, _.bind(function(view) {

                return (_.indexOf(this._mentions.selectedItems, view.getItem().id) > -1);

            }, this)), function(view) {return {type: 'media', view: 'mentions', model: view.getItem()};}));

            selectedItems = selectedItems.concat(_.map(_.filter(this._popular.itemViews, _.bind(function(view) {

                return (_.indexOf(this._popular.selectedItems, view.getItem().id) > -1);

            }, this)), function(view) {return {type: 'popular', view: 'popular', model: view.getItem()};}));

            selectedItems = selectedItems.concat(_.map(_.filter(this._social.itemViews, _.bind(function(view) {

                return (_.indexOf(this._social.selectedItems, view.getItem().id) > -1);

            }, this)), function(view) {return {type: 'media', view: 'social', model: view.getItem()};}));

            return selectedItems;
        },

        _getViewObject: function(view) {

            switch (view) {

                case 'bookmarks':
                    return this._bookmarks;
                case 'mentions':
                    return this._mentions;
                case 'popular':
                    return this._popular;
                case 'social':
                    return this._social;
            }
        },

        _handleCreateClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var selected = this._getSelectedItems();
            selected.reverse();

            this._remixItems(selected);
        },

        _handleHelpClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var popup = new Love.Views.CommonTooltipPopupView({

                data: {

                    text: 'Select items from this list to create a new post with them!'
                },
                positioning: {

                    attachToElement: this.$('[data-action="help"]')
                }
            });

            popup.showPopup();
        },

        _handleItemDelete: function(e) {

            // Currently, only bookmarks can be deleted.

            if (this.options.view === 'bookmarks') {

                var popup = new Love.Views.CommonQuestionPopupView({

                    callbacks: {

                        onYes: _.bind(function() {

                            e.item.destroy()
                                .fail(function(error) {

                                    // TODO RENS: show error notification.

                                    console.log(error);
                                })
                                .always(_.bind(function() {

                                    // Remove deleted items from the selected items collection.

                                    this._bookmarks.selectedItems = _.without(this._bookmarks.selectedItems, e.item.id);
                                    this._updateSelectedItems();

                                    // Remove the item view.

                                    e.view.close();

                                }, this));

                        }, this)
                    },
                    data: {

                        noText: 'Cancel',
                        title: 'Delete bookmark?',
                        text: 'Do you really want to delete this bookmark?',
                        yesText: 'Delete'
                    }
                });

                popup.showPopup();
            }
        },

        _handleTabCurrentClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var viewObj = this._getViewObject(this.options.view);

            if (!_.isEmpty(viewObj.goToUrl))
                Love.router.navigate(viewObj.goToUrl, {trigger: true});
            else
                this._activateView(this.options.view);
        },

        _handleTabNextClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var viewIndex = _.indexOf(this.options.views, this.options.view);
            var newView = this.options.views[viewIndex + 1] ? this.options.views[viewIndex + 1] : this.options.views[0];

            this._activateView(newView);
        },

        _handleTabPreviousClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var viewIndex = _.indexOf(this.options.views, this.options.view);
            var newView = this.options.views[viewIndex - 1] ? this.options.views[viewIndex - 1] : this.options.views[this.options.views.length - 1];

            this._activateView(newView);
        },

        _handleToolbarRemoveClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var itemView = $(e.target).closest('[data-item-view]').attr('data-item-view');
            var itemId = $(e.target).closest('[data-item-id]').attr('data-item-id');

            var viewObj = this._getViewObject(itemView);
            var viewIndex = _.findIndex(viewObj.itemViews, function(view) {return view.getItem().id === itemId;});

            viewObj.selectedItems = _.without(viewObj.selectedItems, itemId);
            if (viewIndex > -1) viewObj.itemViews[viewIndex].setSelected(false, true);

            this._updateSelectedItems();
        },

        _registerWaypoints: function(view) {

            if (view !== this.options.view) return;

            var viewObj = this._getViewObject(view);

            if (viewObj.items.hasNextPage()) {

                var triggerView = _.first(_.last(viewObj.itemViews, this.options.itemsToLoad));

                if (triggerView)
                    viewObj.waypoints = triggerView.$el.waypoint({

                        context: this.$('.module-content'),
                        handler: _.bind(function(direction) {

                            if (direction === 'down') {

                                viewObj.notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                                    dock: 'bottom',
                                    text: 'Fetching more items...'
                                }));
                                this.$('.module').append(viewObj.notification.render().$el);

                                this._clearWaypoints(view);
                                viewObj.items.getNextPage(viewObj.fetchOptions);
                            }

                        }, this),
                        offset: '100%' // When the top of the element has passed the bottom of the context.
                    });
            }
        },

        _remixItems: function(items) {

            // As items can be of mixed origin, we create a cached entry that holds their unique identifiers.

            var bookmarkIds = _.filter(items, function(item) { return item.type === 'bookmark'; });
            bookmarkIds = _.map(bookmarkIds, function(item) { return item.model.id; });

            var mediaIds = _.filter(items, function(item) { return item.type === 'media'; });
            mediaIds = _.map(mediaIds, function(item) { return item.model.id; });

            var urls = _.filter(items, function(item) { return item.type === 'popular'; });
            urls = _.map(urls, function(item) { return item.model.getExternalUrl(); });

            if (bookmarkIds.length > 0 || mediaIds.length > 0 || urls.length > 0) {

                Love.viewBag.remixIdentifierCache = {

                    bookmarkIds: bookmarkIds,
                    mediaIds: mediaIds,
                    urls: urls
                };

                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/new/remix/cache', {trigger: true});
            }
        },

        _renderItems: function(view) {

            var viewObj = this._getViewObject(view);
            var fullCollection = viewObj.items.fullCollection ? viewObj.items.fullCollection : viewObj.items; // fullCollection is only used when mode is infinite.

            var container = this.$('.common-content-list.' + view);
            var addElements = [];

            if (fullCollection.length < 1) {

                switch (view) {

                    case 'bookmarks':
                        container.html(_.template(Love.Templates.remix_bookmark_row_none)());
                        return;
                    case 'mentions':
                        container.html(_.template(Love.Templates.dashboard_modules_social_row_none)()); // TODO RENS NOW: aanpassen naar Remix mentions/etc. row none.
                        return;
                    case 'popular':
                        container.html(_.template(Love.Templates.dashboard_modules_popular_row_none)()); // TODO RENS NOW: aanpassen naar Remix mentions/etc. row none.
                        return;
                    case 'social':
                        container.html(_.template(Love.Templates.dashboard_modules_social_row_none)()); // TODO RENS NOW: aanpassen naar Remix mentions/etc. row none.
                        return;
                }
            }

            viewObj.items.each(function(model) {

                try {

                    var itemView;

                    switch (view) {

                        case 'bookmarks':
                            itemView = this.addSubView(new Love.Views.RemixBookmarkRowView({item: model}));
                            break;
                        case 'mentions':
                            itemView = this.addSubView(new Love.Views.DashboardSocialRowView({item: model, selectable: true})); // TODO RENS NOW: aanpassen naar Remix mentions/etc. row.
                            break;
                        case 'popular':
                            itemView = this.addSubView(new Love.Views.DashboardPopularRowView({item: model, selectable: true})); // TODO RENS NOW: aanpassen naar Remix mentions/etc. row.
                            break;
                        case 'social':
                            itemView = this.addSubView(new Love.Views.DashboardSocialRowView({item: model, selectable: true})); // TODO RENS NOW: aanpassen naar Remix mentions/etc. row.
                            break;
                    }

                    this.listenTo(itemView, 'selectedChanged', _.bind(function(e) {

                        if (e.selected) {

                            if (_.indexOf(viewObj.selectedItems, e.item.id) === -1)
                                viewObj.selectedItems.push(e.item.id);
                        }
                        else
                            viewObj.selectedItems = _.without(viewObj.selectedItems, e.item.id);

                        this._updateSelectedItems();

                    }, this));

                    this.listenTo(itemView, 'delete', _.bind(this._handleItemDelete, this));

                    viewObj.itemViews.push(itemView);
                    addElements.push(itemView.render().$el);
                }
                catch (ex) {

                    console.log(ex); // TODO: proper error handling.
                }

            }, this);

            container.append(addElements);

            this._closeNotification(view);
            this._registerWaypoints(view);

            if (!viewObj.items.hasNextPage() && !viewObj.finalNotificationShown) {

                this._closeNotification(view, true);

                viewObj.notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                    dock: 'bottom',
                    text: 'That\'s it for now!'
                }));
                this.$('.module').append(viewObj.notification.render().$el);

                _.delay(_.bind(function() {

                    this._closeNotification(view);

                }, this), 4000);

                viewObj.finalNotificationShown = true;
            }

            this.trigger('rendered');
        },

        _updateSelectedItems: function() {

            var selectedItems = this._getSelectedItems();
            selectedItems.reverse(); // Newest first.

            if (selectedItems.length < 1)
                this.$('.module-footer').addClass('hidden');

            else {

                this.$('.module-footer').removeClass('hidden');
                this.$('.common-content-list-toolbar-remix [data-action="create"] .text').text(Love.t('dashboard:modules.remix.create', {count: selectedItems.length}));

                var container = this.$('.common-content-list-toolbar-remix .items');

                // Put two items in the container for width measurement.

                container.html(_.template(Love.Templates.dashboard_modules_content_lists_toolbar_thumbnail)({

                        empty: true,
                        more: false

                    }) + _.template(Love.Templates.dashboard_modules_content_lists_toolbar_thumbnail)({

                        empty: true,
                        more: false
                    }));

                var containerWidth = container.width();
                var itemWidth = container.find('.item:last').width();
                var itemMargin = container.find('.item:first').outerWidth(true) - itemWidth; // Include the right margin of the first item.

                container.empty();

                var itemsToShow = Math.floor((containerWidth - itemMargin) / (itemWidth + itemMargin)); // TODO RENS: responsive maken.

                for (var i = 0; i < itemsToShow; i++) {

                    var item = null;

                    if (selectedItems.length > i)
                        item = selectedItems[i];

                    container.append(_.template(Love.Templates.dashboard_modules_content_lists_toolbar_thumbnail)(_.extend({

                        empty: !item,
                        more: (i === itemsToShow - 1 && item)

                    }, item ? {

                        model: _.extend(_.clone(item.model.attributes), {id: item.model.id}),
                        type: item.type,
                        view: item.view

                    } : null)));
                }

                container.find('[data-item-id]').on('mouseenter', _.bind(function(e) {

                    var itemView = $(e.target).closest('[data-item-view]').attr('data-item-view');
                    var itemId = $(e.target).closest('[data-item-id]').attr('data-item-id');

                    var viewObj = this._getViewObject(itemView);
                    var item = _.find(viewObj.itemViews, function(view) {return view.getItem().id === itemId;}).getItem();

                    var title = item.getTitle();

                    if (!_.isEmpty(title)) {

                        this._toolbarThumbnailPopup = new Love.Views.CommonTooltipPopupView({

                            data: {

                                text: title
                            },
                            enableClickInterception: false,
                            positioning: {

                                attachToElement: $(e.target).closest('[data-item-id]'),
                                centerX: true,
                                top: true
                            }
                        });

                        this._toolbarThumbnailPopup.showPopup();
                    }

                }, this));

                container.find('[data-item-id]').on('mouseleave', _.bind(function(e) {

                    if (this._toolbarThumbnailPopup) this._toolbarThumbnailPopup.close();

                }, this));

                Love.Helpers.Images.lazyLoadImages(container);
            }
        }
    });

})(Love);