(function(Love) {

    Love.Models.ContentSetModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.ContentSetModel',

        defaults: function() {

            return {

                id: '',
                contents: [],
                identifier: '',
                length: 4,
                title: ''
            };
        },

        defaultsFragment: function() {

            return {

                index: 0,
                settings: this.defaultsFragmentSettings(),
                type: 'CMSContentWidget'
            };
        },

        defaultsFragmentSettings: function() {

            return {

                type: '',
                type_id: null,
                type_offset: 0
            };
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/contentset/' + this.id + '.json';

            // Create url.

            else
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/contentsets.json';
        },

        initialize: function(options) {

            if (options) this.set(options);

            _.bindAll(this, 'getClientBackup', 'removeClientBackup', 'saveClientBackup');

            this._createFragmentsForLength();

            this.listenTo(this, 'change:length', _.bind(function() {

                this._createFragmentsForLength();

            }, this));
        },

        destroy: function(options) {

            this.removeClientBackup();
            return Love.Models.BaseModel.prototype.destroy.call(this, options);
        },

        parse: function(response, options) {

            var contents = (response.response) ? response.response : response;

            contents.contents = this._contentsToFragments(contents.contents);
            this._createFragmentsForLength(contents.length, contents.contents, true);

            return contents;
        },

        toJSON: function(options) {

            var result = Love.Models.BaseModel.prototype.toJSON.call(this, options);

            result.contents = this._fragmentsToContents(result.contents);

            return result;
        },

        validate: function(attributes, options) {

            var errors = {};

            if (!attributes.title) errors.title = 'Title required.';
            if (!attributes.identifier) errors.identifier = 'Identifier required.';
            if (attributes.length < 1) errors.length = 'Length should be at least 1.';
            if (attributes.length > 8) errors.length = 'Length cannot be more than 8.';

            // TODO: andere attributes valideren voor save.

            if (!_.isEmpty(errors))
                return errors;
        },

        getClientBackup: function() {

            if (this.isNew()) return null;

            var key = 'highlighted_content_set_' + this.id;

            var backup = Love.Helpers.LocalStorageSession.getObject(key);
            var date = moment(Love.Helpers.LocalStorageSession.getObject(key + '_updated_at'));

            if (!backup || !date) return null;

            return {

                backup: backup,
                updatedAt: date
            };
        },

        /**
         * Moves a fragment down.
         *
         * @param index
         * @param amount
         */
        moveFragmentDown: function(index, amount) {

            if (!amount) amount = 1;

            this._swapFragments(index, index + amount);

            this.trigger('change:contents', this, this.get('contents'), {

                action: 'moveFragment',
                index: index + amount,
                oldIndex: index
            });
            this.trigger('change', this);
        },

        /**
         * Moves a fragment up.
         *
         * @param index
         * @param amount
         */
        moveFragmentUp: function(index, amount) {

            if (!amount) amount = 1;

            this._swapFragments(index, index - amount);

            this.trigger('change:contents', this, this.get('contents'), {

                action: 'moveFragment',
                index: index - amount,
                oldIndex: index
            });
            this.trigger('change', this);
        },

        removeClientBackup: function() {

            var key = 'highlighted_content_set_' + this.id;

            Love.Helpers.LocalStorageSession.removeObject(key);
            Love.Helpers.LocalStorageSession.removeObject(key + '_updated_at');
        },

        saveClientBackup: function() {

            if (this.isNew()) return null;

            // Store a copy of the edited model in the local cache.

            var key = 'highlighted_content_set_' + this.id;

            Love.Helpers.LocalStorageSession.setObject(key, this);
            Love.Helpers.LocalStorageSession.setObject(key + '_updated_at', moment());
        },

        /**
         * Updates settings for a fragment.
         *
         * @param index
         * @param settings
         */
        updateFragmentSettings: function(index, settings) {

            if (this.get('contents')[index])
                this.get('contents')[index].settings = settings;

            this.trigger('change:contents', this, this.get('contents'), {

                action: 'settingsFragment',
                index: index
            });
            this.trigger('change', this);
        },

        _contentsToFragments: function(contents) {

            return _.map(contents, function(content, index) {

                return _.extend(this.defaultsFragment(), {

                    index: index,
                    settings: _.extend(this.defaultsFragmentSettings(), content)
                });

            }, this);
        },

        /**
         * Ensures that the amount of content fragments matches the length property.
         */
        _createFragmentsForLength: function(length, fragments, createOnly) {

            var amount = _.isUndefined(length) ? this.get('length') : length;
            fragments = _.isUndefined(fragments) ? this.get('contents') : fragments;

            var changed = false;

            if (fragments.length > amount) {

                fragments = _.first(fragments, amount); // Keep the first n = amounts elements of the array.
                changed = true;
            }
            else if (fragments.length < amount) {

                fragments = _.clone(fragments);
                var createAmount = amount - fragments.length;

                for (var i = 0; i < createAmount; i++) {

                    fragments.push(_.extend(this.defaultsFragment(), {

                        index: fragments.length
                    }));
                }

                changed = true;
            }

            if (changed && !createOnly) {

                this.set('contents', fragments, {silent: true});

                this.trigger('change:contents', this, this.get('contents'), {

                    action: 'amount'
                });
                this.trigger('change', this);
            }

            return fragments;
        },

        _fragmentsToContents: function(fragments) {

            return _.map(fragments, function(fragment) {

                return fragment.settings;
            });
        },

        /**
         * Swaps the position of two fragments.
         *
         * @param indexA
         * @param indexB
         * @private
         */
        _swapFragments: function(indexA, indexB) {

            if (indexB >= 0) {

                var fragments = this.get('contents');

                if (fragments[indexA] && fragments[indexB]) {

                    var temp = fragments[indexA];

                    fragments[indexA] = fragments[indexB];
                    fragments[indexB] = temp;
                    fragments[indexA].index = indexA;
                    fragments[indexB].index = indexB;

                    this.set('contents', fragments, {silent: true});
                }
            }
        }
    });

    Love.Collections.ContentSetCollection = Love.Collections.BasePageableCollection.extend({

        objectClassName: 'Love.Collections.ContentSetCollection',

        model: Love.Models.ContentSetModel,

        url: function() { return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/contentsets.json'; },

        parseRecords: function(response, options) {

            return response.response;
        }
    });

})(Love);