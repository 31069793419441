(function(Love) {

    Love.Views.CommonHeaderView = Love.Views.BaseHeaderView.extend({

        objectClassName: 'Love.Views.CommonHeaderView',

        events: function() {

            return _.extend({}, Love.Views.BaseHeaderView.prototype.events, {

                'click [data-action="create"]': '_handleCreateClick',

                'click .site-controls [data-menu-item="menu-analytics"] .dropdown-toggle': '_openAnalytics',
                'click .site-controls [data-menu-item="menu-content"] .dropdown-toggle': '_openContent',
                'click .site-controls [data-menu-item="menu-create"] .dropdown-toggle': '_openCreate',

                'click .mobile-controls [data-menu-item="menu-create"] .dropdown-toggle': '_openCreate',

                'click .user-controls [data-menu-item="menu-notifications"] .dropdown-toggle': '_openNotifications',
                'click .user-controls [data-menu-item="menu-user"] .dropdown-toggle': '_openUser',

                'click [data-menu-item="menu-mobile-toggle"]': '_handleMobileMenuToggle',

                'click .mobile-menu a': '_handleMobileMenuItemClick',
                'click .mobile-menu .mobile-sub-menu a': '_handleMobileMenuContextClick'
            });
        },

        initialize: function(options) {

            Love.Views.BaseHeaderView.prototype.initialize.call(this, options);

            // TODO: leidt dit niet tot onverwachte resultaten? Het is in ieder geval nodig om de juiste site id in hrefs te hebben.

            this.listenTo(Love.session, 'change', this.render);
        },

        render: function() {

            var createType = this._getCreateType();

            this.$el.html(_.template(Love.Templates.common_header)(_.extend({

                activeItem: this._getActiveMenuItem(),
                createLink: createType.link,
                createType: createType.type

            }, Love.session.attributes)));

            $('body').addClass('has-page-header');

            return Love.Views.BaseHeaderView.prototype.render.call(this);
        },

        onClose: function() {

            $('body').removeClass('has-page-header');
        },

        _getActiveMenuItem: function() {

            switch (Love.appView.getCurrentSection()) {

                case 'events':
                case 'highlighted':
                case 'pages':
                case 'playlists':
                case 'posts':
                case 'remix':
                case 'social':
                    return 'content';

                default:
                    return Love.appView.getCurrentSection();
            }
        },

        _getCreateType: function() {

            var siteId = Love.session.get('currentSiteId');

            switch (Love.appView.getCurrentSection()) {

                // NOTE: type herkenning tijdelijk uitgeschakeld op verzoek van Koen.

                /*
                 case 'events':
                 return {

                 link: '#site/' + siteId + '/event/new',
                 type: 'event'
                 };

                 case 'highlighted':
                 return {

                 link: '#site/' + siteId + '/highlighted/new',
                 type: 'highlighted'
                 };

                 case 'pages':
                 return {

                 link: '#site/' + siteId + '/page/new',
                 type: 'page'
                 };

                 case 'playlists':
                 return {

                 link: '#site/' + siteId + '/playlist/new',
                 type: 'playlist'
                 };

                 case 'posts':
                 */

                default:
                    return {

                        link: '#site/' + siteId + '/post/new',
                        type: 'post'
                    };
            }
        },

        _handleCreateClick: function(e) {

            var type = $(e.target).closest('[data-type]').attr('data-type');
            var href = $(e.target).closest('[href]').attr('href'); // Default action.

            var event = {

                default: href,
                preventDefault: false,
                type: type
            };

            Love.Helpers.Tracking.track('Header - create ' + type);

            this.trigger('create', event);

            if (event.preventDefault)
                e.preventDefault();
        },

        _handleMobileMenuContextClick: function(e) {

            if (e && e.stopPropagation) e.stopPropagation();

            this.$('.mobile-menu').removeClass('open');
            this.$('.mobile-menu').removeClass('mobile-sub-menu-open');
            this.$('.mobile-menu .mobile-sub-menu').removeClass('open');

            if (!$(e.target).attr('href')) {

                // Perform any non-standard href navigation here.

                // TODO RENS: do we want to support reloading if we're already on a page?
                //Love.router.navigateOrReload('site/' + Love.session.get('currentSiteId') + '/post/new', {trigger: true});
            }
        },

        _handleMobileMenuItemClick: function(e) {

            if (e && e.stopPropagation) e.stopPropagation();

            if ($(e.target).attr('href'))
                return;

            // Activate the menu item.

            $(e.target).closest('.mobile-menu').find('[data-menu-item]').removeClass('active');
            $(e.target).closest('[data-menu-item]').addClass('active');

            // Get the current context of the submenu.

            var current = this.$('.mobile-menu .mobile-sub-menu').attr('data-menu-item');

            // Determine the context that is to be copied over to the mobile sub menu.

            var newContext = $(e.target).closest('[data-menu-item]').attr('data-menu-item');

            // Do they differ?

            if (newContext !== current) {

                // Display the new context. No need to toggle.

                var subMenu = this.$('.mobile-menu .mobile-sub-menu');
                var newHtml = this.$('.mobile-menu-contexts [data-menu-item="' + newContext + '"]').html();

                if (!newHtml)
                    newHtml = '<div><a>The menu items couldn\'t be retrieved :(</a></div>';

                subMenu.html(newHtml);

                // Set the data attribute for the next time.

                this.$('.mobile-menu .mobile-sub-menu').attr('data-menu-item', newContext);

                // Ensure the menu is open.

                this.$('.mobile-menu').addClass('mobile-sub-menu-open');
                this.$('.mobile-menu .mobile-sub-menu').addClass('open');
            }
            else {

                // The same item was clicked. Toggle the sub menu.

                this.$('.mobile-menu').toggleClass('mobile-sub-menu-open');
                this.$('.mobile-menu .mobile-sub-menu').toggleClass('open');
            }
        },

        _handleMobileMenuToggle: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this.$('.mobile-menu').toggleClass('open');

            if (!this.$('.mobile-menu').hasClass('open')) {

                // Also close the sub menu, so it doesn't get displayed automatically next time the menu is opened.

                this.$('.mobile-menu').removeClass('mobile-sub-menu-open');
                this.$('.mobile-menu .mobile-sub-menu').removeClass('open');

                this.$('[data-menu-item="menu-mobile-toggle"]').removeClass('open');
            }
            else
                this.$('[data-menu-item="menu-mobile-toggle"]').addClass('open');
        },

        _openAnalytics: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var siteId = Love.session.get('currentSiteId');
            var items = [

                {name: 'Performance', link: '#site/' + siteId + '/analytics/performance'},
                {name: 'Engagement', link: '#site/' + siteId + '/analytics/engagement'}
            ];

            var popup = new Love.Views.CommonListMenuPopupView({

                data: {items: items, triangle: true},
                positioning: {

                    attachToElement: $(e.target).closest('.menu-item'),
                    bottom: true,
                    centerX: true,
                    minWidth: $(e.target).closest('.menu-item').width(),
                    offsetY: 6, // For triangle positioning.
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _openContent: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var siteId = Love.session.get('currentSiteId');

            /*
             var items = [

             {name: 'Posts', link: '#site/' + siteId + '/posts', icon: 'ttl-compose'},
             {name: 'Remix', link: '#site/' + siteId + '/remix', icon: 'ttl-remix'},
             {name: 'Social', link: '#site/' + siteId + '/social', icon: 'icon-share2'},
             {name: 'Events', link: '#site/' + siteId + '/events', icon: 'ttl-calendarevent'}
             ];
             */

            var columns = [

                {
                    title: 'Social',
                    items: [

                        {name: 'POSTS', link: '#site/' + siteId + '/posts', icon: 'ttl-compose'},
                        {name: 'REMIX', link: '#site/' + siteId + '/remix', icon: 'ttl-remix'},
                        {name: 'SOCIAL UPDATES', link: '#site/' + siteId + '/social', icon: 'icon-share2'},
                        {name: 'EVENTS', link: '#site/' + siteId + '/events', icon: 'ttl-calendarevent'}
                    ]
                },
                {
                    title: 'Fixed',
                    items: [

                        {name: 'PAGES', link: '#site/' + siteId + '/pages', icon: 'ttl-page'},
                        {name: 'PLAYLISTS', link: '#site/' + siteId + '/playlists', icon: 'icon-play3'},
                        {
                            name: 'HIGHLIGHTED CONTENT',
                            link: '#site/' + siteId + '/highlighted',
                            icon: 'ttl-highlightedcontent'
                        }
                    ]
                }
            ];

            var popup = new Love.Views.CommonMultipleListMenuPopupView({

                data: {columns: columns, triangle: true, mode: 'icons'},
                positioning: {

                    attachToElement: $(e.target).closest('.menu-item'),
                    bottom: true,
                    centerX: true,
                    minWidth: $(e.target).closest('.menu-item').width(),
                    offsetY: 6, // For triangle positioning.
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _openCreate: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var siteId = Love.session.get('currentSiteId');

            /*
             var items = [

             {
             name: 'Post',
             action: 'create',
             type: 'post',
             link: '#site/' + siteId + '/post/new',
             icon: 'ttl-compose'
             },
             {
             name: 'Social update',
             action: 'create',
             type: 'social',
             link: '#site/' + siteId + '/social/new',
             icon: 'icon-share2'
             },
             {
             name: 'Event',
             action: 'create',
             type: 'event',
             link: '#site/' + siteId + '/event/new',
             icon: 'ttl-calendarevent'
             },

             {name: 'Page', action: 'create', type: 'page', link: '#site/' + siteId + '/page/new'},
             {name: 'Playlist', action: 'create', type: 'playlist', link: '#site/' + siteId + '/playlist/new'}
             ];
             */

            var columns = [

                {
                    title: 'Social',
                    items: [

                        {
                            name: 'POST',
                            action: 'create',
                            type: 'post',
                            link: '#site/' + siteId + '/post/new',
                            icon: 'ttl-compose'
                        },
                        {
                            name: 'SOCIAL UPDATE',
                            action: 'create',
                            type: 'social',
                            link: '#site/' + siteId + '/social/new',
                            icon: 'icon-share2'
                        },
                        {
                            name: 'EVENT',
                            action: 'create',
                            type: 'event',
                            link: '#site/' + siteId + '/event/new',
                            icon: 'ttl-calendarevent'
                        }
                    ]
                },
                {
                    title: 'Fixed',
                    items: [

                        {
                            name: 'PAGE',
                            action: 'create',
                            type: 'page',
                            link: '#site/' + siteId + '/page/new',
                            icon: 'ttl-page'
                        },
                        {
                            name: 'PLAYLIST',
                            action: 'create',
                            type: 'playlist',
                            link: '#site/' + siteId + '/playlist/new',
                            icon: 'icon-play3'
                        },
                        {
                            name: 'HIGHLIGHTED CONTENT',
                            action: 'create',
                            type: 'highlighted',
                            link: '#site/' + siteId + '/highlighted/new',
                            icon: 'ttl-highlightedcontent'
                        }
                    ]
                }
            ];

            var popup = new Love.Views.CommonMultipleListMenuPopupView({

                callbacks: {

                    itemClick: _.bind(function(e) {

                        var event = {

                            default: e.item.link,
                            preventDefault: false,
                            type: e.item.type
                        };

                        Love.Helpers.Tracking.track('Header - create ' + e.item.type);

                        this.trigger('create', event);

                        e.preventDefault = event.preventDefault;

                    }, this)
                },
                data: {columns: columns, triangle: true, mode: 'icons'},
                positioning: {

                    attachToElement: $(e.target).closest('.menu-item').find('.button-header'),
                    bottom: true,
                    centerX: true,
                    minWidth: $(e.target).closest('.menu-item').find('.button-header').width(),
                    offsetY: 14, // For triangle positioning.
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _openNotifications: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var popup = new Love.Views.CommonNotificationsPopupView({

                positioning: {

                    attachToElement: $(e.target).closest('.menu-item'),
                    bottom: true,
                    centerX: true,
                    minWidth: $(e.target).closest('.menu-item').width(),
                    offsetY: 6, // For triangle positioning.
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _openUser: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var items = [

                {name: 'Account', link: '#account', icon: 'icon-user'},
                {name: 'Log out', link: '#logout', icon: 'icon-exit'}
            ];

            var popup = new Love.Views.CommonListMenuPopupView({

                data: {items: items, triangle: true, mode: 'icons'},
                positioning: {

                    attachToElement: $(e.target).closest('.menu-item'),
                    bottom: true,
                    centerX: true,
                    minWidth: $(e.target).closest('.menu-item').width(),
                    offsetY: 6, // For triangle positioning.
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        }
    });

})(Love);