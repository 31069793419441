(function (Love) {

    Love.Models.PlaylistModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.PlaylistModel',

        defaults: function() {

            return {

                id: '',
                identifier: '',
                items: [],
                title: ''
            };
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/playlist/' + this.id + '.json';

            // Create url.

            else
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/playlists.json';
        },

        initialize: function (options) {

            if (options) this.set(options);
        },

        parse: function(response, options) {

            return (response.response) ? response.response : response;
        }
    });

    Love.Collections.PlaylistCollection = Love.Collections.BasePageableCollection.extend({

        objectClassName: 'Love.Collections.PlaylistCollection',

        model: Love.Models.PlaylistModel,
        
        url: function () { return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/playlists.json'; },

        parseRecords: function(response, options) {

            return response.response;
        }
    });

})(Love);