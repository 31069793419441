(function(Love) {

    Love.Models.FileModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.FileModel',

        defaults: function() {

            return {

                createdOn: null,
                fileName: '',
                fileSize: '',
                folderId: '',
                id: '',
                mimeType: '',
                url: ''
            };
        },

        initialize: function(options) {

            if (options) this.set(options);
        },

		dataURItoBlob: function(dataURI) {
			// convert base64/URLEncoded data component to raw binary data held in a string
			var byteString;
			if (dataURI.split(',')[0].indexOf('base64') >= 0)
				byteString = atob(dataURI.split(',')[1]);
			else
				byteString = unescape(dataURI.split(',')[1]);

			// separate out the mime component
			var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

			// write the bytes of the string to a typed array
			var ia = new Uint8Array(byteString.length);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			return new Blob([ia], {type:mimeString});
		},

        uploadScheduleFile: function(file, options) {

            if (!file) throw new Error('File must have a value.');

            options = _.extend({

                title: ''

            }, options);

            var formData = new FormData();

            formData.append('media', file);
            formData.append('title', options.title);

            return $.ajax({

                    url: '/api/1.0/site/' + Love.session.get('currentSite').id + '/schedulemedia.json',
                    data: formData,
                    type: 'POST',
                    contentType: false,
                    processData: false
                })
                .done(_.bind(function(data, textStatus, jqXHR) {

                    this.serverAttributes = this.parse(Love.Helpers.General.deepCloneJSON(data.response));
                    this.set(this.parse(data.response));

                }, this));
        },

        uploadScheduleUrl: function(url, options) {

            if (!url) throw new Error('Url must have a value.');

            // We have to use the proxy to prevent CORS errors. We can also handle orientation and resizing there to speed things up.

            url = Love.Helpers.Proxy.getProxyUrl(url, [

                {name: 'image_correct_orientation', value: true},
                {name: 'image_max_height', value: 1280},
                {name: 'image_max_width', value: 1280}
            ]);
			
            options = _.extend({

                title: ''

            }, options);

            return $.ajax({

                    url: '/api/1.0/site/' + Love.session.get('currentSite').id + '/schedulemedia.json',
                    data: {

                        title: options.title,
                        url: URI(url).absoluteTo(window.location.origin).toString()
                    },
                    type: 'POST'
                })
                .done(_.bind(function(data, textStatus, jqXHR) {

                    this.serverAttributes = this.parse(Love.Helpers.General.deepCloneJSON(data.response));
                    this.set(this.parse(data.response));

                }, this));
        }
    });

})(Love);