(function(Love) {

    Love.Views.DashboardNewsRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.DashboardNewsRowView',

        defaults: function() {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                layoutBlock: false
            });
        },

        renderItem: function() {

            if (this.options.type === 'item') {

                var context = _.extend(_.clone(this.options.item.attributes), {

                    viewOptions: _.extend(_.clone(this.options), {

                        isLoading: _.isEmpty(this.options.item.id)
                    })
                });

                this.$el.html(_.template(Love.Templates.dashboard_modules_news_row)(context));

                this.listenTo(this, 'external', function(e) {

                    var win = window.open('http://www.tagthelove.com/news/' + e.item.id, '_blank');
                    win.focus();
                });
            }
            else if (this.options.type === 'more') {

                this.$el.html(_.template(Love.Templates.dashboard_modules_news_row_more)());

                this.listenTo(this, 'more', function(e) {

                    var win = window.open('http://www.tagthelove.com/archive', '_blank');
                    win.focus();
                });
            }
        }
    });

})(Love);