(function(Love) {

    Love.Helpers.Proxy = {

        getProxyUrl: function(url, parameters) {

            var resolvedUrl = Love.Helpers.Proxy.getAbsolutePath(url);
            if (!validator.isURL(resolvedUrl)) return url;

            var result = 'proxy?csurl=' + encodeURIComponent(resolvedUrl);

            if (parameters)
                _.each(parameters, function(param) {result += '&' + param.name + '=' + param.value;});

            return result;
        },

        getAbsolutePath: function(url) {

            try {

                var link = document.createElement('a');
                link.href = url;

                return (link.protocol + '//' + link.host + link.pathname + link.search + link.hash);
            }
            catch (ex) {

                console.log(ex);
                return url;
            }
        }
    };

})(Love);