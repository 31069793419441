(function(Love) {

    Love.Views.DashboardUpcomingModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardUpcomingModuleView',

        defaults: function() {

            return {

                date: moment().add(1, 'days'),
                eventsPerSlide: 3
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_renderUpcoming');

            this._handleResize = _.debounce(_.bind(this._handleResize, this), 50);

            this._eventCollection = new Love.Collections.CalendarEventCollection();
            this._eventViews = [];
        },

        render: function() {

            var context = _.extend({

                isCalendarMobile: this.isCalendarMobile()

            }, this.options);

            this.$el.html(_.template(Love.Templates.dashboard_modules_upcoming)(context));

            if (!this.isCalendarMobile())
                this.$el.closest('.module-container').addClass('scrollbar-outside-container');

            if (this._slider)
                this._slider.close();

            this._slider = this.addSubView(new Love.Views.CommonContentSliderView({

                itemsPerSlide: this.options.eventsPerSlide
            }));
            this._slider.setElement(this.$('.common-content-slider-container')).render();

            this.stopListening(this.eventCollection, 'sync');
            this.listenToOnce(this._eventCollection, 'sync', _.bind(function() { this._renderUpcoming(this._eventCollection);}, this));

            this._eventCollection.getEventsUpcoming(this.options.date, 9, {

                requestId: this.getAjaxAbortId(),
                abortPendingByIds: [this.getAjaxAbortId()]
            });

            $(window).off('resize', this._handleResize);
            $(window).on('resize', this._handleResize);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            $(window).off('resize', this._handleResize);
        },

        isCalendarMobile: function() {

            return $(window).width() < 880; // $calendar-mobile-breakpoint
        },

        _handleResize: function() {

            this.$('.calendar-base-event').height(0); // Reset to 0 first, as the event heights determine the .common-content-slider-container's height.
            this.$('.calendar-base-event').height(this.$('.common-content-slider-container').height());
        },

        _renderUpcoming: function(collection) {

            _.each(this._eventViews, function(eventView) { eventView.close(); });
            this._eventViews = [];

            collection.each(function(event) {

                var view = this.addSubView(Love.Helpers.Calendar.baseEventViewFactory(event));
                this._eventViews.push(view);

                var html = view.render().$el;

                html.find('.calendar-event-header').append('<div class="calendar-event-upcoming-date">' + event.get('date').format('DD-MM-YYYY') + '</div>');

                html = html.wrapAll('<div class="content-slider-item events-container" />').parent();
                html = html.wrapAll('<div class="content-slider-slide" />').parent();
                html = html.wrapAll('<div>').parent();

                this._slider.addItem(html);

            }, this);

            this._handleResize();

            this._slider.init();
        }
    });

})(Love);