(function(Love) {

    Love.Views.CommonContentTextWidgetViewOld = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentTextWidgetViewOld',

        defaults: function() {

            return {

                settings: null
            };
        },

        events: {

            'input .content': '_handleContentChange',
            'drop .content': '_handleContentChangeDrop',
            'cut .content': '_handleContentChangePaste',
            'paste .content': '_handleContentChangePaste',
            'keydown .content': '_handleContentKeyDown',
            'focusin .content': '_handleContentFocusIn',
            'focusout .content': '_handleContentFocusOut'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'hasContent');

            this.toolbarView = this.addSubView(new Love.Views.CommonTextWidgetToolbarView());
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_widgets_text)({

                content: Love.Helpers.General.htmlSanitize(this.options.settings.contentHTML),
                editorId: ''
            }));

            this.toolbarView.options.editor = this.$('[contenteditable]');
            this.toolbarView.setElement(this.$('.toolbar')).render();

            this.stopListening(this.toolbarView, 'contentChanged', this._handleContentChange);
            this.listenTo(this.toolbarView, 'contentChanged', this._handleContentChange);

            this.$el.closest('.widget-container').addClass('has-text-toolbar');

            return Love.Views.BaseView.prototype.render.call(this);
        },

        hasContent: function() {

            return (this.options.settings && !_.isEmpty(this.options.settings.contentHTML));
        },

        _handleContentChange: function(e) {

            _.defer(_.bind(function() {

                var content = this.$('[contenteditable]').html();
                this._setContent(content);

            }, this));
        },

        _handleContentChangeDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var content = e.originalEvent.dataTransfer.getData('text/html');

            content = Love.Helpers.General.htmlSanitize(content);
            this.$('[contenteditable]').html(content);

            this._setContent(content);
        },

        _handleContentChangePaste: function(e) {

            // Due to the timing of the paste event, we have to defer execution.

            _.defer(_.bind(function() {

                var content = Love.Helpers.General.htmlSanitize(this.$('[contenteditable]').html());
                this.$('[contenteditable]').html(content);

                this._setContent(content);

            }, this));
        },

        _handleContentFocusIn: function(e) {

            this.$('[contenteditable]').addClass('write-mode');
        },

        _handleContentFocusOut: function(e) {

            this.$('[contenteditable]').removeClass('write-mode');
        },

        _handleContentKeyDown: function(e) {

            if (e.keyCode === 13) {

                e.preventDefault();
                Love.Helpers.General.pasteHTMLAtCaret('<br />');
            }
        },

        _raiseChanged: function() {

            this.trigger('changed', {settings: this.options.settings});
        },

        _setContent: function(value) {

            this.options.settings.contentHTML = value;
            this._raiseChanged();
        }
    });

})(Love);