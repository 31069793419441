(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'posts': {

            'popups': {

                'new_widget_remix': {

                    'bookmarks_title_interval': '(0){Bookmarks};(1-inf){Bookmarks ({{count}})};',
                    'social_title_interval': '(0){Social content};(1-inf){Social content ({{count}})};',
                    'add': 'Add to your story ({{count}})'
                }
            },

            'post_editor': {

                'feedback_drop': 'Drop your content here to create a new widget!',
                'feedback_drop_plural': 'Drop your contents here to create new widgets!',
                'feedback_drop_image': 'Drop your image here to create a new widget!',
                'feedback_drop_image_plural': 'Drop your images here to create new widgets!'
            },

            'screen': {

                'common_content_list_actions_delete_interval': '(0){Delete posts};(1){Delete 1 post};(2-inf){Delete {{count}} posts};',
                'common_content_list_description_search': 'There are {{count}} result(s) for: <span class="search">{{query}}</span>'
            },

            'sidebar': {

                'authors': 'Showing posts by <em>{{value}}</em>.',
                'authors_all': 'Showing posts by <em>all authors</em>.',
                'authors_plural': 'Showing posts by <em>{{count}} authors</em>.',
                'dates': 'Showing posts from <em>{{from}}</em> to <em>{{to}}</em>.',
                'dates_all': '<em>All dates</em> are shown.',
                'dates_from': 'Showing posts after <em>{{from}}</em>.',
                'dates_to': 'Showing posts until <em>{{to}}</em>.',
                'categories': '<em>{{value}}</em> is displayed.',
                'categories_all': '<em>All categories</em> are displayed.',
                'categories_plural': '<em>{{count}} categories</em> are displayed.',
                'sorting': 'These items are sorted by <em>{{value}}</em>.',
                'states_all': 'Posts of <em>all states</em> are displayed.',
                'storylines': '<em>{{value}}</em> is displayed.',
                'storylines_all': '<em>All storylines</em> are displayed.',
                'storylines_plural': '<em>{{count}} storylines</em> are displayed.'
            }
        }
    });

})(Love);