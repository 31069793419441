(function(Love) {

    Love.Views.CalendarEventTweetPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarEventTweetPopupView',

        className: 'base-popup popup-calendar popup-calendar-event popup-clean popup-size-auto',
        id: 'popup-calendar-event-tweet',

        defaultsForPopupType: function() {

            return {

                data: {

                    calendarEventModel: {}
                },
                enableClickInterception: false
            };
        },

        render: function() {

            // Hide the popup until it has been completely positioned, i.e. after image loading.
            // TODO RENS: hier een mooiere oplossing voor bedenken?

            this.makeInvisible();

            this.$el.append(_.template(Love.Templates.calendar_popups_event_tweet)(this.options.data.calendarEventModel.attributes));

            var tweetId = this.options.data.calendarEventModel.get('content').info.externalID;

            $.ajax({

                url: 'https://api.twitter.com/1/statuses/oembed.json',
                data: {

                    id: tweetId,
                    omit_script: true
                },
                dataType: 'jsonp',
                success: _.bind(function(data) {

                    this.$('.popup-content').html(data.html);

                    twttr.events.unbind('rendered', this._handleTweetRendered);
                    twttr.events.bind('rendered', _.bind(function(e) {

                        this._handleTweetRendered(e, tweetId);

                    }, this));

                    twttr.widgets.load(this.$('.popup-content')[0]);

                }, this)
            });

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleTweetRendered: function(e, tweetId) {

            if ($(e.target).attr('data-tweet-id') === tweetId) {

                this.resetPosition();
                this.makeVisible();

                twttr.events.unbind('rendered');
            }
        }
    });

})(Love);