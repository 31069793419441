(function(Love) {

    Love.Views.CommonTextWidgetToolbarView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonTextWidgetToolbarView',

        defaults: function() {

            return {

                editor: null,
                editorTiny: null
            };
        },

        events: {
            'mousedown [data-action]': '_handleToolbarMouseDown',
            'mousedown [data-command]': '_handleToolbarMouseDown',
			'click [data-action]': '_handleToolbarClick',
            'click [data-command]': '_handleToolbarClick',
            'mouseenter .toolbar-menu': '_handleToolbarMenuShow',
            'mouseleave .toolbar-menu': '_handleToolbarMenuHide'
        },

        initialize: function(options) {
            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'updateToolbar');
            _.bindAll(this, '_handleHyperlinkDoubleClick');

            this._handleScroll = _.debounce(_.bind(this._handleScroll, this), 50);
        },

        render: function() {

            var el = $(this.options.editor);
            if (el.length < 1) return;

            this.$el.html(_.template(Love.Templates.common_text_widget_toolbar));

            $(window).on('scroll', this._handleScroll);
            $(window).on('resize', this._handleScroll);

            el.on('mouseup', this.updateToolbar);

            this.updateToolbar();
            this._bindHyperlinks();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            // Undelegate custom events when removing the view.

            $(this.options.editor).find('a').off('dblclick', this._handleHyperlinkDoubleClick);

            $(this.options.editor).off('mouseup', this.updateToolbar);

            $(window).off('scroll', this._handleScroll);
            $(window).off('resize', this._handleScroll);
        },

        updateToolbar: function() {

            var hasFocus = this._hasFocus();

            _.each(this.$('[data-command]'), function(el) {

                var $el = $(el);

                var command = $el.attr('data-command');
                var value = $el.attr('data-value');
                var enabled = false;

                if (hasFocus) {

                    if (value)
                        enabled = (this.options.editorTiny.queryCommandValue(command) === value);
                    else
                        enabled = this.options.editorTiny.queryCommandState(command);
                }

                if (enabled) {

                    $el.addClass('enabled');

                    var menu = $el.closest('.toolbar-menu');

                    if (menu.length > 0)
                        menu.find('.menu-title span').text($el.text());
                }
                else
                    $el.removeClass('enabled');

            }, this);

            var linkData = hasFocus ? this._getLinkFromSelection() : {};

            if (linkData.url) {

                this.$('[data-action="create-link"]').addClass('hidden');
                this.$('[data-action="edit-link"], [data-action="remove-link"]').removeClass('hidden');
            }
            else {

                this.$('[data-action="create-link"]').removeClass('hidden');
                this.$('[data-action="edit-link"], [data-action="remove-link"]').addClass('hidden');
            }
        },

        _bindHyperlinks: function() {

            $(this.options.editor).find('a').off('dblclick', this._handleHyperlinkDoubleClick);
            $(this.options.editor).find('a').on('dblclick', this._handleHyperlinkDoubleClick);
        },

        _focusOnEditor: function() {
            var el = $(this.options.editor);
			el[0].focus();

            return true;
        },

        _getLinkFromSelection: function() {

            var linkData = {element: null, text: '', url: '', target: ''};
            var selObj = window.getSelection();

            if (selObj) {

                var anchor = $(selObj.anchorNode).parentsUntil('[contenteditable]', 'a');

                if (anchor.length < 1 && selObj.rangeCount > 0) {

                    var range = selObj.getRangeAt(0);
                    var nodes = Love.Helpers.General.getRangeSelectedNodes(range);

                    for (var i = 0; i < nodes.length; i++) {

                        if (nodes[i].tagName === 'A') {

                            anchor = $(nodes[i]);
                            break;
                        }
                    }
                }

                linkData.element = anchor.length > 0 ? anchor[0] : null;
                linkData.text = anchor.length > 0 ? anchor.text() : selObj.toString();
                linkData.url = anchor.length > 0 ? anchor.attr('href') : '';

                var target = anchor.attr('target') ? anchor.attr('target') : '';
                linkData.target = anchor.length > 0 ? target : '';
            }

            return linkData;
        },

        _handleHyperlinkDoubleClick: function(e) {

            this._setSelectionToContents(e.target); // For visual feedback only.

            var linkData = this._getLinkFromSelection();
            this._showEditHyperlinkPopup(linkData);
        },

        _handleScroll: function() {

            var toolbar = this.$('.common-text-widget-toolbar');
            var container = toolbar.closest('.widget-container');

            // Only toolbars inside a widget container need sticky behavior.

            if (container.length > 0) {

                var containerPosition = container.offset().top;
                var windowHeight = $(window).height();
                var headerHeight = $('#common-header').height();
                var contentHeight = windowHeight - headerHeight;

                var shouldBeSticky = ($(window).scrollTop() > containerPosition - headerHeight);

                // We cannot make something sticky if it's larger than the view area. Otherwise it would have to be scrollable as well.

                shouldBeSticky = (shouldBeSticky && this.$('.common-text-widget-toolbar').height() <= contentHeight);

                if (shouldBeSticky) {

                    toolbar.addClass('sticky');

                    toolbar.css('left', container.offset().left);
                    toolbar.css('width', container.width());
                }
                else {

                    toolbar.css('left', 'auto');
                    toolbar.css('width', '100%');

                    toolbar.removeClass('sticky');
                }
            }
        },

        _handleToolbarMouseDown: function(e) {
            // Prevent toolbar buttons from gaining focus. This enables execCommand calls.

            if (e && e.preventDefault) e.preventDefault();
			return false;
        },

        _handleToolbarClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            // If a dropdown menu was shown, it should be closed now.

            this.$('.menu-dropdown').hide();

            if (!this._focusOnEditor()) return;

            var action = $(e.currentTarget).attr('data-action');
            var command = $(e.currentTarget).attr('data-command');

            if (command) {

                var value = $(e.currentTarget).attr('data-value');

                Love.Helpers.Tracking.track('Text widget toolbar - command ' + command, {

                    data: value
                });

                this._performCommand(command, value);
            }

            if (action) {

                Love.Helpers.Tracking.track('Text widget toolbar - action ' + action);

                var self = this;
                var linkData, popup;

                if (action === 'create-link') {

                    var selObj = window.getSelection();
                    var range = document.createRange();

                    if (selObj.focusOffset > selObj.anchorOffset) {

                        range.setStart(selObj.anchorNode, selObj.anchorOffset);
                        range.setEnd(selObj.focusNode, selObj.focusOffset);
                    }
                    else {

                        range.setStart(selObj.focusNode, selObj.focusOffset);
                        range.setEnd(selObj.anchorNode, selObj.anchorOffset);
                    }

                    if (selObj.toString().length > 0) {

                        popup = new Love.Views.CommonEditHyperlinkPopupView({

                            callbacks: {

                                onConfirm: function(data) {

                                    if (data.url.length > 0) {

                                        selObj.removeAllRanges();
                                        selObj.addRange(range);

                                        self._performCommand('insertHTML', '<a href="' + data.url + '" target="' + data.target + '">' + data.text + '</a>');
                                        self._bindHyperlinks();
                                    }
                                }
                            },
                            data: {text: selObj.toString()},
                            positioning: {

                                attachToElement: selObj.getRangeAt(0),
                                centerX: true,
                                positionAt: 'text',
                                top: true
                            }
                        });

                        popup.showPopup();
                    }
                }

                else if (action === 'edit-link') {

                    linkData = this._getLinkFromSelection();

                    this._setSelectionToContents(linkData.element); // For visual feedback only.
                    this._showEditHyperlinkPopup(linkData);
                }

                else if (action === 'remove-link') {

                    linkData = this._getLinkFromSelection();

                    this._setSelectionToContents(linkData.element); // Actually necessary now.
                    this._performCommand('unlink');
                }
            }
        },

        _handleToolbarMenuShow: function(e) {

            $(e.currentTarget).find('.menu-dropdown').show();
        },

        _handleToolbarMenuHide: function(e) {

            $(e.currentTarget).find('.menu-dropdown').hide();
        },

        _hasFocus: function() {

            var el = $(this.options.editor);
            return el.is(':focus');
        },

        _performCommand: function(command, value) {

            this.options.editorTiny.execCommand(command, false, value);
            this.trigger('contentChanged');

            this.updateToolbar();
        },

        _setSelectionToContents: function(element) {

            if (element) {

                var range = document.createRange();
                var selObj = window.getSelection();

                range.selectNodeContents(element);

                selObj.removeAllRanges();
                selObj.addRange(range);
            }
        },

        _showEditHyperlinkPopup: function(linkData) {

            var self = this;
            var popup = new Love.Views.CommonEditHyperlinkPopupView({

                callbacks: {

                    onConfirm: function(data) {

                        if (data.url.length > 0) {

                            self._setSelectionToContents(linkData.element); // Actually necessary now.
                            self._performCommand('insertHTML', '<a href="' + data.url + '" target="' + data.target + '">' + data.text + '</a>');
                            self._bindHyperlinks();
                        }
                    },
                    onRemove: function(data) {

                        self._setSelectionToContents(linkData.element); // Actually necessary now.
                        self._performCommand('unlink');
                        self._performCommand('insertText', data.text);
                    }
                },
                data: linkData,
                positioning: {

                    attachToElement: $(linkData.element), // <a> element
                    centerX: true,
                    positionAt: 'element',
                    top: true
                }
            });

            popup.showPopup();
        }
    });

})(Love);