(function(Love) {

    Love.Models.PageModel = Love.Models.BaseContentModel.extend({

        objectClassName: 'Love.Models.PageModel',

        defaults: function() {

            return _.extend(Love.Models.BaseContentModel.prototype.defaults.call(this), {

                contentType: 'page',
                path: ''
            });
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/page/' + this.id + '.json?sid=' + Love.session.get('currentSiteId');

            // Create url.

            else
                return 'api/1.0/pages.json?sid=' + Love.session.get('currentSiteId');
        },

        validate: function(attributes, options) {

            var errors = {};

            if (_.isEmpty(attributes.path)) errors.path = 'Path required.';

            // TODO: attributes valideren voor save.

            if (!_.isEmpty(errors))
                return errors;
        },

        getClientBackupKey: function() {

            return 'pages_page_' + this.id;
        }
    });

    Love.Collections.PageCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.PageCollection',

        model: Love.Models.PageModel,

        url: function() { return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/pages.json'; },

        parse: function (response, options) {

            return _.filter(response.response, function(page) {

                return page.hasRevision; // All pages should have a revision, but some seem to corrupted in the database.
            });
        }
    });

})(Love);