(function (Love) {

    Love.Views.CommonPaginationView = Love.Views.BaseHeaderView.extend({

        objectClassName: 'Love.Views.CommonPaginationView',

        defaults: function () {

            return {

                breakAfter: 3,
                selectItemsPerPage: true,
                currentPage: 1,
                itemsPerPage: 5,
                itemsPerPageValues: [2, 5, 10, 20, 50],
                startAt: 1,
                totalItems: 0
            };
        },

        events: {

            'click [data-page]:not(.disabled)': '_handlePageClick',
            'change [name="items-per-page"]': '_handleItemsPerPage'
        },

        render: function () {

            // Check if values are (still) within bounds.

            var totalPages = this._getTotalPages();

            if (totalPages < 1) return;
            if (this.options.currentPage > totalPages) this.options.currentPage = totalPages;
            if (this.options.startAt > totalPages) this.options.startAt = totalPages;

            var context = _.extend(_.clone(this.options), {

                totalPages: totalPages
            });

            // Check how many pages there would be left after the break-after point.

            var breakAfterPage = this.options.startAt + this.options.breakAfter - 1;
            var pagesLeft = totalPages - (breakAfterPage + 1);

            if (pagesLeft < 2)
                context.breakAfter = totalPages; // I.e., never.

            this.$el.html(_.template(Love.Templates.common_pagination)(context));
            this.$el.addClass('common-pagination button-toolbar');

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _getTotalPages: function () {

            return Math.ceil(this.options.totalItems / this.options.itemsPerPage);
        },

        _handlePageClick: function (e) {

            if (e && e.preventDefault) e.preventDefault();

            var page = $(e.target).closest('[data-page]').attr('data-page');

            var direction = 'right';
            var newPage;

            if (page === 'previous') {

                direction = 'left';
                newPage = this.options.currentPage - 1;
            }

            else if (page === 'next')
                newPage = this.options.currentPage + 1;

            else
                newPage = parseInt(page);

            var totalPages = this._getTotalPages();

            var minPagesToShow = Math.min(this.options.breakAfter + 2, totalPages);
            var minStartAtPage = totalPages - minPagesToShow + 1;

            if (direction === 'left' && newPage < this.options.startAt) {

                this.options.startAt = Math.min(minStartAtPage, newPage);
            }
            else if (direction === 'right' && newPage >= this.options.startAt + this.options.breakAfter) {

                this.options.startAt = Math.min(minStartAtPage, newPage);
            }

            this.options.currentPage = newPage;
            this.render();

            this._raisePageChange();
        },

        _handleItemsPerPage: function (e) {

            var items = parseInt($(e.target).val());

            this.options.itemsPerPage = items;
            this.render();

            Love.Helpers.Tracking.track('Pagination - changed items per page to ' + items);

            this._raiseItemsPerPageChange();
        },

        _raiseItemsPerPageChange: function () {

            this.trigger('itemsPerPageChanged', this.options);
        },

        _raisePageChange: function () {

            this.trigger('pageChanged', this.options);
        }
    });

})(Love);