(function(Love) {

    Love.Views.CommonContentTextPlainWidgetView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentTextPlainWidgetView',

        defaults: function() {

            return {

                disabled: false,
                maxLength: null,
                settings: null
            };
        },

        events: {

            'click .content': '_handleContentClick',
            'keydown .content': '_handleContentKeyDown',
            'input .content': '_handleContentChange',
            'drop .content': '_handleContentChangeDrop',
            'paste .content': '_handleContentChangePaste'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'hasContent');
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_widgets_text_plain)({

                disabled: this.options.disabled,
                maxLength: this.options.maxLength,
                content: this.options.settings.contentHTML
            }));

            this._setPlaceholderIfEmpty();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        hasContent: function() {

            return (this.options.settings && !_.isEmpty(this.options.settings.contentHTML));
        },

        setMaxLength: function(value) {

            if (this.options.maxLength !== value) {

                this.options.maxLength = value;

                if (value) {

                    this._trimToLength(value);
                    this.$('.content').attr('maxlength', value);
                }
                else
                    this.$('.content').removeAttr('maxlength');
            }
        },

        _handleContentChange: function(e) {

            var content = $(e.target).val().trim();
            this._setContent(content);
        },

        _handleContentChangeDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var content = e.originalEvent.dataTransfer.getData('text/plain').trim();

            $(e.target).val(content);
            this._setContent(content);
        },

        _handleContentChangePaste: function(e) {

            // Due to the timing of the paste event, we have to defer execution.

            _.defer(_.bind(function() {

                var content = $(e.target).val().trim();

                $(e.target).val(content);
                this._setContent(content);

            }, this));
        },

        _raiseChanged: function() {

            this.trigger('changed', {settings: this.options.settings});
        },

        _setContent: function(value) {

            this.options.settings.contentHTML = value;
            this._setPlaceholderIfEmpty();

            this._raiseChanged();
        },

        _setPlaceholderIfEmpty: function() {

            this.$('.content').toggleClass('empty', _.isEmpty(this.options.settings.contentHTML));
        },

        _trimToLength: function(length) {

            var content = this.$('.content').val().trim().substr(0, length);

            this.$('.content').val(content);
            this._setContent(content);
        }
    });

})(Love);