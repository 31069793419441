(function(Love) {

    Love.Views.PostScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.PostScreenView',

        defaults: function() {

            return {

                date: null,
                edit: null,
                fromBookmarkIds: [],
                fromCache: false,
                fromMediaIds: [],
                fromPageCache: false,
                fromUrl: '',
                postId: null,
                saveNewAsDraft: false // TODO: disabled because of timing issue. When the user makes changes while saving, these go unnoticed by Backbone.
            };
        },

        events: {

            /*
             'change [name="edit-post-title"]': '_handleInputChanged',
             'keyup [name="edit-post-title"]': '_handleInputChanged',
             'cut [name="edit-post-title"]': '_handleInputChanged',
             'paste [name="edit-post-title"]': '_handleInputChanged',
             'change [name="edit-post-summary"]': '_handleInputChanged',
             'keyup [name="edit-post-summary"]': '_handleInputChanged',
             'cut [name="edit-post-summary"]': '_handleInputChanged',
             'paste [name="edit-post-summary"]': '_handleInputChanged',
             */
            'input [name="edit-post-title"]': '_handleInputChanged',
            'input [name="edit-post-summary"]': '_handleInputChanged',

            'click .action-buttons-container [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .action-buttons-container [data-action="delete"]:not(.disabled)': '_handleDeleteClick',
            'click .action-buttons-container [data-action="convert"]:not(.disabled)': '_handleConvertClick',
            'click .action-buttons-container [data-action="schedule"]:not(.disabled)': '_handleScheduleClick',
            'click .action-buttons-container [data-action="unschedule"]:not(.disabled)': '_handlePublishClick',
            'click .action-buttons-container [data-action="publish"]:not(.disabled)': '_handlePublishClick',
            'click .action-buttons-container [data-action="unpublish"]:not(.disabled)': '_handlePublishClick',

            'click .base-sidebar [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .base-sidebar [data-action="preview"]:not(.disabled)': '_handlePreviewClick',
            'click .base-sidebar [data-action="delete"]:not(.disabled)': '_handleDeleteClick',
            'click .base-sidebar [data-action="schedule"]:not(.disabled)': '_handleScheduleClick',
            
            'click .base-sidebar [data-action="unschedule"]:not(.disabled)': '_handleScheduleClick',
            'click .base-sidebar [data-action="publish"]:not(.disabled)': '_handlePublishClick',
            'click .base-sidebar [data-action="unpublish"]:not(.disabled)': '_handlePublishClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            // Not necessary if getData uses refreshing: Love.session.get('currentSite').requireData('bookmarksLatest', true);
            // Not necessary if getData uses refreshing: Love.session.get('currentSite').requireData('bookmarksLatestImages', true);

            _.bindAll(this, '_checkExistingBackup', '_updatePublishButton');

            this.editModel = new Love.Models.BlogPostModel();

            // The view state contains information relevant to this view and any sub views.
            // We use a standard Backbone model, since it's not part of the API but (change) events are useful.

            this.viewState = new Backbone.Model({

                activeRevision: this.editModel.getDefaultRevision(),
                changedByUser: false,
                disableSaving: false
            });

            var createFromBookmarks = (this.options.fromBookmarkIds && this.options.fromBookmarkIds.length > 0);
            var createFromCache = (this.options.fromCache && Love.viewBag.remixIdentifierCache);
            var createFromMedia = (this.options.fromMediaIds && this.options.fromMediaIds.length > 0);
            var createFromPageCache = (this.options.fromPageCache && Love.viewBag.postFromPageCache);
            var createFromUrl = !_.isEmpty(this.options.fromUrl);
            var createFromRemix = createFromBookmarks || createFromCache || createFromMedia || createFromUrl;

            var fromBookmarkIds = this.options.fromBookmarkIds;
            var fromMediaIds = this.options.fromMediaIds;
            var fromUrls = createFromUrl ? [this.options.fromUrl] : [];

            if (createFromPageCache || createFromRemix) {

                if (createFromPageCache) {

                    var attr = Love.viewBag.postFromPageCache;
                    delete Love.viewBag.postFromPageCache;

                    this.editModel = new Love.Models.BlogPostModel(attr, {parse: true});
                }

                if (createFromRemix) {

                    if (createFromCache) {

                        // Try fetching any cached items that can be Remixed to fragments.

                        var identifiers = Love.viewBag.remixIdentifierCache;

                        fromBookmarkIds = _.unique(fromBookmarkIds.concat(identifiers.bookmarkIds));
                        fromMediaIds = _.unique(fromMediaIds.concat(identifiers.mediaIds));
                        fromUrls = _.unique(fromUrls.concat(identifiers.urls));
                    }

                    // Create a new post from existing Remixable content.

                    this._createFromRemixPromises = [];

                    _.each(fromBookmarkIds, function(id) {

                        // We add the bookmark to a list here, synchronously. This is to ensure that bookmarks are
                        // rendered in correct order, independently of the AJAX request completion.

                        var promise = $.Deferred();
                        this._createFromRemixPromises.push(promise);

                        var bookmark = new Love.Models.BookmarkModel({id: id});

                        bookmark.fetch({

                            success: function(model, response, options) {

                                var fragmentOrPromise = Love.Factories.FragmentFactory.createFragmentFromBookmark(model);

                                $.when(fragmentOrPromise).always(function(fragment) {

                                    if (fragment)
                                        promise.resolve(fragment);
                                    else
                                        promise.reject();
                                });
                            },
                            error: function() { promise.reject(); }
                        });

                    }, this);

                    _.each(fromMediaIds, function(id) {

                        // We add the media to a list here, synchronously. This is to ensure that media are
                        // rendered in correct order, independently of the AJAX request completion.

                        var promise = $.Deferred();
                        this._createFromRemixPromises.push(promise);

                        var media = new Love.Models.MediaModel({mediaID: id});

                        media.fetch({

                            success: function(model, response, options) {

                                var fragmentOrPromise = Love.Factories.FragmentFactory.createFragmentFromMedia(model);

                                $.when(fragmentOrPromise).always(function(fragment) {

                                    if (fragment)
                                        promise.resolve(fragment);
                                    else
                                        promise.reject();
                                });
                            },
                            error: function() { promise.reject(); }
                        });

                    }, this);

                    _.each(fromUrls, function(url) {

                        // We add the urls to a list here, synchronously. This is to ensure that the urls are
                        // rendered in correct order, independently of the AJAX request completion.

                        var promise = $.Deferred();
                        this._createFromRemixPromises.push(promise);

                        var fragmentOrPromise = Love.Factories.FragmentFactory.createFragmentFromString(url);

                        $.when(fragmentOrPromise).always(function(fragment) {

                            if (fragment)
                                promise.resolve(fragment);
                            else
                                promise.reject();
                        });

                    }, this);
                }
            }
            else {

                if (this.options.postId)
                    this.editModel.set('id', this.options.postId);

                if (this.options.date && this.options.date.isValid() && this.options.date.isAfter(moment()))
                    this.editModel.set('scheduledOn', this.options.date);
            }
        },

        render: function() {

            // Remove any existing event listeners in case of re-rendering.

            this.stopListening(this.viewState, 'change:disableSaving');

            this.stopListening(this.editModel, 'change', this.editModel.saveClientBackup);
            this.stopListening(this.editModel, 'change:revisions');
            this.stopListening(this.editModel, 'change:scheduledOn', this._updatePublishButton);
            this.stopListening(this.editModel, 'changedByUser');

            // Recreate the subviews, to ensure their event handlers are also removed.

            if (this.editorView) this.editorView.close();

            if (this.sidebarView) {

                this.stopListening(this.sidebarView, 'rendered');
                this.stopListening(this.sidebarView, 'revisionChanged');
                this.stopListening(this.sidebarView, 'toggle');

                this.sidebarView.close();
            }

            this.listenTo(this.viewState, 'change:disableSaving', _.bind(function() {this._setSavingEnabled(!this.viewState.get('disableSaving'));}, this));

            this.editorView = this.addSubView(new Love.Views.PostEditorView({

                editModel: this.editModel,
                viewState: this.viewState
            }));

            this.sidebarView = this.addSubView(new Love.Views.PostSidebarView({

                editModel: this.editModel,
                viewState: this.viewState
            }));

            if (this.editModel.isNew()) {

                var createFromBookmarks = (this.options.fromBookmarkIds && this.options.fromBookmarkIds.length > 0);
                var createFromCache = (this.options.fromCache && Love.viewBag.remixIdentifierCache);
                var createFromMedia = (this.options.fromMediaIds && this.options.fromMediaIds.length > 0);
                var createFromUrl = !_.isEmpty(this.options.fromUrl);
                var createFromRemix = createFromBookmarks || createFromCache || createFromMedia || createFromUrl;

                var whenLoaded = _.bind(function() {

                    this._checkScheduledOn();
                    this._setDefaultCategory();
                    this._setDefaultCoverImage();
                    this._renderModel();

                    // If saving new as draft is enabled, the model is still new and there are actual changes to save, do it now.

                    if (this.options.saveNewAsDraft && this.editModel.isNew() && this._hasUnsavedUserChanges())
                        this._savePostModel(true);

                }, this);

                if (createFromRemix) {

                    // As it might take a while, render an empty page to provide some feedback to the user.
                    // This also ensures that there is a layout displayed over which the loading overlay can be displayed.

                    this._renderModel(); // NOTE: this adds event listeners for the model. Therefore, we have to insert fragments silently when they are available.

                    Love.Helpers.Loading.showLoading(true);

                    $.whenAll.apply($, this._createFromRemixPromises).always(_.bind(function(promises) {

                        var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];

                        promises.reverse(); // Reversing and inserting provides a nicer UI flow.

                        _.each(promises, function(resultArray) {

                            var state = resultArray[0];
                            var fragment = resultArray[1];

                            if (state === 'resolved' && fragment) {

                                this.editModel.insertFragment(revision, fragment.type, fragment.settings, 0, true);
                                this.viewState.set('changedByUser', true);
                            }

                        }, this);

                        Love.Helpers.Loading.showLoading(false);

                        delete Love.viewBag.remixIdentifierCache;

                        whenLoaded();

                    }, this));
                }
                else
                    whenLoaded(); // We don't have to render an empty page first, as the final empty model will be rendered immediately.
            }
            else {

                // We could render an empty page first, but let's assume loading an existing post is fast enough.

                var self = this;
                this.listenToOnce(this.editModel, 'sync', function() {

                    self.viewState.set('activeRevision', self.editModel.getDefaultRevision());

                    self._checkScheduledOn();
                    self._setDefaultCoverImage();
                    self._renderModel();

                    _.defer(self._checkExistingBackup);
                });

                this.editModel.fetch({

                    error: function(model, response, options) {

                        Love.router.navigate('error/404', {trigger: true});
                    }
                });
            }

            this.listenTo(Love.appView, 'navigate', _.bind(function(e) {

                if (this._hasUnsavedUserChanges())
                    e.unsavedChanges = true;

            }, this));

            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _checkExistingBackup: function() {

            // Check if a backup for changes exists in the local storage.

            var entry = this.editModel.getClientBackup();

            if (entry) {

                var self = this;
                var popup = new Love.Views.CommonQuestionPopupView({

                    callbacks: {

                        onNo: this.editModel.removeClientBackup,
                        onYes: function() {

                            Love.Helpers.Tracking.track('Post screen - restore client backup', {

                                model: self.editModel.id
                            });

                            self.editModel.set(self.editModel.parse(entry.backup));
                            self.editModel.trigger('changedByUser', self.editModel);

                            self.viewState.set('activeRevision', self.editModel.getDefaultRevision());

                            self._renderModel();
                        }
                    },
                    data: {

                        title: 'Restore edits?',
                        text: 'A backup of local changes was made at ' + entry.updatedAt.format('ddd, DD MMM YYYY, HH:mm') + '. Do you want to restore it?'
                    }
                });

                popup.showPopup();
            }
        },

        _checkScheduledOn: function() {

            // Check if the scheduled on date is in the future.

            var scheduledOn = this.editModel.get('scheduledOn');

            if (scheduledOn && scheduledOn.isBefore(moment())) {

                this.editModel.set('isScheduled', false);
                this.editModel.set('scheduledOn', null);
            }
        },

        _checkSummaryURL: function() {

            var summary = this.editModel.get('revisions')[this.viewState.get('activeRevision')].summary;
            var summaryElement = this.$('[name="edit-post-summary"]');
            var containsLink = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(summary);

            summaryElement.closest('.form-section').find('[data-feedback="invalid"]').toggleClass('hidden', !containsLink);
        },

        _handleConvertClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        Love.Helpers.Tracking.track('Post screen - convert to page', {

                            model: self.editModel.id
                        });

                        // Create a new model based on the current model's attributes.

                        var newModel = Love.Factories.PageFactory.createFromBlogPostModel(self.editModel);

                        Love.viewBag.pageFromPostCache = newModel.attributes;
                        Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/page/new/post/cache', {trigger: true});
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Convert to page?',
                    text: 'This will convert the page to a new page. The current post won\'t be deleted, to be on the safe side.',
                    yesText: 'Convert'
                }
            });

            popup.showPopup();
        },

        _handleDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        Love.Helpers.Tracking.track('Post screen - delete', {

                            model: self.editModel.id
                        });

                        self.editModel.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                self.viewState.set('changedByUser', false);
                                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/posts', {trigger: true});
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete post?',
                    text: 'Do you really want to delete this post? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleInputChanged: function(e) {

            var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];

            revision.title = this.$('[name="edit-post-title"]').val();
            revision.summary = this.$('[name="edit-post-summary"]').val();

            this.editModel.trigger('change:revisions', this, this.editModel.get('revisions'), {

                action: '',
                revision: revision
            });
            this.editModel.trigger('change', this.editModel);
            this.editModel.trigger('changedByUser', this.editModel);

            this._checkSummaryURL();
        },

        _handlePublishClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var publish = !(this.editModel.get('isPublished'));

            var performAction = function() {

                Love.Helpers.Tracking.track('Post screen - publish', {

                    model: self.editModel.id
                });

                self.editModel.set({

                    isPublished: publish,
                    publishedOn: (publish) ? moment() : self.editModel.get('publishedOn')
                });

                self._handleSaveClick(e, true);
            };

            if (publish) {

                var popup = new Love.Views.PostsConfirmationPopupView({

                    callbacks: {

                        onConfirm: performAction
                    },
                    data: {

                        action: 'publish',
                        editModel: this.editModel
                    }
                });

                popup.showPopup();
            }
            else
                performAction();
        },
		
		_handlePreviewClick: function(e, programmatically) {
			var $buttonEl = $(e.currentTarget);
			window.open($buttonEl.attr('href'));
		},

        _handleSaveClick: function(e, programmatically) {

            if (e && e.preventDefault) e.preventDefault();

            if (!programmatically)
                Love.Helpers.Tracking.track('Post screen - save', {

                    model: this.editModel.id
                });

            // ------------------------------------------------
            // TODO RENS: temporary fix voor title die niet goed is opgeslagen in revision (ie., als lege string ipv wat in het veld staat)
            // ------------------------------------------------

            var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];

            var title = this.$('[name="edit-post-title"]').val();
            var summary = this.$('[name="edit-post-summary"]').val();

            if (revision.title !== title) {

                console.log('ERROR FIXED: title niet goed overgenomen in editModel. Model value: ' + revision.title + '. Form value: ' + title);
                revision.title = title;
            }

            if (revision.summary !== summary) {

                console.log('ERROR FIXED: summary niet goed overgenomen in editModel. Model value: ' + revision.summary + '. Form value: ' + summary);
                revision.summary = summary;
            }

            // ------------------------------------------------
            // END FIX
            // ------------------------------------------------

            Love.Helpers.Loading.showLoading(true);

            var result = this._savePostModel();

            $.when(result).always(function() { Love.Helpers.Loading.showLoading(false);});
        },

        _handleScheduleClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var schedule = !(this.editModel.get('isScheduled'));

            var performAction = function() {

                Love.Helpers.Tracking.track('Post screen - schedule', {

                    model: self.editModel.id,
                    data: self.editModel.get('scheduledOn')
                });

                self.editModel.set({

                    isScheduled: schedule
                });

                self._handleSaveClick(e, true);
            };

            if (schedule) {

                var popup = new Love.Views.PostsConfirmationPopupView({

                    callbacks: {

                        onConfirm: performAction
                    },
                    data: {

                        action: 'schedule',
                        editModel: this.editModel
                    }
                });

                popup.showPopup();
            }
            else
                performAction();
        },

        _hasUnsavedUserChanges: function() {

            return (this.editModel && (this.editModel.isNew() || this.editModel.changedAttributesJSON(this.editModel.serverAttributes)) && this.viewState.get('changedByUser'));
        },

        _renderModel: function() {

            var self = this;

            $(window).scrollTo(0, 'normal');

            var context = _.extend(_.clone(this.editModel.attributes), this.viewState.attributes);
            this.$el.html(_.template(Love.Templates.posts_post_screen)(context));

            this.listenTo(this.sidebarView, 'rendered', _.bind(function() {

                // This needs to be called after the sidebar has been rendered completely.
                // It also updates the buttons in the current view, which is okay to take place at the same timing.

                this._updatePublishButton();

                switch (this.options.edit) {

                    case 'category':
                        this.sidebarView.openCategory();
                        break;
                    case 'social':
                        this.sidebarView.openSocial();
                        break;
                    case 'storylines':
                        this.sidebarView.openStorylines();
                        break;
                }

                this.options.edit = null; // Only do it once.

            }, this));

            this.editorView.setElement(this.$('.editor-container')).render();
            this.sidebarView.setElement(this.$('.base-sidebar-container')).render();

            this.listenTo(this.editModel, 'change', this.editModel.saveClientBackup);
            this.listenTo(this.editModel, 'change:scheduledOn', this._updatePublishButton);

            this.listenTo(this.editModel, 'change:revisions', function(model, value, options) {

                options = options || {};

                if (options.action === 'insertFragment' || options.action === 'settingsFragment')
                    self._setDefaultCoverImage();
            });

            this.listenTo(this.editModel, 'changedByUser', function(e) {

                self.viewState.set('changedByUser', true);

                // If saving new as draft is enabled, the model is still new and there are actual changes to save, do it now.

                if (self.options.saveNewAsDraft && self.editModel.isNew() && self._hasUnsavedUserChanges())
                    self._savePostModel(true);
            });

            this.listenTo(this.sidebarView, 'revisionChanged', function(e) {

                // Update the view state. The sidebar and editor view have an object reference and event listener
                // to/for this view state, so also get updated.

                self.viewState.set('activeRevision', e.newLanguage);

                // As the set will trigger a change event which in turn re-renders the views, at this point the new
                // revision should be displayed. We now scroll to the top of the window. NOTE: only the editor and
                // sidebar views are re-rendered, not the entire post screen.

                $(window).scrollTo(0, 'normal');
            });

            this.listenTo(this.sidebarView, 'toggle', function(e) {

                self.performDotDotDotUpdate();
                self.performLazyLoadImagesResize(true);
            });
        },

        _savePostModel: function(newAsDraft) {

            this._setSavingEnabled(false);
            this._showFeedback(false);

            var wasNew = this.editModel.isNew();

            if (newAsDraft) {

                this.options.saveNewAsDraft = false; // We can only do this once.
                if (!wasNew) return;
            }

            var result = this.editModel.save(this.editModel.attributes, {

                wait: true,
                success: _.bind(function(model, response, options) {

                    if (wasNew) {

                        // If it was a new post, update the url.

                        Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/' + model.id, {

                            trigger: false,
                            replace: true
                        });
                    }

                    // Any changes by the user have now been saved.

                    this.viewState.set('changedByUser', false);

                    // After saving, for both new and old posts, the post model attributes are updated.
                    // This raises a change event which in turn saves a copy of the model to the local storage.
                    // Therefore, we have to remove the client backup here.

                    this.editModel.removeClientBackup();

                    if (!newAsDraft)
                        this._renderModel();

                    this._setSavingEnabled(true);

                }, this),
                error: _.bind(function(model, response, options) {

                    this._setSavingEnabled(true);
                    this._showFeedback(true);

                }, this)
            });

            if (!result) {

                this._setSavingEnabled(true); // Validation failed.
                this._showFeedback(true, "You didn't provide all necessary values. Please check again!", this.editModel.validate(this.editModel.attributes));
            }

            return result;
        },

        _setDefaultCategory: function() {

            var blogs = Love.session.get('currentSite').get('blogs');

            if (!this.editModel.get('groupId') && blogs.length > 0)
                this.editModel.set('groupId', blogs[0].id);
        },

        _setDefaultCoverImage: function() {

            var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];
            var other = this.editModel.getCoverOrDefault(revision);

            if (!_.isEqual(revision.cover, other)) {

                revision.cover = other;

                this.editModel.trigger('change:revisions', this, this.editModel.get('revisions'), {

                    action: 'cover',
                    revision: revision
                });
                this.editModel.trigger('change', this.editModel);
            }
        },

        _setSavingEnabled: function(enabled) {

            if (enabled) {

                this.$('.action-buttons-container [data-action="save"]').removeClass('disabled');
                this.$('.action-buttons-container [data-action="schedule"]').removeClass('disabled');
                this.$('.action-buttons-container [data-action="unschedule"]').removeClass('disabled');
                this.$('.action-buttons-container [data-action="publish"]').removeClass('disabled');
                this.$('.action-buttons-container [data-action="unpublish"]').removeClass('disabled');

                this.$('.base-sidebar [data-action="save"]').removeClass('disabled');
                this.$('.base-sidebar [data-action="schedule"]').removeClass('disabled');
                this.$('.base-sidebar [data-action="unschedule"]').removeClass('disabled');
                this.$('.base-sidebar [data-action="publish"]').removeClass('disabled');
                this.$('.base-sidebar [data-action="unpublish"]').removeClass('disabled');
            }
            else {

                this.$('.action-buttons-container [data-action="save"]').addClass('disabled');
                this.$('.action-buttons-container [data-action="schedule"]').addClass('disabled');
                this.$('.action-buttons-container [data-action="unschedule"]').addClass('disabled');
                this.$('.action-buttons-container [data-action="publish"]').addClass('disabled');
                this.$('.action-buttons-container [data-action="unpublish"]').addClass('disabled');

                this.$('.base-sidebar [data-action="save"]').addClass('disabled');
                this.$('.base-sidebar [data-action="schedule"]').addClass('disabled');
                this.$('.base-sidebar [data-action="unschedule"]').addClass('disabled');
                this.$('.base-sidebar [data-action="publish"]').addClass('disabled');
                this.$('.base-sidebar [data-action="unpublish"]').addClass('disabled');
            }
        },

        _showFeedback: function(show, message, errors) {

            if (show) {

                if (!message)
                    message = 'Oops! Something went wrong.';

                this.$('.editor-feedback').text(message);

                this.$('[data-model-field]').removeClass('feedback-error');

                _.each(errors, _.bind(function(message, field) {

                    this.$('[data-model-field="' + field + '"]').addClass('feedback-error');

                }, this));
            }

            this.$('.editor-feedback').toggleClass('hidden', !show);
        },

        _updatePublishButton: function() {

            // If the post isn't published yet and the schedule date is changed, we display the schedule action instead of the publish action,
            // if the post hasn't already been scheduled.
			
			var postId = this.editModel.get('id');
			if (postId.length > 0) {
				this.$('[data-action="preview"]').attr('href', '/blogpreview/' + Love.session.get('currentSiteId') + '/' + postId);
				this.$('[data-action="preview"]').removeClass('hidden');
			}

            if (this.editModel.get('isPublished')) {

                this.$('[data-action="publish"]').addClass('hidden');
                this.$('[data-action="unpublish"]').removeClass('hidden');
                this.$('[data-action="schedule"]').addClass('hidden');
                this.$('[data-action="unschedule"]').addClass('hidden');
            }
            else if (this.editModel.get('isScheduled')) {

                this.$('[data-action="publish"]').addClass('hidden');
                this.$('[data-action="unpublish"]').addClass('hidden');
                this.$('[data-action="schedule"]').addClass('hidden');
                this.$('[data-action="unschedule"]').removeClass('hidden');
            }
            else if (this.editModel.get('scheduledOn')) {

                this.$('[data-action="publish"]').addClass('hidden');
                this.$('[data-action="unpublish"]').addClass('hidden');
                this.$('[data-action="schedule"]').removeClass('hidden');
                this.$('[data-action="unschedule"]').addClass('hidden');
            }
            else {

                this.$('[data-action="publish"]').removeClass('hidden');
                this.$('[data-action="unpublish"]').addClass('hidden');
                this.$('[data-action="schedule"]').addClass('hidden');
                this.$('[data-action="unschedule"]').addClass('hidden');
            }
        }
    });

})(Love);