(function(Love) {

    Love.Views.CommonContentGridView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentGridView',

        defaults: function() {

            return {

                type: 'image',
                rowMode: true // Set false for column mode
            };
        },

        events: {},

        initialize: function(options) {

            this._hasUploader = false;

            Love.Views.BaseView.prototype.initialize.call(this, options);
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_grid));
            this.$contentContainer = this.$('.grid-content');

            return Love.Views.BaseView.prototype.render.call(this);
        },

        addImageUploader: function() {

            this.$contentContainer.append("<div class='grid-item common-image-upload-container'></div>");
            this._hasUploader = true;
        },

        addItem: function(content, index, addBefore) {

            var html = '<div class="grid-item active-state" data-index="' + index + '">' + content + '</div>';

            if (!addBefore) {

                if (this._hasUploader) {

                    var $uploader = this.$('.common-image-upload-container');
                    $(html).insertBefore($uploader);
                }
                else
                    this.$contentContainer.append(html);
            }
            else
                this.$contentContainer.prepend(html);
        },

        init: function() {

            this.performLazyLoadImages();
        }
    });

})(Love);