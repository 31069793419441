(function (Love) {

    Love.Views.FAQScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.FAQScreenView',

        render: function () {

            this.$el.html(_.template(Love.Templates.faq_screen)());

            var lipsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et ante id lectus dignissim porta eu ac neque. In porttitor eros nec vestibulum commodo. Morbi in neque nulla. Donec id dictum lacus. Praesent accumsan lorem sapien, at accumsan dui pellentesque eu. Pellentesque sed urna ante. Praesent blandit, ex sed mollis venenatis, turpis diam accumsan nibh, ac dignissim arcu enim et nisl. Vestibulum id ante erat. Ut ut erat vel risus ultrices aliquet et sit amet risus. Integer sollicitudin suscipit arcu, a mattis mi ullamcorper a. Ut id quam lacus. Duis ut est quis sem placerat ultrices eu sit amet nunc. Suspendisse vestibulum enim ac tellus volutpat, ac eleifend nisi rutrum. Aliquam erat volutpat.Nam eget nisl vitae mi laoreet egestas et nec justo. Praesent in malesuada quam. Nam ut urna gravida, ornare urna non, laoreet mauris. Proin quis volutpat magna. In aliquam purus ex, et sagittis risus varius quis. Maecenas sit amet ex at diam malesuada tempus. Curabitur condimentum venenatis eros ut eleifend. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non placerat augue. Mauris varius, risus nec vestibulum vestibulum, erat risus vehicula eros, sed ultrices nisi risus et felis. Fusce dui ligula, finibus vel dapibus non, maximus nec magna.';

            var view1 = this.addSubView(new Love.Views.FAQRowView({

                item: {

                    question: 'How do I create a blog post?',
                    answer: lipsum
                }
            }));

            var view2 = this.addSubView(new Love.Views.FAQRowView({

                item: {

                    question: 'How do I add an item to the calendar?',
                    answer: lipsum
                }
            }));

            var view3 = this.addSubView(new Love.Views.FAQRowView({

                item: {

                    question: 'How do I automatically send out a tweet with a blog post?',
                    answer: lipsum
                }
            }));

            var view4 = this.addSubView(new Love.Views.FAQRowView({

                item: {

                    question: 'How can I contact Mobypicture?',
                    answer: 'The easiest way to contact us is by visiting <a href="http://www.tagthelove.com/page/contact" target="_blank">this page</a>.'
                }
            }));

            this.$('.common-content-list').append(view1.render().$el);
            this.$('.common-content-list').append(view2.render().$el);
            this.$('.common-content-list').append(view3.render().$el);
            this.$('.common-content-list').append(view4.render().$el);

            return Love.Views.BaseScreenView.prototype.render.call(this);
        }
    });

})(Love);