(function(Love) {

    Love.Views.SocialUpdateEditorView = Love.Views.BaseView.extend({

        INDEX_TEXT_WIDGET_VIEW: 0,
        INDEX_CONTENT_WIDGET_VIEW: 1,

        objectClassName: 'Love.Views.SocialUpdateEditorView',

        events: {

            'click [data-group="schedule"] [data-value]': '_handleScheduleChange'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.editModel = this.options.editModel;
            this.viewState = this.options.viewState;
        },

        render: function() {

            var context = _.extend({

                isScheduled: this.editModel.get('scheduledOnUtc') && !this.editModel.get('scheduledOnUtcIsImmediately'),
                maxCharacters: this.editModel.get('destination').maxCharacters,
                maxCharactersIcon: this.editModel.get('destination').icon

            }, this.editModel.attributes, this.viewState.attributes);

            this.$el.html(_.template(Love.Templates.social_update_editor)(context));

            this.stopListening(this.editModel, 'change:destination', this._checkContentWidgetView);
            this.listenTo(this.editModel, 'change:destination', this._checkContentWidgetView);

            this.stopListening(this.editModel, 'change:destination', this._checkMaximumLength);
            this.listenTo(this.editModel, 'change:destination', this._checkMaximumLength);

            this.stopListening(this.editModel, 'change:settings', this._checkMaximumLength);
            this.listenTo(this.editModel, 'change:settings', this._checkMaximumLength);

            // Render the text widget view in all cases.

            this._renderTextWidgetView();

            // Render the content and new widget views if applicable.

            if (this.editModel.get('contentType') === 'uploadTtlFile')
                this._renderContentWidgetView();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _checkContentWidgetView: function() {

            var dest = this.editModel.get('destination');

            if (dest) {

                switch (dest.type) {

                    case 'facebook': {

                        this.editModel.set('contentType', 'shareLink');
                        break;
                    }
                    case 'mobypicture': {

                        this.editModel.set('contentType', 'uploadTtlFile');
                        break;
                    }
                    case 'instagram': {

                        this.editModel.set('contentType', 'uploadTtlFile');
                        break;
                    }
                    case 'twitter':
                    default: {

                        this.editModel.set('contentType', 'statusUpdate');
                    }
                }

                var enableContentWidgetView = dest && (dest.type === 'mobypicture' || dest.type === 'instagram');

                if (enableContentWidgetView) {

                    this._renderContentWidgetView();
                    return;
                }
            }

            this._removeContentWidgetView();
            this._removeNewWidgetView();
        },

        _checkMaximumLength: function() {

            var $container = this.$('.message-container');
            var dest = this.editModel.get('destination');

            var content = this.editModel.get('settings').message;
            var max = dest.maxCharacters;

            this.textWidgetView.widgetView.setMaxLength(max);

            var icon = dest.icon;

            if (dest && icon)
                $container.find('.message-length .icon').removeClass().addClass('icon m-r-s ' + icon);
            else
                $container.find('.message-length .icon').addClass('hidden');

            if (dest && isFinite(max)) {

                $container.find('.message-length span').html(Math.max(max - content.length, 0));
                $container.find('.message-length').removeClass('invisible');
            }
            else {

                $container.find('.message-length span').html('0');
                $container.find('.message-length').addClass('invisible');
            }
        },

        _handleScheduleChange: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var schedule = $(e.target).closest('[data-value]').attr('data-value') === 'true';

            if (schedule) {

                var button = $(e.target).closest('.button');
                var updateElement = button.find('span');

                $(e.target).closest('[data-group="schedule"]').removeClass('left');
                $(e.target).closest('[data-group="schedule"]').addClass('right');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="true"]').addClass('active');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="false"]').removeClass('active');

                this.editModel.set('scheduledOnUtcIsImmediately', false);

                if (!this.editModel.get('scheduledOnUtc')) {

                    this.editModel.set('scheduledOnUtc', moment.utc().add(1, 'hours'));

                    updateElement.html('<em>' + this.editModel.get('scheduledOnUtc').clone().local().format('ddd, MMM Do YYYY, HH:mm') + '</em>');
                }

                var popup = new Love.Views.CommonPickDateTimePopupView({

                    callbacks: {

                        onChange: _.bind(function(data) {

                            this.editModel.set('scheduledOnUtc', data.value && data.value.isValid() ? moment(data.value).utc() : null);

                            updateElement.html('<em>' + data.value.format('ddd, MMM Do YYYY, HH:mm') + '</em>');

                        }, this)
                    },
                    data: {

                        canSelectInPast: false,
                        value: this.editModel.get('scheduledOnUtc').clone().local()
                    },
                    positioning: {

                        attachToElement: button,
                        positionAt: 'element'
                    }
                });

                popup.showPopup();
            }
            else {

                this.editModel.set('scheduledOnUtc', moment());
                this.editModel.set('scheduledOnUtcIsImmediately', true);

                $(e.target).closest('[data-group="schedule"]').removeClass('right');
                $(e.target).closest('[data-group="schedule"]').addClass('left');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="true"]').removeClass('active');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="false"]').addClass('active');
            }
        },

        _registerWidgetEvents: function(view) {

            this.listenTo(view, 'changed', _.bind(function(e) {

                if (e.index === this.INDEX_TEXT_WIDGET_VIEW) {

                    this.editModel.get('settings').message = e.settings.contentHTML;

                    this.editModel.trigger('change:settings');
                    this.editModel.trigger('change');
                    this.editModel.trigger('changedByUser', this.editModel);
                }
                else if (e.index === this.INDEX_CONTENT_WIDGET_VIEW) {

                    this.editModel.get('contentFragment').settings = e.settings;
                    this.editModel.set('contents', null);
                    this.editModel.get('settings').ttlUploadId = null;

                    this.editModel.trigger('change:contentFragment');
                    this.editModel.trigger('change:settings');
                    this.editModel.trigger('change');
                    this.editModel.trigger('changedByUser', this.editModel);
                }

            }, this));

            this.listenTo(view.widgetView, 'changing', _.bind(function(e) {

                this.viewState.set('disableSaving', e.isChanging);

            }, this));

            this.listenTo(view, 'delete', _.bind(function(e) {

                if (e.index === this.INDEX_CONTENT_WIDGET_VIEW) {

                    this.editModel.set('contentFragment', null);
                    this.editModel.set('contents', null);
                    this.editModel.get('settings').ttlUploadId = null;

                    this.editModel.trigger('change:settings');
                    this.editModel.trigger('change');
                    this.editModel.trigger('changedByUser', this.editModel);

                    this._renderContentWidgetView();
                }

            }, this));
        },

        _removeContentWidgetView: function() {

            if (this.contentWidgetView) {

                this.contentWidgetView.close();
                this.contentWidgetView.remove();
            }
        },

        _removeNewWidgetView: function() {

            if (this.newWidgetView) {

                this.newWidgetView.close();
                this.newWidgetView.remove();
            }
        },

        _renderContentWidgetView: function() {

            this._removeContentWidgetView();

            // Create a fragment from the contents.

            if (this.editModel.get('contentFragment')) {

                // Create the new view.

                this._removeNewWidgetView();

                this.contentWidgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                    disabled: !this.viewState.get('isEditable'),
                    enableMove: false,
                    enableSettings: false,
                    enableToolbarAside: this.viewState.get('isEditable'),
                    enableToolbarLine: false,
                    fragment: _.extend(_.clone(this.editModel.get('contentFragment')), {

                        index: this.INDEX_CONTENT_WIDGET_VIEW
                    }),
                    uploadAutomatically: false
                }));

                this.$('.message-widgets').append(this.contentWidgetView.render().$el);

                this._registerWidgetEvents(this.contentWidgetView);
            }
            else if (this.editModel.get('contents') && this.editModel.get('contents').ttlUpload) {

                // The uploaded file is either an image, video or audio fragment. Render the content fragment.

                $.when(Love.Factories.FragmentFactory.createFragmentFromString(this.editModel.get('contents').ttlUpload.url))
                    .done(_.bind(function(fragment) {

                        if (fragment) {

                            this.editModel.set('contentFragment', fragment, {silent: true}); // Don't raise change events while rendering.

                            // Create the new view.

                            this._removeNewWidgetView();

                            this.contentWidgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                                disabled: !this.viewState.get('isEditable'),
                                enableMove: false,
                                enableSettings: false,
                                enableToolbarAside: this.viewState.get('isEditable'),
                                enableToolbarLine: false,
                                fragment: _.extend(fragment, {

                                    index: this.INDEX_CONTENT_WIDGET_VIEW
                                }),
                                uploadAutomatically: false
                            }));

                            this.$('.message-widgets').append(this.contentWidgetView.render().$el);

                            this._registerWidgetEvents(this.contentWidgetView);
                        }
                        else
                            this._renderNewWidgetView();

                    }, this));
            }
            else
                this._renderNewWidgetView();
        },

        _renderNewWidgetView: function() {

            this._removeNewWidgetView();

            if (!this.viewState.get('isEditable'))
                return;

            this.newWidgetView = this.addSubView(new Love.Views.CommonContentWidgetNewView({

                enableEmbed: false, // TODO RENS: true, maar alleen image, video en audio.
                enableImage: true,
                enableQuote: false,
                enableRemix: false, // TODO RENS: true, maar alleen image, video en audio.
                enableText: false,
                enableTextImage: false,
                enableTextImageInMenu: false
            }));

            this.$('.message-widgets').append(this.newWidgetView.render().$el);

            this.listenTo(this.newWidgetView, 'addExtraWidget', _.bind(function(e) {

                this.editModel.set('contentFragment', {

                    index: this.INDEX_CONTENT_WIDGET_VIEW,
                    settings: e.settings,
                    type: e.type
                });
                this.editModel.set('contents', null);
                this.editModel.get('settings').ttlUploadId = null;

                this.editModel.trigger('change:settings');
                this.editModel.trigger('change');
                this.editModel.trigger('changedByUser', this.editModel);

                this._renderContentWidgetView();

            }, this));
        },

        _renderTextWidgetView: function() {

            var fragment = {

                index: this.INDEX_TEXT_WIDGET_VIEW,
                settings: {

                    contentHTML: this.editModel.get('settings').message
                },
                type: 'CMSTextPlainWidget'
            };

            this.textWidgetView = this.addSubView(new Love.Views.CommonContentWidgetView({

                disabled: !this.viewState.get('isEditable'),
                enableToolbar: false,
                enableToolbarAside: false,
                enableToolbarLine: false,
                fragment: fragment
            }));

            this.$('.message-widgets').append(this.textWidgetView.render().$el);

            this._registerWidgetEvents(this.textWidgetView);
        }
    });

})(Love);