(function(Love) {

    Love.Views.EventScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.EventScreenView',

        defaults: function() {

            var now = moment();

            return {

                calendarId: null,
                endDate: now.clone().add(1, 'hours'),
                eventId: null,
                fromGoogleEventId: null,
                startDate: now
            };
        },

        events: {

            'click .action-buttons-container [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .action-buttons-container [data-action="delete"]:not(.disabled)': '_handleDeleteClick',

            'click .base-sidebar [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .base-sidebar [data-action="delete"]:not(.disabled)': '_handleDeleteClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            // Not necessary if getData uses refreshing: Love.session.get('currentSite').requireData('bookmarksLatestImages', true);

            _.bindAll(this, '_checkExistingBackup');

            this.editModel = new Love.Models.EventModel();

            // The view state contains information relevant to this view and any sub views.
            // We use a standard Backbone model, since it's not part of the API but (change) events are useful.

            this.viewState = new Backbone.Model({

                changedByUser: false,
                disableSaving: false
            });

            if (this.options.fromGoogleEventId) {

                // Create a new event from an existing Google event.

                // TODO RENS: retrieve the Google event using a real API call.

                var attr = Love.viewBag.eventFromGoogleCache;
                delete Love.viewBag.eventFromGoogleCache;

                if (attr && attr.typeId === this.options.fromGoogleEventId) {

                    var calModel = new Love.Models.CalendarEventModel(attr, {parse: true});

                    this.editModel.set({

                        description: calModel.get('content').description,
                        endsOn: calModel.get('dateEnd'),
                        location: _.extend(this.editModel.get('location'), {venue: calModel.get('content').location}),
                        startsOn: calModel.get('date'),
                        title: calModel.get('content').title
                    });

                    this.editModel.set('startsOn', attr.date);
                    this.editModel.set('endsOn', attr.dateEnd);
                }
            }
            else {

                if (this.options.eventId)
                    this.editModel.set('id', this.options.eventId);

                if (this.options.calendarId)
                    this.editModel.set('calendarId', this.options.calendarId);

                if (this.options.startDate)
                    this.editModel.set('startsOn', this.options.startDate);

                if (this.options.endDate)
                    this.editModel.set('endsOn', this.options.endDate);
            }
        },

        render: function() {

            // Remove any existing event listeners in case of re-rendering.

            this.stopListening(this.viewState, 'change:disableSaving');

            this.stopListening(this.editModel, 'change', this.editModel.saveClientBackup);
            this.stopListening(this.editModel, 'changedByUser');

            // Recreate the subviews, to ensure their event handlers are also removed.

            if (this.editorView) this.editorView.close();

            if (this.sidebarView) {

                this.stopListening(this.sidebarView, 'toggle');

                this.sidebarView.close();
            }

            this.listenTo(this.viewState, 'change:disableSaving', _.bind(function() { this._setSavingEnabled(!this.viewState.get('disableSaving'));}, this));

            this.editorView = this.addSubView(new Love.Views.EventEditorView({editModel: this.editModel, viewState: this.viewState}));
            this.sidebarView = this.addSubView(new Love.Views.EventSidebarView({editModel: this.editModel, viewState: this.viewState}));

            if (this.editModel.isNew()) {

                // We don't have to render an empty page first, as the final empty model will be rendered immediately.

                this._setDefaultCalender();
                this._renderModel();

                _.defer(this._checkExistingBackup);
            }
            else {

                // We could render an empty page first, but let's assume loading an existing event is fast enough.

                var self = this;
                this.listenToOnce(this.editModel, 'sync', function() {

                    self._renderModel();

                    _.defer(self._checkExistingBackup);
                });

                this.editModel.fetch({

                    error: function(model, response, options) {

                        Love.router.navigate('error/404', {trigger: true});
                    }
                });
            }

            this.listenTo(Love.appView, 'navigate', _.bind(function(e) {

                if (this._hasUnsavedUserChanges())
                    e.unsavedChanges = true;

            }, this));

            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _checkExistingBackup: function() {

            // Check if a backup for changes exists in the local storage.

            var entry = this.editModel.getClientBackup();

            if (entry) {

                var self = this;
                var popup = new Love.Views.CommonQuestionPopupView({

                    callbacks: {

                        onNo: this.editModel.removeClientBackup,
                        onYes: function() {

                            Love.Helpers.Tracking.track('Event screen - restore client backup', {

                                model: self.editModel.id
                            });

                            self.editModel.set(self.editModel.parse(entry.backup));
                            self.editModel.trigger('changedByUser', self.editModel);

                            self._renderModel();
                        }
                    },
                    data: {

                        title: 'Restore edits?',
                        text: 'A backup of local changes was made at ' + entry.updatedAt.format('ddd, DD MMM YYYY, HH:mm') + '. Do you want to restore it?'
                    }
                });

                popup.showPopup();
            }
        },

        _handleDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        Love.Helpers.Tracking.track('Event screen - delete', {

                            model: self.editModel.id
                        });

                        self.editModel.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                self.viewState.set('changedByUser', false);
                                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/events', {trigger: true});
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete event?',
                    text: 'Do you really want to delete this event? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleSaveClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            Love.Helpers.Tracking.track('Event screen - save', {

                model: this.editModel.id
            });

            Love.Helpers.Loading.showLoading(true);

            var result = this._saveEventModel();

            $.when(result).always(function() { Love.Helpers.Loading.showLoading(false);});
        },

        _hasUnsavedUserChanges: function() {

            return (this.editModel && (this.editModel.isNew() || this.editModel.changedAttributesJSON(this.editModel.serverAttributes)) && this.viewState.get('changedByUser'));
        },

        _renderModel: function() {

            var self = this;

            $(window).scrollTo(0, 'normal');

            var context = this.editModel.attributes;
            this.$el.html(_.template(Love.Templates.events_event_screen)(context));

            this.editorView.setElement(this.$('.editor-container')).render();
            this.sidebarView.setElement(this.$('.base-sidebar-container')).render();

            this.listenTo(this.editModel, 'change', this.editModel.saveClientBackup);

            this.listenTo(this.editModel, 'changedByUser', function(e) {

                self.viewState.set('changedByUser', true);
            });

            this.listenTo(this.sidebarView, 'toggle', function(e) {

                self.performDotDotDotUpdate();
                self.performLazyLoadImagesResize(true);
            });
        },

        _saveEventModel: function() {

            this._setSavingEnabled(false);
            this._showFeedback(false);

            var wasNew = this.editModel.isNew();
            var calendarChanged = (!wasNew && this.editModel.get('calendarId') !== this.editModel.serverAttributes.calendarId);
            var deleteModel;

            if (calendarChanged) {

                // If the calendarId has been changed for an existing model, we need to recreate it, as updating
                // the calendarId is currently not supported by the API.

                deleteModel = new Love.Models.EventModel({

                    id: this.editModel.id,
                    calendarId: this.editModel.serverAttributes.calendarId
                });

                this.editModel.set('id', ''); // Mark the existing model as new.
            }

            var result = this.editModel.save(this.editModel.attributes, {

                wait: true,
                success: _.bind(function(model, response, options) {

                    if (wasNew || calendarChanged) {

                        // If it was a new event, update the url.

                        Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/event/' + model.get('calendarId') + '/' + model.id, {

                            trigger: false,
                            replace: true
                        });
                    }

                    if (calendarChanged) {

                        // Delete the old model.

                        deleteModel.destroy({wait: true});
                    }

                    // Any changes by the user have now been saved.

                    this.viewState.set('changedByUser', false);

                    // After saving, for both new and old events, the event model attributes are updated.
                    // This raises a change event which in turn saves a copy of the model to the local storage.
                    // Therefore, we have to remove the client backup here.

                    this.editModel.removeClientBackup();

                    this._renderModel();
                    this._setSavingEnabled(true);

                }, this),
                error: _.bind(function(model, response, options) {

                    // If saving failed and the calendar was changed, restore the old id on the model (it had been marked new).

                    if (calendarChanged)
                        this.editModel.set('id', deleteModel.id);

                    this._setSavingEnabled(true);
                    this._showFeedback(true);

                }, this)
            });

            if (!result) {

                this._setSavingEnabled(true); // Validation failed.
                this._showFeedback(true, "You didn't provide all necessary values. Please check again!", this.editModel.validate(this.editModel.attributes));
            }

            return result;
        },

        _setDefaultCalender: function() {

            var calendars = Love.session.get('currentSite').get('calendars');

            if (!this.editModel.get('calendarId') && calendars.length > 0)
                this.editModel.set('calendarId', calendars[0].id);
        },

        _setSavingEnabled: function(enabled) {

            if (enabled) {

                this.$('.action-buttons-container [data-action="save"]').removeClass('disabled');
                this.$('.base-sidebar [data-action="save"]').removeClass('disabled');
            }
            else {

                this.$('.action-buttons-container [data-action="save"]').addClass('disabled');
                this.$('.base-sidebar [data-action="save"]').addClass('disabled');
            }
        },

        _showFeedback: function(show, message, errors) {

            if (show) {

                if (!message)
                    message = 'Oops! Something went wrong.';

                this.$('.editor-feedback').text(message);

                this.$('[data-model-field]').removeClass('feedback-error');

                _.each(errors, _.bind(function(message, field) {

                    this.$('[data-model-field="' + field + '"]').addClass('feedback-error');

                }, this));
            }

            this.$('.editor-feedback').toggleClass('hidden', !show);
        }
    });

})(Love);