(function(Love) {

    Love.Views.CommonContentSliderView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentSliderView',

        isInitialized: false,

        defaults: function() {

            return {

                //centerMode: true, // NEW VOOR VERTICAL
                itemsPerSlide: 3,
                responsiveSlidesToShow: false
                //rows: 1, // NEW VOOR VERTICAL
                //slidesPerRow: 1, // NEW VOOR VERTICAL
                //vertical: false // NEW VOOR VERTICAL
            };
        },

        events: {

            'click .slider-next': '_handleClickNext',
            'click .slider-previous': '_handleClickPrevious',
            'keydown .common-content-slider': '_handleKeyDown'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'init');

            this._handleResize = _.debounce(_.bind(this._handleResize, this), 50);
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_slider)({

                arrows: true
            }));

            $(window).off('resize', this._handleResize);
            $(window).on('resize', this._handleResize);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            $(window).off('resize', this._handleResize);
        },

        addItem: function(content, index, addBefore) {

            if (this.isInitialized) {

                this.$('.slider-content').slick('slickAdd', content, index, addBefore);
                this._handleResize();
            }
            else {

                this.$('.slider-content').append(content); // TODO: index en addBefore afhandelen.
            }
        },

        goToItem: function(number) {

            if (this.isInitialized)
                this.$('.slider-content').slick('slickGoTo', number); // Move to the next visible track if necessary.

            this.$('.slick-slide').removeClass('content-slider-slide-active');
            this.$('.slick-slide[data-slick-index="' + number + '"]').addClass('content-slider-slide-active');
        },

        init: function() {

            if (!this.isInitialized)
                this._initSlider();

            _.defer(_.bind(this._handleResize, this));
        },

        _handleClickNext: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.isInitialized)
                this.$('.slider-content').slick('slickNext');
        },

        _handleClickPrevious: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.isInitialized)
                this.$('.slider-content').slick('slickPrev');
        },

        _handleKeyDown: function(e) {

            // For Slick (in non-center mode), the current slide is always the first visible slide on the track.
            // We also have the notion of a single active slide, which can be any slide on the track.

            if (e.keyCode === 37 || e.keyCode === 39) {

                var newIndex = parseInt(this.$('.slick-slide:not(.slick-cloned).content-slider-slide-active').attr('data-slick-index'));
                var totalSlides = this.$('.slick-slide:not(.slick-cloned)').length;
                var isInfinite = this.$('.slider-content').slick('slickGetOption', 'infinite');

                if (isNaN(newIndex)) newIndex = 0;

                else if (e.keyCode === 37) { // Left arrow

                    newIndex--;
                    if (newIndex < 0) newIndex = isInfinite ? totalSlides - 1 : 0;
                }
                else if (e.keyCode === 39) { // Right arrow

                    newIndex++;
                    if (newIndex === totalSlides) newIndex = isInfinite ? 0 : totalSlides - 1;
                }

                // The animated version is a little buggy. When moving to fast, sometimes an entire goTo is skipped.
                // Until this gets fixed in Slick, we therefore don't animate.

                this.$('.slider-content').slick('slickGoTo', newIndex, true); // Move to the next visible track if necessary.

                this.$('.slick-slide').removeClass('content-slider-slide-active');
                this.$('.slick-slide[data-slick-index="' + newIndex + '"]').addClass('content-slider-slide-active');

                this.trigger('slideChanged', {activeIndex: newIndex});
            }
        },

        _handleResize: function(e) {

            if (this.isInitialized) {

                if (this.options.responsiveSlidesToShow) {

                    var isVariableWidth = this.$('.slider-content').slick('slickGetOption', 'variableWidth');

                    if (!isVariableWidth) {

                        var sliderWidth = this.$('.slider-content').width();
                        var slideWidth = this.$('.content-slider-item').outerWidth(true);

                        if (sliderWidth && slideWidth) {

                            var currentSlidesToShow = this.$('.slider-content').slick('slickGetOption', 'slidesToShow');
                            var currentSlidesToScroll = this.$('.slider-content').slick('slickGetOption', 'slidesToScroll');

                            var totalSlides = this.$('.slick-slide:not(.slick-cloned)').length;
                            var newSlidesToShow = Math.min(Math.floor(sliderWidth / slideWidth), totalSlides);
                            var newSlidesToScroll = newSlidesToShow;

                            if (newSlidesToShow !== currentSlidesToShow)
                                this.$('.slider-content').slick('slickSetOption', 'slidesToShow', newSlidesToShow, true);

                            if (newSlidesToScroll !== currentSlidesToScroll)
                                this.$('.slider-content').slick('slickSetOption', 'slidesToScroll', newSlidesToScroll);
                        }
                    }
                }
            }
        },

        _initSlider: function() {

            this.$('.slider-content').slick({

                accessibility: false, // We have custom arrow key navigation.
                arrows: false,
                //centerMode: this.options.centerMode, // NEW VOOR VERTICAL
                //rows: this.options.rows, // NEW VOOR VERTICAL
                //slidesPerRow: this.options.slidesPerRow, // NEW VOOR VERTICAL
                slidesToScroll: this.options.itemsPerSlide, // Rens @ Jeroen: dit is nodig voor de horizontale slider.
                slidesToShow: this.options.itemsPerSlide
                //vertical: this.options.vertical, // NEW VOOR VERTICAL
                //verticalSwiping: this.options.vertical // NEW VOOR VERTICAL
                //variableWidth: true, // NEW VOOR VERTICAL
            });

            this.isInitialized = true;
        }
    });

})(Love);