(function(Love) {

    Love.Views.CalendarBaseEventView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CalendarBaseEventView',

        events: {

            'click': '_handleEventClick',
            'mouseenter': 'hideTimelines',
            'mousemove': 'hideTimelines',
            'mouseleave': 'showTimelines'
        },

        /*
         * ----------------
         * Calendar content
         * ----------------
         *
         * Events as found on the calendar.
         * Events have a model, containing the API information.
         * Events are moments but are shown as 40 minutes in size. (TODO: Better scaling)
         * Events have a set size
         * Events can be part of Calendar-Events, which have a duration.
         *
         */

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            //Base
            this.model = this.options.model;
            this.displayDateStart = this.options.displayDateStart;
            this.displayDateEnd = this.options.displayDateEnd;

            this.isGoogleCal = this.options.model.get('isGoogleCal');
            this.type = null;

            this.isDraggable = !this.options.model.get('isGoogleCal');

            //Variables used for sorting and sizing
            this.eventTime = 0; //Time in hours. 8,5 means 8:30
            this.height = 0; //How many pixels in the grid it occupies
            this.top = 0; //First pixel it occupies, where pixel 0 is 0:00

            this.overlaps = []; //All other overlapping events
            this.allLinkedOverlaps = []; //All overlapping events of all the overlaps
            this.order = 0; //How many (overlapping) events are to the left of this event
            this.earlierEvents = [];
            this.laterEvents = [];
            this.width = 1; //Width in percentage (f.e. 0.5 = 50%;)
            this.occupationSlots = [];

            this.start = this.model.get('date').clone();
            this.end = this.model.get('dateEnd').clone();

            if (this.start.isSame(this.end)) this.end.add(30, 'minutes'); // Give events a minimum duration.
        },

        onClose: function() {

            this.$el.off('dragstart');
        },

        isPastEvent: function() {

            var now = moment();
            return (now.valueOf() > this.end.valueOf());
        },

        enableDragDrop: function() {

            this.$el.attr('draggable', true);

            this.$el.on('dragstart', _.bind(function(e) {

                e.originalEvent.dataTransfer.setData('ttl/calendar-event', JSON.stringify(this.model.toJSON()));

            }, this));
        },

        /*
         * Start with Markup: CSS 'top', 'height'
         */

        eventMarkup: function() {

            // Calculate the the amount of hourblocks it needs for positioning. For example, 8:30am is 8.5 (8 and a half hourblocks).

            var start = moment.max(this.start, this.displayDateStart);
            this.eventTime = start.hours() + start.minutes() / 60;

            this.eventSize();       //  Height
            this.eventPosition();   //  Top
            this.eventStyling();    //  Extra CSS
        },

        //Set the css 'top' of the event element
        eventPosition: function() {

            var hourblockSize = this.$el.closest('.base-day-calendar').find('.hourblock').outerHeight(); //Size of one hourblock
            var top = this.eventTime * hourblockSize; //Multiply by the amount of hourblocks the time spans

            this.$el.css({'top': top});
            this.top = top;
        },

        //Set height, and check how much space it occupies.
        eventSize: function() {

            var start = this.displayDateStart ? moment.max(this.start, this.displayDateStart) : this.start;
            var end = this.displayDateEnd ? moment.min(this.end, this.displayDateEnd) : this.end;

            var diffInMs = end.diff(start);
            var duration = diffInMs / 1000 / 60;

            var hourBlockSize = this.$el.closest('.base-day-calendar').find('.hourblock').outerHeight();
            this.height = hourBlockSize * duration / 60;
            this.$el.css('height', this.height);
        },

        eventStyling: function() {
            //Extra CSS & Styling
            /*var text = this.$el.find('.calendar-event-text');
             var timeIcon = this.$el.find('.calendar-event-time');
             if ((this.width < 1 && this.calendarView.parentView.viewType !== 'day') ||
             this.width < .24 ||
             (this.isGoogleCal && this.width < .51)) {
             text.css({'display': 'none'});
             timeIcon.css({'width': '100%'});
             } else {
             text.css({'display': 'flex'});
             //timeIcon.css({'width': 'auto'});
             }*/

            //Opacity if in past
            if (this.isPastEvent())
                this.$el.addClass('past');
            else
                this.$el.removeClass('past');
        },

        /*
         * Once Markup is done, overlaps can be calculated and events can be resized so they fit next to eachother.
         */
        eventHandleOverlaps: function(list, index) {

            //if (list && index > -1) {//TODO: waarom?

            //this.setOccupation();
            this.getOverlapCount(list, index);
            if (this.isLastLinkedOverlap()) {
                this.getAllLinkedOverlaps();

                var occupationSlots = this.getOccupationSlots();

                _.each(this.allLinkedOverlaps, function(eventView) {
                    eventView.occupationSlots = occupationSlots.slice(0);
                    eventView.setEventOrder();
                    eventView.occupationSlots[eventView.order] = true;
                }, this);

                occupationSlots = this.removeUnoccupiedSlots(occupationSlots); //TODO: Repeat until no unoccupied slots to remove

                _.each(this.allLinkedOverlaps, function(eventView, index, list) {
                    eventView.occupationSlots = occupationSlots.slice(0);
                    eventView.setEventOrder();
                    eventView.occupationSlots[eventView.order] = true;
                    eventView.eventWidthAndMargin(list, index);
                }, this);
            }
        },

        getOccupationSlots: function() {
            var highestOverlapCount = 0;
            _.each(this.allLinkedOverlaps, function(eventView) {
                if (eventView.overlaps.length > highestOverlapCount)
                    highestOverlapCount = eventView.overlaps.length;
            }, this);
            var occupationSlots = [];

            highestOverlapCount += 1; //Add one, because one overlap means 2 events next to eachother.
            for (var i = 0; i < highestOverlapCount; i++) {
                occupationSlots.push(false);
            }
            return occupationSlots;
        },

        removeUnoccupiedSlots: function(occupationSlots) {
            var allFalse = true;
            _.each(this.allLinkedOverlaps, function(eventView) {
                var lastSlot = eventView.occupationSlots[eventView.occupationSlots.length - 1];
                if (lastSlot === true) {
                    allFalse = false;
                }
            }, this);

            if (allFalse) {
                occupationSlots.pop(); //Remove last item from the array
                return occupationSlots;
            }
            else {
                return occupationSlots;
            }
        },

        //Count the overlaps.
        getOverlapCount: function(list, index) {

            this.overlaps = [];
            this.earlierEvents = [];
            this.laterEvents = [];

            _.each(list, function(eventView, otherIndex) {

                //if the event-view is not THIS event-view, continue, as we're trying to find OTHER event views that overlap with this one.

                if (index !== otherIndex) {

                    if (this.checkIfOverlaps(eventView)) {

                        this.overlaps.push(eventView);

                        if (otherIndex > index)
                            this.laterEvents.push(eventView);
                        else
                            this.earlierEvents.push(eventView);
                    }
                }

            }, this);
        },

        isLastLinkedOverlap: function() {

            return (this.laterEvents.length == 0 && this.earlierEvents.length > 0);
        },

        getAllLinkedOverlaps: function() {

            this.allLinkedOverlaps = [];
            this.allLinkedOverlaps = this.allLinkedOverlaps.concat(this.earlierEvents);
            for (var i = 0; i < 5; i++) {
                _.each(this.allLinkedOverlaps, function(eventView) {
                    this.allLinkedOverlaps = this.allLinkedOverlaps.concat(eventView.earlierEvents);
                }, this);
            }
            this.allLinkedOverlaps.push(this);

            //Remove duplicates
            this.allLinkedOverlaps = _.uniq(this.allLinkedOverlaps, 'cid');
            this.allLinkedOverlaps = _.sortBy(this.allLinkedOverlaps, 'cid');
        },

        setEventOrder: function() {

            this.order = 0;

            if (this.earlierEvents.length === 0) {
                //No other earlier events, so this even order is 0.
                this.order = 0;
            }
            else {
                //There's other events, which are earlier.
                var inLoop = false;
                var freeOrder = 0;
                this.iterateOrder(inLoop, freeOrder); //Loops until a free order is found.
            }
        },

        eventWidthAndMargin: function(list, index) {

            var parWidth = this.$el.closest('.events-container').width();

            var afterEventMarginPix = 3; // TODO RENS / JEROEN: deze staat in de css variables..
            var afterEventMarginPerc = afterEventMarginPix / parWidth;

            this.width = 1 / (this.occupationSlots.length);

            //If it is not the last event, check if there is space free
            if (this.laterEvents.length > 0) {
                //Only fill up remaining space if the later events are not ordered in front of the checked event.
                var allFalse = true;
                _.each(this.laterEvents, function(eventView) {
                    if (eventView.order < this.order)
                        allFalse = false;
                }, this);

                //Conditions met, see if the space is free
                if (allFalse) {
                    allFalse = true;
                    _.each(this.overlaps, function(eventView) {
                        //TODO: Function that check for all overlaps if there's any free occupation slots
                        if (eventView.occupationSlots[this.order + 1] === true)
                            allFalse = false;
                    }, this);
                }

                if (allFalse)
                    this.width = (1 / (this.occupationSlots.length)) * 2;
            }

            if (this.overlaps.length > 0) {

                // Divide the amount of margin between overlapping events as subtraction of width over all overlapping events.

                this.width -= (this.overlaps.length * afterEventMarginPerc) / (this.overlaps.length + 1);
            }

            var widthPix = parWidth * this.width;
            var marginPix = 0;

            if (this.order > 0) {

                // Use the width of the previous event that overlaps with this one as offset.

                for (var i = 1; i <= this.order; i++) {

                    if (list && list[index - 1]) { // TODO RENS: deze if controle zou niet nodig moeten zijn.

                        var previousEvent = list[index - 1];
                        marginPix += (previousEvent.overlaps.length > 0) ? (previousEvent.width * parWidth + afterEventMarginPix) : (previousEvent.width * parWidth);
                    }
                }
            }

            if (widthPix <= 40)
                this.$el.addClass('mini');
            else
                this.$el.removeClass('mini');

            this.$el.css('width', widthPix);
            this.$el.css('margin-left', marginPix);

            this.eventStyling(); // TODO: dit hoort hier eigenlijk niet, maar weghalen levert problemen op. Niet doen dus.
        },

        //Helper functions
        iterateOrder: function(inLoop, freeOrder) {

            var doAgain = false;
            _.each(this.earlierEvents, function(earlierEventView) {
                if (earlierEventView.order === freeOrder) {
                    doAgain = true;
                }
            }, this);

            if (doAgain) {
                freeOrder++;
                this.iterateOrder(true, freeOrder);
            }
            else {
                this.order = freeOrder;
            }
        },

        checkIfOverlaps: function(other) {

            return Love.Helpers.General.isOverlappingRange(this.start, this.end, other.start, other.end);
        },

        checkIfEarlierThan: function(eventView) {

            var result = false;

            if (this.start.isBefore(eventView.start))
                result = true;

            else if (this.start.isSame(eventView.start))
                result = (this.model.id > eventView.model.id);

            return result;
        },

        _handleEventClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var wasActive = this.$el.hasClass('active');
            var needsActiveForPopup = (this.model.get('isGoogleCal') && this.$el.closest('.base-day-calendar').hasClass('moby-calendar'));

            // Activate the current event and any 'linked views' of the same event if the event is spanned over multiple days.

            this.$el.closest('.calendar').find('.calendar-base-event').removeClass('active');
            this.$el.closest('.calendar').find('.calendar-base-event[data-id="' + this.model.id + '"]').addClass('active');

            if (needsActiveForPopup && !wasActive)
                return;

            var instance = Love.Views.CalendarEventPopupView;

            if (this.model.get('type') === 'googlecalendar') instance = Love.Views.CalendarEventGooglePopupView;
            else if (this.model.get('type') === 'scheduledcontent') instance = Love.Views.CalendarEventSocialPopupView;
            else if (this.model.get('contentType') === 'mobypicture') instance = Love.Views.CalendarEventMobyPopupView;
            else if (this.model.get('type') === 'cmscontent' && this.model.get('content').contentType === 'blog') instance = Love.Views.CalendarEventPostPopupView;
            else if (this.model.get('contentType') === 'twitter') instance = Love.Views.CalendarEventTweetPopupView;
            else if (this.model.get('contentType') === 'instagram') instance = Love.Views.CalendarEventInstagramPopupView;

            var popup = new instance({

                data: {

                    calendarEventModel: this.model
                },
                positioning: {

                    attachToElement: $(e.target).closest('.calendar-base-event'),
                    centerX: true,
                    positionAt: 'element',
                    top: true
                }
            });

            var container = $(e.target).closest('.calendar-view-content-container');
            if (container.length > 0) {

                popup.options.positioning.clampY = {

                    max: container.offset().top
                };
            }

            popup.showPopup();
        },

        hideTimelines: function(e) {

            var parent = this.$el.parent();
            var currentTimeline = $(parent).siblings('.timeline')[0];
            $(currentTimeline).css('display', 'none');
        },

        showTimelines: function(e) {

            var parent = this.$el.parent();
            var currentTimeline = $(parent).siblings('.timeline')[0];
            $(currentTimeline).css('display', 'block');
        }

    });

})(Love);