(function(Love) {

    Love.Views.AccountScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.AccountScreenView',

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.editorView = this.addSubView(new Love.Views.AccountEditorView());
            this.sidebarView = this.addSubView(new Love.Views.AccountSidebarView());
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.account_screen)());

            this.editorView.setElement(this.$('.editor-container')).render();
            this.sidebarView.setElement(this.$('.base-sidebar-container')).render();

            return Love.Views.BaseScreenView.prototype.render.call(this);
        }
    });

})(Love);