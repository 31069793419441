(function(Love) {

    Love.Views.EventEditorView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.EventEditorView',

        events: {

            'input [name="edit-event-title"]': '_handleInputChanged',
            'input [name="edit-event-url"]': '_handleInputChanged',
            'input [name="edit-event-url-text"]': '_handleInputChanged',
            'input [name="edit-event-description"]': '_handleInputChanged',

            'click [data-action="end-date"]': '_handleEndDateClick',
            'click [data-action="start-date"]': '_handleStartDateClick',
            'click [data-group="edit-event-has-page"] [data-value]': '_handleHasPageChange',
            'click [data-action="toggle-description"]': '_handleToggleDescription',

            'click .show-detail .button': '_handleLocationShowDetail',
            'click .hide-detail .button': '_handleLocationHideDetail',
            'click [data-action="clear-location"]': '_handleClearLocationClick',

            'input [name="edit-event-location-address"]': '_handleLocationInputChanged',
            'input [name="edit-event-location-city"]': '_handleLocationInputChanged',
            'input [name="edit-event-location-country"]': '_handleLocationInputChanged',
            'input [name="edit-event-location-venue"]': '_handleLocationInputChanged'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.editModel = options.editModel;
            this.viewState = this.options.viewState;

            this.locationPickerView = this.addSubView(new Love.Views.CommonLocationPicker());

            _.bindAll(this, '_handleLocationChanged');
            _.bindAll(this, '_handleLocationInputChanged');
        },

        render: function() {

            var context = this.editModel.attributes;
            this.$el.html(_.template(Love.Templates.events_event_editor)(context));

            this.locationPickerView.setElement(this.$('.location-container')).render();

            this._updateDurationText();
            this._updateHasEventPageHideGroup();

            var location = this.editModel.get('location') || {};
            var locationElements = [

                location.venue,
                location.address,
                location.city,
                location.country
            ];

            locationElements = _.without(locationElements, '', null, void(0));

            var updateAddressInput = true;
            this.locationPickerView.setLocationFromString(locationElements.join(', '), updateAddressInput);

            // Using change events enables updating the editor and sidebar simultaneously.

            this.listenTo(this.editModel, 'change:endsOn', this._updateDurationText);
            this.listenTo(this.editModel, 'change:startsOn', this._updateDurationText);
            this.listenTo(this.editModel, 'change:lastsAllDay', this._updateDurationText);

            this.listenTo(this.editModel, 'change:linkTarget', this._updateHasEventPageHideGroup);

            this.listenTo(this.editModel, 'change:location', _.bind(function() {

                this._handleLocationShowDetail();
                this._updateLocationText();

                var location = this.editModel.get('location') || {};
                var locationElements = [

                    location.venue,
                    location.address,
                    location.city,
                    location.country
                ];

                locationElements = _.without(locationElements, '', null, void(0));

                var updateAddressInput = true;
                this.locationPickerView.setLocationFromString(locationElements.join(', '), updateAddressInput);

            }, this));

            this.listenTo(this.locationPickerView, 'locationChanged', this._handleLocationChanged);

            var autoCompleteService = new google.maps.places.AutocompleteService();

            this.$('[name="edit-event-location-city"]').autocomplete({

                source: function(request, response) {

                    Love.Helpers.GooglePlacesAutocomplete.searchCity(autoCompleteService, request.term, response);
                },
                change: this._handleLocationInputChanged,
                autoFocus: true
            });

            this.$('[name="edit-event-location-country"]').autocomplete({

                source: function(request, response) {

                    Love.Helpers.GooglePlacesAutocomplete.searchCountry(autoCompleteService, request.term, response);
                },
                change: this._handleLocationInputChanged,
                autoFocus: true
            });

            this.$('[name="edit-event-location-venue"]').autocomplete({

                source: function(request, response) {

                    Love.Helpers.GooglePlacesAutocomplete.searchEstablishment(autoCompleteService, request.term, response);
                },
                change: this._handleLocationInputChanged,
                autoFocus: true
            });

            if (Love.Helpers.LocalStorageSession.getObject('common_event_editor_description_hidden'))
                _.defer(_.bind(function() { this.$('.toggle-description').removeClass('open'); }, this));

            this._setDescriptionArrow();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _handleClearLocationClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.locationPickerView.marker.setVisible(false);

            this.editModel.setLocationFromAddress(null);
            this.editModel.trigger('changedByUser', this.editModel);
        },

        _handleEndDateClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var popup = new Love.Views.CommonPickDateTimePopupView({

                callbacks: {

                    onChange: _.bind(function(data) {

                        var newEndDate = data.value && data.value.isValid() ? data.value.clone() : null;
                        var newStartDate = data.dateTimeRange.secondValue.isValid() ? data.dateTimeRange.secondValue.clone() : null;

                        this.editModel.set('endsOn', newEndDate);

                        if (newStartDate)
                            this.editModel.set('startsOn', newStartDate);

                        if (data.dateTimeRange.canLastAllDay)
                            this.editModel.set('lastsAllDay', data.dateTimeRange.lastsAllDay);

                        this.editModel.trigger('changedByUser', this.editModel);

                    }, this)
                },
                data: {

                    dateTimeRange: {

                        canLastAllDay: true,
                        isRange: true,
                        lastsAllDay: this.editModel.get('lastsAllDay'),
                        secondValue: this.editModel.get('startsOn').clone()
                    },
                    value: this.editModel.get('endsOn').clone()
                },
                positioning: {

                    attachToElement: $(e.target).closest('[data-action="end-date"]'),
                    centerY: true,
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _handleHasPageChange: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var hasPage = $(e.target).closest('[data-value]').attr('data-value') === 'true';

            if (hasPage) {

                this.$('[data-group="edit-event-has-page"]').removeClass('right');
                this.$('[data-group="edit-event-has-page"]').addClass('left');
                this.$('[data-group="edit-event-has-page"] [data-value="true"]').addClass('active');
                this.$('[data-group="edit-event-has-page"] [data-value="false"]').removeClass('active');
            }
            else {

                this.$('[data-group="edit-event-has-page"]').removeClass('left');
                this.$('[data-group="edit-event-has-page"]').addClass('right');
                this.$('[data-group="edit-event-has-page"] [data-value="true"]').removeClass('active');
                this.$('[data-group="edit-event-has-page"] [data-value="false"]').addClass('active');
            }

            this.editModel.set('linkTarget', hasPage ? 'page' : 'url');
            this.editModel.trigger('changedByUser', this.editModel);
        },

        _handleInputChanged: function(e) {

            this.editModel.set({

                description: this.$('[name="edit-event-description"]').val().trim(),
                title: this.$('[name="edit-event-title"]').val().trim(),
                url: this.$('[name="edit-event-url"]').val().trim(),
                urlText: this.$('[name="edit-event-url-text"]').val().trim()
            });

            this.editModel.trigger('changedByUser', this.editModel);
        },

        _handleLocationChanged: function(e) {

            this.editModel.setLocationFromAddress(e.address);
            this.editModel.trigger('changedByUser', this.editModel);
        },

        _handleLocationHideDetail: function(e) {

            if (e) e.preventDefault();

            this.$('.hide-detail').addClass('hidden');
            this.$('.show-detail').removeClass('hidden');
            this.$('.location-detail-container').addClass('hidden');

            // Let the map know that it resized, then center it on the marker.

            google.maps.event.trigger(this.locationPickerView.map, 'resize');
            this.locationPickerView.map.setCenter(this.locationPickerView.marker.getPosition());
        },

        _handleLocationInputChanged: function(e) {

            this.editModel.setLocationFromAddress({

                street: this.$('input[name="edit-event-location-address"]').val().trim(),
                city: this.$('input[name="edit-event-location-city"]').val().trim(),
                country: this.$('input[name="edit-event-location-country"]').val().trim(),
                venue: this.$('input[name="edit-event-location-venue"]').val().trim()
            });

            this.editModel.trigger('changedByUser', this.editModel);
        },

        _handleLocationShowDetail: function(e) {

            if (e) e.preventDefault();

            this.$('.show-detail').addClass('hidden');
            this.$('.hide-detail').removeClass('hidden');
            this.$('.location-detail-container').removeClass('hidden');

            // Let the map know that it resized, then center it on the marker.

            google.maps.event.trigger(this.locationPickerView.map, 'resize');
            this.locationPickerView.map.setCenter(this.locationPickerView.marker.getPosition());
        },

        _handleStartDateClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var popup = new Love.Views.CommonPickDateTimePopupView({

                callbacks: {

                    onChange: _.bind(function(data) {

                        var newStartDate = data.value && data.value.isValid() ? data.value.clone() : null;
                        var newEndDate = data.dateTimeRange.secondValue.isValid() ? data.dateTimeRange.secondValue.clone() : null;

                        this.editModel.set('startsOn', newStartDate);

                        if (newEndDate)
                            this.editModel.set('endsOn', newEndDate);

                        if (data.dateTimeRange.canLastAllDay)
                            this.editModel.set('lastsAllDay', data.dateTimeRange.lastsAllDay);

                        this.editModel.trigger('changedByUser', this.editModel);

                    }, this)
                },
                data: {

                    dateTimeRange: {

                        canLastAllDay: true,
                        isRange: true,
                        lastsAllDay: this.editModel.get('lastsAllDay'),
                        secondValue: this.editModel.get('endsOn').clone()
                    },
                    value: this.editModel.get('startsOn').clone()
                },
                positioning: {

                    attachToElement: $(e.target).closest('[data-action="start-date"]'),
                    centerY: true,
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _handleToggleDescription: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this._setDescriptionArrow();

            Love.Helpers.LocalStorageSession.setObject('common_event_editor_description_hidden', !this.$('.toggle-description').hasClass('open'));
        },

        _setDescriptionArrow: function() {

            var isOpen = this.$('.toggle-description').toggleClass('open').hasClass('open');

            if (isOpen) {

                var button = this.$('.toggle-description-button');
                var left = button.offset().left + button.width() / 2 - 1; // With a small correction

                var arrowLeft = left - this.$('.toggle-description').offset().left;

                this.$('.toggle-arrow').css('left', arrowLeft);
            }
        },

        _updateDurationText: function() {

            var start = this.editModel.get('startsOn');
            var end = this.editModel.get('endsOn');
            var lastsAllDay = this.editModel.get('lastsAllDay');

            var getButton = function(action, text) {

                //return '<em data-action="' + action + '">' + text + '</em>';
                return '<a class="button form-control-after-form-control form-control-before-form-control" data-action="' + action + '"><i class="icon icon-calendar m-r-s"></i><span>' + text + '</span></a>';
            };

            if (!lastsAllDay) {

                if (start.isSame(end, 'day'))
                    this.$('.edit-event-duration').html("It starts on " + getButton('start-date', start.format('ddd, DD MMM YYYY, HH:mm')) + " and ends at " + getButton('end-date', end.format('HH:mm')));
                else
                    this.$('.edit-event-duration').html("It starts on " + getButton('start-date', start.format('ddd, DD MMM YYYY, HH:mm')) + " and ends on " + getButton('end-date', end.format('ddd, DD MMM YYYY, HH:mm')));
            }
            else {

                if (start.isSame(end, 'day'))
                    this.$('.edit-event-duration').html("It " + getButton('start-date', 'starts') + " and " + getButton('end-date', 'ends') + " on " + start.format('ddd, DD MMM YYYY') + ", lasting all day.");
                else
                    this.$('.edit-event-duration').html("It starts on " + getButton('start-date', start.format('ddd, DD MMM')) + " and ends on " + getButton('end-date', end.format('ddd, DD MMM YYYY')) + ", lasting all day.");
            }
        },

        _updateHasEventPageHideGroup: function() {

            var hasPage = this.editModel.get('linkTarget') === 'page';

            if (hasPage) this.$('[data-hide-group="has-event-page"]').removeClass('hidden');
            else this.$('[data-hide-group="has-event-page"]').addClass('hidden');

            if (hasPage) {

                this.$('.toggle-description .has-page-true').removeClass('hidden');
                this.$('.toggle-description .has-page-false').addClass('hidden');
            }
            else {

                this.$('.toggle-description .has-page-true').addClass('hidden');
                this.$('.toggle-description .has-page-false').removeClass('hidden');
            }
        },

        _updateLocationText: function() {

            var location = this.editModel.get('location');

            this.$('input[name="edit-event-location-address"]').val(location.address);
            this.$('input[name="edit-event-location-city"]').val(location.city);
            this.$('input[name="edit-event-location-country"]').val(location.country);
            this.$('input[name="edit-event-location-venue"]').val(location.venue);
        }
    });

})(Love);