(function (Love) {

  Love.Locales.nl = _.extend(Love.Locales.nl || {}, {

    'common': {

      'key1': 'value of key 1 in nl',
      'key2': 'value of key 2 in nl',
      'key3': 'value of key 3 in nl'
    }
  });

})(Love);