(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'social': {

            'screen': {

                'common_content_list_actions_delete_interval': '(0){Delete updates};(1){Delete 1 update};(2-inf){Delete {{count}} updates};',
                'common_content_list_description_search': 'There are {{count}} result(s) for: <span class="search">{{query}}</span>'
            }
        }
    });

})(Love);