(function(Love) {

    Love.Helpers.Language = {

        addLeadingZeros: function(num, size) {

            var isNegative = (num < 0);

            var s = String(Math.abs(num));
            while (s.length < size) s = '0' + s;

            return isNegative ? '-' + s : s;
        },

        friendlyDisplayUrl: function(url) {

            //return url.replace('/(.+?)\/\//', '');
            url = url.replace('https://', '').replace('http://', '');

            if (url.slice(-1) === '/')
                url = url.slice(0, url.length - 1);

            return url;
        },

        getLanguages: function() {

            // TODO RENS: pick current locale.

            return Love.Locales.en.languages;
        },

        getViewsEtcText: function(count) {

            count = numeral(count);

            if (count >= 1000000)
                return count.format('0.0a');
            else if (count > 9999)
                return count.format('0a');
            else
                return count.format('0');
        }
    };

})(Love);