(function(Love) {

    Love.Views.CommonTooltipPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonTooltipPopupView',

        className: 'base-popup popup-tooltip popup-size-auto',
        id: 'popup-common-tooltip',

        defaultsForPopupType: function() {

            return {

                data: {

                    text: '',
                    title: ''
                },
                positioning: {

                    bottom: true,
                    canBecomeFullScreen: false,
                    centerX: true,
                    positionAt: 'element'
                }
            };
        },

        render: function() {

            var context = this.options.data;
            this.$el.append(_.template(Love.Templates.common_popups_tooltip)(context));

            return Love.Views.BasePopupView.prototype.render.call(this);
        }
    });

})(Love);