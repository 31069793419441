(function(Love) {

    Love.Views.CalendarMonthView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CalendarMonthView',

        defaults: function() {

            return {

                weeksDisplayed: 6
            };
        },

        events: {

            'click .calendar-week-day': '_createContent',
            'click .calendar-month-cal-events': '_showCalendarEvents',
            'click .calendar-excess-events': '_showExcessEvents',
            'click .calendar-week-day-header .calendar-day-date': '_handleClickDay'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this,

                '_handleScroll'
            );

            this.viewSizeReset = _.debounce(_.bind(this.viewSizeReset, this), 50);

            this.parentView = this.options.parentView;
            this.collection = null;

            this.dayCollections = [];

            this._canScrollMonths = true;
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.calendar_month)());
            this.$el.addClass('calendar-view-month');

            this.updateRenderedView();

            $(window).off('resize', this.viewSizeReset);
            $(window).on('resize', this.viewSizeReset);

            this.stopListening(this.parentView, 'createContent');
            this.listenTo(this.parentView, 'createContent', _.bind(function(e) {

                this._createContent(e.originalEvent, e.fromHeader);

            }, this));

            $(window).on('wheel', this._handleScroll);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this.$el.removeClass('calendar-view-month');

            $(window).off('resize', this.viewSizeReset);
            $(window).off('wheel', this._handleScroll);
        },

        emptyDOM: function() {

            _.each(this.dayCollections, function(day) {

                _.each(day.eventViews, function(eventView) { eventView.close(); });
                _.each(day.eventViewsBackground, function(eventView) { eventView.close(); });

                day.eventViews = [];
                day.eventViewsBackground = [];
            });

            this.$('.events-container').empty();
        },

        populateDomFromCollection: function(collection) {

            this.collection = collection;

            var eventCollection = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return !(model.get('isGoogleCal') || model.get('type') === 'event');}));
            var eventCollectionBackground = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return (model.get('isGoogleCal') || model.get('type') === 'event');}));

            _.each(this.dayCollections, function(day) {

                var dateStart = day.date.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
                var dateEnd = dateStart.clone().add(1, 'days');

                var subset = eventCollection.filter(function(model) {

                    return Love.Helpers.General.isOverlappingRange(model.get('date'), model.get('dateEnd'), dateStart, dateEnd);
                });
                var subsetBackground = eventCollectionBackground.filter(function(model) {

                    return Love.Helpers.General.isOverlappingRange(model.get('date'), model.get('dateEnd'), dateStart, dateEnd);
                });

                _.each(subset, function(model) {

                    var eventView = this._addEventView(model, day, dateStart, dateEnd);
                    day.eventViews.push(eventView);

                }, this);

                var calEvents = this.$('.calendar-week-row[data-week="' + day.week + '"] .calendar-week-day[data-day="' + day.dayOfWeek + '"] .calendar-month-cal-events');

                if (subsetBackground.length > 0) {

                    calEvents.removeClass('hidden');
                    calEvents.find('.calendar-month-cal-events-amount').text(subsetBackground.length);
                }
                else
                    calEvents.addClass('hidden');

                _.each(subsetBackground, function(model) {

                    var eventView = this._addEventView(model, day, dateStart, dateEnd, true);
                    day.eventViewsBackground.push(eventView);

                }, this);

            }, this);

            this._createEventRows();
        },

        updateRenderedView: function() {

            this.firstDayOfMonth = this.parentView.selectedDay.clone().startOf('month');
            this.firstShownDay = this.firstDayOfMonth.clone().startOf('isoweek');

            this._createDayViewsForMonth(this.firstShownDay);

            this.parentView.updateMonthViewSelectors();
        },

        viewSizeReset: function() {

            this._createEventRows();
        },

        _addEventView: function(model, day, dateStart, dateEnd, isCalEvent) {

            var view = this.addSubView(Love.Helpers.Calendar.baseEventViewFactory(model, dateStart, dateEnd));

            if (!isCalEvent) {

                var element = view.render().$el;
                this.$('.calendar-week-row[data-week="' + day.week + '"] .calendar-week-day[data-day="' + day.dayOfWeek + '"] .events-container').append(element);
            }

            return view;
        },

        _createContent: function(e, fromHeader) {

            if (e.button === 0 || fromHeader) { // Left

                if (e && e.preventDefault) e.preventDefault();

                if (Love.appView.hasPopups()) return;

                if (!fromHeader) {

                    var isCalEventsClick = ($(e.target).closest('.calendar-month-cal-events').length > 0);
                    if (isCalEventsClick) return;

                    var isEventClick = ($(e.target).closest('.calendar-base-event').length > 0);
                    if (isEventClick) return;
                }

                var date;

                if (fromHeader) {

                    var today = moment();

                    if (today.isSame(this.firstDayOfMonth, 'month')) {

                        date = today;
                    }
                    else
                        date = this.firstDayOfMonth;
                }
                else {

                    var week = parseInt($(e.target).closest('[data-week]').attr('data-week'));
                    var day = parseInt($(e.target).closest('[data-day]').attr('data-day'));

                    var dayCollection = this.dayCollections[(week - 1) * 7 + day - 1];
                    date = dayCollection.date;
                }

                var popup = new Love.Views.CalendarAddContentPopupView({

                    data: {date: date, showTime: false},
                    positioning: fromHeader ? {

                        attachToElement: $(e.target).closest('.calendar-create-content'),
                        positionAt: 'element'

                    } : {

                        offsetX: e.clientX,
                        offsetY: e.clientY,
                        positionAt: 'mouse'
                    }
                });

                popup.showPopup();
            }
        },

        _createDayViewsForMonth: function(startDate) {

            this.dayCollections = [];

            for (var i = 0; i < this.options.weeksDisplayed; i++) {

                var date = startDate.clone().add(i, 'weeks');
                this._createDayViewsForWeek(i + 1, date);
            }
        },

        _createDayViewsForWeek: function(week, startDate) {

            for (var i = 0; i < 7; i++) {

                var date = startDate.clone().add(i, 'days');

                var day = {

                    date: date,
                    week: week,
                    dayOfWeek: i + 1,
                    eventViews: [],
                    eventViewsBackground: [],
                    excessEvents: []
                };

                this.dayCollections.push(day);

                var $currentDay = this.$('.calendar-week-row[data-week="' + day.week + '"] .calendar-week-day[data-day="' + day.dayOfWeek + '"]');

                $currentDay.html(_.template(Love.Templates.calendar_month_day)(day));
                $currentDay.attr('data-date', day.date.format('YYYY-MM-DD'));

                $currentDay.removeClass('current-day other-month');

                var now = moment();

                if (date.isSame(now, 'day'))
                    $currentDay.addClass('current-day');

                else if (date.isBefore(now, 'day'))
                    $currentDay.addClass('past');

                if (!date.isSame(now, 'month'))
                    $currentDay.addClass('other-month');
            }
        },

        _createEventRows: function() {

            var eventContainers = this.$('.events-container');

            _.each(eventContainers, function(day, index) {

                var dayCollection = this.dayCollections[index];

                var $day = $(day);
                var height = $day.height();
                var width = $day.width();

                var momentEventHeight = $day.find('.calendar-base-event').outerHeight();
                if (momentEventHeight <= 0) return; // Prevent divide by 0 exceptions.

                // Detach all existing events from the container.

                _.each($day.find('.calendar-base-event'), function(event) {$(event).detach();});

                // Remove any existing rows.

                $day.find('.calendar-month-events-row').remove();

                // Put the events in rows again.

                if (dayCollection.eventViews.length > 0) {

                    var numberOfRows = Math.floor(height / 22);
                    var maxEventsPerRow = Math.floor(width / 26);

                    for (var i = 0; i < numberOfRows; i++)
                        $day.append('<div class="calendar-month-events-row"></div>');

                    var amountOfExcessEvents = 0;

                    if (maxEventsPerRow * numberOfRows < dayCollection.eventViews.length)
                        amountOfExcessEvents = 1 + dayCollection.eventViews.length - (maxEventsPerRow * numberOfRows);

                    var events = dayCollection.eventViews.slice(0, dayCollection.eventViews.length - amountOfExcessEvents);
                    var excessEvents = dayCollection.eventViews.slice(dayCollection.eventViews.length - amountOfExcessEvents);

                    var eventsPerRow = Math.ceil(dayCollection.eventViews.length / numberOfRows);
                    var rows = $day.find('.calendar-month-events-row');

                    _.each(events, function(eventView, index) {

                        var i = Math.floor(index / eventsPerRow);
                        $(rows[i]).append(eventView.$el);
                    });

                    if (amountOfExcessEvents)
                        $(rows[numberOfRows - 1]).append('<div class="calendar-base-event calendar-excess-events"><div class="calendar-event-content">+ ' + excessEvents.length + '</div></div>');

                    // Style the events after they've all been added to ensure correct measuring.

                    _.each(events, function(eventView) {eventView.eventStyling();});

                    this.dayCollections[index].excessEvents = excessEvents;
                }
                else
                    this.dayCollections[index].excessEvents = [];

            }, this);
        },

        _handleClickDay: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var week = parseInt($(e.target).closest('[data-week]').attr('data-week'));
            var day = parseInt($(e.target).closest('[data-day]').attr('data-day'));

            var dayCollection = this.dayCollections[(week - 1) * 7 + day - 1];

            if (dayCollection)
                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/calendar/day/' + dayCollection.date.format('YYYY-MM-DD'), {trigger: true});
        },

        _handleScroll: function(e) {

            e = e.originalEvent;
            e.preventDefault();

            var DOM_DELTA_PIXEL = 0;
            var DOM_DELTA_LINE = 1;
            var DOM_DELTA_PAGE = 2;

            var lines = 3;
            var fontSize = 16;

            var threshold;

            if (e.deltaMode === DOM_DELTA_PIXEL)
                threshold = lines * fontSize;
            else if (e.deltaMode === DOM_DELTA_LINE)
                threshold = lines;
            else if (e.deltaMode === DOM_DELTA_PAGE)
                threshold = 1;
            else
                return;

            if (Math.abs(e.deltaY) < threshold) {

                this._canScrollMonths = true;
                return;
            }

            if (this._canScrollMonths) {

                this._canScrollMonths = false;

                //var thresholds = Math.max(Math.floor(Math.abs(e.deltaY) / threshold), 1);
                var thresholds = 1;
                var forward = e.deltaY > 0;

                for (var i = 0; i < thresholds; i++) {

                    if (forward)
                        this.parentView.nextWeek();
                    else
                        this.parentView.previousWeek();
                }
            }
        },

        _showCalendarEvents: function(e) {

            var week = parseInt($(e.target).closest('[data-week]').attr('data-week'));
            var day = parseInt($(e.target).closest('[data-day]').attr('data-day'));

            var dayCollection = this.dayCollections[(week - 1) * 7 + day - 1];

            if (dayCollection && dayCollection.eventViewsBackground.length > 0) {

                var popup = new Love.Views.CalendarListOfEventsPopupView({

                    data: {events: dayCollection.eventViewsBackground},
                    positioning: {

                        attachToElement: $(e.target).closest('.calendar-month-cal-events'),
                        centerX: true,
                        positionAt: 'element'
                    }
                });

                popup.showPopup();
            }
        },

        _showExcessEvents: function(e) {

            var week = parseInt($(e.target).closest('[data-week]').attr('data-week'));
            var day = parseInt($(e.target).closest('[data-day]').attr('data-day'));

            var dayCollection = this.dayCollections[(week - 1) * 7 + day - 1];
            if (dayCollection && dayCollection.excessEvents.length > 0) {

                var popup = new Love.Views.CalendarListOfEventsPopupView({

                    data: {events: dayCollection.excessEvents},
                    positioning: {

                        attachToElement: $(e.target).closest('.calendar-excess-events'),
                        centerX: true,
                        positionAt: 'element'
                    }
                });

                popup.showPopup();
            }
        }
    });

})(Love);