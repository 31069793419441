(function(Love) {

    Love.Helpers.Notifications = {

        createLocal: function(notification) {

            // Create a local notification.
        },

        getLocalNotifications: function() {

            var siteId = Love.session.get('currentSiteId');
            var attributes = [

                {icon: "ttl-moby", message: 'New Moby posted 1 hour 32 minutes ago', link: '#site/' + siteId + '/mobys'},
                {
                    icon: 'icon-twitter',
                    message: "New Tweet posted 2 hours 23 minutes ago",
                    link: '#site/' + siteId + '/tweets'
                },
                {icon: 'icon-twitter', message: 'Tweet got retweeted 100 times!', link: '#site/' + siteId + '/tweets'},
                {icon: 'icon-calendar', message: 'Upcoming event in one week', link: '#site/' + siteId + '/events'},
                {icon: "ttl-moby", message: 'New Moby posted 6 hour 32 minutes ago', link: '#site/' + siteId + '/mobys'},
                {
                    icon: 'icon-twitter',
                    message: "New Tweet posted 5 hours 23 minutes ago",
                    link: '#site/' + siteId + '/tweets'
                },
                {
                    icon: 'icon-twitter',
                    message: "New Tweet posted 7 hours 23 minutes ago",
                    link: '#site/' + siteId + '/tweets'
                }
            ];

            var items = new Love.Collections.NotificationCollection();

            _.each(attributes, function(attr) { items.add(new Love.Models.NotificationModel(attr));});

            return items;
        }
    };

})(Love);