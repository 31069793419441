(function(Love) {

    Love.Views.DashboardPopularModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardPopularModuleView',

        defaults: function() {

            return {

                itemsToLoad: 5
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_closeNotification', '_renderItems');

            this._items = new Love.Collections.PopularContentCollection(null, {

                mode: 'infinite',
                state: {pageSize: this.options.itemsToLoad}
            });

            this._itemViews = [];
            this._waypoints = [];
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.dashboard_modules_popular));

            this.listenTo(this._items, 'sync', this._renderItems);

            this._fetchOptions = {

                data: {

                    period: 'lastMonth',
                    section: 'platform',
                    sectionId: Love.session.get('currentSite').get('nowPlatformId')
                },
                traditional: true,
                requestId: this.getAjaxAbortId(),
                abortPendingByIds: [this.getAjaxAbortId()]
            };

            this._items.getFirstPage(this._fetchOptions);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this._clearWaypoints();
        },

        _clearWaypoints: function() {

            _.each(this._waypoints, function(point) { point.destroy(); });
            this._waypoints = [];
        },

        _closeNotification: function(noFade) {

            if (this._notification) {

                if (!noFade)
                    this._notification.closeFade('slow');
                else
                    this._notification.close();
            }
        },

        _renderItems: function() {

            var fullCollection = this._items.fullCollection ? this._items.fullCollection : this._items; // fullCollection is only used when mode is infinite.
            var addElements = [];

            if (fullCollection.length < 1) {

                this.$('.common-content-list').html(_.template(Love.Templates.dashboard_modules_popular_row_none)());
                return;
            }

            this._items.each(function(model) {

                try {

                    this._itemViews.push(this.addSubView(new Love.Views.DashboardPopularRowView({item: model})));
                    addElements.push(_.last(this._itemViews).render().$el);
                }
                catch (ex) {

                    console.log(ex); // TODO: proper error handling.
                }

            }, this);

            this.$('.common-content-list').append(addElements);

            this._closeNotification();

            var self = this;

            if (self._items.hasNextPage()) {

                var triggerView = _.first(_.last(this._itemViews, this.options.itemsToLoad));

                if (triggerView)
                    this._waypoints = triggerView.$el.waypoint({

                        context: this.$('.common-content-list'),
                        handler: function(direction) {

                            if (direction === 'down') {

                                self._notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                                    dock: 'bottom',
                                    text: 'Fetching more items...'
                                }));
                                self.$('.module').append(self._notification.render().$el);

                                self._clearWaypoints();
                                self._items.getNextPage(self._fetchOptions);
                            }
                        },
                        offset: '100%' // When the top of the element has passed the bottom of the context.
                    });
            }
            else if (!this._finalNotificationShown) {

                this._closeNotification(true);

                this._notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                    dock: 'bottom',
                    text: 'That\'s it for now!'
                }));
                this.$('.module').append(this._notification.render().$el);

                _.delay(self._closeNotification, 4000);

                this._finalNotificationShown = true;
            }

            this.trigger('rendered');
        }
    });

})(Love);