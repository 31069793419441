(function(Love) {

    Love.Enums.ContentSetContentTypes = {

        'cms-group': 'Latest post in category',
        'cms-group-x': 'Latest x post in category',
        'cms-groups-latest': 'Latest post in all categories',
        'cms-groups-latest-x': 'Latest x post in all categories',
        'cms-page': 'Page',
        'cms-blog': 'Post',
        'stream-mobypicture-latest-x': 'Stream - Latest x Moby in stream',
        'custom': 'Custom'
    };

})(Love);