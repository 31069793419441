(function(Love) {

    Love.Views.PostsScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.PostsScreenView',

        defaults: function() {

            return {

                itemsToShow: 10,
                selectedItems: []
            };
        },

        events: {

            'change input[name="list-select-all"]': '_handleListSelectAll',
            'click [data-action="list-selected-action"]': '_handleListSelectedAction'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this._itemViews = [];
            this._sidebar = this.addSubView(new Love.Views.PostsSidebarView());

            this._pagination = this.addSubView(new Love.Views.CommonPaginationView({

                itemsPerPage: this.options.itemsToShow
            }));

            this.listenTo(this._pagination, 'itemsPerPageChanged', _.bind(function(e) {

                this.items.setPageSize(e.itemsPerPage);
                this._getItemsByPage(e.currentPage);

            }, this));
            this.listenTo(this._pagination, 'pageChanged', _.bind(function(e) {this._getItemsByPage(e.currentPage);}, this));

            this.items = new Love.Collections.BlogPostCollection(null, {

                state: {pageSize: this.options.itemsToShow}
            });
        },

        render: function() {

            var context = {siteId: Love.session.get('currentSite').id};
            this.$el.html(_.template(Love.Templates.posts_screen)(context));

            this.$('.common-content-list-toolbar-container').html(_.template(Love.Templates.common_content_list_toolbar({

                actions: [

                    {
                        value: 'delete',
                        text: Love.t('posts:screen.common_content_list_actions_delete_interval', {

                            count: this.options.selectedItems.length,
                            postProcess: 'interval'
                        })
                    }
                ]
            })));

            this._sidebar.setElement(this.$('.base-sidebar-container')).render();

            this.listenTo(this._sidebar, 'filtersChanged', _.bind(function(e) {

                this._getItemsByPageAndFilters(this.items.state.currentPage, e.filters);

            }, this));

            this._getItemsByPage();

            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _addItemTemplate: function(model) {

            var itemView = this.addSubView(new Love.Views.PostRowView({item: model}));
            this.$('.common-content-list').append(itemView.render().$el);

            this._itemViews.push(itemView);

            this.listenTo(itemView, 'selectedChanged', this._handleItemSelectedChanged);
            this.listenTo(itemView, 'delete', this._handleItemDelete);
        },

        _clearSelection: function() {

            _.each(this._itemViews, function(view) {view.setSelected(false);}); // Clear selection on the current page.
            this.options.selectedItems = []; // Also clear selection on other pages.
        },

        _getItemsByPage: function(page) {

            this._getItemsByPageAndFilters(page, this._sidebar.getFilterModel());
        },

        _getItemsByPageAndFilters: function(page, filters) {

            Love.Helpers.Loading.showLoading(true, this.cid, this.$('.common-content-list'));

            this.$('.list-row-empty-list').remove();

            if (this.items.length < 1) {

                // We have a fixed amounts of items to show on each page.
                // Though item data has to be retrieved from the server asynchronously, we can reserve layout space
                // by generating templates in advance.

                for (var i = 0; i < this.options.itemsToShow; i++)
                    this._addItemTemplate(new Love.Models.BlogPostModel());
            }

            var self = this;

            this.listenToOnce(this.items, 'sync', function() {

                var diff = 0;

                if (self.items.length > self._itemViews.length) {

                    // Add extra templates.

                    diff = self.items.length - self._itemViews.length;

                    for (var j = 0; j < diff; j++)
                        self._addItemTemplate(new Love.Models.BlogPostModel());
                }
                else if (self.items.length < self._itemViews.length) {

                    // Remove unnecessary templates.

                    diff = self._itemViews.length - self.items.length;

                    for (var h = 0; h < diff; h++)
                        self._removeLastItemTemplate();
                }

                // Fill the pre-rendered models.

                $(window).scrollTo(0, 'normal');

                if (self.items.length > 0) {

                    self.items.each(function(item, index) {

                        var view = self._itemViews[index];

                        view.setItem(item);
                        view.setSelected(_.indexOf(self.options.selectedItems, item.id) > -1);

                    }, self);
                }
                else
                    self.$('.common-content-list').html(_.template(Love.Templates.posts_post_row_none));

                // Show / update the pagination view.

                self._pagination.options.currentPage = self.items.state.currentPage;
                self._pagination.options.totalItems = self.items.state.totalRecords;

                self._pagination.setElement(self.$('.common-pagination')).render();

                // Show / update the description.

                if (!_.isEmpty(filters.search)) {

                    self.$('.common-content-list-description').html(Love.t('posts:screen.common_content_list_description_search', {

                        count: self.items.state.totalRecords,
                        query: filters.search
                    }));
                    self.$('.common-content-list-description').removeClass('hidden');
                }
                else
                    self.$('.common-content-list-description').addClass('hidden');
            });

            //var isPublished = _.threeStateValue(filters.showPublished, filters.showDrafts);

            var fetchOptions = {

                data: {

                    authorIds: _.undefinedIfEmpty(_.pluck(filters.authors, 'id').join(',')),
                    blogIds: _.undefinedIfEmpty(_.pluck(filters.categories, 'id').join(',')),
                    query: filters.search,
                    states: _.undefinedIfEmpty(_.pluck(filters.states, 'id').join(',')),
                    storylineIds: _.undefinedIfEmpty(_.pluck(filters.storylines, 'id').join(','))
                },
                traditional: true,
                requestId: this.getAjaxAbortId(),
                abortPendingByIds: [this.getAjaxAbortId()]
            };

            this.items.getPage(page ? page : 1, fetchOptions).always(function() {

                Love.Helpers.Loading.showLoading(false, self.cid);
            });
        },

        _handleItemDelete: function(e) {

            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: _.bind(function() {

                        e.item.destroy()
                            .fail(_.bind(function(error) {

                                // TODO RENS: show error notification.

                                console.log(error);
                            }))
                            .done(_.bind(function() {

                                // Remove deleted items from the selected items collection.

                                this.options.selectedItems = _.difference(this.options.selectedItems, e.item.id);
                                this._updateSelectedItems();

                                // Refresh the current page.

                                this._getItemsByPage(this.items.state.currentPage);

                            }, this));

                    }, this)
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete post?',
                    text: 'Do you really want to delete this post?',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleItemSelectedChanged: function(e) {

            if (e.selected) {

                if (_.indexOf(this.options.selectedItems, e.item.id) === -1)
                    this.options.selectedItems.push(e.item.id);
            }
            else
                this.options.selectedItems = _.without(this.options.selectedItems, e.item.id);

            this._updateSelectedItems();
        },

        _handleListSelectAll: function(e) {

            var checked = $(e.target).prop('checked');
            _.each(this._itemViews, function(itemView) { itemView.setSelected(checked); });
        },

        _handleListSelectedAction: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var action = $(e.target).closest('.common-content-list-actions').find('select[name="list-selected-action"]').val();
            if (!action) return;

            var ids = this.options.selectedItems;
            if (ids.length < 1) return;

            switch (action) {

                case 'delete': {

                    var popup = new Love.Views.CommonQuestionPopupView({

                        callbacks: {

                            onYes: _.bind(function() {

                                var promises = [];

                                _.each(ids, function(id, index) {

                                    var model = new Love.Models.BlogPostModel({id: id});
                                    promises[index] = model.destroy();

                                }, this);

                                $.whenAll.apply($, promises).always(_.bind(function() {

                                    // Remove deleted items from the selected items collection.

                                    this.options.selectedItems = _.difference(this.options.selectedItems, ids);
                                    this._updateSelectedItems();

                                    // Refresh the current page.

                                    this._getItemsByPage(this.items.state.currentPage);

                                }, this));

                            }, this)
                        },
                        data: {

                            noText: 'Cancel',
                            title: 'Delete ' + ids.length + ' post(s)?',
                            text: 'Do you really want to delete the selected post(s)?',
                            yesText: 'Delete'
                        }
                    });

                    popup.showPopup();
                    break;
                }
            }
        },

        _removeLastItemTemplate: function() {

            var lastView = this._itemViews[this._itemViews.length - 1];

            if (lastView) {

                this.stopListening(lastView, 'selectedChanged', this._handleItemSelectedChanged);
                this.stopListening(lastView, 'delete', this._handleItemDelete);

                lastView.close();
            }

            this._itemViews.pop();
        },

        _updateSelectedItems: function() {

            if (this.options.selectedItems.length > 0) {

                this.$('.common-content-list-toolbar').addClass('action-mode');
                this.$('[data-action="list-selected-action"]').removeClass('disabled');
            }
            else {

                this.$('.common-content-list-toolbar').removeClass('action-mode');
                this.$('[data-action="list-selected-action"]').addClass('disabled');
            }

            // Update the number of selected items.

            this.$('.common-content-list-actions [value="delete"]').text(Love.t('posts:screen.common_content_list_actions_delete_interval', {

                count: this.options.selectedItems.length,
                postProcess: 'interval'
            }));

            // The select all checkbox operates on a by-page basis by default. Therefore it should only be
            // checked if any of the items on the current page is selected.

            this.$('input[name="list-select-all"]').prop('checked', _.some(this._itemViews, function(view) {return view.isSelected();}));
        }
    });

})(Love);