(function(Love) {

    Love.Views.CommonContentNewWidgetPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonContentNewWidgetPopupView',

        className: 'base-popup popup-list-menu',
        id: '',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    date: moment(),
                    items: _.without([

                        {
                            icon: 'icon-image',
                            icon2: 'icon-paragraph-left',
                            name: 'Image & Text',
                            action: 'confirm',
                            type: 'CMSTextImageWidget',
                            extra: 'left'
                        },
                        {
                            icon: 'icon-paragraph-left',
                            icon2: 'icon-image',
                            name: 'Text & Image',
                            action: 'confirm',
                            type: 'CMSTextImageWidget',
                            extra: 'right'
                        },
                        {icon: 'icon-paragraph-left', name: 'Text', action: 'confirm', type: 'CMSTextWidget'},
                        {icon: 'icon-image', name: 'Image', action: 'confirm', type: 'CMSImageWidget'},
                        {icon: 'icon-quotes-left', name: 'Quote', action: 'confirm', type: 'CMSQuoteWidget'},
                        {icon: 'ttl-remix', name: 'Remix content', action: 'confirm', type: 'remix'},
                        {icon: 'ttl-embedcontent2', name: 'Embedded Media', action: 'confirm', type: 'CMSEmbedWidget'}

                    ], null),
                    mode: 'icons',
                    title: 'What do you want to add?',
                    triangle: true
                }
            };
        },

        events: {

            'click [data-action="close"]': '_handleClose',
            'click [data-action="confirm"]': '_handleConfirm'
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_list_menu)(this.options.data));

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm) {

                var element = $(e.target).closest('[data-type]');
                var type = element.attr('data-type');

                if (type === 'remix') {

                    var popup = new Love.Views.CommonContentNewWidgetRemixPopupView({

                        callbacks: {

                            onConfirm: this.options.callbacks.onConfirm
                        }
                    });

                    popup.showPopup();
                }
                else {

                    var settings = {};

                    if (type === 'CMSTextImageWidget')
                        settings.imageAlignment = element.attr('data-extra');

                    this.options.callbacks.onConfirm({fragments: [{type: type, settings: settings}]});
                }
            }

            this.close();
        }
    });

})(Love);