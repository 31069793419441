(function (Love) {

    Love.Views.LoginRecoverScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.LoginRecoverScreenView',

        events: {

            'click [data-action="submit"]': '_handleSubmit'
        },
        
        render: function () {

            this.$el.html(_.template(Love.Templates.login_recover_screen)());
            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _handleSubmit: function (e) {

            if (e && e.preventDefault) e.preventDefault();

            // TODO RENS: request password recovery by api call.

            this.$('.form').addClass('hidden');

            this.$('.feedback p').text('We have received your request. You should get an e-mail from us with further instructions to recover your password within 10 minutes!');
            this.$('.feedback').removeClass('hidden');
        }
    });

})(Love);