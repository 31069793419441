(function(Love) {

    Love.Views.CommonContentImageWidgetView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentImageWidgetView',

        defaults: function() {

            return {

                disabled: false,
                settings: null,
                uploadAutomatically: true
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this,

                'hasContent',
                'showSettings',
                '_handleImageChange',
                '_handleImageDropMultiple'
            );
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_widgets_image)());

            this.imageUploadView = this.addSubView(new Love.Views.CommonImageUploadView({

                callbacks: {

                    onChange: this._handleImageChange,
                    onDropMultiple: this._handleImageDropMultiple,
                    onLoadingChange: _.bind(function(loading) {this.trigger('changing', {isChanging: loading});}, this)
                },
                disabled: this.options.disabled,
                imageModel: new Love.Models.ImageModel(this.options.settings),
                loadingFullScreen: false,
                uploadAutomatically: this.options.uploadAutomatically
            }));
            this.imageUploadView.setElement(this.$('.widget-image')).render();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        hasContent: function() {

            return (this.options.settings && (this.options.settings.imageURL || (this.options.settings.imageUpload && this.options.settings.imageUpload.files && this.options.settings.imageUpload.files[0])));
        },

        showSettings: function() {

            this.imageUploadView.showPopup();
        },

        _handleImageChange: function(imageModel) {

            _.extend(this.options.settings, imageModel.attributes);
            this._raiseChanged();
        },

        _handleImageDropMultiple: function(file, index) {

            // Create new widgets for any extra dropped files.

            var model = new Love.Models.ImageModel();
            var self = this;

            return model.uploadFile(file)

                .done(_.bind(function() {

                    // Use the current settings as a basis.

                    self._raiseAddExtraWidget(_.extend(_.clone(self.options.settings), this.attributes));

                }, model));
        },

        _raiseChanged: function() {

            this.trigger('changed', {settings: this.options.settings});
        },

        _raiseAddExtraWidget: function(settings) {

            this.trigger('addExtraWidget', {settings: settings});
        }
    });

})(Love);