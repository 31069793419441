(function(Love) {

    Love.Views.CalendarListOfEventsPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarListOfEventsPopupView',

        className: 'base-popup popup-list-menu',
        id: 'popup-calendar-list-of-events',

        defaultsForPopupType: function() {

            return {

                data: {

                    events: []
                },
                enableClickInterception: false
            };
        },

        events: {

            'click .calendar-base-event': '_handleEventClick'
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.calendar_popups_list_of_events)());

            _.each(this.options.data.events, function(eventView) {

                var element = eventView.render().$el;
                eventView.eventStyling();

                eventView.undelegate('click'); // We have our custom click handler below.

                this.$('.events-container').append(element);

            }, this);

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleEventClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            // @ Jeroen: parseInt weggehaald omdat ids (bijna) nergens in de app een int zijn.
            // _.some doet iets anders, werkt voor collections / arrays.
            // _.find is sneller dan _.filter omdat het uitgaat van 1 result.

            var id = $(e.target).closest('[data-id]').attr('data-id');
            var model = _.find(this.options.data.events, function(view) {

                return (view.model.id === id);

            }).model;

            var instance = Love.Views.CalendarEventPopupView;

            if (model.get('type') === 'googlecalendar') instance = Love.Views.CalendarEventGooglePopupView;
            else if (model.get('contentType') === 'mobypicture') instance = Love.Views.CalendarEventMobyPopupView;
            else if (model.get('type') === 'cmscontent' && model.get('content').contentType === 'blog') instance = Love.Views.CalendarEventPostPopupView;
            else if (model.get('contentType') === 'twitter') instance = Love.Views.CalendarEventTweetPopupView;

            var popup = new instance({

                data: {

                    calendarEventModel: model
                },
                parentView: this,
                positioning: {

                    attachToElement: $(e.target).closest('.calendar-base-event'),
                    centerX: true,
                    positionAt: 'element',
                    top: true
                }
            });

            var container = $(e.target).closest('.calendar-view-content-container');
            if (container.length > 0) {

                popup.options.positioning.clampY = {

                    max: container.offset().top
                };
            }

            popup.showPopup({keepOpen: [this]});
        }
    });

})(Love);