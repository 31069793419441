(function(Love) {

    Love.Views.CommonContentQuoteWidgetView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentQuoteWidgetView',

        defaults: function() {

            return {

                isPrefilled: false,
                quoteList: [

                    '"Always borrow money from a pessimist. He won’t expect it back." - Oscar Wilde',
                    '"Knowledge is knowing a tomato is a fruit; wisdom is not putting it in a fruit salad." - Miles Kington',
                    '"I love deadlines. I like the whooshing sound they make as they fly by." - Douglas Adams',
                    '"I asked God for a bike, but I know God doesn’t work that way. So I stole a bike and asked for forgiveness." - Emo Philips',
                    '"Do not argue with an idiot. He will drag you down to his level and beat you with experience." - Greg King',
                    '"Men have only two emotions: hungry and horny. If you see him without an erection, make him a sandwich."',
                    '"A bargain is something you don’t need at a price you can’t resist." - Franklin Jones',
                    '"Never, under any circumstances, take a sleeping pill and a laxative on the same night." - Dave Barry',
                    '"If you steal from one author, it’s plagiarism; if you steal from many, it’s research." - Wilson Miner',
                    '"How is it one careless match can start a forest fire, but it takes a whole box to start a campfire?"',
                    '"A woman can have a smile, and a woman can have a large backside, but I have been to the mountain and I am here to tell you that when a woman has both of those things she is not to be trusted." - Rev. Martin Luther King, Jr.',
                    '"Some cause happiness wherever they go; others, whenever they go." - Oscar Wilde',
                    '"Patience is something you admire in the driver behind you, but not in one ahead." - Bill McGlashen',
                    '"Children: You spend the first 2 years of their life teaching them to walk and talk. Then you spend the next 16 telling them to sit down and shut-up."',
                    '"A diplomat is someone who can tell you to go to hell in such a way that you will look forward to the trip." - Caskie Stinnett',
                    '"By the time a man realizes that his father was right, he has a son who thinks he’s wrong." - Charles Wadsworth',
                    '"We’ve all heard that a million monkeys banging on a million typewriters will eventually reproduce the entire works of Shakespeare. Now, thanks to the Internet, we know this is not true." - Robert Willensky',
                    '"If evolution really works, how come mothers only have two hands?" - Milton Berle',
                    '"Women who seek to be equal with men lack ambition." - Marilyn Monroe',
                    '"By working faithfully eight hours a day you may eventually get to be boss and work twelve hours a day." - Robert Frost',
                    '"When tempted to fight fire with fire, remember that the Fire Department usually uses water."',
                    '"To err is human, to blame it on somebody else shows management potential."',
                    '"On the Internet, nobody knows you’re not quoting Martin Luther King, Jr." - Abraham Lincoln',
                    '"A fish in the bush is worth two in the pants." - Benjamin Franklin'
                ],
                settings: null
            };
        },

        events: {

            'blur .content': '_handleContentBlur',
            'click .content': '_handleContentClick',
            'keydown .content': '_handleContentKeyDown',
            'input .content': '_handleContentChange',
            'drop .content': '_handleContentChangeDrop',
            'paste .content': '_handleContentChangePaste'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'hasContent');

            if (this.options.isNew)
                this.options.isPrefilled = true;
        },

        render: function() {

            if (this.options.isPrefilled) {

                this.$el.html(_.template(Love.Templates.common_content_widgets_quote)({content: this.options.quoteList[_.random(this.options.quoteList.length)]}));
                this.$('.content').addClass('placeholder');
            }
            else
                this.$el.html(_.template(Love.Templates.common_content_widgets_quote)({content: this.options.settings.contentText}));

            this._setPlaceholderIfEmpty();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        hasContent: function() {

            return (this.options.settings && !_.isEmpty(this.options.settings.contentText));
        },

        _clearIfPrefilled: function() {

            if (this.options.isPrefilled) {

                this.$('.content').empty();
                this.$('.content').removeClass('placeholder');

                this._setContent('');

                this.options.isPrefilled = false;
            }
        },

        _handleContentBlur: function(e) {

            this._setPlaceholderIfEmpty();
        },

        _handleContentChange: function(e) {

            // Quotes are not allowed to contain HTML tags. Therefore, we strip them now.

            var content = $(e.target).text().trim();
            this._setContent(content);
        },

        _handleContentChangeDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var content = e.originalEvent.dataTransfer.getData('text/plain').trim();

            $(e.target).text(content);
            this._setContent(content);
        },

        _handleContentChangePaste: function(e) {

            // Due to the timing of the paste event, we have to defer execution.

            _.defer(_.bind(function() {

                var content = $(e.target).text().trim();

                $(e.target).text(content);
                this._setContent(content);

            }, this));
        },

        _handleContentClick: function(e) {

            this._clearIfPrefilled();
        },

        _handleContentKeyDown: function(e) {

            this._clearIfPrefilled();
        },

        _raiseChanged: function() {

            this.trigger('changed', {settings: this.options.settings});
        },

        _setContent: function(value) {

            this.options.settings.contentHTML = value;
            this.options.settings.contentText = value; // contentText overwrites contentHTML server side, to prevent HTML tags in quotes.

            this._raiseChanged();
        },

        _setPlaceholderIfEmpty: function() {

            if (_.isEmpty(this.options.settings.contentText)) {

                // Restore the placeholder, without overwriting the value in the settings (!).

                this.$('.content').text(this.options.quoteList[_.random(this.options.quoteList.length)]);
                this.$('.content').addClass('placeholder');

                this.options.isPrefilled = true;
            }
        }
    });

})(Love);