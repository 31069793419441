(function(Love) {

    Love.Views.CalendarEventPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarEventPopupView',

        className: 'base-popup popup-calendar popup-calendar-event popup-clean popup-size-auto',
        id: 'popup-calendar-event-event',

        defaultsForPopupType: function() {

            return {

                data: {

                    calendarEventModel: {}
                },
                enableClickInterception: false
            };
        },

        events: {

            'click [data-action="delete"]': '_handleDeleteClick',
            'click [data-action="edit"]': '_handleEditClick',
            'click [data-action="view"]': '_handleViewClick'
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.calendar_popups_event_event)(this.options.data.calendarEventModel.attributes));

            var coverModel = new Love.Models.ImageModel();

            if (this.options.data.calendarEventModel.get('content').cover)
                coverModel.set(coverModel.getAttrFromMediaAttr(this.options.data.calendarEventModel.get('content').cover));

            var coverUrl = coverModel.getImageView();

            if (!_.isEmpty(coverUrl)) {

                Love.Helpers.Images.loadAnImage({

                    element: this.$('.popup-details-media .image'),
                    focusPoint: coverModel.getFocusPoint(),
                    onLoad: _.bind(function() {

                        this.$('.image .placeholder').addClass('hidden');

                    }, this),
                    url: coverUrl
                });
            }

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            var model = new Love.Models.EventModel(this.options.data.calendarEventModel.get('content'));

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        model.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                self.options.data.calendarEventModel.destroy();
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete event?',
                    text: 'Do you really want to delete this event? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleEditClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/event/' + this.options.data.calendarEventModel.get('content').calendarId + '/' + this.options.data.calendarEventModel.get('content').id, {trigger: true});
        },

        _handleViewClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var siteUrl = Love.session.get('currentSite').get('url');
            var model = new Love.Models.EventModel(this.options.data.calendarEventModel.get('content'));

            this.close();

            var win = window.open(siteUrl + '/event/' + model.get('id'), '_blank');
            win.focus();
        }
    });

})(Love);