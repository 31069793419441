(function(Love) {

    Love.Views.CommonEditHyperlinkPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonEditHyperlinkPopupView',

        className: 'base-popup popup-tabbed popup-size-auto',
        id: 'popup-common-edit-hyperlink',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null,
                    onRemove: null
                },
                data: {

                    target: '_blank',
                    text: '',
                    url: ''
                },
                enableConfirmByEnter: true
            };
        },

        events: {

            'keyup [data-confirm-by-enter="true"]': '_handleConfirmByEnter',
            'click [data-action="remove"]': '_handleRemove',
            'click [data-action="confirm"]': '_handleConfirm',
            'click [data-action="close"]': '_handleClose'
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.common_popups_edit_hyperlink)(this.options.data));

            _.defer(_.bind(function() {

                this.$('input[name="url"]').focus();

            }, this));

            //this._doNotConfirm = false;
            //this._notAlreadyConfirmed = false;

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        /*
         onClose: function() {

         var data = this._getFormValues();

         // When being closed by clicking outside the popup, it should call onConfirm callbacks.

         if (!this._doNotConfirm && !this._notAlreadyConfirmed) {

         // However, it should only do this once. Sometimes the popup is closed by other means (pressing Esc, clicking the 'remove' button).

         if (this.options.callbacks.onConfirm) {

         this.options.callbacks.onConfirm(data);
         this._notAlreadyConfirmed = true;
         }
         }

         this._doNotConfirm = false;

         Love.Views.BasePopupView.prototype.onClose.call(this);
         },
         */

        _getFormValues: function() {

            return {

                target: this.$('input[name="new-tab"]').prop('checked') ? '_blank' : '',
                text: this.$('input[name="text"]').val().trim(),
                url: this.$('input[name="url"]').val().trim()
            };
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            //this._doNotConfirm = false;
            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var data = this._getFormValues(); // Needs to be called before close().

            this.close();

            //if (!this._doNotConfirm && !this._notAlreadyConfirmed) {

            if (this.options.callbacks.onConfirm) {

                this.options.callbacks.onConfirm(data);
                this._notAlreadyConfirmed = true;
            }
            //}

            //this._doNotConfirm = false;
        },

        _handleRemove: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var data = this._getFormValues(); // Needs to be called before close().

            if (this.options.callbacks.onRemove)
                this.options.callbacks.onRemove(data);

            //this._doNotConfirm = true;
            this.close();
        }
    });
})(Love);