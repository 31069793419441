(function(Love) {

    Love.Views.AccountEditorView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.AccountEditorView',

        render: function() {

            this.$el.html(_.template(Love.Templates.account_editor)());
            return Love.Views.BaseView.prototype.render.call(this);
        }
    });

})(Love);