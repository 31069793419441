(function(Love) {

    Love.Models.BookmarkModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.BookmarkModel',

        defaults: function() {

            return {

                content: '',
                createdOnUtc: null,
                description: '',
                file: null,
                id: '',
                parsedUrlDescription: '',
                parsedUrlImage: '',
                parsedUrlTitle: '',
                title: '',
                type: '',
                url: '',
                user: null
            };
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/bookmarks/' + this.id + '.json';

            // Create url.

            return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/bookmarks.json';
        },

        initialize: function(options) {

            if (options) this.set(options);
        },

        parse: function(response, options) {

            var contents = (response.response) ? response.response : response;

            contents = _.extend(contents, {

                createdOnUtc: contents.createdOnUtc ? moment.utc(contents.createdOnUtc) : null
            });

            if (contents.file === null) contents.file = {};

            if (contents.type === 'image' && _.isEmpty(contents.parsedUrlImage)) {

                // TODO RENS: dit is een hack zolang nog geen parsedUrlImage door komt bij images.

                var imageModel = new Love.Models.ImageModel({

                    imageSource: 'url',
                    imageURL: contents.file.url
                });

                contents.parsedUrlImage = imageModel.getImageView();
            }

            return contents;
        },

        getDescription: function() {

            if (!_.isEmpty(this.get('parsedUrlDescription'))) { return this.get('parsedUrlDescription'); }
            else if (!_.isEmpty(this.get('description'))) { return this.get('description'); }
            else if (this.get('type') === 'audio') { return this.get('file').fileName; }
            else if (this.get('type') === 'file') { return this.get('file').fileName; }
            else if (this.get('type') === 'image') { return this.get('file').fileName; }
            else if (this.get('type') === 'movie') { return this.get('file').fileName; }
            else if (this.get('type') === 'text') { return this.get('content'); }
            else if (this.get('type') === 'url') { return '<a href="' + this.get('url') + '" target="_blank">' + this.get('url') + '</a>'; }

            return '';
        },

        getExternalUrl: function() {

            var url = this.get('url');
            if (_.isEmpty(url) && this.get('file')) url = this.get('file').url;
            if (_.isEmpty(url)) url = this.get('parsedUrlImage');

            return url;
        },

        getTitle: function() {

            if (!_.isEmpty(this.get('title'))) { return this.get('title'); }
            else if (!_.isEmpty(this.get('parsedUrlTitle'))) { return this.get('parsedUrlTitle'); }
            else if (this.get('type') === 'audio') { return this.get('file').fileName || this.get('file').url; }
            else if (this.get('type') === 'file') { return this.get('file').fileName || this.get('file').url; }
            else if (this.get('type') === 'image') { return this.get('file').fileName || this.get('file').url; }
            else if (this.get('type') === 'movie') { return this.get('file').fileName || this.get('file').url; }
            else if (this.get('type') === 'text') { return 'Text'; }
            else if (this.get('type') === 'url') { return 'URL'; }
            else { return 'Bookmark'; }
        }
    });

    Love.Collections.BookmarkCollection = Love.Collections.BasePageableCollection.extend({

        objectClassName: 'Love.Collections.BookmarkCollection',

        model: Love.Models.BookmarkModel,

        url: function() {return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/bookmarks.json';},

        parseRecords: function(response, options) {

            return response.response;
        }
    });

})(Love);