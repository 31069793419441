(function(Love) {

    Love.Models.SessionModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.SessionModel',

        defaults: function() {

            return {

                accountId: null,
                currentSite: null,
                currentSiteId: '',
                displayName: '',
                googleCalendars: [],
                isLoggedIn: false,
                password: '',
                rememberMe: false, // TODO: remember me verwerken in api call.
                username: ''
            };
        },

        initialize: function(options) {

            if (options) this.set(options);
        },

        validate: function(attributes, options) {

            var errors = {};

            if (!attributes.username) errors.username = 'Please enter an username.';
            if (!attributes.password) errors.password = 'Please provide a password.';

            if (!_.isEmpty(errors)) {

                console.log(errors);
                return errors;
            }
        },

        authorize: function(callback) {

            var self = this;
            var url = 'api/1.0/account.json';

            $.ajax(url, {

                type: 'GET',
                dataType: 'json',

                success: function(data, textStatus, jqXHR) {

                    if (data.error) {

                        console.log('Authorization returned error: ' + data.error);
                        if (callback) callback({error: data.error});
                    }
                    else {

                        console.log('Authorization successful.');

                        self._setAuthorizedData(data.response);

                        // Perform any asynchronous operations here and execute the callback function when finished.

                        if (callback) callback({});
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    console.log('Something went wrong in the authorization AJAX request.');
                    console.log(errorThrown);

                    if (callback) callback({error: errorThrown});
                }
            });
        },

        login: function(callback) {

            // TODO: remember me implementeren. Ook in view.

            var formValues = {

                email: this.attributes.username,
                password: this.attributes.password
            };

            var self = this;
            var url = 'api/1.0/session/authenticate.json';

            $.ajax({

                url: url,
                type: 'POST',
                dataType: 'json',
                data: formValues,

                success: function(data, textStatus, jqXHR) {

                    if (data.error) {

                        console.log('Login returned error: ' + data.error);
                        if (callback) callback({error: data.error});
                    }
                    else {

                        console.log('Login successful.');

                        self._setAuthorizedData(data.response);

                        // Perform any asynchronous operations here and execute the callback function when finished.

                        if (callback) callback({});
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    console.log('Something went wrong in the login AJAX request.');
                    console.log(errorThrown);

                    if (callback) callback({error: errorThrown});
                }
            });
        },

        logout: function(callback) {

            var self = this;
            var url = 'api/1.0/session/logout.json';

            $.ajax({

                url: url,
                type: 'GET',

                success: function(data, textStatus, jqXHR) {

                    if (data.error) {

                        console.log('Logout returned error: ' + data.error);
                        if (callback) callback({error: data.error});
                    }
                    else {

                        console.log('Logout successful.');

                        // Reset the session model.

                        self.clear({silent: true}).set(self.defaults());
                        self._sites = new Love.Collections.SiteCollection();

                        if (callback) callback({});
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {

                    console.log('Something went wrong in the logout AJAX request.');
                    console.log(errorThrown);

                    if (callback) callback({error: errorThrown});
                }
            });
        },

        retrieveSiteData: function(site, successCallback) {

            if (!site) return;

            var start = new Date().getTime();

            console.log('Retrieving site data...');

            return site.fetch({

                data: {

                    //part: ['authors', 'blogs', 'calendars', 'destinations', 'googleCalendars', 'storylines', 'streams'].join(',')
                },
                traditional: true,
                async: false,
                success: function(model, response, options) {

                    var end = new Date().getTime();
                    console.log('Site data retrieved! (Execution time: ' + (end - start) + ' ms)');

                    if (successCallback)
                        successCallback(model, response, options);
                },
                error: function(model, response, options) {

                    var end = new Date().getTime();
                    console.log('Site data retrieval FAILED! (Execution time: ' + (end - start) + ' ms)');

                    Love.router.navigate('error/session', {trigger: true});
                }
            });
        },

        setCurrentSite: function(siteId) {

            var currentSite = this.get('currentSite');
            var currentSiteId = this.get('currentSiteId');

            if (!_.isEmpty(siteId)) {

                // Check if the new siteId matches the current one.

                if (siteId !== currentSiteId) {

                    var newSite = new Love.Models.SiteModel({id: siteId});
                    newSite.incrementUsageAmount();

                    this.set('currentSite', newSite);
                    this.set('currentSiteId', siteId);

                    Love.Helpers.Tracking.register({

                        currentSite: newSite.attributes
                    });

                    console.log('Site changed.');
                    this.retrieveSiteData(newSite);
                }
                else {

                    // The site didn't change.

                    if (currentSite.lastSyncedBeforeMinutes(1)) {

                        console.log('Site did not change, but site data is older than 10 minutes.');
                        this.retrieveSiteData(currentSite);
                    }
                }
            }
            else {

                this.set('currentSite', null);
                this.set('currentSiteId', '');

                Love.Helpers.Tracking.register({

                    currentSite: null
                });
            }

            return !_.isEqual(currentSiteId, this.get('currentSiteId'));
        },

        _setAuthorizedData: function(data) {

            var cal = data.googleCalendars || [];

            this.set({

                accountId: data.id,
                isLoggedIn: true,
                displayName: data.displayName,
                googleCalendars: cal
            });

            Love.Helpers.Tracking.identify(data.id);

            mixpanel.people.set({

                displayName: data.displayName,
                id: data.id,
                name: data.displayName
            });
        }
    });

})(Love);