(function(Love) {

    Love.Factories.FragmentFactory = {

        createEmbedAudioFragmentFromUrl: function(url) {

            var settings = {

                vendor: 'audio',
                vendorContentID: url,
                vendorEmbedCode: url,
                vendorSpecificHeight: '',
                vendorSpecificWidth: ''
            };

            return {settings: settings, type: 'CMSEmbedWidget'};
        },

        createEmbedFragmentFromBookmark: function(bookmark) {

            switch (bookmark.get('type')) {

                case 'audio': {

                    return Love.Factories.FragmentFactory.createEmbedAudioFragmentFromUrl(bookmark.get('file').url);
                }
                case 'movie': {

                    return Love.Factories.FragmentFactory.createEmbedVideoFragmentFromUrl(bookmark.get('file').url);
                }
                case 'url': {

                    return Love.Factories.FragmentFactory.createEmbedFragmentFromString(bookmark.get('url'));
                }
            }

            return null;
        },

        createEmbedFragmentFromString: function(str, returnInvalid) {

            var vendorRecognized = false;
            var localPromise;
            var settings = {

                vendor: '',
                vendorContentID: '',
                vendorEmbedCode: str,
                vendorSpecificHeight: '',
                vendorSpecificWidth: ''
            };

            str = str.trim();

            var regex, match;
            var hasHttp = (str.indexOf('http://') > -1 || str.indexOf('https://') > -1);
            var hasProtocol = (str.indexOf('://') > -1);

            // Custom iFrame code

            if (str.substr(0, 7) === '<iframe') {

                vendorRecognized = true;

                settings.vendor = 'iframe';
                settings.vendorSpecificHeight = '500px';
                settings.vendorSpecificWidth = '100%';

                regex = [/src="(.*?)"/i, /src='(.*?)'/i];
                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });

                regex = [/height="(.*?)"/i, /height='(.*?)'/i];
                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorSpecificHeight = match[1];
                });

                regex = [/width="(.*?)"/i, /width='(.*?)'/i];
                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorSpecificWidth = match[1];
                });
            }

            // Typeform

            else if (str.indexOf('<div class="typeform-widget" ') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'iframe';
                settings.vendorSpecificHeight = '500px';
                settings.vendorSpecificWidth = '100%';

                regex = [/data-url="(.*?)"/i, /data-url='(.*?)'/i];
                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });

                regex = [/height:(.*?);/i];
                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorSpecificHeight = match[1];
                });

                regex = [/width:(.*?);/i];
                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorSpecificWidth = match[1];
                });
            }

            // BNR

            else if (str.indexOf('bnr.nl/player/') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'iframe';
                settings.vendorContentID = str;
                settings.vendorSpecificHeight = '300px';
                settings.vendorSpecificWidth = '100%';
            }

            // Gallery

            else if (str.indexOf('tagthelove.com/gallery/') !== -1 || str.indexOf('tyrsday.com/gallery/') !== -1 || str.indexOf('gallery.mobynow.com/gallery/') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'iframe';
                settings.vendorContentID = str;
                settings.vendorSpecificHeight = '340px';
                settings.vendorSpecificWidth = '100%';
            }

            // Deezer

            else if (str.indexOf('deezer') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'deezer';

                regex = [

                    /*
                     /https?\:\/\/deezer\.com\/plugins\/player\?type=tracks&id=([0-9]*)/i,
                     /https?\:\/\/www\.deezer\.com\/plugins\/player\?type=tracks&id=([0-9]*)/i,
                     /\/\/www\.deezer\.com\/plugins\/player\?type=tracks&id=([0-9]*)/i,
                     /\/\/deezer\.com\/plugins\/player\?type=tracks&id=([0-9]*)/i
                     */

                    /.*deezer\.com\/plugins\/player\?type=tracks&id=([0-9]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });
            }

            // Facebook posts

            else if (str.indexOf('facebook.com') !== -1 && str.indexOf('/posts/') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'iframe';
                settings.vendorContentID = 'https://www.facebook.com/plugins/post.php?href=' + encodeURIComponent(str);
                settings.vendorEmbedCode = str;
                settings.vendorSpecificHeight = '550px';
                settings.vendorSpecificWidth = 'auto';
            }

            // Facebook videos

            else if (str.indexOf('facebook.com') !== -1 && str.indexOf('/videos/') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'iframe';
                settings.vendorContentID = 'https://www.facebook.com/plugins/video.php?href=' + encodeURIComponent(str);
                settings.vendorEmbedCode = str;
                settings.vendorSpecificHeight = '315px';
                settings.vendorSpecificWidth = '560px';
            }

            // Flickr

            else if (str.indexOf('flickr') !== -1 || str.indexOf('flic.kr') !== -1) {

                vendorRecognized = true;

                var imageFragmentOrPromise;

                if (validator.isURL(str)) {

                    // Retrieve the image data for the embed.

                    imageFragmentOrPromise = $.Deferred();

                    $.ajax({

                            url: 'https://www.flickr.com/services/oembed/',
                            data: {

                                format: 'json',
                                url: str
                            },
                            type: 'GET',
                            useProxyServer: true
                        })
                        .done(function(data, textStatus, jqXHR) {

                            var imageUrl = data.url;

                            if (_.isEmpty(imageUrl))
                                imageFragmentOrPromise.reject();

                            else {

                                var imageFragmentOrPromise2 = Love.Factories.FragmentFactory.createImageFragmentFromString(imageUrl);

                                $.when(imageFragmentOrPromise2).always(function(fragment) {

                                    if (fragment) imageFragmentOrPromise.resolve(fragment);
                                    else imageFragmentOrPromise.reject();
                                });
                            }
                        })
                        .fail(function() {imageFragmentOrPromise.reject();});
                }
                else {

                    // We're probably dealing with Flickrs own embed code, which contains a direct reference to the image.
                    // <img src="https://c4.staticflickr.com/4/3023/2757229739_b81e7dff41_b.jpg" width="1024" height="768" alt="Other T-shirts">

                    regex = [/<img\ssrc="(.*?)"/i, /<img\ssrc='(.*?)'/i];
                    regex.forEach(function(regex) {

                        match = str.match(regex);
                        if (match) imageFragmentOrPromise = Love.Factories.FragmentFactory.createImageFragmentFromString(match[1]);
                    });
                }

                if (imageFragmentOrPromise) return imageFragmentOrPromise;
            }

            // Instagram

            else if (str.indexOf('instagram') !== -1 || str.indexOf('instagr.am') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'instagram';

                regex = [

                    /*
                     /https?\:\/\/instagram\.com\/p\/([0-9a-zA-Z\-_]*)/i,
                     /https?\:\/\/www\.instagram\.com\/p\/([0-9a-zA-Z\-_]*)/i,
                     /\/\/www\.instagram\.com\/p\/([0-9a-zA-Z\-_]*)/i,
                     /\/\/instagram\.com\/p\/([0-9a-zA-Z\-_]*)/i,
                     /https?\:\/\/instagr\.am\/p\/([0-9a-zA-Z\-_]*)/i,
                     /https?\:\/\/www\.instagr\.am\/p\/([0-9a-zA-Z\-_]*)/i,
                     /\/\/www\.instagr\.am\/p\/([0-9a-zA-Z\-_]*)/i,
                     /\/\/instagr\.am\/p\/([0-9a-zA-Z\-_]*)/i
                     */

                    /.*instagram\.com\/p\/([0-9a-zA-Z\-_]*)/i,
                    /.*instagr\.am\/p\/([0-9a-zA-Z\-_]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });
            }

            // Mobypicture

            else if ((str.indexOf('moby.to') !== -1 || str.indexOf('mobypicture.com') !== -1) && hasHttp) {

                vendorRecognized = true;

                // Mobypicture embeds don't work without http(s).

                settings.vendor = 'iframe';
                settings.vendorContentID = 'http://www.mobypicture.com/embed?url=' + str;
                settings.vendorSpecificHeight = '';
                settings.vendorSpecificWidth = '';

                var mobyPostId = '';

                if (str.indexOf('mobypicture.com') !== -1) {

                    // http://www.mobypicture.com/user/USERNAME/view/POSTID

                    regex = [

                        /*
                         /https?\:\/\/mobypicture\.com\/user\/[0-9a-zA-Z\-_]*\/view\/([0-9]*)/i,
                         /https?\:\/\/www\.mobypicture\.com\/user\/[0-9a-zA-Z\-_]*\/view\/([0-9]*)/i
                         */

                        /.*mobypicture\.com\/user\/[0-9a-zA-Z\-_]*\/view\/([0-9]*)/i
                    ];

                    regex.forEach(function(regex) {

                        match = str.match(regex);
                        if (match) mobyPostId = match[1];
                    });
                }

                // Retrieve the height and width data for the embed.

                localPromise = $.Deferred();

                $.ajax({

                        url: 'https://api.mobypicture.com/',
                        data: {

                            action: 'getThumbUrl',
                            format: 'json',
                            key: Love.Constants.MOBY_API_KEY,
                            post_id: !_.isEmpty(mobyPostId) ? mobyPostId : '',
                            size: 'thumbnail',
                            tinyurl_code: !_.isEmpty(mobyPostId) ? '' : str
                        },
                        dataType: 'json',
                        type: 'GET',
                        useProxyServer: true
                    })
                    .done(function(data, textStatus, jqXHR) {

                        var url = data.url;

                        if (_.isEmpty(url))
                            localPromise.reject();

                        else {

                            // Get the resized image or video thumbnail as displayed on Mobypicture.

                            if (url.indexOf('vid.mobypicture.com') > -1)
                                url = url.replace('thumbnail', 'movie');
                            else
                                url = url.replace('thumbnail', 'view');

                            var state = loadImage(url, function(img) {

                                settings.vendorSpecificHeight = img.height;
                                settings.vendorSpecificWidth = img.width;

                                localPromise.resolve();
                            });

                            if (!state) localPromise.reject();
                            state.onerror = function() { localPromise.reject(); };
                        }
                    })
                    .fail(function() {localPromise.reject();});
            }

            // Playbuzz

            else if (str.indexOf('playbuzz') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'playbuzz';

                regex = [/.*playbuzz\.com(.*)/i];

                regex.forEach(function(regex) {

                    match = str.match(regex);

                    if (match) settings.vendorContentID = match[1];
                });
            }

            // Slideshare

            else if (str.indexOf('slideshare') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'slideshare';

                regex = [

                    /*
                     /https?\:\/\/slideshare\.net\/slideshow\/embed_code\/([0-9]*)/i,
                     /https?\:\/\/www\.slideshare\.net\/slideshow\/embed_code\/([0-9]*)/i,
                     /\/\/www\.slideshare\.net\/slideshow\/embed_code\/([0-9]*)/i,
                     /\/\/slideshare\.net\/slideshow\/embed_code\/([0-9]*)/i
                     */

                    /.*slideshare\.net\/slideshow\/embed_code\/([0-9]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });
            }

            // Soundcloud

            else if (str.indexOf('soundcloud') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'soundcloud';
                settings.vendorContentID = str;

                //regex = [
                //
                //    /https?\:\/\/soundcloud\.com\/([0-9a-zA-Z\-\_]*)\/([0-9a-zA-Z\-\_]*)/i,
                //    /https?\:\/\/w\.soundcloud\.com\/player\/\?url\=([a-zA-Z0-9\/\%\.]*)/i,
                //    /https?\:\/\/api\.soundcloud\.com\/tracks\/([0-9a-zA-Z\-\_]*)/i
                //];
                //
                //regex.forEach(function(regex) {
                //
                //    match = str.match(regex);
                //
                //    if (match) {
                //
                //        if (match.length === 2)
                //            settings.vendorContentID = match[0];
                //        else if (match.length === 3) {
                //            settings.vendorContentID = match[0];
                //
                //        settings.vendorContentID = decodeURIComponent(settings.vendorContentID);
                //    }
                //});
            }

            // Spotify

            else if (str.indexOf('spotify') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'spotify';

                regex = [

                    /*
                     /https?\:\/\/open\.spotify\.com\/([a-zA-Z0-9]*)\/([a-zA-Z0-9\-]*)\/([a-zA-Z0-9\-]*)\/([a-zA-Z0-9\-]*)/i,
                     /https?\:\/\/open\.spotify\.com\/([a-zA-Z0-9]*)\/([a-zA-Z0-9\-]*)/i,
                     /https?\:\/\/embed\.spotify\.com\/\?uri\=spotify\:([a-zA-Z0-9\-]*)\:([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*)/i,
                     /https?\:\/\/embed\.spotify\.com\/\?uri\=spotify\:([a-zA-Z0-9\-]*)\:([a-zA-Z0-9\-]*)/i,
                     /spotify\:([a-zA-Z0-9\-]*)\:([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*)/i,
                     /spotify\:([a-zA-Z0-9\-]*)\:([a-zA-Z0-9\-]*)/i
                     */

                    /.*open\.spotify\.com\/([a-zA-Z0-9]*)\/([a-zA-Z0-9\-]*)/i,
                    /.*open\.spotify\.com\/([a-zA-Z0-9]*)\/([a-zA-Z0-9\-]*)\/([a-zA-Z0-9\-]*)\/([a-zA-Z0-9\-]*)/i,
                    /.*embed\.spotify\.com\/\?uri=spotify:([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*)/i,
                    /.*embed\.spotify\.com\/\?uri=spotify:([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*)/i,
                    /spotify:([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*)/i,
                    /spotify:([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*):([a-zA-Z0-9\-]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);

                    if (match) {

                        if (match.length === 3)
                            settings.vendorContentID = 'spotify:' + match[1] + ':' + match[2];
                        else if (match.length === 5)
                            settings.vendorContentID = 'spotify:' + match[1] + ':' + match[2] + ':' + match[3] + ':' + match[4];
                    }
                });
            }

            // Tag The Love platform media

            else if (str.indexOf('/media?mediaId=') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'iframe';
                settings.vendorContentID = '';
                settings.vendorSpecificHeight = '';
                settings.vendorSpecificWidth = '';

                var mediaId = '';

                // http://www.gijsbregt.nl/media?mediaId=24278061

                regex = [

                    /.*\/media\?mediaId=([0-9]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) mediaId = match[1];
                });

                // Retrieve the height and width data for the embed.

                localPromise = $.Deferred();

                $.ajax({

                        url: 'https://api.mobynow.com/1.0/media/' + mediaId + '.json',
                        data: {

                            platformID: '', // Must be supplied, but can be empty.
                            type: 'now'
                        },
                        dataType: 'json',
                        type: 'GET',
                        useProxyServer: true
                    })
                    .done(function(data, textStatus, jqXHR) {

                        var url = (data.content && data.content.info && data.content.info.media && data.content.info.media.thumbnails) ? data.content.info.media.thumbnails.view : '';
                        var tinyUrl = (data.content) ? data.content.tinyUrlCode : '';

                        if (_.isEmpty(url) || _.isEmpty(tinyUrl))
                            localPromise.reject();

                        else {

                            settings.vendorContentID = 'http://www.mobypicture.com/embed?url=http://moby.to/' + tinyUrl;

                            // Get the resized image or video thumbnail as displayed on Mobypicture.

                            var state = loadImage(url, function(img) {

                                settings.vendorSpecificHeight = img.height;
                                settings.vendorSpecificWidth = img.width;

                                localPromise.resolve();
                            });

                            if (!state) localPromise.reject();
                            state.onerror = function() { localPromise.reject(); };
                        }
                    })
                    .fail(function() {localPromise.reject();});
            }

            // Twitter

            else if (str.indexOf('twitter') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'twitter';

                regex = [

                    /*
                     /https?\:\/\/twitter\.com\/[0-9a-zA-Z\-_]*\/status\/([0-9]*)/i,
                     /https?\:\/\/www\.twitter\.com\/[0-9a-zA-Z\-_]*\/status\/([0-9]*)/i,
                     /\/\/www\.twitter\.com\/[0-9a-zA-Z\-_]*\/status\/([0-9]*)/i,
                     /\/\/twitter\.com\/[0-9a-zA-Z\-_]*\/status\/([0-9]*)/i,
                     /https?\:\/\/twitter\.com\/[0-9a-zA-Z\-_]*\/statuses\/([0-9]*)/i,
                     /https?\:\/\/www\.twitter\.com\/[0-9a-zA-Z\-_]*\/statuses\/([0-9]*)/i,
                     /\/\/www\.twitter\.com\/[0-9a-zA-Z\-_]*\/statuses\/([0-9]*)/i,
                     /\/\/twitter\.com\/[0-9a-zA-Z\-_]*\/statuses\/([0-9]*)/i
                     */

                    /.*twitter\.com\/#!\/[0-9a-zA-Z\-_]*\/status(?:es)?\/([0-9]*)/i,
                    /.*twitter\.com\/[0-9a-zA-Z\-_]*\/status(?:es)?\/([0-9]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });
            }

            // Vimeo

            else if (str.indexOf('vimeo') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'vimeo';

                regex = [

                    /*
                     /https?\:\/\/www\.vimeo\.com\/([0-9\-]*)/i,
                     /https?\:\/\/vimeo\.com\/([0-9\-]*)/i,
                     /https?\:\/\/player\.vimeo\.com\/video\/([0-9\-]*)/i
                     */

                    /*
                     /.*vimeo\.com\/([0-9\-]*)/i,
                     /.*vimeo\.com\/video\/([0-9\-]*)/i
                     */

                    /.*vimeo\.com(?:\/video)?\/([0-9\-]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });
            }

            // YouTube

            else if (str.indexOf('youtube') !== -1 || str.indexOf('youtu.be') !== -1) {

                vendorRecognized = true;

                settings.vendor = 'youtube';

                regex = [

                    /*
                     /https?\:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9\-_]*)/i,
                     /https?\:\/\/www\.youtube\.com\/watch\?v\=([a-zA-Z0-9\-\_]*)/i,
                     /\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9\-_]*)/i,
                     /\/\/www\.youtube\.com\/watch\?v\=([a-zA-Z0-9\-\_]*)/i,
                     /.*\:\/\/youtu\.be\/([a-zA-Z0-9\-_]*)/i
                     */

                    /*
                     /.*youtube\.com\/embed\/([a-zA-Z0-9\-_]*)/i,
                     /.*youtube\.com\/watch\?v=([a-zA-Z0-9\-_]*)/i,
                     /.*youtu\.be\/([a-zA-Z0-9\-_]*)/i
                     */

                    /.*youtube\.com\/embed\/([a-zA-Z0-9\-_]*)/i,
                    /.*youtube\.com\/watch\?v=([a-zA-Z0-9\-_]*)/i,
                    /.*youtu\.be\/([a-zA-Z0-9\-_]*)/i
                ];

                regex.forEach(function(regex) {

                    match = str.match(regex);
                    if (match) settings.vendorContentID = match[1];
                });
            }

            // Check for easter eggs.

            else if (str.length > 3 && str[0] === ':' && str[str.length - 1] === ':') {

                return Love.Factories.FragmentFactory.createImageFragmentFromStringEasterEggs(str);
            }

            // Audio / video

            else {

                // TODO RENS: this is temporary support. We need to add a nicer way to embed audio and video.

                var audioExtensions = [

                    'mp3',
                    'wma',
                    'aac',
                    'aif',
                    'au',
                    'flac',
                    'ra',
                    'wav',
                    'ogg',
                    '3gp'
                ];

                var videoExtensions = [

                    'flv',
                    'mpeg',
                    'mkv',
                    'wmv',
                    'mov',
                    '3gp',
                    'mp4',
                    'avi'
                ];

                var combined = audioExtensions.concat(videoExtensions);

                for (var i = 0; i < combined.length; i++) {

                    if (_.stringEndsWith(str, combined[i])) {

                        vendorRecognized = true;

                        settings.vendor = 'iframe';
                        settings.vendorContentID = str;

                        break;
                    }
                }
            }

            if (!vendorRecognized && validator.isURL(str)) {

                // Finally, try using Noembed as a backup for content we don't currently support.

                localPromise = $.Deferred();

                $.ajax({

                        url: 'https://noembed.com/embed',
                        data: {

                            url: str
                        },
                        type: 'GET',
                        useProxyServer: false
                    })
                    .done(function(data, textStatus, jqXHR) {

                        if (data && data.html) {

                            settings.vendor = 'html';
                            settings.vendorContentID = data.html;

                            localPromise.resolve();
                        }
                        else
                            localPromise.reject();
                    })
                    .fail(function() {localPromise.reject();});

                /*

                 // Finally, try using Iframely as a backup for content we don't currently support.

                 localPromise = $.Deferred();

                 $.ajax({

                 url: 'https://iframe.ly/api/oembed',
                 data: {

                 api_key: Love.Constants.IFRAMELY_API_KEY,
                 url: str
                 },
                 type: 'GET',
                 useProxyServer: false
                 })
                 .done(function(data, textStatus, jqXHR) {

                 if (data && data.html) {

                 settings.vendor = 'html';
                 settings.vendorContentID = data.html;

                 localPromise.resolve();
                 }
                 else
                 localPromise.reject();
                 })
                 .fail(function() {localPromise.reject();});
                 */
            }

            /*
             // Custom url

             else if (str.indexOf('.') !== -1 && str.length >= 4) {

             settings.vendor = 'iframe';
             settings.vendorContentID = str;
             }
             */

            var deferredResult = $.Deferred();

            $.when(localPromise).done(function() {

                if (settings.vendor === 'iframe' && settings.vendorContentID.substr(0, 4) !== 'http')
                    settings.vendorContentID = 'http://' + settings.vendorContentID;

                var valid = (settings.vendor && settings.vendorContentID) || returnInvalid;

                deferredResult.resolve(valid ? {settings: settings, type: 'CMSEmbedWidget'} : null);

            }).fail(function() { deferredResult.reject();});

            return deferredResult;
        },

        createEmbedVideoFragmentFromUrl: function(url) {

            var settings = {

                vendor: 'video',
                vendorContentID: url,
                vendorEmbedCode: url,
                vendorSpecificHeight: '',
                vendorSpecificWidth: ''
            };

            return {settings: settings, type: 'CMSEmbedWidget'};
        },

        createFragmentFromBookmark: function(bookmark) {

            var fragmentOrPromise = null, fragmentOrPromise2 = null;
            var imageModel;
            var description, title;

            switch (bookmark.get('type')) {

                case 'audio': {

                    fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedAudioFragmentFromUrl(bookmark.get('file').url);
                    break;
                }
                case 'file': {

                    imageModel = new Love.Models.ImageModel({

                        imageSource: 'url',
                        imageURL: '/static/img/remix/logo-remix.png'
                    });

                    title = bookmark.getTitle();
                    if (!_.isEmpty(title)) title = '<h1><a href="' + bookmark.get('file').url + '">' + title + '</a></h1>';

                    description = bookmark.get('parsedUrlDescription');
                    if (_.isEmpty(description)) description = bookmark.get('description');
                    if (!_.isEmpty(description)) description = '<div>' + description + '</div>';

                    fragmentOrPromise = Love.Factories.FragmentFactory.createTextImageFragment(title + description, imageModel, 'left');
                    break;
                }
                case 'image': {

                    imageModel = new Love.Models.ImageModel({

                        imageSource: 'url',
                        imageURL: bookmark.get('file').url
                    });

                    fragmentOrPromise = Love.Factories.FragmentFactory.createImageFragment(imageModel);
                    break;
                }
                case 'movie': {

                    fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedVideoFragmentFromUrl(bookmark.get('file').url);
                    break;
                }
                case 'text': {

                    fragmentOrPromise = Love.Factories.FragmentFactory.createTextFragment(bookmark.get('content'));
                    break;
                }
                case 'url': {

                    fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedFragmentFromString(bookmark.get('url'));

                    $.when(fragmentOrPromise).always(_.bind(function(fragment) {

                        if (!fragment) {

                            description = bookmark.get('parsedUrlDescription');
                            if (_.isEmpty(description)) description = bookmark.get('description');

                            fragmentOrPromise2 = Love.Factories.FragmentFactory.createLinkFragment(bookmark.get('url'), bookmark.getTitle(), description, bookmark.get('parsedUrlImage'));
                        }

                    }, this));

                    break;
                }
                default: {

                    fragmentOrPromise = Love.Factories.FragmentFactory.createTextFragment('Your bookmark will be displayed here soon. We\'re working on it!');
                    break;
                }
            }

            var deferredResult = $.Deferred();

            $.when(fragmentOrPromise).always(function(fragment) {

                if (!fragment) {

                    $.when(fragmentOrPromise2).always(function(fragment2) {

                        deferredResult.resolve(fragment2);
                    });
                }
                else
                    deferredResult.resolve(fragment);
            });

            return deferredResult;
        },

        createFragmentFromMedia: function(media) {

            switch (media.get('mediaType')) {

                case 'instagram':
                    return Love.Factories.FragmentFactory.createEmbedFragmentFromString(media.getExternalUrl());
                    break;

                case 'mobypicture':
                    return Love.Factories.FragmentFactory.createEmbedFragmentFromString(media.getExternalUrl());
                    break;

                case 'twitter':
                    return Love.Factories.FragmentFactory.createEmbedFragmentFromString(media.getExternalUrl());
                    break;

                default:
                    return null;
            }
        },

        createFragmentFromString: function(str) {

            var deferredResult = $.Deferred();
            var fragmentOrPromise, fragmentOrPromise2, fragmentOrPromise3, fragmentOrPromise4;

            fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedFragmentFromString(str);

            $.when(fragmentOrPromise).always(function(fragment) {

                if (!fragment) {

                    fragmentOrPromise2 = Love.Factories.FragmentFactory.createImageFragmentFromString(str);

                    $.when(fragmentOrPromise2).always(function(fragment) {

                        if (!fragment) {

                            fragmentOrPromise3 = Love.Factories.FragmentFactory.createLinkFragment(str, 'Link to page');

                            $.when(fragmentOrPromise3).always(function(fragment) {

                                if (!fragment) {

                                    fragmentOrPromise4 = Love.Factories.FragmentFactory.createTextFragment(str);

                                    $.when(fragmentOrPromise4).always(function(fragment) {

                                        if (!fragment)
                                            deferredResult.reject();
                                        else
                                            deferredResult.resolve(fragment);
                                    });
                                }
                                else
                                    deferredResult.resolve(fragment);
                            });
                        }
                        else
                            deferredResult.resolve(fragment);
                    });
                }
                else
                    deferredResult.resolve(fragment);
            });

            return deferredResult;
        },

        createImageFragment: function(imageModel) {

            return {

                settings: imageModel.attributes,
                type: 'CMSImageWidget'
            };
        },

        createImageFragmentFromBookmark: function(bookmark) {

            if (bookmark.get('type') === 'image') {

                var imageModel = new Love.Models.ImageModel({

                    imageSource: 'url',
                    imageURL: bookmark.get('file').url
                });

                return Love.Factories.FragmentFactory.createImageFragment(imageModel);
            }

            return null;
        },

        createImageFragmentFromString: function(str) {

            if (_.isEmpty(str)) return null;

            // We first try parsing the image url by extension. This is the fastest way.

            var imageExtensions = [

                'bmp',
                'gif',
                'jpeg',
                'jpg',
                'png'
            ];

            for (var i = 0; i < imageExtensions.length; i++) {

                if (_.stringEndsWith(str, imageExtensions[i])) {

                    var imageModel = new Love.Models.ImageModel({

                        imageSource: 'url',
                        imageURL: str
                    });

                    return Love.Factories.FragmentFactory.createImageFragment(imageModel);
                }
            }

            // If the extension didn't match, we try using a promise with a content type header check.

            return Love.Factories.FragmentFactory.createImageFragmentFromStringByContentType(str);
        },

        createImageFragmentFromStringByContentType: function(str) {

            if (_.isEmpty(str)) return null;

            // Retrieve the content type of the url.

            var localPromise = $.Deferred();

            $.ajax({

                    url: str,
                    type: 'HEAD',
                    useProxyServer: true
                })
                .done(function(data, textStatus, jqXHR) {

                    var type = jqXHR.getResponseHeader('Content-Type');

                    if (type && type.startsWith('image/'))
                        localPromise.resolve();
                    else
                        localPromise.reject();
                })
                .fail(function() {localPromise.reject();});

            var deferredResult = $.Deferred();

            $.when(localPromise).done(function() {

                var imageModel = new Love.Models.ImageModel({

                    imageSource: 'url',
                    imageURL: str
                });

                deferredResult.resolve(Love.Factories.FragmentFactory.createImageFragment(imageModel));

            }).fail(function() { deferredResult.reject();});

            return deferredResult;
        },

        createImageFragmentFromStringEasterEggs: function(str) {

            switch (str) {

                case ':mathys:': {

                    str = 'http://b0.img.mobypicture.com/b47d74d20281ddcd63d64a4c71931a7b_view.jpg';
                    break;
                }
                case ':sam:': {

                    str = 'http://b2.img.mobypicture.com/a2eb85e567d83037884c33dcb38ef8aa_large.jpg';
                    break;
                }
                case ':daan:': {

                    str = 'http://static.tagthelove.com/cms/137_ac67243eb4e88721_1280box.jpg';
                    break;
                }
                case ':bram:': {

                    str = 'http://b2.img.mobypicture.com/82cd1a745df7a22c71a5152bcb9adc07_view.jpg';
                    break;
                }
                case ':koen:': {

                    str = 'http://b0.img.mobypicture.com/b80ed61bad49611c68f201788df4a665_large.jpg';
                    break;
                }
                case ':rens:': {

                    str = 'https://emoji.slack-edge.com/T025LUE2G/rens/37b6e220b0d3604e.png';
                    $('body').addClass('easter-egg-rensor');

                    break;
                }
                case ':berry:': {

                    str = 'http://b2.img.mobypicture.com/5eb9212e0c0f4f5254d8343a4af8facc_view.jpg';
                    break;
                }
                case ':metten:': {

                    str = 'https://pbs.twimg.com/profile_images/578177680283041792/YRVjZfJD.jpeg';
                    break;
                }
                case ':dennis:': {

                    str = 'https://avatars1.githubusercontent.com/u/6653979?v=3&s=400';
                    break;
                }
                case ':jeroen:': {

                    str = 'https://fbcdn-sphotos-a-a.akamaihd.net/hphotos-ak-xpf1/t31.0-8/339752_2197074498696_4594137_o.jpg';
                    break;
                }
                case ':gijsbregt:': {

                    str = 'http://media.tagthelove.com/141/files/gb.png';
                    break;
                }
                case ':malou:': {

                    str = 'https://pbs.twimg.com/media/CJ3Rb1gXAAA1Hm8.jpg';
                    break;
                }
                case ':dylan:': {

                    str = 'http://static.tagthelove.com/cms/190_7672128f6293459b_1280box.jpg';
                    break;
                }
                case ':aad:': {

                    str = 'https://aadvanesschoten.nl/img/zelf_portret.png';
                    break;
                }
                case ':floris:': {

                    str = 'http://thejeurnalist.com/wp-content/uploads/2014/11/10807054_10201616194206171_1849474051_n-150x150.jpg';
                    break;
                }
                case ':jenny:': {

                    str = 'https://scontent-ams3-1.xx.fbcdn.net/v/t1.0-0/p206x206/12642827_10208834015835717_5713603030833945780_n.jpg?oh=daec6323644aa9704f2910f6fc1aa5d8&oe=586C9E5C';
                    break;
                }
                case ':marnix:':
                case ':amelie:':
                case ':mika:':
                case ':liesbeth:': {

                    str = 'http://lesallues.nl/wp-content/uploads/2013/09/kleintjes-lac-de-tueda.jpg';
                    break;
                }
                case ':sophie:':
                case ':eli:': {

                    str = 'http://img.mobypicture.com/58e6628fc75cfbaa6bc984f51dcc5db9_view.jpg';
                    break;
                }
                case ':fabian:': {

                    str = 'http://b2.img.mobypicture.com/3647acff5796f0ab5fd8fdcff689c4bb_view.jpg';
                    break;
                }
                case ':rinus:': {

                    str = 'http://b1.img.mobypicture.com/c18e9250cbdb81685e33d2b40e4ce274_view.jpg';
                    break;
                }
                case ':dancingkoen:': {

                    str = 'http://media.tagthelove.com/141/files/koen.gif';
                    break;
                }
                case ':donald:': {

                    var trumps = [

                        'https://media.giphy.com/media/3o7TKWDGfvUWb9FMZi/source.gif',
                        'https://slack-imgs.com/?c=1&url=http%3A%2F%2Fmedia1.giphy.com%2Fmedia%2FjSB2l4zJ82Rvq%2Fgiphy.gif',
                        'https://slack-imgs.com/?c=1&url=http%3A%2F%2Fmedia2.giphy.com%2Fmedia%2Ft6HA09rAltIpq%2Fgiphy-tumblr.gif',
                        'https://slack-imgs.com/?c=1&url=http%3A%2F%2Fmedia4.giphy.com%2Fmedia%2F3o7TKUMk1IysRprsis%2Fgiphy.gif'
                    ];

                    str = trumps[Math.round(Math.random() * (trumps.length - 1))];

                    break;
                }
                case ':rick:': {

                    str = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
                    return Love.Factories.FragmentFactory.createEmbedFragmentFromString(str);
                }
            }

            return Love.Factories.FragmentFactory.createImageFragmentFromString(str);
        },

        createLinkFragment: function(url, title, description, image) {

            var valid = validator.isURL(url);
            if (!valid) return null;

            var localPromise;

            if (_.isEmpty(title) || _.isEmpty(description) || _.isEmpty(image)) {

                // Try extracting extra information from the Hyperlinks API.

                localPromise = $.Deferred();

                $.ajax({

                        url: 'https://api.mobynow.com/1.0/hyperlinks/info.json',
                        data: {

                            url: url
                        },
                        type: 'GET',
                        useProxyServer: true
                    })
                    .done(function(data, textStatus, jqXHR) {

                        if (data.content) {

                            data = data.content;

                            url = data.url;
                            title = data.title;
                            description = data.description;
                            image = (_.isArray(data.media) && data.media.length > 0) ? data.media[0].url : '';

                            localPromise.resolve();
                        }
                        else
                            localPromise.reject();
                    })
                    .fail(function() {localPromise.reject();});
            }

            var deferredResult = $.Deferred();

            $.when(localPromise).always(function() {

                var imageModel;

                if (!_.isEmpty(image))
                    imageModel = new Love.Models.ImageModel({

                        imageSource: 'url',
                        imageURL: image
                    });
                else
                    imageModel = new Love.Models.ImageModel();

                title = !_.isEmpty(title) ? '<h1><a href="' + url + '">' + title + '</a></h1>' : '';
                description = !_.isEmpty(description) ? '<div>' + description + '</div>' : url;

                deferredResult.resolve(Love.Factories.FragmentFactory.createTextImageFragment(title + description, imageModel, 'left'));
            });

            return deferredResult;
        },

        createTextFragment: function(html) {

            return {

                settings: {contentHTML: html},
                type: 'CMSTextWidget'
            };
        },

        createTextImageFragment: function(html, imageModel, imageAlignment) {

            return {

                settings: _.extend(imageModel.attributes, {

                    contentHTML: html,
                    imageAlignment: imageAlignment

                }),
                type: 'CMSTextImageWidget'
            };
        }
    };

})(Love);