(function(Love) {

    Love.Helpers.Calendar = {

        baseEventViewFactory: function(model, displayDateStart, displayDateEnd) {

            var view;

            if (model.get('isGoogleCal'))
                view = new Love.Views.CalendarGoogleCalendarEventView({

                    model: model,
                    displayDateStart: displayDateStart,
                    displayDateEnd: displayDateEnd
                });

            else if (model.get('type') === 'event')
                view = new Love.Views.CalendarCalendarEventView({

                    model: model,
                    displayDateStart: displayDateStart,
                    displayDateEnd: displayDateEnd
                });

            else if (model.get('contentType') === 'poll')
                view = new Love.Views.CalendarDurationEventView({

                    model: model,
                    displayDateStart: displayDateStart,
                    displayDateEnd: displayDateEnd
                });

            else
                view = new Love.Views.CalendarMomentEventView({

                    model: model,
                    displayDateStart: displayDateStart,
                    displayDateEnd: displayDateEnd
                });

            return view;
        },

        getFlairImageForEvent: function(text) {

            text = text.trim().toLowerCase();

            var flairs = Love.Locales.en.calendar.flairs;

            _.each(Love.Locales.nl.calendar.flairs, function(keywords, value) {

                if (flairs[value])
                    flairs[value] = flairs[value] + ', ' + keywords;
                else
                    flairs[value] = keywords;
            });

            var bestMatch = null;

            _.each(flairs, function(keywords, value) {

                keywords = _.map(keywords.split(','), function(word) { return word.trim().toLowerCase(); });

                var numberOfMatches = 0;
                _.each(keywords, function(keyword) {if (text.indexOf(keyword) > -1) numberOfMatches++;});

                var match = {

                    value: value,
                    confidence: numberOfMatches
                };

                if (!bestMatch || match.confidence > bestMatch.confidence)
                    bestMatch = match;
            });

            bestMatch = (bestMatch && bestMatch.confidence > 0) ? bestMatch : null;

            return bestMatch ? bestMatch.value : '';
        },

        getParametersForEvent: function(model, displayDateStart, displayDateEnd) {

            var info = Love.Helpers.Calendar.getEventInfoForType(model);
            var time;

            // We have whole day events (as marked by the api) and events that span a full day.

            if (model.get('isWholeDay'))
                time = 'Whole day';

            else {

                var start = displayDateStart ? moment.max(model.get('date'), displayDateStart) : model.get('date');
                var end = displayDateEnd ? moment.min(model.get('dateEnd'), displayDateEnd) : model.get('dateEnd');

                time = start.format('HH:mm');

                if (start.isBefore(end) && model.get('dateEnd'))
                    time += ' - ' + end.format('HH:mm');

                if (displayDateStart && displayDateEnd) {

                    if (start <= displayDateStart && end >= displayDateEnd)
                        time = 'Whole day';
                }
            }

            return {

                date: model.attributes.date,
                time: time,
                color: info.color,
                icon: info.icon,
                url: info.url,
                text: info.text,
                additional: info.additional
            };
        },

        getEventInfoForType: function(model) {

            var icon = '';
            var flair = '';
            var color = '';
            var text = '';
            var url = '';
            var additional = {};

            var type = model.get('type');
            var contentType = model.get('contentType');
            var contentSubType = model.get('contentSubType');

            if (type === 'scheduledcontent') {

                icon = model.get('content').destination.icon;
                text = model.get('content').settings.message;
                url = model.get('content').external ? model.get('content').external.url : '';
            }
            else if (contentType === 'twitter') {

                icon = 'icon-twitter';
                color = 'storyline-1';
                text = model.get('content').info.message;
                url = model.get('content').info.externalURL;
            }
            else if (contentType === 'mobypicture') {

                icon = 'ttl-moby';
                color = 'storyline-14';
                url = model.get('content').info.externalURL;
                text = model.get('content').info.title;
                _.extend(additional, {views: model.get('content').info.media.views});
            }
            else if (contentType === 'facebook') {

                icon = 'icon-facebook';
                color = 'storyline-8';
            }
            else if (type === 'event') {

                icon = 'icon-calendar';
                color = 'storyline-13';
                text = model.get('content').title;
                flair = Love.Helpers.Calendar.getFlairImageForEvent(text);
            }
            else if (type === 'googlecalendar') {

                icon = 'icon-calendar';
                color = '';
                text = model.get('content').title;
                flair = Love.Helpers.Calendar.getFlairImageForEvent(text);
            }
            else if (type === 'cmscontent' && model.get('content').contentType === 'blog') {

                var postModel = new Love.Models.BlogPostModel(model.get('content'), {parse: true});
                var revision = postModel.get('revisions')[postModel.getDefaultRevision()];

                icon = 'ttl-ttl-logo';
                color = 'storyline-7';
                text = revision.title;
                _.extend(additional, {views: model.get('content').views});
            }
            else if (contentType === 'poll') {

                icon = 'icon-question';
                color = 'storyline-2';
            }
            else if (contentType === 'instagram') {

                icon = 'icon-instagram';
                color = 'storyline-6';
            }

            if (!_.isEmpty(flair)) icon = 'icon-' + flair;

            return {icon: icon, color: color, text: text, url: url, additional: additional};
        },

        greyoutPastHourblocks: function(hourblockContainer, date) {

            var cal = hourblockContainer;

            cal.find('.hourblock').removeClass('past past-current hourblock-past-filler');
            cal.find('.hourblock .hourblock-past-filler-top').remove();
            cal.find('.hourblock .hourblock-past-filler-bottom').remove();

            var now = moment();
            var i;

            if (date.isSame(now, 'day')) {

                for (i = 0; i <= now.hours(); i++)
                    cal.find('.hourblock:eq(' + i + ')').addClass('past');

                // Create a filling element to greyout the current hour until the current minute.

                var hourHeight = cal.find('.hourblock').outerHeight();

                cal.find('.hourblock:eq(' + now.hours() + ')').addClass('hourblock-past-filler');
                cal.find('.hourblock:eq(' + now.hours() + ')').append('<div class="hourblock-past-filler-top"></div>');
                cal.find('.hourblock:eq(' + now.hours() + ')').append('<div class="hourblock-past-filler-bottom"></div>');
                cal.find('.hourblock-past-filler-top').css('height', Math.floor(hourHeight / 60 * now.minutes()));
                cal.find('.hourblock-past-filler-bottom').css('height', Math.floor(hourHeight / 60 * (60 - now.minutes())));
            }
            else if (date.isBefore(now, 'day'))
                cal.find('.hourblock').addClass('past');
        },

        hideCurrentTime: function(cal) {

            cal.find('.calendar-current-time').hide();
            cal.find('.timeline').css('display', 'none');
        },

        scrollToTime: function(cal, toCurrentTime, smooth) {

            var today = moment();

            var hourblockSize = cal.find('.hourblock').outerHeight();
            var scrollValue;

            if (toCurrentTime)
                scrollValue = hourblockSize * (today.hours());
            else
                scrollValue = hourblockSize * 9;

            if (smooth)
                cal.find('.calendar-view-content-container').animate({scrollTop: scrollValue}, 600);
            else
                cal.find('.calendar-view-content-container').scrollTop(scrollValue);
        },

        setCurrentTimeLine: function(cal, show) {

            var now = moment();
            var element = cal.find('.calendar-current-time-line');

            cal.find('.calendar-day-times [data-hour]').removeClass('invisible');

            if (show) {

                var hourblockSize = cal.find('.hourblock').outerHeight();
                var top = (now.hours() * 60 + now.minutes()) * hourblockSize / 60;

                element.removeClass('hidden');
                element.css('top', top);
                element.find('.calendar-current-time-text').text(now.format('HH:mm'));

                // Hide any day times that might overlap the current time line text. A better measure would be the line height,
                // but < 20 and > 40 minutes will do for now.

                if (now.minutes() < 20)
                    cal.find('.calendar-day-times [data-hour="' + now.hours() + '"]').addClass('invisible');
                else if (now.minutes() > 40)
                    cal.find('.calendar-day-times [data-hour="' + (now.hours() + 1) + '"]').addClass('invisible');
            }
            else
                element.addClass('hidden');
        },

        showCurrentTime: function(cal, timeline) {

            cal.find('.calendar-current-time').show();
            cal.find('.timeline').css('display', 'none'); // Hide all other timelines.

            timeline.css('display', 'block');
            //timeline.css('width', this.$('.calendar-view-content .moby-calendar').width());
        },

        stringifyGoogleCalendars: function(googleCalArray) {

            var string = "";

            _.each(googleCalArray, function(cal) {

                if (string === "")
                    string = cal.id;
                else
                    string = string + "," + cal.id;
            });

            return string;
        }
    };

})(Love);