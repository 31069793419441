(function (Love) {

    Love.Views.CommonPickTimePopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonPickTimePopupView',

        className: 'base-popup popup-clean popup-size-auto',
        id: 'popup-common-pick-time',

        defaultsForPopupType: function () {

            return {

                callbacks: {

                    onChange: null
                },
                data: {

                    value: moment()
                },
                enableConfirmByEnter: true
            };
        },

        render: function () {

            this.$el.html(_.template(Love.Templates.common_popups_pick_time)());

            this.timePickerView = this.addSubView(new Love.Views.CommonTimePickerView2({

                callbacks: {

                    onChange: _.bind(function (time) {

                        this.options.data.value = time;

                        if (this.options.callbacks.onChange)
                            this.options.callbacks.onChange(this.options.data);

                    }, this)
                },
                time: this.options.data.value
            }));
            this.timePickerView.setElement(this.$('.popup-content')).render();

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        isConsideredEqualTo: function(other) {

            if (Love.Views.BasePopupView.prototype.isConsideredEqualTo.call(this, other))
                return true;

            var isSameType = _.isEqual(this.objectClassName, other.objectClassName);
            if (!isSameType) return false;
            
            var data = this.options.data;
            var otherData = other.options.data;

            if (!data.value || !otherData.value) return false;

            if (!data.value.isValid() && !otherData.value.isValid()) return true;
            if (data.value.format('HH:mm') === otherData.value.format('HH:mm')) return true;
        }
    });
})(Love);