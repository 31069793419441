(function(Love) {

    Love.Views.HighlightedContentSetScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.HighlightedContentSetScreenView',

        defaults: function() {

            return {

                contentSetId: null
            };
        },

        events: {

            'input [name="edit-content-set-title"]': '_handleInputChanged',
            'input [name="edit-content-set-identifier"]': '_handleInputChanged',
            'input [name="edit-content-set-length"]': '_handleInputChanged',

            'click .action-buttons-container [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .action-buttons-container [data-action="delete"]:not(.disabled)': '_handleDeleteClick',

            'click .base-sidebar [data-action="save"]:not(.disabled)': '_handleSaveClick',
            'click .base-sidebar [data-action="delete"]:not(.disabled)': '_handleDeleteClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_checkExistingBackup');

            this.editModel = new Love.Models.ContentSetModel();

            // The view state contains information relevant to this view and any sub views.
            // We use a standard Backbone model, since it's not part of the API but (change) events are useful.

            this.viewState = new Backbone.Model({

                changedByUser: false,
                disableSaving: false
            });

            if (this.options.contentSetId)
                this.editModel.set('id', this.options.contentSetId);
        },

        render: function() {

            // Remove any existing event listeners in case of re-rendering.

            this.stopListening(this.viewState, 'change:disableSaving');

            this.stopListening(this.editModel, 'change', this.editModel.saveClientBackup);
            this.stopListening(this.editModel, 'changedByUser');

            // Recreate the subviews, to ensure their event handlers are also removed.

            if (this.editorView) this.editorView.close();

            if (this.sidebarView) {

                this.stopListening(this.sidebarView, 'toggle');

                this.sidebarView.close();
            }

            this.listenTo(this.viewState, 'change:disableSaving', _.bind(function() {this._setSavingEnabled(!this.viewState.get('disableSaving'));}, this));

            this.editorView = this.addSubView(new Love.Views.HighlightedContentSetEditorView({editModel: this.editModel, viewState: this.viewState}));
            this.sidebarView = this.addSubView(new Love.Views.HighlightedContentSetSidebarView({editModel: this.editModel, viewState: this.viewState}));

            if (this.editModel.isNew()) {

                // We don't have to render an empty page first, as the final empty model will be rendered immediately.

                this._renderModel();

                _.defer(this._checkExistingBackup);
            }
            else {

                // We could render an empty page first, but let's assume loading an existing content set is fast enough.

                var self = this;
                this.listenToOnce(this.editModel, 'sync', function() {

                    self._renderModel();

                    _.defer(self._checkExistingBackup);
                });

                this.editModel.fetch({

                    error: function(model, response, options) {

                        Love.router.navigate('error/404', {trigger: true});
                    }
                });
            }

            this.listenTo(Love.appView, 'navigate', _.bind(function(e) {

                if (this._hasUnsavedUserChanges())
                    e.unsavedChanges = true;

            }, this));

            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _checkExistingBackup: function() {

            // Check if a backup for changes exists in the local storage.

            var entry = this.editModel.getClientBackup();

            if (entry) {

                var self = this;
                var popup = new Love.Views.CommonQuestionPopupView({

                    callbacks: {

                        onNo: this.editModel.removeClientBackup,
                        onYes: function() {

                            Love.Helpers.Tracking.track('Highlighted content set screen - restore client backup', {

                                model: self.editModel.id
                            });

                            self.editModel.set(self.editModel.parse(entry.backup));
                            self.editModel.trigger('changedByUser', self.editModel);

                            self._renderModel();
                        }
                    },
                    data: {

                        title: 'Restore edits?',
                        text: 'A backup of local changes was made at ' + entry.updatedAt.format('ddd, DD MMM YYYY, HH:mm') + '. Do you want to restore it?'
                    }
                });

                popup.showPopup();
            }
        },

        _handleDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        Love.Helpers.Tracking.track('Highlighted content set screen - delete', {

                            model: self.editModel.id
                        });

                        self.editModel.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                self.viewState.set('changedByUser', false);
                                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/highlighted', {trigger: true});
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete content set?',
                    text: 'Do you really want to delete this content set? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleInputChanged: function(e) {

            this.editModel.set({

                title: this.$('[name="edit-content-set-title"]').val(),
                identifier: this.$('[name="edit-content-set-identifier"]').val(),
                length: parseInt(this.$('[name="edit-content-set-length"]').val())
            });

            this.editModel.trigger('changedByUser', this.editModel);
        },

        _handleSaveClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            Love.Helpers.Tracking.track('Highlighted content set screen - save', {

                model: this.editModel.id
            });

            Love.Helpers.Loading.showLoading(true);

            var result = this._saveContentSetModel();

            $.when(result).always(function() { Love.Helpers.Loading.showLoading(false);});
        },

        _hasUnsavedUserChanges: function() {

            return (this.editModel && (this.editModel.isNew() || this.editModel.changedAttributesJSON(this.editModel.serverAttributes)) && this.viewState.get('changedByUser'));
        },

        _renderModel: function() {

            var self = this;

            $(window).scrollTo(0, 'normal');

            var context = this.editModel.attributes;
            this.$el.html(_.template(Love.Templates.highlighted_content_set_screen)(context));

            this.editorView.setElement(this.$('.editor-container')).render();
            this.sidebarView.setElement(this.$('.base-sidebar-container')).render();

            this.listenTo(this.editModel, 'change', this.editModel.saveClientBackup);

            this.listenTo(this.editModel, 'changedByUser', function(e) {

                self.viewState.set('changedByUser', true);
            });

            this.listenTo(this.sidebarView, 'toggle', function(e) {

                self.performDotDotDotUpdate();
                self.performLazyLoadImagesResize(true);
            });
        },

        _saveContentSetModel: function() {

            this._setSavingEnabled(false);
            this._showFeedback(false);

            var wasNew = this.editModel.isNew();

            var result = this.editModel.save(this.editModel.attributes, {

                wait: true,
                success: _.bind(function(model, response, options) {

                    if (wasNew) {

                        // If it was a new content set, update the url.

                        Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/highlighted/' + model.id, {

                            trigger: false,
                            replace: true
                        });
                    }

                    // Any changes by the user have now been saved.

                    this.viewState.set('changedByUser', false);

                    // After saving, for both new and old content sets, the content set model attributes are updated.
                    // This raises a change event which in turn saves a copy of the model to the local storage.
                    // Therefore, we have to remove the client backup here.

                    this.editModel.removeClientBackup();

                    this._renderModel();
                    this._setSavingEnabled(true);

                }, this),
                error: _.bind(function(model, response, options) {

                    this._setSavingEnabled(true);
                    this._showFeedback(true);

                }, this)
            });

            if (!result) {

                this._setSavingEnabled(true); // Validation failed.
                this._showFeedback(true, "You didn't provide all necessary values. Please check again!", this.editModel.validate(this.editModel.attributes));
            }

            return result;
        },

        _setSavingEnabled: function(enabled) {

            if (enabled) {

                this.$('.action-buttons-container [data-action="save"]').removeClass('disabled');
                this.$('.base-sidebar [data-action="save"]').removeClass('disabled');
            }
            else {

                this.$('.action-buttons-container [data-action="save"]').addClass('disabled');
                this.$('.base-sidebar [data-action="save"]').addClass('disabled');
            }
        },

        _showFeedback: function(show, message, errors) {

            if (show) {

                if (!message)
                    message = 'Oops! Something went wrong.';

                this.$('.editor-feedback').text(message);

                this.$('[data-model-field]').removeClass('feedback-error');

                _.each(errors, _.bind(function(message, field) {

                    this.$('[data-model-field="' + field + '"]').addClass('feedback-error');

                }, this));
            }

            this.$('.editor-feedback').toggleClass('hidden', !show);
        }
    });
})
(Love);