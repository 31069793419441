(function(Love) {

    Love.Helpers.GooglePlacesAutocomplete = {

        searchCity: function(service, input, callback) {

            this._search(service, input, ['(cities)'], function(results, status) {

                if (!results || status !== 'OK') results = [];

                results = results.map(function(result) {return result.terms[0].value;});
                results = _.uniq(results);

                callback(results);
            });
        },

        searchCountry: function(service, input, callback) {

            this._search(service, input, ['(regions)'], function(results, status) {

                if (!results || status !== 'OK') results = [];

                results = results.filter(function(result) {

                    return result.types.indexOf('country') > -1;

                }).map(function(result) {

                    return result.description;
                });

                results = _.uniq(results);

                callback(results);
            });
        },

        searchEstablishment: function(service, input, callback) {

            this._search(service, input, ['establishment'], function(results, status) {

                if (!results || status !== 'OK') results = [];

                results = results.map(function(result) {

                    return result.terms[0].value;
                });

                results = _.uniq(results);

                callback(results);
            });
        },

        _search: function(service, input, types, callback) {

            service.getPlacePredictions({

                input: input,
                types: types

            }, function(results, status) {

                callback(results, status);
            });
        }
    };

})(Love);