(function(Love) {

    Love.Views.CommonContentEmbedWidgetView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentEmbedWidgetView',

        defaults: function() {

            return {

                settings: null
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this,

                'hasContent',
                'showSettings'
            );
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_widgets_embed));
            this.renderEmbed(this.options.settings, this.$('.embed-container'));

            // If a widget was inserted, we'll try to show the settings dialog for the inserted widget.

            if (this.options.isNew && !this.hasContent())
                _.defer(this.showSettings); // Show after the widget contents have been rendered.

            return Love.Views.BaseView.prototype.render.call(this);
        },

        hasContent: function() {

            return (this.options.settings && !(_.isEmpty(this.options.settings.vendor) || _.isEmpty(this.options.settings.vendorContentID)));
        },

        showSettings: function() {

            var self = this;
            var popup = new Love.Views.CommonContentEmbedPopupView({

                callbacks: {

                    onConfirm: function(data) {

                        Love.Helpers.Loading.showLoading(true);

                        var fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedFragmentFromString(data.code);

                        $.when(fragmentOrPromise).always(function(fragment) {

                            if (fragment) {

                                var isCustomEmbed = (fragment.settings.vendor === 'iframe');

                                if (isCustomEmbed) {

                                    // Override parsed values with custom values.

                                    if (!_.isEmpty(data.height)) fragment.settings.vendorSpecificHeight = data.height;
                                    if (!_.isEmpty(data.width)) fragment.settings.vendorSpecificWidth = data.width;
                                }

                                self.options.settings = fragment.settings;
                                self._raiseChanged();
                            }

                            self.renderEmbed(self.options.settings, self.$('.embed-container'));

                            Love.Helpers.Loading.showLoading(false);
                        });
                    }
                },
                data: {

                    //url: this.getEmbedUrl(this.options.settings),
                    code: !_.isEmpty(this.options.settings.vendorEmbedCode) ? this.options.settings.vendorEmbedCode : this.getEmbedUrl(this.options.settings),
                    height: this.options.settings.vendorSpecificHeight,
                    width: this.options.settings.vendorSpecificWidth
                }
            });

            popup.showPopup();
        },

        getEmbedUrl: function(settings) {

            var source = settings.vendorContentID;

            switch (settings.vendor) {

                case 'audio':
                    return source;

                case 'deezer':
                    return 'https://www.deezer.com/plugins/player?type=tracks&id=' + source;

                case 'html':
                    return !_.isEmpty(settings.vendorEmbedCode) ? settings.vendorEmbedCode : source;

                case 'iframe':
                    return !_.isEmpty(settings.vendorEmbedCode) ? settings.vendorEmbedCode : source;

                case 'instagram':
                    return 'https://instagram.com/p/' + source;

                case 'playbuzz':
                    return 'https://www.playbuzz.com/' + source;

                case 'slideshare':
                    return 'https://www.slideshare.net/slideshow/embed_code/' + source;

                case 'soundcloud':
                    return 'https://api.soundcloud.com/tracks/' + source;

                case 'spotify':
                    return 'https://open.spotify.com/track/' + source;

                case 'twitter':
                    return 'https://twitter.com/anyuser/status/' + source;

                case 'video':
                    return source;

                case 'vimeo':
                    return 'https://vimeo.com/' + source;

                case 'youtube':
                    return 'https://www.youtube.com/embed/' + source;

                default:
                    return '';
            }
        },

        renderEmbed: function(settings, element) {

            switch (settings.vendor) {

                case 'audio':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_audio)(settings));
                    break;

                case 'deezer':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_deezer)(settings));
                    break;

                case 'html':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_html)(settings));
                    break;

                case 'iframe':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_iframe)(settings));
                    break;

                case 'instagram':

                    $.ajax({

                        url: 'https://api.mobynow.com/1.0/embed/instagram',
                        data: {

                            maxwidth: _.isNumber(settings.vendorSpecificWidth) ? settings.vendorSpecificWidth : void(0),
                            omitscript: 'true',
                            url: 'https://instagr.am/p/' + settings.vendorContentID
                        },
                        dataType: 'json',
                        success: function(data) {

                            element.html(_.template(Love.Templates.common_content_widgets_embed_instagram)(settings));
                            element.find('.embed-iframe').html(data.html);

                            instgrm.Embeds.process();
                        }
                    });

                    break;

                case 'playbuzz':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_playbuzz)(settings));
                    break;

                case 'slideshare':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_slideshare)(settings));
                    break;

                case 'soundcloud':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_soundcloud)(settings));
                    break;

                case 'spotify':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_spotify)(settings));
                    break;

                case 'twitter':

                    $.ajax({

                        url: 'https://api.twitter.com/1/statuses/oembed.json',
                        data: {

                            id: settings.vendorContentID,
                            maxwidth: _.isNumber(settings.vendorSpecificWidth) ? settings.vendorSpecificWidth : void(0),
                            omit_script: true
                        },
                        dataType: 'jsonp',
                        success: function(data) {

                            element.html(_.template(Love.Templates.common_content_widgets_embed_twitter)(settings));
                            element.find('.embed-iframe').html(data.html);

                            twttr.widgets.load(element.find('.embed-iframe')[0]);
                        }
                    });

                    break;

                case 'video':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_video)(settings));
                    break;

                case 'vimeo':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_vimeo)(settings));
                    break;

                case 'youtube':
                    element.html(_.template(Love.Templates.common_content_widgets_embed_youtube)(settings));
                    break;

                default:
                    element.html(_.template(Love.Templates.common_content_widgets_embed_empty)());
                    break;
            }
        },

        _raiseChanged: function() {

            this.trigger('changed', {settings: this.options.settings});
        }
    });

})(Love);