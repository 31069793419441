(function (Love) {

    Love.Views.FAQRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.FAQRowView',

        defaults: function () {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true
            });
        },

        renderItem: function () {

            var context = _.extend(_.clone(this.options.item), {

                viewOptions: _.extend(_.clone(this.options), {})
            });

            this.$el.html(_.template(Love.Templates.faq_faq_row)(context));
        }
    });

})(Love);