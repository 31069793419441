(function(Love) {

    Love.Models.MediaModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.MediaModel',

        idAttribute: 'mediaID',

        defaults: function() { return {}; },

        url: function() {return 'https://api.mobynow.com/1.0/media/' + this.id + '.json?type=now';},

        initialize: function(options) {

            if (options) this.set(options);
        },

        parse: function(response, options) {

            var contents = (response.content) ? response.content : response;

            contents = _.extend(contents, {

                datetime: contents.datetime ? moment.utc(contents.datetime) : null
            });

            return contents;
        },

        getExternalUrl: function() {

            return this.get('info').externalURL;
        },

        getTitle: function() {

            var mediaType = this.get('mediaType');

            if (mediaType === 'mobypicture') { return this.get('info').title; }
            else if (mediaType === 'instagram') { return this.get('info').caption; }
            else if (mediaType === 'twitter') { return this.get('info').message; }
            else { return ''; }
        }
    });

    Love.Collections.MediaCollection = Love.Collections.BasePageableCollectionNow.extend({

        objectClassName: 'Love.Collections.MediaCollection',

        model: Love.Models.MediaModel,

        url: function() {

            var platformId = Love.session.get('currentSite').get('nowPlatformId');
            return 'https://api.mobynow.com/1.0/streams/media.json?platformID=' + platformId;
        },

        getPagingId: function(response) { return response.streamMediaID; },

        parseRecords: function(response, options) {

            return response.content;
        }
    });

})(Love);