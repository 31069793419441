(function(Love) {

    Love.Views.AccountSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.AccountSidebarView',

        render: function() {

            this.$el.html(_.template(Love.Templates.account_sidebar)());
            return Love.Views.BaseSidebarView.prototype.render.call(this);
        }
    });

})(Love);