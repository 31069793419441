(function (Love) {

    Love.Models.NewsItemModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.NewsItemModel',

        defaults: function () {

            return {

                id: '',
                revisions: {'': {title: '', summary: ''}},
                thumbnail: ''
            };
        },

        url: 'api/1.0/general/news.json',

        initialize: function (options) {

            if (options) this.set(options);
        },

        parse: function (response, options) {

            var contents = (response.response) ? response.response : response;

            contents.revisions = {'': contents.revision}; // TODO: weghalen als bram call heeft aangepast zoals bij posts.

            contents = _.extend(contents, {

                revisions: contents.hasRevision ? contents.revisions : this.defaults().revisions
            });

            contents.thumbnail = this._getFirstAvailableImage(contents.revisions['']);

            return contents;
        },

        _getFirstAvailableImage: function (revision) {

            var imageURL = '';

            var cover = revision.cover;

            if (cover && cover.files) {

                if (cover.files.length > 0) // at least 1 image
                    imageURL = cover.files[0].url;

                else if (cover.files.length > 1) // at least 2 images
                    imageURL = cover.files[cover.files.length - 2].url;
            }

            var fragmentArray = revision.fragments;

            if (!imageURL) {

                for (var i = 0; i < fragmentArray.length; i++) {

                    if (fragmentArray[i].type === "CMSImageWidget") {

                        if (fragmentArray[i].settings.imageUpload) {

                            imageURL = fragmentArray[i].settings.imageUpload.files[4].url;
                            break;
                        }
                        else if (fragmentArray[i].settings.imageURL) {

                            imageURL = fragmentArray[i].settings.imageURL;
                            break;
                        }
                    }
                }
            }

            return imageURL;
        }
    });

    Love.Collections.NewsItemCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.NewsItemCollection',

        model: Love.Models.NewsItemModel,
        
        url: 'api/1.0/general/news.json',

        parse: function (response, options) {

            return response.response;
        }
    });

})(Love);