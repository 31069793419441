(function(Love) {

    Love.Views.CommonContentEmbedPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonContentEmbedPopupView',

        className: 'base-popup popup-centered popup-tabbed',
        id: 'popup-common-content-embed-settings',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    code: '',
                    height: '',
                    width: ''
                },
                enableLightbox: true
            };
        },

        events: {

            'input textarea[name="code"]': '_handleChange',
            'click [data-feedback="invalid"]': '_clearInput',
            'click [data-action="embed-as-iframe"]': '_handleEmbedAsiFrame',
            'click .embed-example': '_handleExample',
            'click [data-action="examples"]': '_handleShowExamples',
            'click [data-action="close"]': '_handleClose',
            'click [data-action="confirm"]': '_handleConfirm'
        },

        initialize: function(options) {

            Love.Views.BasePopupView.prototype.initialize.call(this, options);

            _.bindAll(this, '_getInputValues');
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.common_content_popups_embed)(this.options.data));

            this._handleChange(); // Render the preview initially, if necessary (i.e., when editing an existing embed).

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _clearInput: function(e) {

            e.stopPropagation();

            this.$('textarea[name="code"]').val('');
            this._handleChange();
        },

        _getInputValues: function() {

            var values = {

                code: (this.$('#popup-embed-code').val() || '').trim(),
                height: (this.$('#popup-embed-height').val() || '').trim(),
                width: (this.$('#popup-embed-width').val() || '').trim()
            };

            var px = 'px';

            if (_.stringEndsWith(values.height, px))
                values.height = values.height.substr(0, values.height.length - 2);

            if (_.stringEndsWith(values.width, px))
                values.width = values.width.substr(0, values.width.length - 2);

            return values;
        },

        _handleChange: function() {

            this.$('.embed-as-iframe').addClass('hidden');

            var data = this._getInputValues();
            var fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedFragmentFromString(data.code);

            $.when(fragmentOrPromise).always(_.bind(function(fragment) {

                if (fragment) {

                    var isCustomEmbed = (fragment.settings.vendor === 'iframe');

                    if (isCustomEmbed) {

                        // Override parsed values with custom values.

                        if (!_.isEmpty(data.height)) fragment.settings.vendorSpecificHeight = data.height;
                        if (!_.isEmpty(data.width)) fragment.settings.vendorSpecificWidth = data.width;

                        // Display the parsed or overriden height and width.

                        this.$('#popup-embed-height').val(fragment.settings.vendorSpecificHeight);
                        this.$('#popup-embed-width').val(fragment.settings.vendorSpecificWidth);
                    }

                    this.$('textarea[name="code"]').siblings('[data-feedback="invalid"]').addClass('hidden');
                    this.$('textarea[name="code"]').siblings('[data-feedback="valid"]').removeClass('hidden');

                    var instance = this.addSubView(new Love.Views.CommonContentEmbedWidgetView({fragment: {}, editModel: null}));
                    instance.renderEmbed(fragment.settings, this.$('.embed-preview'));

                    if (isCustomEmbed) this.$('.embed-custom-data').removeClass('hidden');
                    this.$('.embed-preview').removeClass('hidden');
                }
                else {

                    this.$('textarea[name="code"]').siblings('[data-feedback="valid"]').addClass('hidden');

                    if (!_.isEmpty(data.code)) {

                        this.$('textarea[name="code"]').siblings('[data-feedback="invalid"]').removeClass('hidden');

                        if (validator.isURL(data.code))
                            this.$('.embed-as-iframe').removeClass('hidden');
                    }
                    else
                        this.$('textarea[name="code"]').siblings('[data-feedback="invalid"]').addClass('hidden');

                    this.$('.embed-custom-data').addClass('hidden');
                    this.$('.embed-preview').addClass('hidden');
                }

            }, this));
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm(this._getInputValues());

            this.close();
        },

        _handleEmbedAsiFrame: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var data = this._getInputValues();

            this.$('textarea[name="code"]').val('<iframe src="' + data.code + '"></iframe>');
            this._handleChange();
        },

        _handleExample: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;

            var element = $(e.target).closest('.embed-example');
            var urls = [element.attr('data-url')];

            this.$('.embed-example').removeClass('active');
            element.addClass('active');

            this.$('.example-urls-container').empty();

            _.each(urls, function(url) {

                self.$('.example-urls-container').append('<p class="example-url" data-url="' + url + '">' + url + ' <i class="icon m-l-s icon-copy"></i></p>');
            });

            this.$('.example-url .icon').click(function(e) {

                self.$('textarea[name="code"]').val($(e.target).closest('[data-url]').attr('data-url'));
                self._handleChange(); // Raise the change event, as the input has been changed programmatically.
            });

            this.$('.example-urls-container').removeClass('hidden');
        },

        _handleShowExamples: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.$('.embed-examples').removeClass('hidden');
            this.$('[data-action="examples"]').hide();
        }
    });

})(Love);