(function (Love) {

    Love.Views.EventRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.EventRowView',

        defaults: function () {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true,
                selectable: true,
                selected: false
            });
        },

        renderItem: function () {

            var calendar = _.findWhere(Love.session.get('currentSite').get('calendars'), {id: this.options.item.get('calendarId')});

            var coverModel = new Love.Models.ImageModel(this.options.item.get('cover'));
            var coverUrl = coverModel.getImageView();

            var location = this.options.item.get('location') || {};
            var locationElements = [

                location.venue,
                location.address,
                location.city,
                location.country
            ];

            locationElements = _.without(locationElements, '', null, void(0));

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    calendarTitle: calendar ? calendar.title : '',
                    externalClicks: 0,
                    externalUrl: Love.session.get('currentSite').get('url') + '/event/' + this.options.item.id, // TODO RENS: juiste external url bepalen.
                    hasCoverImage: !_.isEmpty(coverUrl),
                    isLoading: _.isEmpty(this.options.item.id),
                    locationText: locationElements.join(', '),
                    views: 0
                })
            });

            this.$el.html(_.template(Love.Templates.events_event_row)(context));

            if (!_.isEmpty(coverUrl)) {

                Love.Helpers.Images.loadAnImage({

                    element: this.$('.image'),
                    focusPoint: coverModel.getFocusPoint(),
                    onLoad: _.bind(function() {

                        this.$('.image .placeholder').addClass('hidden');

                    }, this),
                    url: coverUrl
                });
            }
            else
                this.$('.image .placeholder').removeClass('hidden');
        }
    });

})(Love);