(function (Love) {

    Love.Views.CommonPickDatePopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonPickDatePopupView',

        className: 'base-popup popup-clean popup-size-auto',
        id: 'popup-common-pick-date',

        defaultsForPopupType: function () {

            return {

                data: {

                    canSelectInPast: true,
                    value: moment()
                },
                enableConfirmByEnter: true
            };
        },

        render: function () {

            this.$el.html(_.template(Love.Templates.common_popups_pick_date)());

            this.datePickerView = this.addSubView(new Love.Views.CommonDatePickerView({

                callbacks: {

                    onChange: _.bind(function (date) {

                        this.options.data.value = date;

                        if (this.options.callbacks.onChange)
                            this.options.callbacks.onChange(this.options.data);

                        this.close();

                    }, this)
                },
                canSelectInPast: this.options.data.canSelectInPast,
                date: this.options.data.value
            }));
            this.datePickerView.setElement(this.$('.popup-content')).render();

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        isConsideredEqualTo: function (other) {

            if (Love.Views.BasePopupView.prototype.isConsideredEqualTo.call(this, other))
                return true;

            var isSameType = _.isEqual(this.objectClassName, other.objectClassName);
            if (!isSameType) return false;

            var data = this.options.data;
            var otherData = other.options.data;

            if (!data.value || !otherData.value) return false;

            if (!data.value.isValid() && !otherData.value.isValid()) return true;
            if (data.value.isSame(otherData.value, 'day')) return true;
        }
    });
})(Love);