(function(Love) {

    Love.Helpers.General = {

        blobToDataURL: function(blob, callback) {

            if (!callback) return;

            var reader = new FileReader();
            reader.onload = function(e) {callback(e.target.result);};

            reader.readAsDataURL(blob);
        },

        blobToFile: function(blob, name, modified) {

            if (!modified) modified = new Date();
            if (_.isNull(name) || _.isUndefined((name))) name = '';

            // NOTE: ideally, we'd clone the blob for a nicer function signature.
            // However, _.clone() doesn't preserve enough data, so we'd need to use some deep cloning method that works reliably.

            blob.lastModifiedDate = modified;
            blob.name = name;

            return blob;
        },

        dataURLtoBlob: function(dataUrl, callback) {

            if (!callback) return;

            var blob = window.dataURLtoBlob(dataUrl);
            callback(blob);

            /*
             // CODE BELOW DOESN'T WORK IN SAFARI: cross origin requests are only supported for HTTP.

             var req = new XMLHttpRequest();
             dataUrl = URL.createObjectURL(dataUrl);

             req.open('GET', dataUrl);
             req.responseType = 'arraybuffer'; // Can't use blob directly because of https://crbug.com/412752

             req.onload = function(e) {

             var mime = this.getResponseHeader('content-type');

             // Remove any other parts of the content type header, such as charset.

             if (mime.indexOf(';') > -1)
             mime = mime.substring(0, mime.indexOf(';'));

             var arrayBufferView = new Uint8Array(this.response);
             var blob = new Blob([arrayBufferView], {type: mime});

             callback(blob);
             };

             req.send();
             */
        },

        deepClone: function(obj) {

            return $.extend(true, {}, obj);
        },

        deepCloneJSON: function(obj) {

            return JSON.parse(JSON.stringify(obj));
        },

        detectBrowser: function() {

            // Opera 8.0+
            var isOpera = (Boolean(window.opr) && Boolean(opr.addons)) || Boolean(window.opera) || navigator.userAgent.indexOf(' OPR/') >= 0;
            // Firefox 1.0+
            var isFirefox = typeof InstallTrigger !== 'undefined';
            // At least Safari 3+: "[object HTMLElementConstructor]"
            var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
            // Internet Explorer 6-11
            var isIE = /*@cc_on!@*/false || Boolean(document.documentMode);
            // Edge 20+
            var isEdge = !isIE && Boolean(window.StyleMedia);
            // Chrome 1+
            var isChrome = Boolean(window.chrome) && Boolean(window.chrome.webstore);
            // Blink engine detection
            var isBlink = (isChrome || isOpera) && Boolean(window.CSS);

            return {

                isOpera: isOpera,
                isFirefox: isFirefox,
                isSafari: isSafari,
                isEdge: isEdge,
                isChrome: isChrome,
                isBlink: isBlink
            };
        },

        /**
         * Returns the overlap between to straight lines / ranges.
         *
         * >>> overlap(0, 10, 80, 90)
         * 0
         * >>> overlap(0, 50, 40, 90)
         * 10
         * >>> overlap(0, 50, 40, 45)
         * 5
         * >>> overlap(0, 100, 0, 20)
         * 20
         *
         * @param start1
         * @param end1
         * @param start2
         * @param end2
         */
        getLineOverlap: function(start1, end1, start2, end2) {

            return Math.max(0, Math.min(end1, end2) - Math.max(start1, start2));
        },

        getNextNode: function(node) {

            if (node.hasChildNodes())
                return node.firstChild;

            else {

                while (node && !node.nextSibling)
                    node = node.parentNode;

                if (!node)
                    return null;

                return node.nextSibling;
            }
        },

        getQueryParameters: function(query) {

            if (_.isEmpty(query))
                query = location.search;

            if (_.isEmpty(query))
                return {};

            if (query.substr(0, 1) === '?')
                query = query.substring(1);

            query = query.replace(/\+/g, ' ');

            // Semicolons are nonstandard but we accept them.

            var x = query.replace(/;/g, '&').split('&'), i, name, t;

            for (query = {}, i = 0; i < x.length; i++) {

                t = x[i].split('=', 2);
                name = decodeURIComponent(t[0]);

                if (!query[name])
                    query[name] = [];

                if (t.length > 1)
                    query[name][query[name].length] = decodeURIComponent(t[1]);

                // Next two lines are nonstandard.

                else
                    query[name][query[name].length] = true;
            }

            return query;
        },

        getRangeSelectedNodes: function(range) {

            var node = range.startContainer;
            var endNode = range.endContainer;

            // Special case for a range that is contained within a single node

            if (node == endNode)
                return [node];

            // Iterate nodes until we hit the end container

            var rangeNodes = [];

            while (node && node != endNode)
                rangeNodes.push(node = Love.Helpers.General.getNextNode(node));

            // Add partially selected nodes at the start of the range

            node = range.startContainer;

            while (node && node != range.commonAncestorContainer) {

                rangeNodes.unshift(node);
                node = node.parentNode;
            }

            return rangeNodes;
        },

        /**
         * Create html element string from string with values
         *
         * @returns {string}
         */
        htmlReplace: function() {

            var i, i1, i2, str, strIf, strEndIf, arg, argFound, endIfFound;

            // Replace all given arguments in html code
            var html = arguments[0];
            for (i = 1; i < arguments.length; i++) {
                arg = arguments[i];
                argFound = true;

                // Search for {if }-{endif} constructions
                endIfFound = true;
                while (endIfFound) {
                    strIf = '{if ' + i + '}';
                    i1 = html.indexOf(strIf, 0);
                    strEndIf = '{endif ' + i + '}';
                    i2 = html.indexOf(strEndIf, 0);

                    if (i1 >= 0) {
                        // If-endif is used
                        if (arg == '') {
                            // Argument is empty, so delete entire if-endif part
                            html = html.substr(0, i1) + html.substr(i2 + strEndIf.length);
                        }
                        else {
                            // Delete if-endif tags
                            html = html.substr(0, i1) + html.substring(i1 + strIf.length, i2) + html.substr(i2 + strEndIf.length);
                        }
                    }
                    else {
                        endIfFound = false;
                    }
                }

                // Replace argument
                str = '{' + i + '}';
                while (argFound) {
                    argFound = false;
                    i1 = html.indexOf(str);
                    if (i1 >= 0) {
                        html = html.substr(0, i1) + arg + html.substr(i1 + str.length);
                        argFound = true;
                    }
                }
            }

            return html;
        },

        htmlSanitize: function(html) {

            if (_.isEmpty(html)) return '';

            // https://github.com/punkave/sanitize-html

            return sanitizeHtml(html, {

                //allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2'])
                allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'a', 'ul', 'ol', 'li', 'b', 'i', 'strong', 'em', 'code', 'br', 'div']
            });
        },

        isOverlappingRange: function(start1, end1, start2, end2) {

            if ((start1 <= start2) && (start2 < end1) && (end1 < end2))
                return true;

            else if ((start2 < start1) && (start1 < end2) && (end2 <= end1))
                return true;

            else if ((start2 < start1) && (start1 <= end1) && (end1 < end2))
                return true;

            else if ((start1 <= start2) && (start2 <= end2) && (end2 <= end1))
                return true;

            return false;
        },

        pasteHTMLAtCaret: function(html) {

            // NOTE: functie overgenomen van Bram

            var sel, range;

            if (window.getSelection) {

                // IE9 and non-IE
                sel = window.getSelection();

                if (sel.getRangeAt && sel.rangeCount) {

                    range = sel.getRangeAt(0);
                    range.deleteContents();

                    // Range.createContextualFragment() would be useful here but is
                    // non-standard and not supported in all browsers (IE9, for one)

                    var el = document.createElement("div");
                    el.innerHTML = html;

                    var frag = document.createDocumentFragment(), node, lastNode;

                    while ((node = el.firstChild))
                        lastNode = frag.appendChild(node);

                    range.insertNode(frag);

                    // Preserve the selection

                    if (lastNode) {

                        range = range.cloneRange();
                        range.setStartAfter(lastNode);
                        range.collapse(true);
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                }
            }

            else if (document.selection && document.selection.type != "Control") {

                // IE < 9

                document.selection.createRange().pasteHTML(html);
            }
        },

        stringBeginsWith: function(str, start) {

            return (str.substr(0, start.toLowerCase().length) === start.toLowerCase());
        }
    };

})(Love);