(function(Love) {

    Love.Views.SettingsSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.SettingsSidebarView',

        render: function() {

            this.$el.html(_.template(Love.Templates.settings_sidebar)());
            return Love.Views.BaseSidebarView.prototype.render.call(this);
        }
    });

})(Love);