(function(Love) {

    Love.Views.CalendarEventPostPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarEventPostPopupView',

        className: 'base-popup popup-calendar popup-calendar-event popup-clean popup-size-auto',
        id: 'popup-calendar-event-post',

        defaultsForPopupType: function() {

            return {

                data: {

                    calendarEventModel: {}
                },
                enableClickInterception: false
            };
        },

        events: {

            'click [data-action="edit-category"]': '_handleEditCategoryClick',
            'click [data-action="edit-social"]': '_handleEditSocialClick',
            'click [data-action="edit-storylines"]': '_handleEditStorylinesClick',

            'click [data-action="delete"]': '_handleDeleteClick',
            'click [data-action="edit"]': '_handleEditClick',
            'click [data-action="view"]': '_handleViewClick'
        },

        render: function() {

            var model = new Love.Models.BlogPostModel(this.options.data.calendarEventModel.get('content'), {parse: true});
            var revision = model.get('hasRevision') ? model.get('revisions')[model.getDefaultRevision()] : null;
            var coverModel = new Love.Models.ImageModel(revision.cover);
            var coverUrl = coverModel.getImageView();

            this.$el.append(_.template(Love.Templates.calendar_popups_event_post)(_.extend({

                activeRevision: model.getDefaultRevision()

            }, model.attributes)));

            if (!_.isEmpty(coverUrl)) {

                Love.Helpers.Images.loadAnImage({

                    element: this.$('.popup-details-media .image'),
                    focusPoint: coverModel.getFocusPoint(),
                    onLoad: _.bind(function() {

                        this.$('.image .placeholder').addClass('hidden');

                    }, this),
                    url: coverUrl
                });
            }

            var category = 'Add this to a <a data-action="edit-category">category</a>.';

            var blog = _.findWhere(Love.session.get('currentSite').get('blogs'), {id: this.options.data.calendarEventModel.get('content').groupId});
            if (blog) category = '<a data-action="edit-category">' + blog.title + '</a>';

            this.$('.popup-details-category span').html(category);

            var storylines = 'Add this to a <a data-action="edit-storylines">storyline</a>.';

            if (this.options.data.calendarEventModel.get('content').storylines.length > 0)
                storylines = 'Part of <a data-action="edit-storylines">' + this.options.data.calendarEventModel.get('content').storylines.length + '</a> storyline(s).';

            this.$('.popup-details-storylines span').html(storylines);

            var social = 'Add a <a data-action="edit-social">social media</a> update.';

            this.$('.popup-details-social span').html(social);

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            var model = new Love.Models.BlogPostModel(this.options.data.calendarEventModel.get('content'));

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        model.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                self.options.data.calendarEventModel.destroy();
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete post?',
                    text: 'Do you really want to delete this post? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleEditClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/' + this.options.data.calendarEventModel.get('content').id, {trigger: true});
        },

        _handleEditCategoryClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/' + this.options.data.calendarEventModel.get('content').id + '/edit/category', {trigger: true});
        },

        _handleEditSocialClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/' + this.options.data.calendarEventModel.get('content').id + '/edit/social', {trigger: true});
        },

        _handleEditStorylinesClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/' + this.options.data.calendarEventModel.get('content').id + '/edit/storylines', {trigger: true});
        },

        _handleViewClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var siteUrl = Love.session.get('currentSite').get('url');
            var model = new Love.Models.BlogPostModel(this.options.data.calendarEventModel.get('content'));
            var revision = model.get('revisions')[model.getDefaultRevision()];

            if (!_.isEmpty(revision.slug)) {

                this.close();

                var win = window.open(siteUrl + '/news/' + revision.slug, '_blank');
                win.focus();
            }
        }
    });

})(Love);