(function(Love) {

    Love.Views.CommonQuestionPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonQuestionPopupView',

        className: 'base-popup popup-centered popup-size-auto',
        id: 'popup-common-question',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onCancel: null,
                    onNo: null,
                    onYes: null
                },
                data: {

                    canCancel: true,
                    noText: 'No',
                    text: '',
                    title: 'Question',
                    yesText: 'Yes'
                },
                enableConfirmByEnter: true,
                enableLightbox: true
            };
        },

        events: {

            'click [data-action="cancel"]': '_handleCancel',
            'click [data-action="no"]': '_handleNo',
            'click [data-action="yes"]': '_handleYes'
        },

        render: function() {

            var context = this.options.data;
            this.$el.append(_.template(Love.Templates.common_popups_question)(context));

            this.listenTo(this, 'confirmRequested', this._handleYes);
            this.listenTo(this, 'closeRequested', this._handleCancel);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleCancel: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            if (this.options.callbacks.onCancel)
                this.options.callbacks.onCancel();
        },

        _handleNo: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            if (this.options.callbacks.onNo)
                this.options.callbacks.onNo();
        },

        _handleYes: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            if (this.options.callbacks.onYes)
                this.options.callbacks.onYes();
        }
    });

})(Love);