(function(Love) {

    Love.Views.PostsSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.PostsSidebarView',

        defaults: function() {

            return {

                selectByDefault: false
            };
        },

        filterModelDefaults: function() {

            return {

                authors: [],
                categories: [],
                dateFrom: null,
                dateTo: moment(),
                search: '',
                sorting: {field: '', ascending: false},
                states: [],
                storylines: []
            };
        },

        events: function() {

            return _.extend({}, Love.Views.BaseSidebarView.prototype.events, {

                'change [data-type="dates"] .sidebar-datetime input': '_handleDates',
                'click [data-type="dates"] [data-action="set-date"]': '_handleDatesSetDate',
                'change input[name="search"]': '_handleSearchChange',
                'click [data-type="authors"] [data-value]': '_handleSelectAuthor',
                'click [data-type="categories"] [data-value]': '_handleSelectCategory',
                'click [data-type="states"] [data-value]': '_handleSelectState',
                'click [data-type="storylines"] [data-value]': '_handleSelectStoryline'
            });
        },

        initialize: function(options) {

            Love.Views.BaseSidebarView.prototype.initialize.call(this, options);

            this._filterModel = this.filterModelDefaults();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.posts_sidebar)());

            this._renderAuthors();
            this._renderCategories();
            this._renderDates();
            this._renderSorting();
            this._renderStates();
            this._renderStorylines();

            this._updateAuthorsText();
            this._updateCategoriesText();
            this._updateDatesText();
            this._updateSortingText();
            this._updateStatesText();
            this._updateStorylinesText();

            return Love.Views.BaseSidebarView.prototype.render.call(this);
        },

        getFilterModel: function() {

            return (this._filterModel) ? this._filterModel : this.filterModelDefaults();
        },

        _renderAuthors: function() {

            this.$('[data-type="authors"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(Love.session.get('currentSite').get('authors'), function(value, index, list) {

                    var displayEmail = (_.indexOf(_.pluck(list.slice(0, index), 'name'), value.name) > -1);

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.name + ((displayEmail) ? ' (' + value.id + ')' : ''),
                        imageSource: (value.imageUrl) ? value.imageUrl : "http://placekitten.com/g/50/50"
                    };

                }, this),
                displayNew: false,
                emptyText: 'No authors :('
            }));
        },

        _renderCategories: function() {

            this.$('[data-type="categories"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(Love.session.get('currentSite').get('blogs'), function(value) {

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: false,
                emptyText: 'No categories :('
            }));
        },

        _renderDates: function() {

            var from = this._filterModel.dateFrom;
            var to = this._filterModel.dateTo;

            this.$('[data-type="dates"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_datetime)({

                date1: from ? from.format('YYYY-MM-DD') : '',
                date1Label: 'From:',
                date2: to ? to.format('YYYY-MM-DD') : '',
                date2Label: 'To:',
                showDate1: true,
                showDate2: true
            }));
        },

        _renderSorting: function() {

            // TODO RENS.

            var sortFields = [

                {id: 'createdOn', title: 'Creation date'},
                {id: 'updatedOnUtc', title: 'Last update'}
            ];

            this.$('[data-type="sorting"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'radio',
                items: _.map(sortFields, function(value) {

                    return {

                        selected: (value.id === 'updatedOnUtc'),
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: false
            }));
        },

        _renderStates: function() {

            var states = [

                {id: 'published', title: 'Published posts'},
                {id: 'scheduled', title: 'Scheduled posts'},
                {id: 'draft', title: 'Drafts'}
            ];

            this.$('[data-type="states"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(states, function(value) {

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: false
            }));
        },

        _renderStorylines: function() {

            this.$('[data-type="storylines"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(Love.session.get('currentSite').get('storylines'), function(value) {

                    return {

                        selected: this.options.selectByDefault,
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: false,
                emptyText: 'No storylines :('
            }));
        },

        _handleDates: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var date1 = this.$('[data-type="dates"] input[name="date1"]').val();
            var date2 = this.$('[data-type="dates"] input[name="date2"]').val();

            date1 = date1 ? moment(date1) : null;
            date2 = date2 ? moment(date2) : null;

            this._filterModel.dateFrom = date1;
            this._filterModel.dateTo = date2;
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateDatesText();
        },

        _handleDatesSetDate: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var input = $(e.target).closest('.input-group').find('input[type="date"]');

            var self = this;
            var popup = new Love.Views.CommonPickDatePopupView({

                callbacks: {

                    onChange: function(data) {

                        input.val(data.value.format('YYYY-MM-DD'));
                        self._handleDates();
                    }
                },
                data: {value: moment(input.val(), 'YYYY-MM-DD')},
                positioning: {

                    attachToElement: $(e.target).closest('.button'),
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _handleSearchChange: function(e) {

            this._filterModel.search = $(this.$('[data-type="search"] input[name="search"]')).val();
            this.trigger('filtersChanged', {filters: this._filterModel});
        },

        _handleSelectAuthor: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="authors"] [data-value]').closest('ul'));

            this._filterModel.authors = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateAuthorsText();
        },

        _handleSelectCategory: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="categories"] [data-value]').closest('ul'));

            this._filterModel.categories = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateCategoriesText();
        },

        _handleSelectSorting: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            // TODO RENS

            this._updateSortingText();
        },

        _handleSelectState: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="states"] [data-value]').closest('ul'));

            this._filterModel.states = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateStatesText();
        },

        _handleSelectStoryline: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var items = this.getDropdownListItems(this.$('[data-type="storylines"] [data-value]').closest('ul'));

            this._filterModel.storylines = _.where(items, {selected: true});
            this.trigger('filtersChanged', {filters: this._filterModel});

            this._updateStorylinesText();
        },

        _updateAuthorsText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="authors"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('posts:sidebar.authors_all');

            else
                display = Love.t('posts:sidebar.authors', {

                    value: (this._filterModel.authors[0]) ? this._filterModel.authors[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="authors"] .sidebar-row-content span').html(display);
        },

        _updateCategoriesText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="categories"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('posts:sidebar.categories_all');

            else
                display = Love.t('posts:sidebar.categories', {

                    value: (this._filterModel.categories[0]) ? this._filterModel.categories[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="categories"] .sidebar-row-content span').html(display);
        },

        _updateDatesText: function() {

            var from = this._filterModel.dateFrom;
            var to = this._filterModel.dateTo;
            var display;

            if (!from && !to)
                display = Love.t('posts:sidebar.dates_all');

            else if (from && !to)
                display = Love.t('posts:sidebar.dates_from', {from: from.format('YYYY-MM-DD')});

            else if (!from && to)
                display = Love.t('posts:sidebar.dates_to', {to: to.format('YYYY-MM-DD')});

            else
                display = Love.t('posts:sidebar.dates', {from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD')});

            this.$('[data-type="dates"] .sidebar-row-content span').html(display);
        },

        _updateSortingText: function() {

            this.$('[data-type="sorting"] .sidebar-row-head .icon').removeClass('icon-sort-alpha-asc icon-sort-alpha-desc');

            if (this._filterModel.sorting.ascending)
                this.$('[data-type="sorting"] .sidebar-row-head .icon').addClass('icon-sort-alpha-asc');
            else
                this.$('[data-type="sorting"] .sidebar-row-head .icon').addClass('icon-sort-alpha-desc');

            this.$('[data-type="sorting"] .sidebar-row-content span').html(Love.t('posts:sidebar.sorting', {value: 'last update'}));
        },

        _updateStatesText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="states"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0 || selected.length === items.length)
                display = Love.t('posts:sidebar.states_all');

            else {

                var joined = _.pluck(selected, 'value').join('</em>, <em>');
                var index = joined.lastIndexOf(',');

                if (index > -1)
                    joined = joined.substring(0, index) + ' and' + joined.substring(index + 1);

                display = 'Showing <em>' + joined.toLowerCase() + '</em>.';
            }

            this.$('[data-type="states"] .sidebar-row-content span').html(display);
        },

        _updateStorylinesText: function() {

            var items = this.getDropdownListItems(this.$('[data-type="storylines"] [data-value]').closest('ul'));
            var selected = _.where(items, {selected: true});
            var display;

            if (selected.length === 0)
                display = Love.t('posts:sidebar.storylines_all');

            else
                display = Love.t('posts:sidebar.storylines', {

                    value: (this._filterModel.storylines[0]) ? this._filterModel.storylines[0].value : '',
                    count: selected.length
                });

            this.$('[data-type="storylines"] .sidebar-row-content span').html(display);
        }
    });

})(Love);