(function(Love) {

    Love.Helpers.LocalStorageSession = {

        getSessionIdentifier: function() {

            if (!Love.session || !Love.session.get('isLoggedIn'))
                return 'anonymous';
            else
                return 'account_' + Love.session.get('accountId');
        },

        getInteger: function(key) {

            return Love.Helpers.LocalStorage.getInteger(Love.Helpers.LocalStorageSession.getSessionIdentifier() + '_' + key);
        },

        getObject: function(key) {

            return Love.Helpers.LocalStorage.getObject(Love.Helpers.LocalStorageSession.getSessionIdentifier() + '_' + key);
        },

        isSupported: function() { return (typeof(Storage) !== 'undefined'); },

        removeObject: function(key) {

            return Love.Helpers.LocalStorage.removeObject(Love.Helpers.LocalStorageSession.getSessionIdentifier() + '_' + key);
        },

        setItem: function(key, value) {

            return Love.Helpers.LocalStorage.setItem(Love.Helpers.LocalStorageSession.getSessionIdentifier() + '_' + key, value);
        },

        setObject: function(key, value) {

            return Love.Helpers.LocalStorage.setObject(Love.Helpers.LocalStorageSession.getSessionIdentifier() + '_' + key, value);
        }
    };

})(Love);