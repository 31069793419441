(function(Love) {

    Love.Views.SiteRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.SiteRowView',

        renderItem: function() {

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    isLoading: _.isEmpty(this.options.item.id)
                })
            });

            this.$el.html(_.template(Love.Templates.sites_site_row)(context));

            this.listenTo(this, 'edit', function(e) {

                Love.router.navigate('site/' + e.item.id + '/dashboard', {trigger: true});
            });
        }
    });

})(Love);