(function(Love) {

    Love.Views.PagesSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.PagesSidebarView',

        defaults: function() {

            return {

                selectByDefault: false
            };
        },

        filterModelDefaults: function() {

            return {};
        },

        events: function() {

            return _.extend({}, Love.Views.BaseSidebarView.prototype.events, {});
        },

        initialize: function(options) {

            Love.Views.BaseSidebarView.prototype.initialize.call(this, options);

            this._filterModel = this.filterModelDefaults();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.pages_sidebar)());
            return Love.Views.BaseSidebarView.prototype.render.call(this);
        },

        getFilterModel: function() {

            return (this._filterModel) ? this._filterModel : this.filterModelDefaults();
        }
    });

})(Love);