(function(Love) {

    Love.Views.RemixBookmarkRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.RemixBookmarkRowView',

        defaults: function() {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true,
                deletable: true,
                selectable: true,
                selected: false,
                remixable: true
            });
        },

        renderItem: function() {

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    bookmarkDescription: this.options.item.getDescription(),
                    bookmarkTitle: this.options.item.getTitle() || 'Bookmark',
                    externalUrl: this.options.item.getExternalUrl(),
                    isLoading: _.isEmpty(this.options.item.id)
                })
            });

            this.$el.html(_.template(Love.Templates.remix_bookmark_row)(context));

            var fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedFragmentFromBookmark(this.options.item);

            $.when(fragmentOrPromise).always(_.bind(function(fragment) {

                if (fragment) {

                    var instance = this.addSubView(new Love.Views.CommonContentEmbedWidgetView({fragment: {}, editModel: null}));
                    instance.renderEmbed(fragment.settings, this.$('.embed-container'));

                    this.$('.list-row-hero').removeClass('hidden');

                    if (fragment.settings.vendor === 'twitter')
                        this.$('.list-row-content .left').addClass('hidden'); // For Tweets, the description matches the embed content.
                }

            }, this));

            this.listenTo(this, 'remix', function(e) {

                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/new/remix/' + this.options.item.id, {trigger: true});
            });
        }
    });

})(Love);