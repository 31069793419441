(function(Love) {

    Love.Views.CommonMultipleListMenuPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonMultipleListMenuPopupView',

        className: 'base-popup popup-multiple-list-menu',
        id: '',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    itemClick: null
                },
                data: {

                    columns: [],
                    mode: 'default',
                    title: '',
                    triangle: true
                }
            };
        },

        events: {

            'click .popup-content li': '_handleItemClick'
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_multiple_list_menu)(this.options.data));

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleItemClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var preventDefault = false;

            if (this.options.callbacks.itemClick) {

                var colIndex = parseInt($(e.target).closest('[data-column-index]').attr('data-column-index'));
                var itemIndex = parseInt($(e.target).closest('[data-item-index]').attr('data-item-index'));

                var event = {

                    item: this.options.data.columns[colIndex].items[itemIndex],
                    preventDefault: false
                };

                this.options.callbacks.itemClick(event);

                if (event.preventDefault) preventDefault = true;
            }

            if (!preventDefault) {

                var href = $(e.target).closest('[data-item-index]').find('a[href]').attr('href');

                if (!_.isEmpty(href))
                    Love.router.navigate(href, {trigger: true});
            }

            this.close();
        }
    });

})(Love);