(function(Love) {

    Love.Views.BaseScreenView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.BaseScreenView',

        render: function(dontTrigger) {

            $(window).scrollTop(0); // When rendering a new screen, we reset the scroll position on the body.

            return Love.Views.BaseView.prototype.render.call(this, dontTrigger);
        }
    });

})(Love);