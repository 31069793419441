(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'highlighted': {

            'screen': {

                'common_content_list_actions_delete_interval': '(0){Delete content sets};(1){Delete 1 content set};(2-inf){Delete {{count}} content sets};',
                'common_content_list_description_search': 'There are {{count}} result(s) for: <span class="search">{{query}}</span>'
            }
        }
    });

})(Love);