(function (Love) {

    Love.Models.ContentTypeModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.ContentTypeModel',

        defaults: function () {

            return {

                id: '',
                title: ''
            };
        },

        initialize: function (options) {

            if (options) this.set(options);
        }
    });

    Love.Collections.ContentTypeCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.ContentTypeCollection',

        model: Love.Models.ContentTypeModel,

        initialize: function () {

            var types = ['Tweets', "Moby's", 'Facebook', 'Events', 'Blog posts', 'Polls', 'Instagram'];

            _.each(types, function (value, index) {

                var model = new Love.Models.ContentTypeModel();

                model.set('id', index + 1);
                model.set('title', value);

                this.add(model);

            }, this);
        }
    });

})(Love);