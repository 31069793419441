(function (Love) {

    Love.Models.StorylineModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.StorylineModel',

        defaults: function() {

            return {

                id: '',
                title: ''
            };
        },

        initialize: function (options) {

            if (options) this.set(options);
        }
    });

    Love.Collections.StorylineCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.StorylineCollection',

        model: Love.Models.StorylineModel,
        
        url: function () { return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/storylines.json'; },

        parse: function (response, options) {

            var storylines = [];

            $.each(response.response, function (i, value) {

                if (value.class === 'storyline')
                    storylines.push(value);
            });

            return storylines;
        }
    });

})(Love);