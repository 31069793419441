(function(Love) {

    Love.Views.SocialUpdateSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.SocialUpdateSidebarView',

        initialize: function(options) {

            Love.Views.BaseSidebarView.prototype.initialize.call(this, options);

            this.editModel = options.editModel;
            this.viewState = this.options.viewState;
        },

        render: function() {

            var context = _.extend(_.clone(this.editModel.attributes), this.viewState.attributes);
            this.$el.html(_.template(Love.Templates.social_update_sidebar)(context));

            this.stopListening(this.viewState, 'change:changedByUser', this._updateSavedText);

            this.stopListening(this.editModel, 'change', this._updateSavedText);

            // Now the base layout has been rendered, we first allow some functions from the BaseSidebarView to be executed
            // before continuing the rendering process.

            _.defer(_.bind(function() {

                this._updateSavedText();
                this._updateStatusText();

                this.listenTo(this.viewState, 'change:changedByUser', this._updateSavedText);

                this.listenTo(this.editModel, 'change', this._updateSavedText);
                this.listenTo(this.editModel, 'change:status', this._updateStatusText());

                this.trigger('rendered');

            }, this));

            return Love.Views.BaseSidebarView.prototype.render.call(this, true); // We'll trigger the rendered event later.
        },

        _updateSavedText: function() {

            var changedAttrs = this.editModel.changedAttributesJSON(this.editModel.serverAttributes);
            var isNew = this.editModel.isNew();

            if (!isNew && !changedAttrs)
                this.$('[data-type="saved"] .sidebar-row-content span').html("Your changes have been saved!");
            else
                this.$('[data-type="saved"] .sidebar-row-content span').html("Your changes have <em>not been saved</em>.");
        },

        _updateStatusText: function() {

            var text = '';

            switch (this.editModel.get('status')) {

                case 'error':
                    text = 'We\'re sorry, this update has <em>failed</em>. Please try creating a new update!';
                    break;

                case 'idle':
                    text = 'This is a <em>draft</em>. You can schedule the update when you\'re ready!';
                    break;

                case 'published':
                    text = 'This update has been <em>published</em>.';
                    break;

                case 'queued':
                    text = 'This update has been <em>queued</em> and will be posted very soon.';
                    break;

                case 'scheduled':
                    text = 'This update has been scheduled for <em class="filled">' + this.editModel.get('scheduledOnUtc').clone().local().format('ddd, DD MMM YYYY, HH:mm') + '</em>';
                    break;

                case 'unpublished':
                    text = 'This update has been <em>unpublished</em>.';
                    break;
            }

            this.$('[data-type="status"] .sidebar-row-content span').html(text);
        }
    });

})(Love);