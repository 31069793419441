(function(Love) {

    Love.Views.DashboardAnalyticsViewsModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardAnalyticsViewsModuleView',

        defaults: function() {

            return {

                date: moment().hours(0).minutes(0).seconds(0).milliseconds(0),
                days: 14
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this._eventCollection = new Love.Collections.CalendarEventCollection();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.dashboard_modules_analytics_views));

            this.listenTo(this._eventCollection, 'sync', _.bind(function() {

                var data = [];

                for (var i = 0; i < this.options.days; i++) {

                    var offset = -(this.options.days - i) + 1;

                    var start = this.options.date.clone().add(offset, 'days');
                    var end = this.options.date.clone().add(offset + 1, 'days');

                    data.push({

                        startDate: start,
                        endDate: end,
                        stats: this._eventCollection.getStatistics(start, end)
                    });
                }

                this._renderGraph(data);

            }, this));

            this._eventCollection.getEventsByRange(this.options.date.clone().add(-this.options.days + 1, 'days'), this.options.date);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _renderGraph: function(data) {

            var labels = [];
            var empty = true;
            var today = moment();

            _.each(data, function(dataSet) {

                var display = '';

                if (!empty) {

                    if (dataSet.startDate.isSame(today, 'day'))
                        display = 'Today';
                    else
                        display = dataSet.startDate.format('DD/MM/YY');
                }

                labels.push(display);
                empty = !empty;
            });

            data = _.map(data, function(value) { return value.stats; });

            var datasets = [{

                label: 'Moby views',
                borderColor: 'rgb(109, 136, 196)',
                backgroundColor: 'rgba(109, 136, 196, 0.1)',
                lineTension: 0,
                capBezierPoints: true,
                data: _.pluck(data, 'totalViewsMobys')
            }, {

                label: 'Post views',
                borderColor: 'rgb(235, 109, 121)',
                backgroundColor: 'rgba(235, 109, 121, 0.1)',
                lineTension: 0,
                capBezierPoints: true,
                data: _.pluck(data, 'totalViewsPosts')
            }];

            var self = this;
            var ctx = this.$('.chart')[0].getContext('2d');
            var chart = new Chart(ctx, {

                type: 'line',
                data: {

                    labels: labels,
                    datasets: datasets
                },
                options: {

                    maintainAspectRatio: false,
                    legend: {display: false},
                    animation: {duration: 600},
                    tooltips: {

                        callbacks: {

                            title: function(tooltipItems, data) {

                                return tooltipItems[0].xLabel;
                            },

                            label: function(tooltipItem, data) {

                                return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
                            }
                        }
                    },
                    scales: {

                        xAxes: [{

                            gridLines: {display: false}
                        }],
                        yAxes: [{

                            gridLines: {display: true}
                        }]
                    },
                    hover: {

                        mode: 'dataset',
                        onHover: function(elements) {

                            var datasetIndex = -1;

                            if (elements.length)
                                datasetIndex = elements[0]._datasetIndex;

                            self.$('.icon').removeClass('active');
                            
                            if (datasetIndex > -1)
                                self.$('.icon-' + datasetIndex).addClass('active');
                        }
                    }
                }
            });
        }
    });

})(Love);