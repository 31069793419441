(function(Love) {

    Love.Models.PopularContentModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.PopularContentModel',

        defaults: function() {

            return {};
        },

        initialize: function(options) {

            if (options) this.set(options);
        },

        getExternalUrl: function() {

            return this.get('url');
        },

        getTitle: function() {

            return this.get('title');
        }
    });

    Love.Collections.PopularContentCollection = Love.Collections.BasePageableCollectionNow.extend({

        objectClassName: 'Love.Collections.PopularContentCollection',

        model: Love.Models.PopularContentModel,

        url: function() {

            var platformId = Love.session.get('currentSite').get('nowPlatformId');
            return 'https://api.mobynow.com/1.0/hyperlinks/popular.json?platformID=' + platformId;
        },

        parseRecords: function(response, options) {

            return response.content;
        }
    });

})(Love);