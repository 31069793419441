(function(Love) {

    Love.Views.SettingsEditorView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.SettingsEditorView',

        defaults: function() {

            return {

                availableDestinations: _.sortBy(Love.session.get('currentSite').get('destinations'), function(dest) { return dest.type + dest.accountName; })
            };
        },

        events: {

            'click .common-destinations [data-action="delete"]': '_handleDestinationDeleteClick',
            'click .common-destinations .common-destination-add': '_handleDestinationAddClick'
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.settings_editor)());

            this._renderDestinations();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _handleDestinationAddClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            // TODO RENS: save current settings before redirecting.

            var type = $(e.target).closest('[data-type]').attr('data-type');
            window.location = '/destinations/' + type + '/connect?sid=' + Love.session.get('currentSiteId');
        },

        _handleDestinationDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('[data-id]');
            var id = element.attr('data-id');
            var destination = new Love.Models.DestinationModel(_.find(this.options.availableDestinations, function(obj) {return obj.id === id;}));

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        destination.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                Love.session.retrieveSiteData(Love.session.get('currentSite')).then(_.bind(function() {

                                    self.options.availableDestinations = _.sortBy(Love.session.get('currentSite').get('destinations'), function(dest) { return dest.type + dest.accountName; });
                                    self._renderDestinations();

                                }, self));
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete social media account?',
                    text: 'Do you really want to delete this account? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _renderDestinations: function() {

            this.$('.common-destinations .common-destination').remove();

            _.each(this.options.availableDestinations, function(model) {

                this.$('.common-destinations .common-destination-add').first().before(_.template(Love.Templates.settings_destination(model)));

            }, this);
        }
    });

})(Love);