(function(Love) {

    Love.Models.BaseContentModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.BaseContentModel',

        defaults: function() {

            return {

                id: '',
                isPublished: false,
                isScheduled: false,
                hasRevision: true,
                platformId: '',
                publishedOn: null,
                revisions: {'': this.defaultsRevision()},
                scheduledOn: null,
                storylines: [],
                thumbnail: '', // Client side only.
                views: 0
            };
        },

        defaultsCover: function() {

            var model = new Love.Models.ImageModel();
            return model.attributes;
        },

        defaultsOGImage: function() {

            var model = new Love.Models.ImageModel();
            return model.attributes;
        },

        defaultsRevision: function() {

            return {

                author: {},
                cover: this.defaultsCover(),
                fragments: [],
                id: '',
                ogImage: this.defaultsOGImage(),
                slug: '',
                summary: '',
                tags: [],
                title: '',
                updatedOnUtc: null
            };
        },

        initialize: function(options) {

            if (options) this.set(options);

            _.bindAll(this, 'getClientBackup', 'removeClientBackup', 'saveClientBackup');
        },

        destroy: function(options) {

            this.removeClientBackup();
            return Love.Models.BaseModel.prototype.destroy.call(this, options);
        },

        parse: function(response, options) {

            var contents = (response.response) ? response.response : response;
            var defaultRevision = (contents.revisions['']) ? '' : _.first(_.keys(contents.revisions));

            contents = _.extend(contents, {

                publishedOn: contents.publishedOn && moment(contents.publishedOn).isValid() ? moment(contents.publishedOn) : null,
                revisions: contents.hasRevision ? _.each(contents.revisions, function(revision, lang) {

                        // ------------------------------------------------
                        // TODO RENS: part of the cover-image-by-url hack!
                        // ------------------------------------------------

                        var coverModel = new Love.Models.ImageModel();

                        if (revision.cover) {

                            // When parsing server data, the cover is an imageUpload structure.
                            // When parsing client serialized data (for example, from local backups), the cover is an ImageModel already.

                            if (_.has(revision.cover, 'imageSource'))
                                coverModel = new Love.Models.ImageModel(revision.cover);
                            else
                                coverModel.set(coverModel.getAttrFromMediaAttr(revision.cover));
                        }

                        revision.cover = coverModel.attributes;

                        var ogImageModel = new Love.Models.ImageModel();

                        if (revision.ogImage) {

                            // When parsing server data, the ogImage is an imageUpload structure.
                            // When parsing client serialized data (for example, from local backups), the ogImage is an ImageModel already.

                            if (_.has(revision.ogImage, 'imageSource'))
                                ogImageModel = new Love.Models.ImageModel(revision.ogImage);
                            else
                                ogImageModel.set(ogImageModel.getAttrFromMediaAttr(revision.ogImage));
                        }

                        revision.ogImage = ogImageModel.attributes;

                        // ------------------------------------------------
                        // End part of the cover-image-by-url hack!
                        // ------------------------------------------------

                        revision.updatedOnUtc = revision.updatedOnUtc && moment.utc(revision.updatedOnUtc).isValid() ? moment.utc(revision.updatedOnUtc) : null;

                        // START API HACK: fragments die met een leeg settings object worden opgeslagen, geven fragment.settings = array(0) terug. Dit moet een leeg object {} zijn!

                        if (revision.fragments) {

                            _.each(revision.fragments, function(fragment) {

                                if (_.isArray(fragment.settings)) fragment.settings = {};
                            });
                        }

                        // END API HACK.

                    }, this) : this.defaults().revisions,
                scheduledOn: contents.scheduledOn && moment(contents.scheduledOn).isValid() ? moment(contents.scheduledOn) : null
            });

            // ------------------------------------------------
            // TODO RENS: TEMPORARY FIX totdat Bram geen Wed, 30 Nov -001 00:00:00 +0019 datums meer meestuurt / isPublished=true als die datums niet exact null zijn.
            // ------------------------------------------------

            if (!contents.scheduledOn || contents.scheduledOn._i === 'Wed, 30 Nov -001 00:00:00 +0019') {

                contents.isScheduled = false;
                contents.scheduledOn = null;
            }

            /*
             if (!contents.publishedOn || contents.publishedOn._i === 'Wed, 30 Nov -001 00:00:00 +0019') {

             contents.isPublished = false;
             contents.publishedOn = null;
             }

             _.each(contents.revisions, function(revision) {

             if (!revision.updatedOnUtc || revision.updatedOnUtc._i === 'Wed, 30 Nov -001 00:00:00 +0019') revision.updatedOnUtc = null;
             });
             */

            // ------------------------------------------------
            // END FIX
            // ------------------------------------------------

            var cover = contents.revisions[defaultRevision].cover;
            var coverModel = cover ? new Love.Models.ImageModel(cover) : null;

            if (coverModel) contents.thumbnail = coverModel.getImageView();

            return contents;
        },

        save: function(attrs, options) {

            // TODO RENS: part of the cover-image-by-url hack!

            // NOTE by RENS: I've wrapped the save function to allow for uploading the cover images before saving the model.
            // Until Bram adds cover-image-by-url functionality, we have to upload all cover images now if not already done.

            // In order to maintain asynchronous operation, the new save function returns a Deferred object instead of a jqXHR.
            // The first part is copied from Backbone source files, to support returning false on validation errors.

            // Note that the code assumes that, after changing the cover image models, validation can't fail anymore!

            // BEGIN COPIED FROM BACKBONE SOURCE.

            options = _.extend({validate: true, parse: true}, options);
            var wait = options.wait;

            if (attrs && !wait) {

                if (!this.set(attrs, options)) return false;
            }
            else if (!this._validate(attrs, options))
                return false;

            // END COPIED FROM BACKBONE SOURCE.

            var resultPromise = $.Deferred();
            var promises = [];
            var deferredUpload;

            _.each(attrs.revisions, function(revision) {

                if (revision.cover) {

                    var coverModel = new Love.Models.ImageModel(revision.cover);

                    if (!coverModel.isUploaded()) {

                        if (coverModel.hasImage()) {

                            // We have to add the deferred objects synchronously, as we need to have them all in the array when checking promises below.

                            deferredUpload = $.Deferred();
                            promises.push(deferredUpload);

                            coverModel.uploadUrl(coverModel.getImageView())
                                .done(_.bind(function() {

                                    revision.cover = coverModel.attributes;
                                    this.resolve();

                                }, deferredUpload))
                                .fail(_.bind(function() {this.reject();}, deferredUpload));
                        }
                        else
                            revision.cover = this.defaultsCover();
                    }
                }

                if (revision.ogImage) {

                    var ogImageModel = new Love.Models.ImageModel(revision.ogImage);

                    if (!ogImageModel.isUploaded()) {

                        if (ogImageModel.hasImage()) {

                            // We have to add the deferred objects synchronously, as we need to have them all in the array when checking promises below.

                            deferredUpload = $.Deferred();
                            promises.push(deferredUpload);

                            ogImageModel.uploadUrl(ogImageModel.getImageView())
                                .done(_.bind(function() {

                                    revision.ogImage = ogImageModel.attributes;
                                    this.resolve();

                                }, deferredUpload))
                                .fail(_.bind(function() {this.reject();}, deferredUpload));
                        }
                        else
                            revision.ogImage = this.defaultsOGImage();
                    }
                }
            });

            $.whenAll.apply($, promises).always(_.bind(function() {

                _.each(attrs.revisions, function(revision) {

                    // All covers should now be either null or uploaded image models.
                    // The API expects imageUpload structures.

                    if (revision.cover) revision.cover = revision.cover.imageUpload;
                    if (revision.ogImage) revision.ogImage = revision.ogImage.imageUpload;

                    // AT THIS POINT, THE CLIENT SIDE MODEL STRUCTURE IS INCONSISTENT WITH THE EXPECTED STRUCTURE!
                    // This might lead to problems if change events are raised or saving fails. If saving succeeds,
                    // the expected (ImageModel) structure is recreated by the parse function.
                });

                var xhr = Love.Models.BaseModel.prototype.save.call(this, attrs, _.extend(_.clone(options), {validate: false}));

                $.when(xhr).done(function() {

                    // Refresh site data for the session, as storylines and other things might have changed.

                    Love.session.retrieveSiteData(Love.session.get('currentSite'));

                    resultPromise.resolve();

                }).fail(function() { resultPromise.reject();});

            }, this));

            return resultPromise;
        },

        toJSON: function(options) {

            // This model has some custom attributes that should not be sent to the server.
            // Other attributes might just not be necessary for saving and can save some network load.

            var result = Love.Models.BaseModel.prototype.toJSON.call(this, options);

            delete result.thumbnail;

            return result;
        },

        /**
         * Adds a fragment at the end of the list.
         *
         * @param revision
         * @param type
         * @param settings
         */
        addFragment: function(revision, type, settings) {

            Love.Helpers.Tracking.track('Widgets - add fragment ' + type, {

                model: this.id,
                modelClass: this.objectClassName,
                data: settings
            });

            return this.insertFragment(revision, type, settings, revision.fragments.length);
        },

        addLanguage: function(lang, copy, copyFrom) {

            var revision = this.defaultsRevision();

            if (copy) {

                var fromRevision = Love.Helpers.General.deepClone(this.get('revisions')[copyFrom]);
                _.extend(revision, fromRevision);
            }

            this.get('revisions')[lang] = revision;

            this.trigger('change:revisions', this, this.get('revisions'), {

                action: 'add',
                revision: revision,
                index: lang
            });
            this.trigger('change', this);
        },

        /**
         * Deletes a fragment.
         *
         * @param revision
         * @param index
         */
        deleteFragment: function(revision, index) {

            var fragments = revision.fragments;

            if (fragments[index]) {

                Love.Helpers.Tracking.track('Widgets - delete fragment ' + fragments[index].type, {

                    model: this.id,
                    modelClass: this.objectClassName,
                    data: fragments[index].settings
                });

                fragments.splice(index, 1);
                _.each(fragments, function(fragment, arrayIndex) {fragment.index = arrayIndex;});

                this.trigger('change:revisions', this, this.get('revisions'), {

                    action: 'deleteFragment',
                    revision: revision,
                    index: index
                });
                this.trigger('change', this);
            }
        },

        deleteLanguage: function(lang) {

            delete this.get('revisions')[lang];

            this.trigger('change:revisions', this, this.get('revisions'), {

                action: 'delete',
                index: lang
            });
            this.trigger('change', this);
        },

        /**
         * Inserts a fragment.
         *
         * @param revision
         * @param type
         * @param settings
         * @param index
         * @param silent
         */
        insertFragment: function(revision, type, settings, index, silent) {

            if (type) {

                Love.Helpers.Tracking.track('Widgets - insert fragment ' + type, {

                    model: this.id,
                    modelClass: this.objectClassName,
                    data: settings
                });

                settings = settings || {};
                index = index || 0;

                var fragment = {

                    type: type,
                    settings: settings,
                    index: index
                };

                var fragments = revision.fragments;

                fragments.splice(index, 0, fragment);
                _.each(fragments, function(fragment, arrayIndex) {fragment.index = arrayIndex;});

                if (!silent) {

                    this.trigger('change:revisions', this, this.get('revisions'), {

                        action: 'insertFragment',
                        revision: revision,
                        index: index
                    });
                    this.trigger('change', this);
                }
            }
        },

        getClientBackup: function() {

            if (this.isNew()) return null;

            var key = this.getClientBackupKey();

            var backup = Love.Helpers.LocalStorageSession.getObject(key);
            var date = moment(Love.Helpers.LocalStorageSession.getObject(key + '_updated_at'));

            if (!backup || !date) return null;

            return {

                backup: backup,
                updatedAt: date
            };
        },

        getClientBackupKey: function() {

            // Implement in child class.
        },

        getCoverImagesAvailable: function(revision, removeDuplicates) {

            var currentImage;
            var images = [];

            // Get the current cover image for the post.

            currentImage = new Love.Models.ImageModel(revision.cover);

            if (currentImage.hasImage())
                images.push(currentImage);

            // Also add any images added within widgets.

            var imageFragments = _.filter(revision.fragments, function(fragment) {

                return (fragment.type === 'CMSImageWidget' || fragment.type === 'CMSTextImageWidget');
            });

            _.each(imageFragments, function(fragment) {

                var model = new Love.Models.ImageModel(fragment.settings);

                if (model.getImageView())
                    images.push(model);
            });

            // Some embeds also contain usable images. Add them as well.

            var embedFragments = _.where(revision.fragments, {type: 'CMSEmbedWidget'});

            _.each(embedFragments, function(fragment) {

                if (_.isEmpty(fragment.settings.vendorContentID)) return;

                var url;

                if (fragment.settings.vendor === 'youtube')
                    url = 'http://i.ytimg.com/vi/' + fragment.settings.vendorContentID + '/hqdefault.jpg';

                if (!_.isEmpty(url)) {

                    var model = new Love.Models.ImageModel({

                        imageSource: 'url',
                        imageURL: url
                    });

                    images.push(model);
                }
            });

            // Remove duplicate images.

            if (removeDuplicates)
                images = _.uniq(images, false, function(model) {return model.getImageView();});

            return images;
        },

        getCoverOrDefault: function(revision) {

            var coverModel = new Love.Models.ImageModel(revision.cover);

            if (coverModel.hasImage())
                return revision.cover;

            else {

                var available = this.getCoverImagesAvailable(revision, true);
                return available.length > 0 ? available[0].attributes : this.defaultsCover();
            }
        },

        /**
         * Gets the default revision for this post. If the international revision isn't available, this returns the first that is.
         */
        getDefaultRevision: function() {

            var revision = '';

            if (!this.get('revisions')[revision])
                revision = _.first(_.keys(this.get('revisions')));

            return revision;
        },

        /**
         * Moves a fragment down.
         *
         * @param revision
         * @param index
         * @param amount
         */
        moveFragmentDown: function(revision, index, amount) {

            if (!amount) amount = 1;

            Love.Helpers.Tracking.track('Widgets - move fragment down', {

                model: this.id,
                modelClass: this.objectClassName
            });

            this._swapFragments(revision, index, index + amount);

            this.trigger('change:revisions', this, this.get('revisions'), {

                action: 'moveFragment',
                revision: revision,
                index: index + amount,
                oldIndex: index
            });
            this.trigger('change', this);
        },

        /**
         * Moves a fragment up.
         *
         * @param revision
         * @param index
         * @param amount
         */
        moveFragmentUp: function(revision, index, amount) {

            if (!amount) amount = 1;

            Love.Helpers.Tracking.track('Widgets - move fragment up', {

                model: this.id,
                modelClass: this.objectClassName
            });

            this._swapFragments(revision, index, index - amount);

            this.trigger('change:revisions', this, this.get('revisions'), {

                action: 'moveFragment',
                revision: revision,
                index: index - amount,
                oldIndex: index
            });
            this.trigger('change', this);
        },

        removeClientBackup: function() {

            var key = this.getClientBackupKey();

            Love.Helpers.LocalStorageSession.removeObject(key);
            Love.Helpers.LocalStorageSession.removeObject(key + '_updated_at');
        },

        saveClientBackup: function() {

            if (this.isNew()) return null;

            // Store a copy of the edited model in the local cache.

            var key = this.getClientBackupKey();

            Love.Helpers.LocalStorageSession.setObject(key, this);
            Love.Helpers.LocalStorageSession.setObject(key + '_updated_at', moment());
        },

        /**
         * Updates settings for a fragment.
         *
         * @param revision
         * @param index
         * @param settings
         */
        updateFragmentSettings: function(revision, index, settings) {

            if (revision.fragments[index])
                revision.fragments[index].settings = settings;

            this.trigger('change:revisions', this, this.get('revisions'), {

                action: 'settingsFragment',
                revision: revision,
                index: index
            });
            this.trigger('change', this);
        },

        /**
         * Swaps the position of two fragments.
         *
         * @param revision
         * @param indexA
         * @param indexB
         * @private
         */
        _swapFragments: function(revision, indexA, indexB) {

            if (indexB >= 0) {

                var fragments = revision.fragments;

                if (fragments[indexA] && fragments[indexB]) {

                    var temp = fragments[indexA];

                    fragments[indexA] = fragments[indexB];
                    fragments[indexB] = temp;
                    fragments[indexA].index = indexA;
                    fragments[indexB].index = indexB;

                    revision.fragments = fragments;
                }
            }
        }
    });

})(Love);