(function(Love) {

    Love.Views.ErrorsSessionView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.ErrorsSessionView',

        defaults: function() {

            return {

                query: ''
            };
        },

        render: function() {

            var context = _.extend(_.clone(this.options), {

                siteId: Love.session.get('currentSiteId')
            });

            this.$el.html(_.template(Love.Templates.errors_session)(context));

            return Love.Views.BaseScreenView.prototype.render.call(this);
        }
    });

})(Love);