(function(Love) {

    Love.Views.SettingsScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.SettingsScreenView',

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.editorView = this.addSubView(new Love.Views.SettingsEditorView());
            this.sidebarView = this.addSubView(new Love.Views.SettingsSidebarView());
        },

        render: function() {

            var context = {siteId: Love.session.get('currentSite').id};
            this.$el.html(_.template(Love.Templates.settings_screen)(context));

            this.editorView.setElement(this.$('.editor-container')).render();
            this.sidebarView.setElement(this.$('.base-sidebar-container')).render();

            return Love.Views.BaseScreenView.prototype.render.call(this);
        }
    });

})(Love);