(function(Love) {

    Love.Views.CommonMobyHeroView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonMobyHeroView',

        events: {

            'click .common-moby-hero': '_handleMobyClick',
            'mouseenter .common-moby-hero-teaser-trigger': '_handleMobyTeaserEnter',
            'mouseleave .common-moby-hero-teaser-trigger': '_handleMobyTeaserLeave',
            'click .common-moby-hero-teaser-trigger': '_handleMobyClick',
            'click .moby-hero-controls [data-action="edit"]': function(e) {

                e.preventDefault();
                e.stopPropagation();
            },
            'click .moby-hero-controls [data-action="platform"]': function(e) {e.stopPropagation();},
            'click .moby-hero-controls [data-action="previous"]': '_handlePreviousClick',
            'click .moby-hero-controls [data-action="next"]': '_handleNextClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this,

                'hideMoby',
                '_handleContentClick'
            );
        },

        render: function() {

            this.updateCollection();
            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this.hideMoby();
        },

        hideMoby: function() {

            $('#primary-content').off('click', this._handleContentClick);

            this.$('.common-moby-hero').removeClass('open');
            $('#primary-content').removeClass('moby-hero-hidden');
            $('#primary-content').removeClass('moby-hero-teaser');
        },

        isOpen: function() {

            return this.$('.common-moby-hero').hasClass('open');
        },

        showMoby: function() {

            Love.Helpers.Tracking.track('Moby hero - show');

            $('#primary-content').addClass('moby-hero-hidden');
            $('#primary-content').removeClass('moby-hero-teaser');
            this.$('.common-moby-hero').addClass('open');

            $('#primary-content').on('click', this._handleContentClick);
        },

        teaseMoby: function() {

            $('#primary-content').addClass('moby-hero-teaser');
        },

        toggleMoby: function() {

            if (this.isOpen()) this.hideMoby();
            else this.showMoby();
        },

        updateCollection: function() {

            if (this._mobyCollection)
                this.stopListening(this._mobyCollection, 'reset');

            var brandStream = _.findWhere(Love.session.get('currentSite').get('streams'), {isBrandStream: true});

            if (brandStream) {

                this._fetchOptions = {

                    data: {

                        mediaType: 'mobypicture',
                        mediaSubType: 'photo',
                        streamIDs: brandStream.id
                    },
                    traditional: true,
                    requestId: this.getAjaxAbortId(),
                    abortPendingByIds: [this.getAjaxAbortId()]
                };

                this._mobyCollection = new Love.Collections.MediaCollection(null, {

                    mode: 'infinite',
                    state: {pageSize: 1}
                });

                // We need to listen to the reset event, as Backbone.Paginator uses this when performing infinite/client side paging.

                this.listenTo(this._mobyCollection, 'reset', _.bind(function() {

                    if (this._mobyCollection.length > 0)
                        this._renderMoby(this._mobyCollection.at(0));

                    else if (this._renderedMoby)
                        this._renderMoby(this._renderedMoby); // Re-render the current Moby.

                }, this));

                this._mobyCollection.getFirstPage(this._fetchOptions);
            }
        },

        _handleContentClick: function(e) {

            // Prevent propagation to the .common-moby-hero click handler.
            // We also don't want to raise click events on the content, as this click is just about re-showing the content again.

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this.toggleMoby();
        },

        _handleMobyClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.toggleMoby();
        },

        _handleMobyTeaserEnter: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (!this.isOpen())
                this.teaseMoby();
        },

        _handleMobyTeaserLeave: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (!this.isOpen())
                this.hideMoby();
        },

        _handleNextClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            if ($(e.target).closest('[data-action]').hasClass('disabled')) return;

            this.$('[data-action="next"]').addClass('disabled');

            this.$('[data-action="next"] .icon').removeClass('ttl-arrow-right').addClass('icon-spinner');
            this.$('[data-action="previous"] .icon').removeClass('icon-spinner').addClass('ttl-arrow-left');

            this._mobyCollection.getNextPage(this._fetchOptions);
        },

        _handlePreviousClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            if ($(e.target).closest('[data-action]').hasClass('disabled')) return;

            this.$('[data-action="previous"]').addClass('disabled');

            this.$('[data-action="next"] .icon').removeClass('icon-spinner').addClass('ttl-arrow-right');
            this.$('[data-action="previous"] .icon').removeClass('ttl-arrow-left').addClass('icon-spinner');

            this._mobyCollection.getPreviousPage(this._fetchOptions);
        },

        _renderMoby: function(moby) {

            this._renderedMoby = moby;

            var view = moby.get('info').media.thumbnails.view;

            if (!_.isEmpty(view))
                view = view.replace('view', 'full'); // TODO RENS: tijdelijke fix totdat we de full image (liefst ook rotated) van de API doorkrijgen.

            Love.Helpers.Images.loadAnImage({

                loadImageOptions: {

                    canvas: true // Enables getting a data url for the corrected image.
                },
                onLoad: _.bind(function(imgOrUrl) {

                    var context = _.extend(_.clone(moby.attributes), {

                        hasNext: this._mobyCollection.hasNextPage(),
                        hasPrevious: this._mobyCollection.hasPreviousPage(),
                        isOpen: this.isOpen(),
                        platformUrl: Love.session.get('currentSite').get('url') + '/media?mediaId=' + moby.get('mediaID')
                    });
                    this.$el.html(_.template(Love.Templates.common_moby_hero)(context));

                    // We disable image filters in Safari, as they cause slow rendering (ie., on scroll).

                    if (!Love.Helpers.General.detectBrowser().isSafari)
                        this.$('.common-moby-hero').addClass('use-filters');

                    this.$('.moby-hero-image').css('background-image', 'url(' + imgOrUrl + ')');

                    this._setGeolocationReversed(moby, _.bind(function(moby) {

                        var reversed = moby.get('info').geolocation.reversed;

                        if (!_.isEmpty(reversed)) {

                            this.$('.location').text(reversed);
                            this.$('.location').removeClass('hidden');
                        }

                    }, this));

                }, this),
                url: view
            });
        },

        /**
         * Performs an asynchronous request for the reversed geolocation for a Moby and sets it.
         * @param moby
         * @param callback
         * @private
         */
        _setGeolocationReversed: function(moby, callback) {

            var geolocation = moby.get('info').geolocation;

            if (geolocation && geolocation.available) {

                if (geolocation.reversed) { // We already have one.

                    if (callback)
                        callback(moby);

                    return;
                }

                // The reversed geolocation has to be retrieved from Mapbox with a limit of 10 requests per second and can be locally cached.

                var jqxhr = $.ajax({

                        url: 'http://ec2-184-72-246-60.compute-1.amazonaws.com/nominatim/reverse.php?format=json&lat=' + geolocation.latitude + '&lon=' + geolocation.longitude + '&zoom=16&accept-language=en-US',
                        //url: "https://api.mapbox.com/geocoding/v5/mapbox.places/" + geolocation.longitude + "," + geolocation.latitude + ".json?access_token=" + this.config.mapboxAccessToken,
                        dataType: 'json',
                        success: function(data) {

                            var address = data.address;

                            if (address) {

                                geolocation.reversed = [

                                    address.road,
                                    address.city,
                                    address.country

                                ].filter(function(val) {return val;}).join(', '); // Filter prevents null or undefined elements to be joined.
                                moby.get('info').geolocation = geolocation;
                            }

                            /*
                             if (data.features && data.features.length > 0) {

                             geolocation.reversed = data.features[0].place_name; // Mapbox seems to order locations by precision.
                             moby.get('info').geolocation = geolocation;
                             }
                             */
                        }
                    })
                    .always(function() {

                        if (callback)
                            callback(moby);
                    });
            }
        }
    });

})(Love);