(function(Love) {

    Love.Models.NotificationModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.NotificationModel',

        defaults: function() {

            return {

                createdOn: moment(),
                icon: '',
                link: '',
                message: ''
            };
        },

        initialize: function(options) {

            if (options) this.set(options);
        }
    });

    Love.Collections.NotificationCollection = Love.Collections.BasePageableCollection.extend({

        objectClassName: 'Love.Collections.NotificationCollection',

        model: Love.Models.NotificationModel
    });

})(Love);