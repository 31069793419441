(function(Love) {

    Love.Helpers.Tracking = {

        identify: function(id) {

            try {

                return mixpanel.identify(id);
            }
            catch (ex) {

                console.log(ex);
            }
        },
        register: function(data) {

            try {

                return mixpanel.register(data);
            }
            catch (ex) {

                console.log(ex);
            }
        },
        track: function(event, data) {

            try {

                return mixpanel.track(event, data);
            }
            catch (ex) {

                console.log(ex);
            }
        }
    };

})(Love);