(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'dashboard': {

            'modules': {

                'remix': {

                    'create': 'Create post with {{count}} Remix item(s)'
                }
            }
        }
    });

})(Love);