(function(Love) {

    Love.Views.CommonCoverImagePopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonCoverImagePopupView',

        className: 'base-popup popup-centered',
        id: 'popup-common-cover-image-old',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    animate: true,
                    canChangeCroppedArea: true,
                    canChangeFocusPoint: true,
                    containerRatio: 4 / 3, // 4:3 is the approximate ratio Facebook etc. use.
                    cropperRatio: 1.91, // 1.91:1 is the approximate ratio Facebook etc. use.
                    imageModel: null,
                    images: [],
                    ogImageModel: null
                },
                enableLightbox: true
            };
        },

        events: {

            'click .cover-available-images .image': '_handleThumbnailClick',
            'click .cover-slider-minus': function(e) { this._handleSliderClick(e, -10); },
            'click .cover-slider-plus': function(e) { this._handleSliderClick(e, 10); },
            'input .cover-slider': '_handleSliderZoom',
            'dragover .popup-content': function(e) {e.preventDefault();},
            'drop .cover-cropper': '_handleDrop',
            'click [data-action="toggle-description"]': '_handleToggleDescription',
            'click [data-action="remove"]': '_handleRemove',
            'click [data-action="confirm"]': '_handleConfirm',
            'click [data-action="close"]': '_handleClose',
            'mousedown .cover-cropper-mover': '_handleCropperMoverMouseDown',
            'touchstart .cover-cropper-mover': '_handleCropperMoverMouseDown'
        },

        initialize: function(options) {

            Love.Views.BasePopupView.prototype.initialize.call(this, options);

            _.bindAll(this,

                '_createCropper',
                '_handleCropperCrop',
                '_handleCropperMouseDown',
                '_handleCropperMouseUp',
                '_handleCropperMoverMouseDown',
                '_handleCropperMoverMouseMove',
                '_handleCropperMoverMouseUp',
                '_handleCropperZoom'
            );

            this.$cropper = null;
            this.$focusPoint = null;

            this._cropperCreated = false;
            this._cropperInitialized = false;
            this._maxRatio = 2;
            this._minRatio = 1;
            this._originalRatio = 1;
            this._zoom = 0;

            // We deep clone all image models here to ensure predictable Cancel / OK behavior:

            // - in the popup, focus point data should be kept while open.
            // - on Cancel, data should not be kept.
            // - on OK, data should be kept for the active imageModel.

            _.each(this.options.data.images, function(image, index) {

                this.options.data.images[index] = Love.Helpers.General.deepClone(image);

            }, this);

            if (this.options.data.imageModel)
                this.options.data.imageModel = Love.Helpers.General.deepClone(this.options.data.imageModel);

            else if (this.options.data.images[0])
                this.options.data.imageModel = this.options.data.images[0];

            if (this.options.data.ogImageModel)
                this.options.data.ogImageModel = Love.Helpers.General.deepClone(this.options.data.ogImageModel);
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_cover_image_old)());

            this.$cropper = this.$('.cover-cropper > .cropper-image');
            this._$cropBoxMoverContainer = this.$('.cover-cropper-mover-container');
            this._$cropBoxMover = this.$('.cover-cropper-mover');

            this._grid = this.addSubView(new Love.Views.CommonContentGridView({type: 'image'}));
            this._grid.setElement(this.$('.common-content-grid-container')).render();

            _.each(this.options.data.images, function(image, index) {

                var fp = image.getFocusPoint();
                this._grid.addItem('<div class="image" data-load-url="' + image.getImageView() + '" data-focus-x="' + fp.x + '" data-focus-y="' + fp.y + '"></div>', index);

            }, this);

            this._grid.addImageUploader();
            this._grid.init();

            this._imageUploadView = this.addSubView(new Love.Views.CommonImageUploadView({

                addImageMode: true,
                callbacks: {

                    onChange: _.bind(function(imageModel) {

                        if (imageModel && !_.isEmpty(imageModel.getImageView())) {

                            this._addNewImage(imageModel, true);
                            this._imageUploadView.setImage(new Love.Models.ImageModel());
                        }

                    }, this),
                    onDropMultiple: _.bind(this._handleImageDropMultiple, this)
                },
                mini: true,
                parentPopupView: this
            }));
            this._imageUploadView.setElement(this.$('.common-image-upload-container')).render();

            this._setCropperContainerSize();

            this._updateDisplayedControls();

            _.defer(_.bind(function() {

                if (this.options.data.imageModel)
                    this._updateDisplayedImage();

            }, this));

            if (Love.Helpers.LocalStorageSession.getObject('common_popups_cover_image_description_hidden'))
                _.defer(_.bind(function() { this.$('.toggle-description').removeClass('open'); }, this));

            this._unregisterCustomEvents();
            this._registerCustomEvents();

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        onClose: function() {

            this._unregisterCustomEvents();

            if (this.$cropper) {

                this.$cropper.off('crop');
                this.$cropper.off('cropstart');
                this.$cropper.off('cropend');
            }

            Love.Views.BasePopupView.prototype.onClose.call(this);
        },

        onResize: function() {

            Love.Views.BasePopupView.prototype.onResize.call(this);

            this._setCropperContainerSize();

            if (this._cropperCreated && this._cropperInitialized) {

                this.$cropper.cropper('reset');

                this._updateFocusPoint();
                this._updateCropBoxMover();
                this._zoomToFocusPoint();
            }

            // NOTE: updating the content slider isn't necessary here, as it has it's own $(window).resize listener.
        },

        _addNewImage: function(imageModel, activate) {

            imageModel = Love.Helpers.General.deepClone(imageModel);

            this.options.data.images.push(imageModel);

            var fp = imageModel.getFocusPoint();

            this._grid.addItem('<div class="image" data-load-url="' + imageModel.getImageView() + '" data-focus-x="' + fp.x + '" data-focus-y="' + fp.y + '"></div>', this.options.data.images.length - 1);
            this._grid.init();

            this._updateDisplayedControls();

            if (activate) {

                this.options.data.imageModel = imageModel;
                this.options.data.ogImageModel = null;

                this._updateDisplayedImage();
            }

            this.performLazyLoadImages();

            // NOTE: updating the content slider isn't necessary here, as it performs a resize after adding a new slide.
        },

        _animate: function(beforeAnimate, afterAnimate) {

            var duration = 'slow';
            var $cropperViewBox = this.$('.cropper-view-box');

            this.$focusPoint.hide();
            $cropperViewBox.hide();

            var showCropper = function() {

                $cropperViewBox.fadeIn(duration, function() {

                    if (afterAnimate) afterAnimate();
                });
            };

            if (beforeAnimate) beforeAnimate();

            if (this.options.data.canChangeFocusPoint) {

                this.$focusPoint.fadeIn(duration);
                showCropper();
            }
            else if (this.options.data.canChangeCroppedArea) {

                showCropper();
            }
            else if (afterAnimate)
                afterAnimate();
        },

        _calculateCropperPosition: function() {

            var value;
            var cropperHeight = this.$('.cover-cropper').height();
            var cropBoxData = this.$cropper.cropper('getCropBoxData');
            var canvasData = this.$cropper.cropper('getCanvasData');

            var focusPoint = this._getModelFocusPoint();
            var trueFocusPoint = this._imageToCanvasCoords(focusPoint);

            var atTop = (trueFocusPoint.y - (cropBoxData.height / 2) <= 0);
            var atBottom = (trueFocusPoint.y + (cropBoxData.height / 2) >= canvasData.height);

            // Check if the cropbox should be all the way to the top or bottom.

            if (atTop) // Top
                value = 0;

            else if (atBottom)
                value = cropperHeight - cropBoxData.height; // Bottom

            else {

                //value = ((cropperHeight / 2) - (cropBoxData.height / 2)); // Center

                value = trueFocusPoint.y + canvasData.top - (cropBoxData.height / 2);
                //value -= canvasData.top;
            }

            value = Math.max(value, 0);
            value = Math.min(value, cropperHeight - cropBoxData.height);

            this._setCropperPosition(value);
        },

        _calculateMaxRatio: function() {

            // Facebook recommended Shared Image size: 1200 x 630 pixels. The aspect ratio of this is 1.91:1.
            // Facebook shows 476 width.
            // Minimaal OG size: 200x200. May not upload smaller than 200 x 200.

            // TODO JEROEN: Warning when image reaches 1200 x 630 threshhold: "Facebook prefers 1200 x 630"

            var canvasData = this.$cropper.cropper('getCanvasData');
            var cropBoxData = this.$cropper.cropper('getCropBoxData');

            var maxZoomX = canvasData.naturalWidth / cropBoxData.width;
            var maxZoomY = canvasData.naturalHeight / cropBoxData.height;

            var maxZoom;

            if (maxZoomX < maxZoomY)
                maxZoom = maxZoomX;
            else
                maxZoom = maxZoomY;

            // Round number with one decimal.

            maxZoom *= 10;
            maxZoom = Math.round(maxZoom);
            maxZoom /= 10;

            this.$('.cover-slider').attr({'max': maxZoom * 100});

            return maxZoom;
        },

        _calculateMinRatio: function() {

            var canvasData = this.$cropper.cropper('getCanvasData');
            var cropBoxData = this.$cropper.cropper('getCropBoxData');

            var minZoom;

            if (canvasData.naturalHeight < canvasData.naturalWidth)
                minZoom = cropBoxData.height / canvasData.naturalHeight;
            else
                minZoom = cropBoxData.width / canvasData.naturalWidth;

            // Round number with one decimal.

            minZoom *= 10;
            minZoom = Math.round(minZoom);
            minZoom /= 10;

            this.$('.cover-slider').attr({'min': minZoom * 100});

            return minZoom;
        },

        _centerFocusPoint: function() {

            var cropBoxData = this.$cropper.cropper('getCropBoxData');

            var cropBoxCenter = {

                x: cropBoxData.width / 2,
                y: cropBoxData.height / 2
            };

            var focusPoint = this._getModelFocusPoint();
            var trueFocusPoint = this._imageToCanvasCoords(focusPoint);

            var move = {

                x: -(trueFocusPoint.x - cropBoxCenter.x),
                y: -(trueFocusPoint.y - cropBoxCenter.y - cropBoxData.top)
            };

            this.$cropper.cropper('moveTo', move.x, move.y);
        },

        _createCropper: function() {

            // https://github.com/fengyuanchen/cropper

            this.$('.cover-cropper-loader').removeClass('hidden');
            this.$('.cover-cropper-container').addClass('invisible');

            Love.Helpers.Loading.showLoading(true, this.cid, this.$('.cover-cropper-loader'));

            this.$cropper.off('crop', this._handleCropperCrop);

            this.$cropper.off('cropstart', this._handleCropperMouseDown);
            this.$cropper.off('cropend', this._handleCropperMouseUp);

            this.$cropper.cropper({

                aspectRatio: this.options.data.cropperRatio,
                autoCropArea: 1, // Force the crop box to become full width.
                background: false,
                center: false,
                checkOrientation: false, // The proxy handles this to ensure consistent behavior and promote caching.
                cropBoxMovable: false,
                cropBoxResizable: false,
                dragMode: 'move',
                guides: false,
                highlight: false,
                responsive: true,
                toggleDragModeOnDblclick: false,
                viewMode: 1, // NOTE: some functions are documented to work only in certain view modes!
                wheelZoomRatio: 0.075,
                zoomable: (this.options.data.canChangeCroppedArea),
                zoom: this._handleCropperZoom,
                ready: _.bind(function() {

                    // This function gets called every time the cropper is rebuilt for an image.

                    var canvasData = this.$cropper.cropper('getCanvasData');

                    this._zoom = canvasData.width / canvasData.naturalWidth;

                    this._originalRatio = this._zoom;
                    this._maxRatio = this._originalRatio * this._calculateMaxRatio();
                    this._minRatio = this._originalRatio; //* this._calculateMinRatio(); // Only used when viewmode is 0.

                    var croppedArea = this._getModelCroppedArea();

                    if (croppedArea) {

                        this.$cropper.cropper('setCropBoxData', croppedArea.cropBox); // This must be called first.
                        this.$cropper.cropper('setCanvasData', croppedArea.canvas);

                        // We can't trust the provided canvas data, so we retrieve it again from the cropper.

                        canvasData = this.$cropper.cropper('getCanvasData');

                        this._zoom = canvasData.width / canvasData.naturalWidth;
                        this._updateSlider();
                    }

                    this.$focusPoint = $('<div class="focuspoint hidden-animated"><i class="icon ttl-focus"></i></div>').appendTo(this.$('.cropper-container'));
                    this.$focusPoint.hide();

                    this._updateFocusPoint(); // Retrieve the focus point data from the model and set it on $focusPoint.

                    if (!croppedArea)
                        this._zoomToFocusPoint(); // Since we didn't have a custom cropped area yet, we try to optimize the view for the user.

                    var beforeAnimate = _.bind(function() {

                        Love.Helpers.Loading.showLoading(false, this.cid);

                        this.$('.cover-cropper-loader').addClass('hidden');
                        this.$('.cover-cropper-container').removeClass('invisible');

                        if (!this.options.data.canChangeCroppedArea) {

                            this.$('.cropper-crop-box').addClass('hidden');
                            this.$('.cropper-drag-box').addClass('hidden');
                        }

                    }, this);

                    var afterAnimate = _.bind(function() {

                        this._updateCropBoxMover(); // Set the slider to match the cropped area, after it has been correctly displayed.

                        this.$cropper.on('crop', this._handleCropperCrop);
                        this.$cropper.on('cropstart', this._handleCropperMouseDown);
                        this.$cropper.on('cropend', this._handleCropperMouseUp);

                        this._cropperInitialized = true;

                    }, this);

                    if (this.options.data.animate)
                        this._animate(beforeAnimate, afterAnimate);

                    else {

                        beforeAnimate();
                        afterAnimate();
                    }

                }, this)
            });

            this._cropperCreated = true;
        },

        _getCanvasCroppedData: function(callback) {

            // Though we don't have to use a callback, this allows easily replacing the data URL by a blob.

            var canvas = this.$cropper.cropper('getCroppedCanvas');
            var url = (canvas && canvas.toDataURL) ? canvas.toDataURL() : null;

            callback(url);
        },

        _getCenterFocusPoint: function() {

            var cropBoxData = this.$cropper.cropper('getCropBoxData');

            var cropBoxCenter = {

                x: cropBoxData.width / 2,
                y: cropBoxData.height / 2
            };

            var focusPoint = this._getModelFocusPoint();
            var trueFocusPoint = this._imageToCanvasCoords(focusPoint);

            return {

                x: -(trueFocusPoint.x - cropBoxCenter.x),
                y: -(trueFocusPoint.y - cropBoxCenter.y - cropBoxData.top)
            };
        },

        _getCroppedAreaData: function() {

            var canvasData = this.$cropper.cropper('getCanvasData');
            var cropBoxData = this.$cropper.cropper('getCropBoxData');

            return {

                canvas: canvasData,
                cropBox: cropBoxData
            };
        },

        _getModelCroppedArea: function() {

            return (this.options.data.ogImageModel && !this.options.data.ogImageModel.hasDefaultCropRegion()) ? this.options.data.ogImageModel.getCroppedArea() : null;
        },

        _getModelFocusPoint: function() {

            // We store the focus point as a percentage on server side.

            var canvasData = this.$cropper.cropper('getCanvasData');
            var percPoint = this.options.data.imageModel ? this.options.data.imageModel.getFocusPoint() : {

                    x: 50,
                    y: 50
                };

            // Return the focus point in pixels relative to the original image size.

            return {

                x: percPoint.x / 100 * canvasData.naturalWidth,
                y: percPoint.y / 100 * canvasData.naturalHeight
            };
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.$cropper && this.$cropper.length > 0)
                this.$cropper.cropper('destroy');

            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm) {

                var ogImageModel = this.options.data.ogImageModel ? this.options.data.ogImageModel : new Love.Models.ImageModel();

                if (this.options.data.canChangeCroppedArea) {

                    // Though it would be best for performance to create the ogImage in the background or server side,
                    // we have an instance of the cropper plugin available here that can do the job.

                    Love.Helpers.Loading.showLoading(true);

                    // If the crop handler wasn't executed yet / after cropping, we might have to create a new ogImageModel and store the data.
                    // We'll do it here just in case.

                    ogImageModel.setCroppedArea(this._getCroppedAreaData());

                    this._getCanvasCroppedData(_.bind(function(blob) {

                        Love.Helpers.Loading.showLoading(false);

                        if (blob) {

                            // Storing the image data as an URI allows us to upload it later.

                            ogImageModel.set('imageSource', 'url');
                            ogImageModel.set('imageURL', blob);

                            this.options.callbacks.onConfirm({

                                imageModel: this.options.data.imageModel,
                                ogImageModel: ogImageModel
                            });
                        }

                    }, this));
                }
                else
                    this.options.callbacks.onConfirm({

                        imageModel: this.options.data.imageModel,
                        ogImageModel: ogImageModel
                    });
            }

            this.close();
        },

        _handleCropperCrop: function(e) {

            this._setModelCroppedArea(this._getCroppedAreaData());
        },

        _handleCropperMouseDown: function(e) {

            Love.Helpers.DragDrop.dragDetectionHandler(e.originalEvent);
        },

        _handleCropperMouseUp: function(e) {

            if (Love.Helpers.DragDrop.isDragOperation(e.originalEvent))
                return; // Don't handle the event if the user performed a dragging operation.

            var $coverCropper = this.$('.cover-cropper');
            var canvasData = this.$cropper.cropper('getCanvasData');

            var click = {

                // Coordinates relative to the canvas.

                x: e.pageX - $coverCropper.offset().left,
                y: e.pageY - $coverCropper.offset().top
            };

            var clickOnImage = {

                // Coordinates relative to the original image.

                x: (click.x - canvasData.left) / this._zoom,
                y: (click.y - canvasData.top) / this._zoom
            };

            // Make sure the desired focus point is within image bounds.

            clickOnImage.x = Math.max(clickOnImage.x, 0);
            clickOnImage.y = Math.max(clickOnImage.y, 0);

            clickOnImage.x = Math.min(clickOnImage.x, canvasData.naturalWidth);
            clickOnImage.y = Math.min(clickOnImage.y, canvasData.naturalHeight);

            this._updateFocusPoint(clickOnImage.x, clickOnImage.y);
            this._zoomToFocusPoint();
        },

        _handleCropperMoverMouseDown: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this._cropperMoverStart = parseInt(this._$cropBoxMover.css('top'));

            $('body').on('mouseup.croppermover', this._handleCropperMoverMouseUp);
            $('body').on('mousemove.croppermover', this._handleCropperMoverMouseMove);
            $('body').on('touchend.croppermover', this._handleCropperMoverMouseUp);
            $('body').on('touchmove.croppermover', this._handleCropperMoverMouseMove);
        },

        _handleCropperMoverMouseMove: function(e) {

            var change = (Love.Helpers.DragDrop.getMousePositionForEvent(e).y - Love.Helpers.DragDrop.getMousePositionForDragStart().y);
            var newTop = this._cropperMoverStart + change;

            newTop = Math.max(newTop, 0);
            newTop = Math.min(newTop, this._$cropBoxMoverContainer.height() - this._$cropBoxMover.height());

            this._setCropperPosition(newTop);
        },

        _handleCropperMoverMouseUp: function(e) {

            $('body').off('touchend.croppermover', this._handleCropperMoverMouseUp);
            $('body').off('touchmove.croppermover', this._handleCropperMoverMouseMove);
            $('body').off('mouseup.croppermover', this._handleCropperMoverMouseUp);
            $('body').off('mousemove.croppermover', this._handleCropperMoverMouseMove);
        },

        _handleCropperZoom: function(e) {

            e.preventDefault();

            var ratio = Math.min(e.ratio, this._maxRatio);
            ratio = Math.max(ratio, this._minRatio);

            var canvasData = this.$cropper.cropper('getCanvasData');

            var newWidth = canvasData.naturalWidth * ratio;
            var newHeight = canvasData.naturalHeight * ratio;

            canvasData.left -= (newWidth - canvasData.width) * ((this._getModelFocusPoint().x - canvasData.left) / canvasData.width);
            canvasData.top -= (newHeight - canvasData.height) * ((this._getModelFocusPoint().y - canvasData.top) / canvasData.height);

            this.$cropper.cropper('setCanvasData', {

                left: canvasData.left,
                top: canvasData.top,
                width: newWidth,
                height: newHeight
            });

            this._zoom = ratio;

            this._updateFocusPoint();
            this._updateSlider();

            if (!this._zoomingToFocusPoint)
                this._centerFocusPoint();
        },

        _handleDragEnter: function(e) {

            this.$('.focuspoint').addClass('hidden');

            this.$('.cover-cropper-drop-overlay .icon').removeClass('icon-image');
            this.$('.cover-cropper-drop-overlay .icon').removeClass(Love.Helpers.DragDrop.getHeartsIconClasses().join(' '));

            var amount = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            this.$('.cover-cropper-drop-overlay .icon').addClass(Love.Helpers.DragDrop.getHeartsIconClass(amount));

            var display = Love.t('common:dragdrop.feedback_drop', {count: amount});
            this.$('.cover-cropper-drop-overlay .drop-feedback').text(display);

            this.$('.cover-cropper-drop-overlay').removeClass('hidden');
        },

        _handleDragEnterCropper: function(e) {

            this.$('.cover-cropper').addClass('hover');

            var display = Love.t('common:dragdrop.feedback_drop_close', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.cover-cropper-drop-overlay .drop-feedback').text(display);
        },

        _handleDragLeave: function(e) {

            this.$('.focuspoint').removeClass('hidden');

            this.$('.cover-cropper-drop-overlay').addClass('hidden');
        },

        _handleDragLeaveCropper: function(e) {

            this.$('.cover-cropper').removeClass('hover');

            var display = Love.t('common:dragdrop.feedback_drop', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.cover-cropper-drop-overlay .drop-feedback').text(display);
        },

        _handleDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault(); // Default is to open as link on drop.

            var count = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            if (count > 0) {

                var self = this;

                _.each(Love.Helpers.DragDrop.getDataItems(e.originalEvent), function(item, index) {

                    var dataType = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent, index);

                    if (dataType === 'image') {

                        var reader = new FileReader();

                        reader.addEventListener('load', function() {

                            var imageModel = new Love.Models.ImageModel({

                                imageSource: 'url',
                                imageURL: reader.result
                            });

                            self._addNewImage(imageModel, true);

                        }, false);

                        reader.readAsDataURL(item.getAsFile());
                    }

                }, this);
            }
        },

        _handleImageDropMultiple: function(file, index) {

            // Add any extra dropped images to the grid.

            var model = new Love.Models.ImageModel();
            var self = this;

            return model.uploadFile(file)

                .done(function() {

                    self._addNewImage(model);
                });
        },

        _handleRemove: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm({

                    imageModel: new Love.Models.ImageModel(),
                    ogImageModel: new Love.Models.ImageModel()
                });

            this.close();
        },

        _handleSliderClick: function(e, amount) {

            var $coverSlider = this.$('.cover-slider');
            var current = parseInt($coverSlider.val());

            $coverSlider.val(current + amount);
            $coverSlider.trigger('input');
        },

        _handleSliderZoom: function(e) {

            var zoom = e.target.value / 100 * this._originalRatio;
            this.$cropper.cropper('zoomTo', zoom);
        },

        _handleThumbnailClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var index = parseInt($(e.target).closest('[data-index]').attr('data-index'));

            this.options.data.imageModel = this.options.data.images[index];
            this.options.data.ogImageModel = null;

            this._updateDisplayedImage();
        },

        _handleToggleDescription: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this._setDescriptionArrow();

            Love.Helpers.LocalStorageSession.setObject('common_popups_cover_image_description_hidden', !this.$('.toggle-description').hasClass('open'));
        },

        _imageToCanvasCoords: function(point) {

            return {

                y: point.y * this._zoom,
                x: point.x * this._zoom
            };
        },

        _registerCustomEvents: function() {

            _.bindAll(this, '_handleDragEnter');
            _.bindAll(this, '_handleDragEnterCropper');
            _.bindAll(this, '_handleDragLeave');
            _.bindAll(this, '_handleDragLeaveCropper');

            Love.Helpers.DragDrop.registerEnterLeaveEvents($('body'), this.cid, this._handleDragEnter, this._handleDragLeave, true);
            Love.Helpers.DragDrop.registerEnterLeaveEvents(this.$('.cover-cropper'), this.cid, this._handleDragEnterCropper, this._handleDragLeaveCropper, true);
        },

        _setCropperContainerSize: function() {

            var width, height;

            width = 550;
            height = width / this.options.data.containerRatio;

            width = Math.min(width, $(window).width() * 0.7);
            height = Math.min(height, $(window).height() * 0.6);

            /*if (this.$el.hasClass('popup-full-screen')) {

             if (this.isPortrait()) {

             width = this.$('.cover-cropper-container').width();
             //height = width / this.options.data.cropperRatio;
             height = width / this.options.data.containerRatio; //4:3
             }
             else {

             height = this.$('.cover-cropper-container').height();
             width = height * this.options.data.containerRatio;
             }
             }
             else {

             if (this.isPortrait()) {

             //width = $(window).width() * 0.6;
             width = 550;
             height = width / this.options.data.containerRatio;
             }
             else {

             //height = $(window).height() * 0.5;
             //width = height * this.options.data.containerRatio;
             width = 550;
             height = width / this.options.data.containerRatio;
             }

             if (this.options.data.images.length > 0)
             this.$('.cover-available-images').css('height', height);
             }*/
            /*width = this.$('.cover-cropper-container').innerWidth() - this._$cropBoxMoverContainer.outerWidth();
             var imageData = this.$cropper.cropper('getImageData');
             var aspectRatio = imageData.aspectRatio ? imageData.aspectRatio : (4/3);
             height = width / aspectRatio;*/

            if (this.isFullScreen()) {

                var horizontalSpace = this.$('.cover-cropper-container').innerWidth() - this.$('.cover-cropper-mover-container').outerWidth(true);
                width = Math.min(width, horizontalSpace);

                var verticalSpace = this.$('.popup-content').innerHeight() - this.$('.cover-slider-container').outerHeight(true);

                if (this.isPortrait())
                    verticalSpace -= this.$('.cover-available-images').outerHeight(true);

                height = Math.min(height, verticalSpace);
            }

            this.$('.cover-cropper').css('width', width);
            this.$('.cover-cropper').css('height', height);

            this._$cropBoxMoverContainer.css('height', height);
        },

        _setCropperPosition: function(value) {

            this.$cropper.cropper('setCropBoxData', {top: value});
            this._updateCropBoxMover();
        },

        _setDescriptionArrow: function() {

            var isOpen = this.$('.toggle-description').toggleClass('open').hasClass('open');

            if (isOpen) {

                var button = this.$('.toggle-description-button');
                var left = button.offset().left + button.width() / 2 - 1; // With a small correction

                var arrowLeft = left - this.$('.toggle-description').offset().left;

                this.$('.toggle-arrow').css('left', arrowLeft);
            }
        },

        _setModelCroppedArea: function(data) {

            if (!this.options.data.canChangeCroppedArea) return;

            if (!this.options.data.ogImageModel) {

                // Client side, we want to store the cropped area before the ogImage has been created.
                // This enables us to keep a consistent user interface, while only creating the actual image when necessary.
                // Therefore, if the images doesn't exist yet, we just create an empty image model to hold the cropped area data.

                this.options.data.ogImageModel = new Love.Models.ImageModel();
            }

            this.options.data.ogImageModel.setCroppedArea(data);
        },

        _setModelFocusPoint: function(x, y) {

            if (!this.options.data.canChangeFocusPoint) return;

            if (this.options.data.imageModel) {

                // We store the focus point as a percentage on server side.

                var canvasData = this.$cropper.cropper('getCanvasData');
                var percPoint = {

                    x: Math.round(x / canvasData.naturalWidth * 100),
                    y: Math.round(y / canvasData.naturalHeight * 100)
                };

                this.options.data.imageModel.setFocusPoint(percPoint);
            }
        },

        _transitionToPosition: function(originalData, newData) {

            this.$('.cropper-container').removeClass('transition');

            this.$cropper.cropper('moveTo', -originalData.move.left, -originalData.move.top);
            this.$cropper.cropper('zoomTo', originalData.zoom);

            // Add the transition class with the defer, so the zoom and move to original position does not get a transition.

            var self = this;
            _.defer(function() {

                self.$('.cropper-container').addClass('transition');
                self.$cropper.cropper('zoomTo', newData.zoom);

                // After 300ms (transition lasts 250ms), remove transition class.

                setTimeout(function() {

                    self.$('.cropper-container').removeClass('transition');

                }, 300);
            });
        },

        _unregisterCustomEvents: function() {

            Love.Helpers.DragDrop.unregisterEnterLeaveEvents($('body'), this.cid);
            Love.Helpers.DragDrop.unregisterEnterLeaveEvents(this.$('.cover-cropper'), this.cid);

            $('body').off('mouseup.croppermover', this._handleCropperMoverMouseUp);
            $('body').off('movemove.croppermover', this._handleCropperMoverMouseMove);
            $('body').off('touchend.croppermover', this._handleCropperMoverMouseUp);
            $('body').off('touchmove.croppermover', this._handleCropperMoverMouseMove);
        },

        _updateCropBoxMover: function() {

            var cropBoxData = this.$cropper.cropper('getCropBoxData');

            this._$cropBoxMover.css('height', cropBoxData.height);
            this._$cropBoxMover.css('top', cropBoxData.top);
        },

        _updateDisplayedControls: function() {

            if (this.options.data.images.length < 1) {

                this.$('.common-content-slider-container').addClass('hidden');
                this.$('.common-image-upload-container').addClass('full');
                this.$('.cover-cropper-container').addClass('hidden');
            }
            else {

                this.$('.common-content-slider-container').removeClass('hidden');
                this.$('.common-image-upload-container').removeClass('full');
                this.$('.cover-cropper-container').removeClass('hidden');

                if (this.options.data.canChangeCroppedArea) {

                    this.$('.cover-cropper-wrapper').removeClass('no-controls');
                    this.$('.cover-cropper-mover-container').removeClass('hidden');
                    this.$('.cover-slider-container').removeClass('hidden');
                }
                else {

                    this.$('.cover-cropper-wrapper').addClass('no-controls');
                    this.$('.cover-cropper-mover-container').addClass('hidden');
                    this.$('.cover-slider-container').addClass('hidden');
                }
            }
        },

        _updateDisplayedImage: function() {

            var imageModel = this.options.data.imageModel;
            var imageIndex = _.findIndex(this.options.data.images, function(model) {

                // We can't match on reference here, as the image models are all deep cloned.

                return model.getImageView() === imageModel.getImageView();
            });

            // Mark the active image in the available images list.

            this.$('.cover-available-images .grid-item').removeClass('active');
            this.$('.cover-available-images .grid-item[data-index="' + imageIndex + '"]').addClass('active');

            // Display the active image in the cropper.

            var parameters = [];

            parameters.push({name: 'image_correct_orientation', value: true});

            if (this._cropperCreated) {

                this.$cropper.cropper('replace', Love.Helpers.Proxy.getProxyUrl(imageModel.getImageView(), parameters));
            }
            else {

                this.$cropper.attr('src', Love.Helpers.Proxy.getProxyUrl(imageModel.getImageView(), parameters));
                this._createCropper();
            }

            this.$('.cover-slider').val('0');
        },

        _updateFocusPoint: function(x, y) {

            // x and y are defined in pixels, relative to the original image size.

            var current = this._getModelFocusPoint();

            x = _.isUndefined(x) ? current.x : x;
            y = _.isUndefined(y) ? current.y : y;

            // The focus point coordinates are relative to the original image. Also, the focus point is positioned on the same level als the canvas.
            // To display them on the canvas, we have to correct for the zoom factor and the canvas position.

            var trueFocusPoint = this._imageToCanvasCoords({x: x, y: y});
            var canvasData = this.$cropper.cropper('getCanvasData');

            trueFocusPoint.x += canvasData.left;
            trueFocusPoint.y += canvasData.top;

            this.$focusPoint.css({top: trueFocusPoint.y, left: trueFocusPoint.x});

            this._setModelFocusPoint(x, y);
        },

        _updateSlider: function() {

            var value = this._zoom / this._originalRatio * 100;
            this.$('.cover-slider').val(value);
        },

        _zoomToFocusPoint: function() {

            this._zoomingToFocusPoint = true;

            // Fully zoom in. This allows for easily moving the focus point to the center.

            var originalData = {

                zoom: this._zoom,
                move: this.$cropper.cropper('getCropBoxData')
            };

            this.$cropper.cropper('zoomTo', this._maxRatio);

            var cropBoxData = this.$cropper.cropper('getCropBoxData');

            var cropBoxCenter = {

                x: cropBoxData.width / 2,
                y: cropBoxData.height / 2
            };

            var focusPoint = this._getModelFocusPoint();
            var trueFocusPoint = this._imageToCanvasCoords(focusPoint);

            var canvasData = this.$cropper.cropper('getCanvasData');

            var remainderX, remainderY;
            var remainderTop = Math.max(trueFocusPoint.y - cropBoxCenter.y, 0);
            var remainderBottom = Math.max(canvasData.height - trueFocusPoint.y - cropBoxCenter.y, 0);
            var remainderLeft = Math.max(trueFocusPoint.x - cropBoxCenter.x, 0);
            var remainderRight = Math.max(canvasData.width - trueFocusPoint.x - cropBoxCenter.x, 0);

            // Use smallest remainder, as that's what determines the space to expand.
            // Multiply this by two, because it should occupy that much space on BOTH sides.

            remainderY = (remainderTop < remainderBottom) ? remainderTop * 2 : remainderBottom * 2;
            remainderX = (remainderLeft < remainderRight) ? remainderLeft * 2 : remainderRight * 2;

            // Canvas height or width minus the space it can still occupy (as calculated above) gives us what the canvas should become.
            // Dividing this by the natural height or width gives us the zoom level it should go to.

            var ratioX = (canvasData.width - remainderX) / canvasData.naturalWidth;
            var ratioY = (canvasData.height - remainderY) / canvasData.naturalHeight;

            // Use the larger ratio.

            var ratio;

            if (ratioX === ratioY || ratioX > ratioY)
                ratio = ratioX;
            else
                ratio = ratioY;

            // For transitions, we go from the original position to the new position.

            var newData = {zoom: ratio};

            this._transitionToPosition(originalData, newData);

            this._zoomingToFocusPoint = false;

            this._centerFocusPoint();
            this._calculateCropperPosition();
        }
    });

})(Love);