(function(Love) {

    Love.Views.CalendarWeekView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CalendarWeekView',

        events: {

            'click .moby-calendar': '_createContent',
            'click .calendar-view-header ul [data-date]': '_handleClickDay',
            'mouseleave .calendar-view-content .base-day-calendar': '_hideCurrentTime',
            'mouseenter .calendar-view-content .base-day-calendar': '_showCurrentTime',
            'mousemove .calendar-view-content .base-day-calendar': '_updateCurrentTime'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this,

                'scrollToTime',
                '_setCurrentTimeLine'
            );

            this.viewSizeReset = _.debounce(_.bind(this.viewSizeReset, this), 50);
            this._updateCurrentTime = _.throttle(_.bind(this._updateCurrentTime, this), 50);

            this.parentView = this.options.parentView;
            this.collection =  null;

            this.dayCollections = [];
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.calendar_week)({

                isMobile: this.parentView.isMobile()
            }));
            this.$el.addClass('calendar-view-week');

            this.updateRenderedView();

            $(window).off('resize', this.viewSizeReset);
            $(window).on('resize', this.viewSizeReset);

            this.stopListening(this.parentView, 'createContent');
            this.listenTo(this.parentView, 'createContent', _.bind(function(e) {

                this._createContent(e.originalEvent, e.fromHeader);

            }, this));

            var self = this;
            this._currentTimeLineInterval = window.setInterval(function() {

                self._greyoutPast();
                self._setCurrentTimeLine();

                // Update the header days to reflect any changes at midnight.

                self.parentView.updateWeekViewSelectors();

            }, 60 * 1000); // Every minute.

            this.$calendar = this.$el.closest('.calendar');
            this.$calendarCurrentTime = this.$calendar.find('.calendar-current-time');
            this.$timeline = this.$('.timeline');

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this.$el.removeClass('calendar-view-week');

            $(window).off('resize', this.viewSizeReset);
            window.clearInterval(this._currentTimeLineInterval);
        },

        emptyDOM: function() {

            _.each(this.dayCollections, function(day) {

                _.each(day.eventViews, function(eventView) { eventView.close(); });
                _.each(day.eventViewsBackground, function(eventView) { eventView.close(); });
                _.each(day.eventViewsWholeDay, function(eventView) { eventView.close(); });

                day.eventViews = [];
                day.eventViewsBackground = [];
                day.eventViewsWholeDay = [];
            });

            this.$('.events-container').empty();
            this.$('.whole-day-events').addClass('hidden');
        },

        populateDomFromCollection: function(collection) {

            this.collection = collection;

            var eventCollection = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return !(model.get('isGoogleCal') || model.get('type') === 'event') && !model.get('isWholeDay');}));
            var eventCollectionBackground = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return (model.get('isGoogleCal') || model.get('type') === 'event') && !model.get('isWholeDay');}));
            var eventCollectionWholeDay = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return model.get('isWholeDay');}));

            var hasWholeDayEvents = false;
            var highestWholeDayCount = 0;

            _.each(this.dayCollections, function(day) {

                var dateStart = day.date.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
                var dateEnd = dateStart.clone().add(1, 'days');

                var subset = eventCollection.filter(function(model) {return Love.Helpers.General.isOverlappingRange(model.get('date'), model.get('dateEnd'), dateStart, dateEnd);});
                var subsetBackground = eventCollectionBackground.filter(function(model) {return Love.Helpers.General.isOverlappingRange(model.get('date'), model.get('dateEnd'), dateStart, dateEnd);});
                var subsetWholeDay = eventCollectionWholeDay.filter(function(model) {return Love.Helpers.General.isOverlappingRange(model.get('date'), model.get('dateEnd'), dateStart, dateEnd);});

                // Whole day events are added to a separate view.

                _.each(subsetWholeDay, function(model) {

                    var eventView = this._addEventView(model, day, dateStart, dateEnd);
                    day.eventViewsWholeDay.push(eventView);

                }, this);

                // Background events are added first, to allow graceful overlapping.

                _.each(subsetBackground, function(model) {

                    var eventView = this._addEventView(model, day, dateStart, dateEnd);
                    day.eventViewsBackground.push(eventView);

                }, this);

                _.each(subset, function(model) {

                    var eventView = this._addEventView(model, day, dateStart, dateEnd);
                    day.eventViews.push(eventView);

                }, this);

                // Once the DOM is populated, resize and sort based on overlaps.

                _.each(day.eventViews, function(eventView, index, list) {

                    eventView.eventMarkup();
                    eventView.eventHandleOverlaps(list, index);
                });

                _.each(day.eventViewsBackground, function(eventView, index, list) {

                    eventView.eventMarkup();
                    eventView.eventHandleOverlaps(list, index);
                });

                if (day.eventViewsWholeDay.length > 0)
                    hasWholeDayEvents = true;

                if (day.eventViewsWholeDay.length > highestWholeDayCount)
                    highestWholeDayCount = day.eventViewsWholeDay.length;

            }, this);

            if (hasWholeDayEvents) {

                var self = this;

                $('.calendar-view-header-container').addClass('scrollbar');

                if (highestWholeDayCount > 4)
                    this.$('.whole-day-events .base-day-calendar').css('height', 22 * highestWholeDayCount); // TODO JEROEN: Moet dit nog bij de resize ook?
                else
                    this.$('.whole-day-events .base-day-calendar').css('height', 'auto');

                this.$('.whole-day-events').removeClass('hidden');
                this.$('.whole-day-events').removeClass('scrollbar-outside');

                this.$('.whole-day-events').hide().slideDown('fast', function() {

                    if (!self.parentView.isMobile())
                        self.$('.whole-day-events').addClass('scrollbar-outside');

                    _.each(self.dayCollections, function(day) {

                        _.each(day.eventViewsWholeDay, function(eventView, index, list) {eventView.eventMarkup();});
                    });
                });
            }
            else
                this.$('.whole-day-events').addClass('hidden');
        },

        scrollToTime: function(smooth) {

            Love.Helpers.Calendar.scrollToTime(this.$calendar, this.firstDayOfWeek.isSame(moment(), 'week'), smooth);
        },

        updateRenderedView: function() {

            this.firstDayOfWeek = this.parentView.selectedDay.clone().startOf('isoweek');

            this.$('.calendar-view-header-container').removeClass('scrollbar');

            this._createDayViewsForWeek(this.firstDayOfWeek);

            this._greyoutPast();
            _.defer(this._setCurrentTimeLine);

            this._calendarPopupOpen = false;
            this._selectedTime = this.firstDayOfWeek.clone();

            this.parentView.updateWeekViewSelectors();

            this.$timeline = this.$('.timeline');
        },

        viewSizeReset: function() {

            this.parentView.updateWeekViewSelectors(); // Responsive titles.

            _.each(this.dayCollections, function(day) {

                _.each(day.eventViews, function(eventView, index, list) {eventView.eventWidthAndMargin(list, index);});
                _.each(day.eventViewsBackground, function(eventView, index, list) {eventView.eventWidthAndMargin(list, index);});
                _.each(day.eventViewsWholeDay, function(eventView, index, list) {eventView.eventMarkup();});
            });

            this._setCurrentTimeLine();
        },

        _addEventView: function(model, day, dateStart, dateEnd) {

            var view = this.addSubView(Love.Helpers.Calendar.baseEventViewFactory(model, dateStart, dateEnd));
            var element = view.render().$el;

            if (model.get('isWholeDay')) {

                this.$('.whole-day-events [data-day="' + day.dayOfWeek + '"] .events-container').append(element);
            }
            else
                this.$('.calendar-week-day[data-day="' + day.dayOfWeek + '"] .events-container').append(element);

            return view;
        },

        _createContent: function(e, fromHeader) {

            if (e.button === 0 || fromHeader) { // Left

                if (e && e.preventDefault) e.preventDefault();

                // Deactivate any activated calendar events.

                this.$calendar.find('.calendar-base-event').removeClass('active');

                if (Love.appView.hasPopups()) return;

                var isWholeDayClick = fromHeader;

                if (!fromHeader) {

                    var isEventClick = ($(e.target).closest('.calendar-base-event').length > 0);
                    if (isEventClick) return;

                    isWholeDayClick = $(e.target).closest('.whole-day-events').length > 0;
                }

                var date;

                if (isWholeDayClick) {

                    var dayCollection;

                    if (fromHeader) {

                        var firstDayOfWeek = this.dayCollections[0];
                        var today = moment();

                        if (today.isSame(firstDayOfWeek.date, 'week')) {

                            dayCollection = this.dayCollections[today.day() - 1];
                        }
                        else
                            dayCollection = firstDayOfWeek;
                    }
                    else
                        dayCollection = this.dayCollections[parseInt($(e.target).closest('[data-day]').attr('data-day')) - 1];

                    date = dayCollection.date;
                }
                else {

                    // Update the current time indicators, in case the mouse wasn't moved yet.

                    this._updateCurrentTime(e);

                    date = this._selectedTime;
                }

                var self = this;
                var popup = new Love.Views.CalendarAddContentPopupView({

                    callbacks: {

                        onClose: function() {self._calendarPopupOpen = false;}
                    },
                    data: {date: date, showTime: !isWholeDayClick},
                    positioning: fromHeader ? {

                            attachToElement: $(e.target).closest('.calendar-create-content'),
                            positionAt: 'element'

                        } : {

                            //attachToElement: $(e.target).closest('.base-day-calendar').find('.timeline .timeline-indicator').first(),
                            offsetX: e.clientX,
                            offsetY: e.clientY,
                            positionAt: 'mouse'
                        }
                });

                popup.showPopup();

                this._calendarPopupOpen = true;
            }
        },

        _createDayViewsForWeek: function(startDate) {

            this.dayCollections = [];

            for (var i = 0; i < 7; i++) {

                var date = startDate.clone().add(i, 'days');

                var day = {

                    date: date,
                    dayOfWeek: i + 1,
                    eventViews: [],
                    eventViewsBackground: [],
                    eventViewsWholeDay: []
                };

                this.dayCollections.push(day);

                var $currentDay = this.$('.calendar-week-day[data-day="' + day.dayOfWeek + '"]');
                var $currentWholeDay = this.$('.whole-day-events [data-day="' + day.dayOfWeek + '"]');

                $currentDay.html(_.template(Love.Templates.calendar_base_day_moby)(day));
                $currentDay.attr('data-date', day.date.format('YYYY-MM-DD'));
                $currentDay.removeClass('current-day');

                $currentWholeDay.attr('data-date', day.date.format('YYYY-MM-DD'));
                $currentWholeDay.removeClass('current-day');

                var now = moment();

                if (date.isSame(now, 'day')) {

                    $currentDay.addClass('current-day');
                    $currentWholeDay.addClass('current-day');
                }
            }
        },

        _greyoutPast: function() {

            // TODO RENS: ook voor whole day event-containers.

            _.each(this.$('.calendar-week-day'), function(day) {

                var dayCollection = this.dayCollections[parseInt($(day).attr('data-day')) - 1];
                var cal = $(day).find('.base-day-calendar');

                Love.Helpers.Calendar.greyoutPastHourblocks(cal, dayCollection.date);

            }, this);
        },

        _handleClickDay: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('[data-date]');

            if (element.length > 0) {

                var targetString = element.attr('data-date');
                var targetDate = moment(targetString);

                this.parentView.setView('day');
                this.parentView.goToDate(targetDate);
            }
        },

        _hideCurrentTime: function() {

            if (this._calendarPopupOpen) return;

            Love.Helpers.Calendar.hideCurrentTime(this.$calendar);
        },

        _setCurrentTimeLine: function() {

            Love.Helpers.Calendar.setCurrentTimeLine(this.$calendar, this.firstDayOfWeek.isSame(moment(), 'week'));

            var weekDay = this.$('.calendar-week-day[data-date="' + moment().format('YYYY-MM-DD') + '"]');
            var dayNum = parseInt(weekDay.attr('data-day'));
            var dayWidth = weekDay.outerWidth(true);
            var left = dayWidth * (dayNum - 1);

            this.$('.calendar-current-time-line .calendar-current-time-circle').css('left', left);
        },

        _showCurrentTime: function(e) {

            if (this._calendarPopupOpen) return;

            Love.Helpers.Calendar.showCurrentTime(this.$calendar, $(e.target).closest('.base-day-calendar').find('.timeline'));
        },

        _updateCurrentTime: function(e) {

            if (this._calendarPopupOpen) return;

            var $eventGrid = this.$calendar.find('.calendar-view-content-container');
            var timelinePosition = Math.round(e.clientY + $eventGrid.scrollTop() - $eventGrid.offset().top);
            var hourSize = this.$('.hourblock').outerHeight();
            var quarter = hourSize / 6;
            var top = quarter * Math.round(timelinePosition / quarter);

            var dayCollection = this.dayCollections[parseInt($(e.target).closest('[data-day]').attr('data-day')) - 1];
            this._selectedTime = dayCollection.date.clone();

            var time = top / hourSize;
            var hours = Math.floor(time);
            var minutes = Math.round((time % 1) * 60);

            this._selectedTime.hours(hours);
            this._selectedTime.minutes(minutes);

            this.$calendarCurrentTime.text(this._selectedTime.format('HH:mm'));
            this.$timeline.css('top', top);
        }
    });

})(Love);