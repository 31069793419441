(function(Love) {

    Love.Views.CommonContentTextWidgetView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentTextWidgetView',

        defaults: function() {

            var editorId = 'editor-' + this.cid;

            return {

                editorId: editorId,
                editorSelector: '#' + editorId,
                enableToolbar: true,
                settings: null
            };
        },

        events: {

            'input .content': '_handleContentChange',
            'drop .content': '_handleContentChangeDrop',
            'cut .content': '_handleContentChangePaste',
            'paste .content': '_handleContentChangePaste',
            'keydown .content': '_handleContentKeyDown',
            'focusin .content': '_handleContentFocusIn',
            'focusout .content': '_handleContentFocusOut'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'hasContent');

            if (this.options.enableToolbar)
                this.toolbarView = this.addSubView(new Love.Views.CommonTextWidgetToolbarView());
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_widgets_text)({

                content: Love.Helpers.General.htmlSanitize(this.options.settings.contentHTML),
                editorId: this.options.editorId
            }));

            tinymce.init({

                allow_unsafe_link_target: true,
                //content_css: '/static/css/tinymce.css', // Only necessary if using form / iFrame mode.
                //convert_urls: false,
                formats: {

                    bold: {inline: 'strong'},
                    italic: {inline: 'em'}
                },
                inline: true,
                //keep_styles: false,
                menubar: false,
                selector: this.options.editorSelector,
                setup: function(editor) {

                    /*
                     editor.on('input', function(e) {

                     _.defer(function() {

                     self._setContent(editor.getContent());
                     });
                     });
                     */
                },
                statusbar: false,
                toolbar: false,
                //urlconverter_callback: _.identity
                valid_elements: 'h1,h2,h3,h4,h5,h6,p,a[href|name|target],ul,ol,li,b,i,strong,em,code,br,div'
            });

            this.editor = tinymce.get(this.options.editorId);

            if (this.options.enableToolbar) {

                this.toolbarView.options.editor = this.$(this.options.editorSelector);
                this.toolbarView.options.editorTiny = this.editor;
                this.toolbarView.setElement(this.$('.toolbar')).render();

                this.stopListening(this.toolbarView, 'contentChanged', this._handleContentChange);
                this.listenTo(this.toolbarView, 'contentChanged', this._handleContentChange);

                this.$el.closest('.widget-container').addClass('has-text-toolbar');
            }

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this.editor.destroy();
        },

        hasContent: function() {

            return value; //return (this.options.settings && !_.isEmpty(this.options.settings.contentHTML));
        },

        _handleContentChange: function(e) {

            _.defer(_.bind(function() {

                var content = this.editor.getContent();
                this._setContent(content);

            }, this));
        },

        _handleContentChangeDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var content = e.originalEvent.dataTransfer.getData('text/html');

            content = Love.Helpers.General.htmlSanitize(content);
            this.editor.setContent(content);

            this._setContent(content);
        },

        _handleContentChangePaste: function(e) {

            // Due to the timing of the paste event, we have to defer execution.

            _.defer(_.bind(function() {

                var content = Love.Helpers.General.htmlSanitize(this.editor.getContent());
                this.editor.setContent(content);

                this._setContent(content);

            }, this));
        },

        _handleContentFocusIn: function(e) {

            this.$(this.options.editorSelector).addClass('write-mode');
        },

        _handleContentFocusOut: function(e) {

            this.$(this.options.editorSelector).removeClass('write-mode');
        },

        _handleContentKeyDown: function(e) {

            if (_.indexOf([8, 13, 46], e.keyCode) > -1) {

                // Somehow inserting a new paragraph or <br /> doesn't trigger the input event. (13)
                // The same goes for backspace (8) and delete (46).
                // Therefore, we trigger it here manually.

                this._handleContentChange();
            }
        },

        _raiseChanged: function() {

            this.trigger('changed', {settings: this.options.settings});
        },

        _setContent: function(value) {

            this.options.settings.contentHTML = value;

            this._raiseChanged();
            this._setPlaceholder(_.isEmpty(value));
        },

        _setPlaceholder: function(show) {

            if (show)
                this.$(this.options.editorSelector).addClass('placeholder');
            else
                this.$(this.options.editorSelector).removeClass('placeholder');
        }
    });

})(Love);