(function(Love) {

    Love.Views.HighlightedContentSetSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.HighlightedContentSetSidebarView',

        initialize: function(options) {

            Love.Views.BaseSidebarView.prototype.initialize.call(this, options);

            this.editModel = options.editModel;
            this.viewState = this.options.viewState;
        },

        render: function() {

            var context = _.extend(_.clone(this.editModel.attributes), this.viewState.attributes);
            this.$el.html(_.template(Love.Templates.highlighted_content_set_sidebar)(context));

            this.stopListening(this.viewState, 'change:changedByUser', this._updateSavedText);

            this.stopListening(this.editModel, 'change', this._updateSavedText);

            // Now the base layout has been rendered, we first allow some functions from the BaseSidebarView to be executed
            // before continuing the rendering process.

            _.defer(_.bind(function() {

                this._updateSavedText();

                this.listenTo(this.viewState, 'change:changedByUser', this._updateSavedText);

                this.listenTo(this.editModel, 'change', this._updateSavedText);

                this.trigger('rendered');

            }, this));

            return Love.Views.BaseSidebarView.prototype.render.call(this, true); // We'll trigger the rendered event later.
        },

        _updateSavedText: function() {

            var changedAttrs = this.editModel.changedAttributesJSON(this.editModel.serverAttributes);
            var isNew = this.editModel.isNew();

            if (!isNew && !changedAttrs)
                this.$('[data-type="saved"] .sidebar-row-content span').html("Your changes have been saved!");
            else
                this.$('[data-type="saved"] .sidebar-row-content span').html("Your changes have <em>not been saved</em>.");
        }
    });

})(Love);