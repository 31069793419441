(function(Love) {

    Love.Factories.BlogPostFactory = {

        createFromPageModel: function(pageModel) {

            return new Love.Models.BlogPostModel({

                revisions: _.each(_.clone(pageModel.get('revisions')), function(revision, lang) {

                    revision.id = '';
                }),
                storylines: _.clone(pageModel.get('storylines'))
            });
        }
    };

})(Love);