(function (Love) {

    Love.Views.CalendarDurationEventView = Love.Views.CalendarBaseEventView.extend({

        objectClassName: 'Love.Views.CalendarDurationEventView',

        render: function () {

            var parameters = Love.Helpers.Calendar.getParametersForEvent(this.model, this.displayDateStart, this.displayDateEnd);
            this.$el.html(_.template(Love.Templates.calendar_events_duration)(parameters));

            this.$el.addClass('calendar-base-event');
            this.$el.addClass('calendar-duration-event');
            this.$el.addClass(this._getDuration());
            this.$el.addClass(parameters.color);
            this.$el.attr('data-id', this.model.id);
            this.$el.data('datetime', parameters.date.format());

            if (this.isDraggable) this.enableDragDrop();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        eventStyling: function () {

            Love.Views.CalendarBaseEventView.prototype.eventStyling.call(this);

            // HEADER

            var header = this.$('.calendar-event-header');
            var icon = this.$('.calendar-event-icon');
            var title = this.$('.calendar-event-title');
            var time = this.$('.calendar-event-time');

            // NOTE: hidden (display: none;) objects have no width. This function is called when resizing,
            // so we have to support both hiding and showing elements again.

            // Enable width measuring.

            title.removeClass('hidden');
            time.removeClass('hidden');

            var headerWidth = header.width();
            var iconWidth = icon.outerWidth(true);
            var titleWidth = title.outerWidth(true);
            var timeWidth = time.outerWidth(true);

            // Check if the time element fits. This has priority over the title.

            if (iconWidth + timeWidth <= headerWidth) {

                time.removeClass('hidden');

                // If the time fits, check if there's also room to display the title.

                if (iconWidth + titleWidth + timeWidth <= headerWidth)
                    title.removeClass('hidden');
                else
                    title.addClass('hidden');
            }
            else {

                time.addClass('hidden');
                title.addClass('hidden');
            }

            // TEXT

            var text = this.$('.calendar-event-text');

            // TODO RENS: ook tekst moet verdwijnen als het event te smal is.
        },

        _getDuration: function () {

            var duration = this.end.diff(this.start, 'minutes');

            if (duration < 30)
                return 'duration-short';
            else if (duration < 60)
                return 'duration-medium';
            else
                return 'duration-long';
        }
    });

})(Love);