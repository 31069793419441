(function(Love) {

    Love.Views.CalendarDayView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CalendarDayView',

        events: {

            'click .moby-calendar': '_createContent',
            'mouseleave .calendar-view-content .base-day-calendar': '_hideCurrentTime',
            'mouseenter .calendar-view-content .base-day-calendar': '_showCurrentTime',
            'mousemove .calendar-view-content .base-day-calendar': '_updateCurrentTime'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this,

                'scrollToTime',
                '_setCurrentTimeLine'
            );

            this.viewSizeReset = _.debounce(_.bind(this.viewSizeReset, this), 50);
            this._updateCurrentTime = _.throttle(_.bind(this._updateCurrentTime, this), 50);

            this.parentView = this.options.parentView;
            this.collection = null;

            this.dayCollection = {

                date: this.parentView.selectedDay.clone().hours(0).minutes(0).seconds(0).milliseconds(0),
                eventViews: [],
                eventViewsBackground: [],
                eventViewsWholeDay: [],
                separateEventViews: [],
                separateEventViewsBackground: [],
                separateEventViewsWholeDay: []
            };
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.calendar_day)({

                isMobile: this.parentView.isMobile()
            }));
            this.$el.addClass('calendar-view-day');

            this.updateRenderedView();

            $(window).off('resize', this.viewSizeReset);
            $(window).on('resize', this.viewSizeReset);

            this.stopListening(this.parentView, 'createContent');
            this.listenTo(this.parentView, 'createContent', _.bind(function(e) {

                this._createContent(e.originalEvent, e.fromHeader);

            }, this));

            var self = this;
            this._currentTimeLineInterval = window.setInterval(function() {

                self._greyoutPast();
                self._setCurrentTimeLine();

                // Update the header days to reflect any changes at midnight.

                if (self.parentView.updateDayViewSelectors)
                    self.parentView.updateDayViewSelectors();

            }, 60 * 1000); // Every minute.

            this.$calendar = this.$el.closest('.calendar');
            this.$calendarCurrentTime = this.$calendar.find('.calendar-current-time');
            this.$timeline = this.$('.timeline');

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this.$el.removeClass('calendar-view-day');

            $(window).off('resize', this.viewSizeReset);
            window.clearInterval(this._currentTimeLineInterval);
        },

        emptyDOM: function() {

            var day = this.dayCollection;

            _.each(day.eventViews, function(eventView) { eventView.close(); });
            _.each(day.eventViewsBackground, function(eventView) { eventView.close(); });
            _.each(day.eventViewsWholeDay, function(eventView) { eventView.close(); });
            _.each(day.separateEventViews, function(eventView) { eventView.close(); });
            _.each(day.separateEventViewsBackground, function(eventView) { eventView.close(); });
            _.each(day.separateEventViewsWholeDay, function(eventView) { eventView.close(); });

            day.eventViews = [];
            day.eventViewsBackground = [];
            day.eventViewsWholeDay = [];
            day.separateEventViews = [];
            day.separateEventViewsBackground = [];
            day.separateEventViewsWholeDay = [];

            this.$('.events-container').empty();
            this.$('.whole-day-events').addClass('hidden');
        },

        populateDomFromCollection: function(collection) {

            this.collection = collection;

            var eventCollection = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return !(model.get('isGoogleCal') || model.get('type') === 'event') && !model.get('isWholeDay');}));
            var eventCollectionBackground = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return (model.get('isGoogleCal') || model.get('type') === 'event') && !model.get('isWholeDay');}));
            var eventCollectionWholeDay = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {return model.get('isWholeDay');}));

            var day = this.dayCollection;

            var dateStart = day.date.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
            var dateEnd = dateStart.clone().add(1, 'days');

            // Whole day events are added to a separate view.

            eventCollectionWholeDay.each(function(model) {

                var eventView = this._addEventView(model, dateStart, dateEnd);

                //if (model.get('isGoogleCal'))
                //    day.separateEventViewsWholeDay.push(eventView);
                //else
                day.eventViewsWholeDay.push(eventView);

            }, this);

            // Background events are added first, to allow graceful overlapping.

            eventCollectionBackground.each(function(model) {

                var eventView = this._addEventView(model, dateStart, dateEnd);

                if (model.get('isGoogleCal') || model.hasDuration())
                    day.separateEventViewsBackground.push(eventView);
                else
                    day.eventViewsBackground.push(eventView);

            }, this);

            eventCollection.each(function(model) {

                var eventView = this._addEventView(model, dateStart, dateEnd);

                if (model.get('isGoogleCal') || model.hasDuration())
                    day.separateEventViews.push(eventView);
                else
                    day.eventViews.push(eventView);

            }, this);

            // Once the DOM is populated, resize and sort based on overlaps.

            _.each(day.eventViews, function(eventView, index, list) {

                eventView.eventMarkup();
                eventView.eventHandleOverlaps(list, index);
            });

            _.each(day.separateEventViews, function(eventView, index, list) {

                eventView.eventMarkup();
                eventView.eventHandleOverlaps(list, index);
            });

            _.each(day.eventViewsBackground, function(eventView, index, list) {

                eventView.eventMarkup();
                eventView.eventHandleOverlaps(list, index);
            });

            _.each(day.separateEventViewsBackground, function(eventView, index, list) {

                eventView.eventMarkup();
                eventView.eventHandleOverlaps(list, index);
            });

            if (day.eventViewsWholeDay.length > 0 || day.separateEventViewsWholeDay.length > 0) {

                $('.calendar-view-header-container').addClass('scrollbar');

                var highestWholeDayCount = Math.max(day.eventViewsWholeDay.length, day.separateEventViewsWholeDay.length);

                if (day.eventViewsWholeDay.length > 4 || day.separateEventViewsWholeDay.length > 4)
                    this.$('.whole-day-events .base-day-calendar').css('height', 22 * highestWholeDayCount); // TODO JEROEN: moet dit nog bij de resize ook?
                else
                    this.$('.whole-day-events .base-day-calendar').css('height', 'auto');

                this.$('.whole-day-events').removeClass('hidden');
                this.$('.whole-day-events').removeClass('scrollbar-outside');

                this.$('.whole-day-events').hide().slideDown('fast', _.bind(function() {

                    if (!this.parentView.isMobile())
                        this.$('.whole-day-events').addClass('scrollbar-outside');

                    _.each(day.eventViewsWholeDay, function(eventView, index, list) {eventView.eventMarkup();});
                    _.each(day.separateEventViewsWholeDay, function(eventView, index, list) {eventView.eventMarkup();});

                }, this));
            }
            else
                this.$('.whole-day-events').addClass('hidden');
        },

        scrollToTime: function(smooth) {

            Love.Helpers.Calendar.scrollToTime(this.$calendar, this.dayCollection.date.isSame(moment(), 'day'), smooth);
        },

        updateRenderedView: function() {

            this.dayCollection.date = this.parentView.selectedDay.clone().hours(0).minutes(0).seconds(0).milliseconds(0);

            this.$('.calendar-view-header-container').removeClass('scrollbar');

            this.$('.calendar-view-content .moby-calendar').replaceWith(_.template(Love.Templates.calendar_base_day_moby));
            this.$('.calendar-view-content .google-calendar').replaceWith(_.template(Love.Templates.calendar_base_day_google));

            this.$('.base-day-calendar').attr('data-date', this.dayCollection.date.format('YYYY-MM-DD'));

            if (this.dayCollection.date.isSame(moment(), 'day'))
                this.$el.addClass('current-day');
            else
                this.$el.removeClass('current-day');

            this._greyoutPast();
            _.defer(this._setCurrentTimeLine);

            this._calendarPopupOpen = false;
            this._selectedTime = this.dayCollection.date.clone();

            if (this.parentView.updateDayViewSelectors)
                this.parentView.updateDayViewSelectors();

            this.$timeline = this.$('.timeline');
        },

        viewSizeReset: function() {

            _.each(this.dayCollection.eventViews, function(eventView, index, list) {eventView.eventWidthAndMargin(list, index);});
            _.each(this.dayCollection.eventViewsBackground, function(eventView, index, list) {eventView.eventWidthAndMargin(list, index);});
            _.each(this.dayCollection.eventViewsWholeDay, function(eventView, index, list) {eventView.eventMarkup();});
            _.each(this.dayCollection.separateEventViews, function(eventView, index, list) {eventView.eventWidthAndMargin(list, index);});
            _.each(this.dayCollection.separateEventViewsBackground, function(eventView, index, list) {eventView.eventWidthAndMargin(list, index);});
            _.each(this.dayCollection.separateEventViewsWholeDay, function(eventView, index, list) {eventView.eventMarkup();});

            this._setCurrentTimeLine();

            if (this.parentView.updateDayViewSelectors)
                this.parentView.updateDayViewSelectors();
        },

        _addEventView: function(model, dateStart, dateEnd) {

            var view = this.addSubView(Love.Helpers.Calendar.baseEventViewFactory(model, dateStart, dateEnd));
            var element = view.render().$el;

            if (model.get('isGoogleCal')) {

                if (model.get('isWholeDay')) {

                    this.$('.whole-day-events .google-calendar .events-container').append(element);
                }
                else
                    this.$('.calendar-view-content .google-calendar .events-container').append(element);
            }
            else {

                if (model.get('isWholeDay')) {

                    this.$('.whole-day-events .moby-calendar .events-container').append(element);
                }
                else if (model.hasDuration()) {

                    this.$('.calendar-view-content .google-calendar .events-container').append(element);
                }
                else
                    this.$('.calendar-view-content .moby-calendar .events-container').append(element);
            }

            return view;
        },

        _createContent: function(e, fromHeader) {

            if (e.button === 0 || fromHeader) { // Left

                if (e && e.preventDefault) e.preventDefault();

                // Deactivate any activated calendar events.

                this.$calendar.find('.calendar-base-event').removeClass('active');

                if (Love.appView.hasPopups()) return;

                var isWholeDayClick = fromHeader;

                if (!fromHeader) {

                    var isEventClick = ($(e.target).closest('.calendar-base-event').length > 0);
                    if (isEventClick) return;

                    isWholeDayClick = $(e.target).closest('.whole-day-events').length > 0;
                }

                var date;

                if (isWholeDayClick) {

                    date = this.dayCollection.date;
                }
                else {

                    // Update the current time indicators, in case the mouse wasn't moved yet.

                    this._updateCurrentTime(e);

                    date = this._selectedTime;
                }

                var self = this;
                var popup = new Love.Views.CalendarAddContentPopupView({

                    callbacks: {

                        onClose: function() {self._calendarPopupOpen = false;}
                    },
                    data: {date: date, showTime: !isWholeDayClick, dayFormatting: !isWholeDayClick},
                    positioning: fromHeader ? {

                            attachToElement: $(e.target).closest('.calendar-create-content'),
                            positionAt: 'element'

                        } : {

                            //attachToElement: $(e.target).closest('.base-day-calendar').find('.timeline .timeline-indicator').first(),
                            offsetX: e.clientX,
                            offsetY: e.clientY,
                            positionAt: 'mouse'
                        }
                });

                popup.showPopup();

                this._calendarPopupOpen = true;
            }
        },

        _greyoutPast: function() {

            // TODO RENS: ook voor whole day event-containers.

            Love.Helpers.Calendar.greyoutPastHourblocks(this.$('.calendar-view-content .base-day-calendar'), this.dayCollection.date);
        },

        _hideCurrentTime: function() {

            if (this._calendarPopupOpen) return;

            Love.Helpers.Calendar.hideCurrentTime(this.$calendar);
        },

        _setCurrentTimeLine: function() {

            Love.Helpers.Calendar.setCurrentTimeLine(this.$calendar, this.dayCollection.date.isSame(moment(), 'day'));
        },

        _showCurrentTime: function(e) {

            if (this._calendarPopupOpen) return;

            Love.Helpers.Calendar.showCurrentTime(this.$calendar, this.$timeline);
        },

        _updateCurrentTime: function(e) {

            if (this._calendarPopupOpen) return;

            var $eventGrid = this.$calendar.find('.calendar-view-content-container');
            var timelinePosition = Math.round(e.clientY + $eventGrid.scrollTop() - $eventGrid.offset().top);
            var hourSize = this.$('.hourblock').outerHeight();
            var quarter = hourSize / 6;
            var top = quarter * Math.round(timelinePosition / quarter);

            var time = top / hourSize;
            var hours = Math.floor(time);
            var minutes = Math.round((time % 1) * 60);

            this._selectedTime.hours(hours);
            this._selectedTime.minutes(minutes);

            this.$calendarCurrentTime.text(this._selectedTime.format('HH:mm'));
            this.$timeline.css('top', top);
        }
    });

})(Love);
