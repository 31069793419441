(function(Love) {

    Love.Views.CommonContentWidgetView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentWidgetView',

        className: 'widget-container',
        id: '',

        defaults: function() {

            return {

                disabled: false,
                enableDelete: true,
                enableMove: true,
                enableSettings: true,
                enableToolbar: true,
                enableToolbarAside: true,
                enableToolbarLine: true,
                fragment: null,
                maxLength: null,
                totalWidgets: 0,
                uploadAutomatically: true
            };
        },

        events: {

            'mouseenter': 'setActive',
            'click': 'setActive',
            'click [data-action="settings"]': 'showSettings',
            'click .widget-toolbar-aside [data-action="up"]': 'moveUp',
            'click .widget-toolbar-aside [data-action="down"]': 'moveDown',
            'click .widget-toolbar-aside [data-action="delete"]': 'delete'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, 'renderToolbars');

            this.widgetView = null;
            this.toolbarLineView = null;
        },

        render: function() {

            var context = this.options;
            this.$el.html(_.template(Love.Templates.common_content_widgets_widget)(context));

            if (this.widgetView)
                this.widgetView.close();

            var widgetOptions = {

                disabled: this.options.disabled,
                enableToolbar: this.options.enableToolbar,
                isNew: this.options.isNew,
                maxLength: this.options.maxLength,
                settings: _.clone(this.options.fragment.settings),
                uploadAutomatically: this.options.uploadAutomatically
            };

            switch (this.options.fragment.type) {

                case 'CMSTextWidget':
                    this.widgetView = this.addSubView(new Love.Views.CommonContentTextWidgetView(widgetOptions));
                    break;

                case 'CMSTextImageWidget':
                    this.widgetView = this.addSubView(new Love.Views.CommonContentTextImageWidgetView(widgetOptions));
                    break;

                case 'CMSTextPlainWidget':
                    this.widgetView = this.addSubView(new Love.Views.CommonContentTextPlainWidgetView(widgetOptions));
                    break;

                case 'CMSImageWidget':
                    this.widgetView = this.addSubView(new Love.Views.CommonContentImageWidgetView(widgetOptions));
                    break;

                case 'CMSQuoteWidget':
                    this.widgetView = this.addSubView(new Love.Views.CommonContentQuoteWidgetView(widgetOptions));
                    break;

                case 'CMSEmbedWidget':
                    this.widgetView = this.addSubView(new Love.Views.CommonContentEmbedWidgetView(widgetOptions));
                    break;

                case 'CMSContentWidget':
                    this.widgetView = this.addSubView(new Love.Views.CommonContentContentWidgetView(widgetOptions));
                    break;
            }

            if (this.widgetView) {

                this.listenTo(this.widgetView, 'changed', _.bind(function(e) {

                    this.trigger('changed', {

                        index: this.options.fragment.index,
                        settings: e.settings
                    });

                }, this));

                this.listenTo(this.widgetView, 'addExtraWidget', _.bind(function(e) {

                    this.trigger('addExtraWidget', {

                        index: this.options.fragment.index,
                        settings: e.settings,
                        type: this.options.fragment.type
                    });

                }, this));

                // We use deferred rendering here to ensure widget contents are rendered after the container
                // has been placed in the DOM. This enables correct measurement for loading images, etc.

                // TODO RENS: dit wordt te ingewikkeld.. misschien een Marionette-achtig mechanisme voor subview rendering?

                _.defer(_.bind(function() {

                    this.widgetView.setElement(this.$('.widgetception')).render();

                }, this));
            }

            this.renderToolbars();

            // In overleg met mathys nu geen waypoints maar mouseover
            // Het is handig om de code hier te laten staan als hij misschien
            // van gedachte verandert.
            //
            // Setup waypoints so we can track if the user has scrolled to this widget
            //var self = this;
            //// Defer it otherwise it will get called many times on load
            //_.defer(function () {
            //    new Waypoint({
            //        element: $widget,
            //        handler: function(direction) {
            //            if (direction === 'down') {
            //                self.setActive();
            //            }
            //        },
            //        offset: '30%'
            //    });
            //
            //    new Waypoint({
            //        element: $widget,
            //        handler: function(direction) {
            //            if (direction === 'up') {
            //                self.setActive();
            //            }
            //        },
            //        offset: function() {
            //            return -this.element.innerHeight() + window.innerHeight / 2.5;
            //        }
            //    });
            //});

            return Love.Views.BaseView.prototype.render.call(this);
        },

        renderToolbars: function(totalWidgets) {

            totalWidgets = _.isUndefined(totalWidgets) ? this.options.totalWidgets : totalWidgets;

            var isFirstWidget = this.options.fragment.index === 0;
            var isLastWidget = this.options.fragment.index >= (totalWidgets - 1);

            if (this.options.enableToolbarAside) {

                var context = _.extend(_.clone(this.options), {

                    showDelete: this.options.enableDelete,
                    showDown: this.options.enableMove && !isLastWidget,
                    showSettings: this.options.enableSettings && this._isWidgetWithSettings(),
                    showUp: this.options.enableMove && !isFirstWidget
                });

                this.$('.widget-toolbar-aside-container').html(_.template(Love.Templates.common_content_widgets_widget_toolbar_aside)(context));
            }

            if (!isLastWidget && this.options.enableToolbarLine) {

                if (!this.toolbarLineView) {

                    this.toolbarLineView = this.addSubView(new Love.Views.CommonContentWidgetToolbarLineView(this.options));

                    this.listenTo(this.toolbarLineView, 'addExtraWidget', _.bind(function(e) {

                        this.trigger('addExtraWidget', {

                            index: this.options.fragment.index,
                            settings: e.settings,
                            type: e.type
                        });

                    }, this));
                }

                this.toolbarLineView.setElement(this.$('.widget-toolbar-line-container')).render();
            }
            else {

                if (this.toolbarLineView)
                    this.toolbarLineView.close();
            }
        },

        setActive: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            $('.widget-container').removeClass('active-widget active-z');
            $('.widget-toolbar-aside').removeClass('active-widget active-z');

            this.$el.addClass('active-widget');
            this.$('.widget-toolbar-aside').addClass('active-widget');

            // Wait for transitions to finish.

            _.delay(_.bind(function() {

                this.$el.addClass('active-z');
                this.$('.widget-toolbar-aside').addClass('active-z');

            }, this), 300);

            /*
             // Focus on the newly activated widget.
             // NOTE: uitgeschakeld omdat dit irritant is. Vraag Rens / Mathys.

             var x = window.scrollX;
             var y = window.scrollY;

             if (this.$('.content').length > 0)
             this.$('.content')[0].focus(); // Use native focus, jQuery focus doesn't work an a div.
             else
             this.$el[0].focus();

             window.scrollTo(x, y);
             */

            // Update the text widget toolbar if necessary.

            if (this._isTextWidget() && this.widgetView.toolbarView)
                this.widgetView.toolbarView.updateToolbar();
        },

        delete: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var type = 'widget';

            switch (this.options.fragment.type) {

                case 'CMSTextWidget':
                    type = 'text';
                    break;

                case 'CMSTextImageWidget':
                    type = 'text and image';
                    break;

                case 'CMSTextPlai9nWidget':
                    type = 'text';
                    break;

                case 'CMSImageWidget':
                    type = 'image';
                    break;

                case 'CMSQuoteWidget':
                    type = 'quote';
                    break;

                case 'CMSEmbedWidget':
                    type = 'embed';
                    break;

                case 'CMSContentWidget':
                    type = 'content';
                    break;
            }

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        self.trigger('delete', {index: self.options.fragment.index});
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete ' + type + '?',
                    text: 'Do you really want to delete this ' + type + '? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        hasContent: function() {

            return this.widgetView.hasContent();
        },

        moveDown: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.trigger('moveDown', {index: this.options.fragment.index});
        },

        moveUp: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.trigger('moveUp', {index: this.options.fragment.index});
        },

        showSettings: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            Love.Helpers.Tracking.track('Widget fragment - show settings', {

                data: this.options.fragment.type
            });

            if (this.widgetView.showSettings) return this.widgetView.showSettings();
        },

        /**
         * Checks if the widget is a text-like widget. If so, it needs (for example) a text editing header.
         *
         * @returns {boolean}
         * @private
         */
        _isTextWidget: function() {

            return (this.options.fragment.type === 'CMSTextWidget' || this.options.fragment.type === 'CMSTextImageWidget' || this.options.fragment.type === 'CMSTextPlainWidget');
        },

        /**
         * Checks if the widget has user editable settings. If so, it needs a settings button.
         *
         * @returns {boolean}
         * @private
         */
        _isWidgetWithSettings: function() {

            return (this.options.fragment.type === 'CMSEmbedWidget' || this.options.fragment.type === 'CMSTextImageWidget' || this.options.fragment.type === 'CMSImageWidget');
        }
    });

})(Love);