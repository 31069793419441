(function(Love) {

    Love.Models.EventModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.EventModel',

        defaults: function() {

            return {

                author: {}, // TODO BRAM: API doesn't support this yet (long term).
                calendarId: '',
                cover: this.defaultsCover(),
                description: '',
                endsOn: null,
                id: '',
                lastsAllDay: false, // TODO BRAM: API doesn't support this yet (long term).
                linkTarget: 'page',
                location: {

                    address: '',
                    city: '',
                    country: '',
                    venue: ''
                },
                startsOn: null,
                storylines: [], // TODO BRAM: API doesn't support this yet (long term).
                thumbnail: '', // Client side only.
                timezone: 'Europe/Amsterdam', // TODO RENS / KOEN: aanpasbaar maken in UI.
                title: '',
                url: '',
                urlText: ''
            };
        },

        defaultsCover: function() {

            var model = new Love.Models.ImageModel();
            return model.attributes;
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/calendar/' + this.get('calendarId') + '/event/' + this.id + '.json?sid=' + Love.session.get('currentSiteId');

            // Create url.

            else
                return 'api/1.0/calendar/' + this.get('calendarId') + '/events.json?sid=' + Love.session.get('currentSiteId');
        },

        initialize: function(options) {

            if (options) this.set(options);

            _.bindAll(this, 'getClientBackup', 'removeClientBackup', 'saveClientBackup');
        },

        destroy: function(options) {

            this.removeClientBackup();
            return Love.Models.BaseModel.prototype.destroy.call(this, options);
        },

        parse: function(response, options) {

            var event = (response.response) ? response.response : response;

            _.extend(event, {

                endsOn: event.endsOn && moment(event.endsOn).isValid() ? moment(event.endsOn) : null,
                startsOn: event.startsOn && moment(event.startsOn).isValid() ? moment(event.startsOn) : null
            });

            // ------------------------------------------------
            // TODO RENS: part of the cover-image-by-url hack!
            // ------------------------------------------------

            var coverModel = new Love.Models.ImageModel();

            if (event.cover) {

                // When parsing server data, the cover is an imageUpload structure.
                // When parsing client serialized data (for example, from local backups), the cover is an ImageModel already.

                if (_.has(event.cover, 'imageSource'))
                    coverModel = new Love.Models.ImageModel(event.cover);
                else
                    coverModel.set(coverModel.getAttrFromMediaAttr(event.cover));
            }

            event.cover = coverModel.attributes;

            // ------------------------------------------------
            // End part of the cover-image-by-url hack!
            // ------------------------------------------------

            event.thumbnail = coverModel.getImageView();

            return event;
        },

        save: function(attrs, options) {

            // TODO RENS: part of the cover-image-by-url hack!

            // NOTE by RENS: I've wrapped the save function to allow for uploading the cover images before saving the model.
            // Until Bram adds cover-image-by-url functionality, we have to upload all cover images now if not already done.

            // In order to maintain asynchronous operation, the new save function returns a Deferred object instead of a jqXHR.
            // The first part is copied from Backbone source files, to support returning false on validation errors.

            // Note that the code assumes that, after changing the cover image models, validation can't fail anymore!

            // BEGIN COPIED FROM BACKBONE SOURCE.

            options = _.extend({validate: true, parse: true}, options);
            var wait = options.wait;

            if (attrs && !wait) {

                if (!this.set(attrs, options)) return false;
            }
            else if (!this._validate(attrs, options))
                return false;

            // END COPIED FROM BACKBONE SOURCE.

            var resultPromise = $.Deferred();
            var promises = [];
            var deferredUpload;

            if (attrs.cover) {

                var coverModel = new Love.Models.ImageModel(attrs.cover);

                if (!coverModel.isUploaded()) {

                    if (coverModel.hasImage()) {

                        // We have to add the deferred objects synchronously, as we need to have them all in the array when checking promises below.

                        deferredUpload = $.Deferred();
                        promises.push(deferredUpload);

                        coverModel.uploadUrl(coverModel.getImageView())
                            .done(_.bind(function() {

                                attrs.cover = coverModel.attributes;
                                this.resolve();

                            }, deferredUpload))
                            .fail(_.bind(function() {this.reject();}, deferredUpload));
                    }
                    else
                        attrs.cover = this.defaultsCover();
                }
            }

            $.whenAll.apply($, promises).always(_.bind(function() {

                // All covers should now be either null or uploaded image models.
                // The API expects imageUpload structures.

                if (attrs.cover) attrs.cover = attrs.cover.imageUpload;

                // AT THIS POINT, THE CLIENT SIDE MODEL STRUCTURE IS INCONSISTENT WITH THE EXPECTED STRUCTURE!
                // This might lead to problems if change events are raised or saving fails. If saving succeeds,
                // the expected (ImageModel) structure is recreated by the parse function.

                var xhr = Love.Models.BaseModel.prototype.save.call(this, attrs, _.extend(_.clone(options), {validate: false}));

                $.when(xhr).done(function() {

                    // Refresh site data for the session, as storylines and other things might have changed.

                    Love.session.retrieveSiteData(Love.session.get('currentSite'));

                    resultPromise.resolve();

                }).fail(function() { resultPromise.reject();});

            }, this));

            return resultPromise;
        },

        toJSON: function(options) {

            // This model has some custom attributes that should not be sent to the server.
            // Other attributes might just not be necessary for saving and can save some network load.

            var result = Love.Models.BaseModel.prototype.toJSON.call(this, options);

            delete result.author; // Not yet supported by API.
            delete result.lastsAllDay; // Not yet supported by API.
            delete result.storylines; // Not yet supported by API.
            delete result.thumbnail;

            return result;
        },

        validate: function(attributes, options) {

            var errors = {};

            if (!attributes.calendarId) errors.calendarId = 'Calendar id required.';
            if (!attributes.title) errors.title = 'Title required.';

            // TODO: andere attributes valideren voor save.

            if (!_.isEmpty(errors))
                return errors;
        },

        getClientBackup: function() {

            if (this.isNew()) return null;

            var key = 'events_event_' + this.id;

            var backup = Love.Helpers.LocalStorageSession.getObject(key);
            var date = moment(Love.Helpers.LocalStorageSession.getObject(key + '_updated_at'));

            if (!backup || !date) return null;

            return {

                backup: backup,
                updatedAt: date
            };
        },

        getCoverImagesAvailable: function(removeDuplicates) {

            var currentImage;
            var images = [];

            // Get the current cover image for the event.

            currentImage = new Love.Models.ImageModel(this.get('cover'));

            if (currentImage.hasImage())
                images.push(currentImage);

            // Remove duplicate images.

            if (removeDuplicates)
                images = _.uniq(images, false, function(model) {return model.getImageView();});

            return images;
        },

        removeClientBackup: function() {

            var key = 'events_event_' + this.id;

            Love.Helpers.LocalStorageSession.removeObject(key);
            Love.Helpers.LocalStorageSession.removeObject(key + '_updated_at');
        },

        saveClientBackup: function() {

            if (this.isNew()) return null;

            // Store a copy of the edited model in the local cache.

            var key = 'events_event_' + this.id;

            Love.Helpers.LocalStorageSession.setObject(key, this);
            Love.Helpers.LocalStorageSession.setObject(key + '_updated_at', moment());
        },

        setLocationFromAddress: function(address) {

            address = address || {};

            this.set('location', {

                'address': address.street,
                'city': address.city,
                'country': address.country,
                'venue': address.venue
            });
        }
    });

    Love.Collections.EventCollection = Love.Collections.BasePageableCollection.extend({

        objectClassName: 'Love.Collections.EventCollection',

        model: Love.Models.EventModel,

        url: function() { return 'api/1.0/search/events.json?sid=' + Love.session.get('currentSiteId'); },

        parseRecords: function(response, options) {

            return response.response;
        }
    });

})(Love);