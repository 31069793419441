(function(Love) {

    Love.Helpers.Images = {

        getElementAttributes: function($el) {

            var options = {

                callback: null,
                element: $el,
                fit: $el.attr('data-fit') ? true : false,
                focusPoint: $el.attr('data-focus-x') && $el.attr('data-focus-y') ? {

                        x: parseInt($el.attr('data-focus-x')),
                        y: parseInt($el.attr('data-focus-y'))

                    } : null,
                isLoaded: $el.attr('data-is-loaded') ? true : false,
                loadImageOptions: {

                    // These options were previously handled client side by the loadImage library, but are now handled by the image proxy.

                    contain: $el.attr('data-contain') ? true : false,
                    cover: $el.attr('data-cover') ? true : false,
                    maxHeight: $el.attr('data-max-height'),
                    maxWidth: $el.attr('data-max-width'),
                    orientation: true
                },
                noMaxHeight: ($el.attr('data-max-height') === 'none'),
                noMaxWidth: ($el.attr('data-max-width') === 'none'),
                onLoad: null,
                placeholder: $el.attr('data-placeholder-url'),
                resize: $el.attr('data-resize') ? true : false,
                url: $el.attr('data-load-url'),
                useProxy: true
            };

            if (options.resize) {

                if (!options.noMaxHeight) {

                    if (!options.loadImageOptions.maxHeight) options.loadImageOptions.maxHeight = $el.closest('.image').height();
                }
                else delete options.loadImageOptions.maxHeight;

                if (!options.noMaxWidth) {

                    if (!options.loadImageOptions.maxWidth) options.loadImageOptions.maxWidth = $el.closest('.image').width();
                }
                else delete options.loadImageOptions.maxWidth;
            }

            return options;
        },

        lazyLoadImages: function(selector) {

            _.each($(selector), function(el) {

                var $el = $(el);
                var options = _.extend(Love.Helpers.Images.getElementAttributes($el));

                if (_.isEmpty(options.url)) {

                    options.url = options.placeholder;
                    options.placeholder = '';
                    options.useProxy = false; // We assume that placeholders are owned static resources.
                }

                if (!_.isEmpty(options.url))
                    Love.Helpers.Images.loadAnImage(options);
            });
        },

        loadAnImage: function(options) {

            options = _.extend({

                loadImageOptions: {},
                url: ''

            }, options);

            //options.loadImageOptions.orientation = true; // Override.

            if (options.focusPoint) options.loadImageOptions.canvas = true; // This line is only needed when using the loadImage library.

            if (!options.callback) {

                options.callback = function(imgOrUrl) {

                    var isLoadImageResult = false;
                    var isCanvasElement = false;
                    var isImageElement = false;

                    if (!_.isString(imgOrUrl)) {

                        // We're dealing with a result of the loadImage library.

                        if (imgOrUrl.type === 'error') {

                            console.log(imgOrUrl);
                            return;
                        }

                        isLoadImageResult = true;
                        isCanvasElement = $(imgOrUrl).is('canvas');
                        isImageElement = $(imgOrUrl).is('img');
                    }

                    options.isLoaded = true;

                    if (options.element) {

                        Love.Helpers.Loading.startFadeInLoading(options.element); // Hide the image first...

                        var whenDone = function() {

                            Love.Helpers.Loading.finishFadeInLoading($(this)); // ... and show it once it has been loaded.
                        };

                        // NOTE: this code assumes that when a focusPoint is specified, we're not dealing with an img element, but with a div, canvas, ... instead.

                        if (!options.element.is('img') || options.focusPoint) {

                            // Set the new image as the background for the element with CSS.

                            var source;

                            if (isCanvasElement)
                                source = imgOrUrl.toDataURL();
                            else if (isImageElement)
                                source = $(imgOrUrl).attr('src');
                            else
                                source = imgOrUrl;

                            options.element.css('background-image', 'url(' + source + ')');

                            // Set the correct focus point.

                            if (options.focusPoint) {

                                options.element.css('background-position', options.focusPoint.x + '% ' + options.focusPoint.y + '%');
                                options.element.css('background-size', 'cover');
                            }

                            _.delay(function() {

                                options.element.waitForImages({waitForAll: true}).progress(whenDone);

                            }, 5); // NOTE by RENS: vanaf 5ms lijkt het te werken bij programatically loaded images.
                        }
                        else {

                            if (isLoadImageResult) {

                                options.element.replaceWith($(imgOrUrl)); // Replace the element with the new image.
                                _.bind(whenDone, options.element)();
                            }
                            else {

                                options.element.one('load', whenDone);
                                options.element.attr('src', imgOrUrl); // Replace the source with the new image.
                            }
                        }

                        // Store the attributes on the existing element.

                        Love.Helpers.Images.setElementAttributes(options.element, options);
                    }

                    if (options.onLoad)
                        options.onLoad(imgOrUrl);
                };
            }

            var resolvedUrl = Love.Helpers.Proxy.getAbsolutePath(options.url);

            if (validator.isURL(resolvedUrl)) {

                if (options.useProxy) {

                    var parameters = [];

                    // Translate our options to proxy parameters.

                    if (options.loadImageOptions.orientation)
                        parameters.push({name: 'image_correct_orientation', value: true});

                    if (options.resize) {

                        if (options.loadImageOptions.contain)
                            parameters.push({name: 'image_contain', value: true});

                        if (options.loadImageOptions.cover)
                            parameters.push({name: 'image_cover', value: true});

                        if (options.loadImageOptions.maxHeight)
                            parameters.push({name: 'image_max_height', value: options.loadImageOptions.maxHeight});

                        if (options.loadImageOptions.maxWidth)
                            parameters.push({name: 'image_max_width', value: options.loadImageOptions.maxWidth});
                    }

                    options.callback(Love.Helpers.Proxy.getProxyUrl(options.url, parameters));
                }
                else
                    options.callback(options.url);
            }
            else {

                // Try handling orientation and such via the loadImage library.

                loadImage(options.url, options.callback, options.loadImageOptions);
            }
        },

        setElementAttributes: function($el, options) {

            $el.attr('data-load-url', options.url);

            if (options.isLoaded) $el.attr('data-is-loaded', options.isLoaded);

            if (options.loadImageOptions.contain) $el.attr('data-contain', options.loadImageOptions.contain);
            if (options.loadImageOptions.cover) $el.attr('data-cover', options.loadImageOptions.cover);
            if (options.loadImageOptions.maxHeight) $el.attr('data-max-height', options.loadImageOptions.maxHeight);
            if (options.loadImageOptions.maxWidth) $el.attr('data-max-width', options.loadImageOptions.maxWidth);

            if (options.fit) $el.attr('data-fit', options.fit);
            if (options.focusPoint) $el.attr('data-focus-x', options.focusPoint.x);
            if (options.focusPoint) $el.attr('data-focus-y', options.focusPoint.y);
            if (options.noMaxHeight) $el.attr('data-max-height', 'none');
            if (options.noMaxWidth) $el.attr('data-max-width', 'none');
            if (!_.isEmpty(options.placeholder)) $el.attr('data-placeholder-url', options.placeholder);
            if (options.resize) $el.attr('data-resize', options.resize);
        }
    };

})(Love);