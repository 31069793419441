(function(Love) {

    // IMPORTANT: this code shouldn't be used anymore, as the server side alternative is much more efficient.
    // However, it's kept here because it contains some interesting mechanisms.

    Love.Helpers.ImagesClientSide = {

        getElementAttributes: function($el) {

            var options = {

                callback: null,
                element: $el,
                fit: $el.attr('data-fit') ? true : false,
                focusPoint: $el.attr('data-focus-x') && $el.attr('data-focus-y') ? {

                        x: parseInt($el.attr('data-focus-x')),
                        y: parseInt($el.attr('data-focus-y'))

                    } : null,
                isLoaded: $el.attr('data-is-loaded') ? true : false,
                loadImageOptions: {

                    contain: $el.attr('data-contain') ? true : false,
                    cover: $el.attr('data-cover') ? true : false,
                    maxHeight: $el.attr('data-max-height'),
                    maxWidth: $el.attr('data-max-width')
                },
                noMaxHeight: ($el.attr('data-max-height') === 'none'),
                noMaxWidth: ($el.attr('data-max-width') === 'none'),
                onLoad: null,
                placeholder: $el.attr('data-placeholder-url'),
                resize: $el.attr('data-resize') ? true : false,
                url: $el.attr('data-load-url'),
                useProxy: true
            };

            if (options.loadImageOptions.contain || options.loadImageOptions.cover || options.fit || options.resize) {

                if (!options.noMaxHeight) {

                    if (!options.loadImageOptions.maxHeight) options.loadImageOptions.maxHeight = $el.closest('.image').height();
                }
                else delete options.loadImageOptions.maxHeight;

                if (!options.noMaxWidth) {

                    if (!options.loadImageOptions.maxWidth) options.loadImageOptions.maxWidth = $el.closest('.image').width();
                }
                else delete options.loadImageOptions.maxWidth;
            }

            return options;
        },

        lazyLoadImages: function(selector) {

            _.each($(selector), function(el) {

                var $el = $(el);
                var options = _.extend(Love.Helpers.Images.getElementAttributes($el));

                if (_.isEmpty(options.url)) {

                    options.url = options.placeholder;
                    options.placeholder = '';
                    options.useProxy = false; // We assume that placeholders are owned static resources.
                }

                if (!_.isEmpty(options.url))
                    Love.Helpers.Images.loadAnImage(options);
            });
        },

        lazyLoadImagesResize: function(selector) {

            _.each($(selector), function(el) {

                var $el = $(el);
                var options = Love.Helpers.Images.getElementAttributes($el);

                // Check if the image is resizable: it should be loaded and have resizing dimensions specified.

                if (options.isLoaded && (options.loadImageOptions.maxHeight || options.loadImageOptions.maxWidth)) {

                    if ($el.is('img')) {

                        var $scaledImage = $(loadImage.scale($el[0], options.loadImageOptions)); // Doesn't work well without [0]!

                        Love.Helpers.Images.setElementAttributes($scaledImage, options);
                        $el.replaceWith($scaledImage);
                    }
                    else {

                        // We can only resize img elements, as other elements (ie., canvas) don't hold a reference to the original image.
                        // Therefore, we have to perform a full reload :(

                        Love.Helpers.Images.loadAnImage(options);
                    }
                }
            });
        },

        loadAnImage: function(options) {

            options = _.extend({

                correctOrientation: true, url: '',
                loadImageOptions: {}

            }, options);

            if (options.focusPoint) options.loadImageOptions.canvas = true; // This line is only needed when using the loadImage library.

            if (!options.callback) {

                options.callback = function(img) {

                    if (!img) return;

                    if (img.type === 'error') {

                        console.log(img);
                        return;
                    }

                    options.isLoaded = true;

                    if (options.element) {

                        Love.Helpers.Loading.startFadeInLoading(options.element); // Hide the image first...

                        var whenDone = function() {

                            Love.Helpers.Loading.startFadeInLoading($(this)); // ... and show it once it has been loaded.
                        };

                        if (options.focusPoint) {

                            // Set the new image as the background for the element with CSS.

                            options.element.css('background-image', 'url(' + img.toDataURL() + ')').waitForImages(true).progress(whenDone);

                            // Set the correct focus point.

                            options.element.css('background-position', options.focusPoint.x + '% ' + options.focusPoint.y + '%');
                            options.element.css('background-size', 'cover');

                            // Store the attributes on the existing element.

                            Love.Helpers.Images.setElementAttributes(options.element, options);
                        }
                        else {

                            // Replace the element with the new image.

                            var $img = $(img);

                            Love.Helpers.Images.setElementAttributes($img, options);
                            options.element.replaceWith($img);

                            _.bind(whenDone, options.element)();
                        }
                    }

                    if (options.onLoad)
                        options.onLoad(img.toDataURL());
                };
            }

            var handleImage = function(request) {

                if (request.status == 200) {

                    var blob = request.response;

                    if (options.correctOrientation) {

                        loadImage.parseMetaData(blob, function(data) {

                            var orientation = data.exif ? data.exif.get('Orientation') : 0;
                            loadImage(blob, options.callback, _.extend(_.clone(options.loadImageOptions), {orientation: orientation}));
                        });
                    }
                    else
                        loadImage(blob, options.callback, options.loadImageOptions);
                }
                else if (!_.isEmpty(options.placeholder)) {

                    Love.Helpers.Images.loadAnImage(_.extend(_.clone(options), {

                        placeholder: '',
                        url: options.placeholder,
                        useProxy: false // We assume that placeholders are owned static resources.
                    }));
                }
            };

            var resolvedUrl = Love.Helpers.Proxy.getAbsolutePath(options.url);

            if (validator.isURL(resolvedUrl)) {

                var xhr = new XMLHttpRequest();

                if (options.useProxy) {

                    var parameters = [];

                    // Translate our options to proxy parameters.

                    if (options.correctOrientation)
                        parameters.push({name: 'image_correct_orientation', value: true});

                    if (options.resize) {

                        if (options.loadImageOptions.contain)
                            parameters.push({name: 'image_contain', value: true});

                        if (options.loadImageOptions.cover)
                            parameters.push({name: 'image_cover', value: true});

                        if (options.loadImageOptions.maxHeight)
                            parameters.push({name: 'image_max_height', value: options.loadImageOptions.maxHeight});

                        if (options.loadImageOptions.maxWidth)
                            parameters.push({name: 'image_max_width', value: options.loadImageOptions.maxWidth});
                    }

                    xhr.open('GET', Love.Helpers.Proxy.getProxyUrl(options.url, parameters), true);
                }
                else
                    xhr.open('GET', options.url, true);

                xhr.responseType = 'blob';
                xhr.onload = function() {

                    handleImage({response: this.response, status: this.status});
                };
                xhr.send();
            }
            else {

                Love.Helpers.General.dataURLtoBlob(options.url, function(blob) {

                    handleImage({response: blob, status: 200});
                });
            }
        },

        setElementAttributes: function($el, options) {

            $el.attr('data-load-url', options.url);

            if (options.isLoaded) $el.attr('data-is-loaded', options.isLoaded);

            if (options.loadImageOptions.contain) $el.attr('data-contain', options.loadImageOptions.contain);
            if (options.loadImageOptions.cover) $el.attr('data-cover', options.loadImageOptions.cover);
            if (options.loadImageOptions.maxHeight) $el.attr('data-max-height', options.loadImageOptions.maxHeight);
            if (options.loadImageOptions.maxWidth) $el.attr('data-max-width', options.loadImageOptions.maxWidth);

            if (options.fit) $el.attr('data-fit', options.fit);
            if (options.focusPoint) $el.attr('data-focus-x', options.focusPoint.x);
            if (options.focusPoint) $el.attr('data-focus-y', options.focusPoint.y);
            if (options.noMaxHeight) $el.attr('data-max-height', 'none');
            if (options.noMaxWidth) $el.attr('data-max-width', 'none');
            if (!_.isEmpty(options.placeholder)) $el.attr('data-placeholder-url', options.placeholder);
            if (options.resize) $el.attr('data-resize', options.resize);
        }
    };

})(Love);