(function(Love) {

    Love.Views.CommonLoadingOverlayView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonLoadingOverlayView',

        className: 'common-loading-overlay',

        defaults: function() {

            return {

                parentElement: null
            };
        },

        viewOptions: function() {

            return _.extend({}, Love.Views.BaseView.prototype.viewOptions, {

                removeElementOnClose: true
            });
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_resetPosition');

            this._$parent = $(this.options.parentElement);
        },

        render: function() {

            this._$parent.append(this.$el);
            this.$el.html(_.template(Love.Templates.common_loading_overlay)());

            _.defer(_.bind(function() {

                this._resetPosition();
                this.$('.common-loading-overlay-image').removeClass('invisible');

            }, this));

            $(window).on('resize', this._resetPosition);
            $(window).on('scroll', this._resetPosition);

            this.$el.on('scroll', this._resetPosition);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            $(window).off('resize', this._resetPosition);
            $(window).off('scroll', this._resetPosition);

            this.$el.off('scroll', this._resetPosition);
        },

        _getImageOffset: function() {

            // Determine the area of the containing element that is actually visible.

            var headerHeight = $('#common-header').outerHeight(true);
            var contentHeight = window.innerHeight - headerHeight;

            var rect = this._$parent[0].getBoundingClientRect(); // Relative to the viewport.

            var visibleTop = Math.max(headerHeight, rect.top);
            var visibleBottom = Math.min(contentHeight, rect.bottom);

            var visibleAreaHeight = visibleBottom - visibleTop;
            var imageHeight = this.$('.common-loading-overlay-image').outerHeight(true);

            var offset = visibleAreaHeight / 2 - (imageHeight / 2); // + $(window).scrollTop();
            offset = offset + (visibleTop - rect.top);

            offset = Math.max(offset, 16);

            return offset;
        },

        _resetPosition: function() {

            this.$('.common-loading-overlay-image').css('top', this._getImageOffset());
        }
    });

})(Love);