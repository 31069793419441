(function(Love) {

    Love.Collections.BasePageableCollectionNow = Backbone.PageableCollection.extend({

        objectClassName: 'Love.Collections.BasePageableCollectionNow',

        // You can configure the mapping from a `Backbone.PageableCollection#state`
        // key to the query string parameters accepted by your server API.

        queryParams: {

            // `Backbone.PageableCollection#queryParams` converts to ruby's
            // will_paginate keys by default.

            currentPage: 'browseOffset',
            pageSize: 'browseAmount',
            totalPages: null,
            totalRecords: null
        },

        // Any `state` or `queryParam` you override in a subclass will be merged with
        // the defaults in `Backbone.PageableCollection` 's prototype.

        state: {

            // You can use 0-based or 1-based indices, the default is 1-based.
            // You can set to 0-based by setting ``firstPage`` to 0.

            firstPage: 0
        },

        getPagingId: function(response) { return response.id; },

        parseLinks: function(response, options) {

            var contents = response.content;
            var link = _.result(this, 'url');

            if (contents && _.isArray(contents) && contents.length > 0) {

                var firstId = this.getPagingId(_.first(contents));
                var lastId = this.getPagingId(_.last(contents));

                return {

                    first: link,
                    next: link + '&browseStyle=endAt&browseOffset=' + lastId,
                    prev: link + '&browseStyle=startAt&browseOffset=' + firstId
                };
            }
            else
                return {first: link};
        },

        parseState: function(response, queryParams, state, options) {

            return {};
        }
    });

})(Love);