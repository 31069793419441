(function(Love) {

    Love.Views.DashboardRemixModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardRemixModuleView',

        defaults: function() {

            return {

                itemsToLoad: 5,
                selectedItems: []
            };
        },

        events: {

            'click [data-action="create"]': '_handleCreateClick',
            'click [data-action="help"]': '_handleHelpClick',
            'click .common-content-list-toolbar-remix [data-action="remove"]': '_handleToolbarRemoveClick'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_closeNotification', '_renderItems');

            this._items = new Love.Collections.BookmarkCollection(null, {

                mode: 'infinite',
                state: {pageSize: this.options.itemsToLoad}
            });

            this._itemViews = [];
            this._waypoints = [];
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.dashboard_modules_remix));

            this.listenTo(this._items, 'sync', this._renderItems);
            this._items.getFirstPage();

            _.defer(_.bind(function() {

                // Deferred to 'ensure' the header has been rendered.

                this.listenTo(Love.appView.headerView, 'create', _.bind(function(e) {

                    var ids = _.map(_.filter(this._itemViews, function(view) { return view.isSelected(); }), function(view) {

                        return view.options.item.id;
                    });

                    ids.reverse();

                    if (e.type === 'post' && ids.length > 0) {

                        Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/new/remix/' + ids.join(','), {trigger: true});
                        e.preventDefault = true;
                    }

                }, this));

            }, this));

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this._clearWaypoints();
        },

        _clearWaypoints: function() {

            _.each(this._waypoints, function(point) { point.destroy(); });
            this._waypoints = [];
        },

        _closeNotification: function(noFade) {

            if (this._notification) {

                if (!noFade)
                    this._notification.closeFade('slow');
                else
                    this._notification.close();
            }
        },

        _handleCreateClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var ids = _.map(_.filter(this._itemViews, function(view) { return view.isSelected(); }), function(view) {

                return view.options.item.id;
            });

            ids.reverse();

            if (ids.length > 0)
                Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/new/remix/' + ids.join(','), {trigger: true});
        },

        _handleHelpClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var popup = new Love.Views.CommonTooltipPopupView({

                data: {

                    text: 'Select items from this list to create a new post with them.'
                },
                positioning: {

                    attachToElement: $(e.target).closest('[data-action="help"]')
                }
            });

            popup.showPopup();
        },

        _handleItemDelete: function(e) {

            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: _.bind(function() {

                        e.item.destroy()
                            .fail(function(error) {

                                // TODO RENS: show error notification.

                                console.log(error);
                            })
                            .always(_.bind(function() {

                                // Deselect the item.

                                this.options.selectedItems = _.without(this.options.selectedItems, e.item.id);
                                this._updateSelectedItems();

                                // Remove the item view.

                                e.view.close();

                            }, this));

                    }, this)
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete bookmark?',
                    text: 'Do you really want to delete this bookmark?',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleToolbarRemoveClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var itemId = $(e.target).closest('[data-item-id]').attr('data-item-id');
            var viewIndex = _.findIndex(this._itemViews, function(view) {return view.getItem().id === itemId;});

            this.options.selectedItems = _.without(this.options.selectedItems, itemId);
            if (viewIndex > -1) this._itemViews[viewIndex].setSelected(false, true);

            this._updateSelectedItems();
        },

        _renderItems: function() {

            var fullCollection = this._items.fullCollection ? this._items.fullCollection : this._items; // fullCollection is only used when mode is infinite.
            var addElements = [];

            if (fullCollection.length < 1) {

                this.$('.common-content-list').html(_.template(Love.Templates.remix_bookmark_row_none)());
                return;
            }

            this._items.each(function(model) {

                try {

                    var itemView = this.addSubView(new Love.Views.RemixBookmarkRowView({item: model}));

                    this.listenTo(itemView, 'selectedChanged', _.bind(function(e) {

                        if (e.selected) {

                            if (_.indexOf(this.options.selectedItems, e.item.id) === -1)
                                this.options.selectedItems.push(e.item.id);
                        }
                        else
                            this.options.selectedItems = _.without(this.options.selectedItems, e.item.id);

                        this._updateSelectedItems();

                    }, this));

                    this.listenTo(itemView, 'delete', this._handleItemDelete);

                    this._itemViews.push(itemView);
                    addElements.push(itemView.render().$el);
                }
                catch (ex) {

                    console.log(ex); // TODO: proper error handling.
                }

            }, this);

            this.$('.common-content-list').append(addElements);

            this._closeNotification();

            var self = this;

            if (self._items.hasNextPage()) {

                var triggerView = _.first(_.last(this._itemViews, this.options.itemsToLoad));

                if (triggerView)
                    this._waypoints = triggerView.$el.waypoint({

                        context: this.$('.module-content'),
                        handler: function(direction) {

                            if (direction === 'down') {

                                self._notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                                    dock: 'bottom',
                                    text: 'Fetching more items...'
                                }));
                                self.$('.module').append(self._notification.render().$el);

                                self._clearWaypoints();
                                self._items.getNextPage();
                            }
                        },
                        offset: '100%' // When the top of the element has passed the bottom of the context.
                    });
            }
            else if (!this._finalNotificationShown) {

                this._closeNotification(true);

                this._notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                    dock: 'bottom',
                    text: 'That\'s it for now!'
                }));
                this.$('.module').append(this._notification.render().$el);

                _.delay(self._closeNotification, 4000);

                this._finalNotificationShown = true;
            }

            this.trigger('rendered');
        },

        _updateSelectedItems: function() {

            if (this.options.selectedItems.length < 1)
                this.$('.module-footer').addClass('hidden');

            else {

                this.$('.module-footer').removeClass('hidden');
                this.$('.common-content-list-toolbar-remix [data-action="create"] .text').text(Love.t('dashboard:modules.remix.create', {count: this.options.selectedItems.length}));

                var selectedIds = _.clone(this.options.selectedItems);
                var fullCollection = this._items.fullCollection ? this._items.fullCollection : this._items; // fullCollection is only used when mode is infinite.

                var container = this.$('.common-content-list-toolbar-remix .items');

                // Put two items in the container for width measurement.

                container.html(_.template(Love.Templates.dashboard_modules_remix_toolbar_thumbnail)({

                        empty: true,
                        more: false

                    }) + _.template(Love.Templates.dashboard_modules_remix_toolbar_thumbnail)({

                        empty: true,
                        more: false
                    }));

                var containerWidth = container.width();
                var itemWidth = container.find('.item:last').width();
                var itemMargin = container.find('.item:first').outerWidth(true) - itemWidth; // Include the right margin of the first item.

                container.empty();

                var itemsToShow = Math.floor((containerWidth - itemMargin) / (itemWidth + itemMargin)); // TODO RENS: responsive maken.

                selectedIds.reverse(); // Newest first.

                for (var i = 0; i < itemsToShow; i++) {

                    var item = null;

                    if (selectedIds.length > i) {

                        var id = selectedIds[i];
                        item = fullCollection.findWhere({id: id});
                    }

                    container.append(_.template(Love.Templates.dashboard_modules_remix_toolbar_thumbnail)(_.extend({

                        empty: !item,
                        more: (i === itemsToShow - 1 && item)

                    }, item ? item.attributes : null)));
                }

                Love.Helpers.Images.lazyLoadImages(container);
            }
        }
    });

})(Love);