(function(Love) {

    Love.Views.SitesScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.SitesScreenView',

        defaults: function() {

            return {

                itemsToShow: 10
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this._itemViews = [];

            this.items = new Love.Collections.SiteCollection();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.sites_screen));

            this._getItems();

            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _addItemTemplate: function(model) {

            var itemView = this.addSubView(new Love.Views.SiteRowView({item: model}));
            this.$('.common-content-list').append(itemView.render().$el);

            this._itemViews.push(itemView);
        },

        _getItems: function() {

            Love.Helpers.Loading.showLoading(true, this.cid, this.$('.common-content-list'));

            this.$('.list-row-empty-list').remove();

            if (this.items.length < 1) {

                // We have a fixed amounts of items to show on each page.
                // Though site data has to be retrieved from the server asynchronously, we can reserve layout space
                // by generating templates in advance.

                for (var i = 0; i < this.options.itemsToShow; i++)
                    this._addItemTemplate(new Love.Models.SiteModel());
            }

            var self = this;

            this.listenToOnce(this.items, 'sync', function() {

                // Sort the items by usage amount and title.

                self.items = new Love.Collections.SiteCollection(self.items.chain()
                    .sortBy(function(model) { return model.get('title'); })
                    .sortBy(function(model) {

                        var usage = model.getUsageAmount();
                        return usage < 1 ? 2 : 1 / usage;
                    })
                    .value());

                // Items are now sorted.

                var diff = 0;

                if (self.items.length > self._itemViews.length) {

                    // Add extra templates.

                    diff = self.items.length - self._itemViews.length;

                    for (var j = 0; j < diff; j++)
                        self._addItemTemplate(new Love.Models.SiteModel());
                }
                else if (self.items.length < self._itemViews.length) {

                    // Remove unnecessary templates.

                    diff = self._itemViews.length - self.items.length;

                    for (var h = 0; h < diff; h++)
                        self._removeLastItemTemplate();
                }

                // Fill the pre-rendered models.

                $(window).scrollTo(0, 'normal');

                if (self.items.length > 0) {

                    self.items.each(function(item, index) {

                        var view = self._itemViews[index];
                        view.setItem(item);

                    }, self);
                }
                else
                    self.$('.common-content-list').html(_.template(Love.Templates.sites_site_row_none));
            });

            var fetchOptions = {

                data: {

                    part: 'title,url,imageUrl,streams,nowPlatformId'
                },
                error: function(model, response, options) {

                    // TODO RENS: nu kunnen we niets meer :( We moeten kunnen refreshen. Net als bij posts etc.
                },
                traditional: true,
                requestId: this.getAjaxAbortId(),
                abortPendingByIds: [this.getAjaxAbortId()]
            };

            this.items.fetch(fetchOptions).always(function() {

                Love.Helpers.Loading.showLoading(false, self.cid);
            });
        },

        _removeLastItemTemplate: function() {

            var lastView = this._itemViews[this._itemViews.length - 1];

            if (lastView)
                lastView.close();

            this._itemViews.pop();
        }
    });

})(Love);