(function(Love) {

    Love.Views.EventSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.EventSidebarView',

        events: function() {

            return _.extend({}, Love.Views.BaseSidebarView.prototype.events, {

                'click [data-action="end-date"]': '_handleEndDateClick',
                'click [data-action="start-date"]': '_handleStartDateClick',
                'click [data-type="author"] [data-value]': '_handleAuthorSelect',
                'click [data-type="calendar"] [data-value]': '_handleCalendarSelect',
                'click [data-type="cover-image"]:not(.common-image-upload-container)': '_handleCoverImageClick',
                'click [data-type="storylines"] [data-value]': '_handleStorylineSelect',
                'keyup [data-type="storylines"] input[name="new"]': '_handleStorylineAddKeyUp',
                'click [data-type="storylines"] [data-action="new"]': '_handleStorylineAdd'
            });
        },

        initialize: function(options) {

            Love.Views.BaseSidebarView.prototype.initialize.call(this, options);

            this.editModel = this.options.editModel;
            this.viewState = this.options.viewState;
        },

        render: function() {

            var context = _.extend(_.clone(this.editModel.attributes), this.viewState.attributes);
            this.$el.html(_.template(Love.Templates.events_event_sidebar)(context));

            this.stopListening(this.viewState, 'change:changedByUser', this._updateSavedText);

            this.stopListening(this.editModel, 'change', this._updateSavedText);
            this.stopListening(this.editModel, 'change:endsOn', this._updateDurationText);
            this.stopListening(this.editModel, 'change:startsOn', this._updateDurationText);
            this.stopListening(this.editModel, 'change:lastsAllDay', this._updateDurationText);

            this.stopListening(this, 'imagesDropped', this._handleImagesDropped);

            // Now the base layout has been rendered, we first allow some functions from the BaseSidebarView to be executed
            // before continuing the rendering process. This allows us (for example) to open the sidebar automatically before
            // rendering the cover image, which is necessary for measuring.

            _.defer(_.bind(function() {

                this._renderAuthors();
                this._renderCalendars();
                this._renderCoverImage();
                this._renderStorylines();

                this._updateAuthorText();
                this._updateCalendarText();
                this._updateDurationText();
                this._updateSavedText();
                this._updateStorylinesText();

                this.listenTo(this.viewState, 'change:changedByUser', this._updateSavedText);

                this.listenTo(this.editModel, 'change', this._updateSavedText);
                this.listenTo(this.editModel, 'change:endsOn', this._updateDurationText);
                this.listenTo(this.editModel, 'change:startsOn', this._updateDurationText);
                this.listenTo(this.editModel, 'change:lastsAllDay', this._updateDurationText);

                this.listenTo(this, 'imagesDropped', this._handleImagesDropped);

                this.trigger('rendered');

            }, this));

            return Love.Views.BaseSidebarView.prototype.render.call(this, true); // We'll trigger the rendered event later.
        },

        _renderAuthors: function() {

            this.$('[data-type="author"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'images',
                items: _.map(Love.session.get('currentSite').get('authors'), function(value, index, list) {

                    var displayEmail = (_.indexOf(_.pluck(list.slice(0, index), 'name'), value.name) > -1);

                    return {

                        selected: (value.id === this.editModel.get('author').id),
                        id: value.id,
                        name: value.name + ((displayEmail) ? ' (' + value.id + ')' : ''),
                        imageSource: (value.imageUrl) ? value.imageUrl : "http://placekitten.com/g/50/50"
                    };

                }, this),
                displayNew: false,
                emptyText: 'No authors :('
            }));
        },

        _renderCalendars: function() {

            this.$('[data-type="calendar"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'radio',
                items: _.map(Love.session.get('currentSite').get('calendars'), function(value) {

                    return {

                        selected: (value.id === this.editModel.get('calendarId')),
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: 'New calendar'
            }));
        },

        _renderCoverImage: function() {

            var coverModel = new Love.Models.ImageModel(this.editModel.get('cover'));

            if (_.isEmpty(coverModel.getImageView())) {

                var uploadContainer = this.$('[data-type="cover-image"].common-image-upload-container');
                var imageContainer = this.$('[data-type="cover-image"]:not(.common-image-upload-container)');

                uploadContainer.removeClass('hidden');
                imageContainer.addClass('hidden');

                this._imageUploadView = this.addSubView(new Love.Views.CommonImageUploadView({

                    callbacks: {

                        onChange: _.bind(function(imageModel) {

                            this._handleCoverImage({

                                imageModel: imageModel,
                                ogImageModel: null
                            });

                        }, this),
                        onDropMultiple: function(file, index) {

                            // TODO RENS: eventueel dit afhandelen voor consistency met drop op bestaand image via base/sidebar.js.
                        },
                        onLoadingChange: _.bind(function(loading) {this.viewState.set('disableSaving', loading);}, this)
                    },
                    loadingFullScreen: false
                }));
                this._imageUploadView.setElement(uploadContainer).render();
            }
            else
                this._updateCoverImage(coverModel, false);
        },

        _renderStorylines: function() {

            var storylines = Love.session.get('currentSite').get('storylines');
            storylines = storylines.concat(this.editModel.get('storylines'));

            storylines = _.uniq(storylines, false, function(o) {return o.title;});
            storylines = _.sortBy(storylines, 'title');

            this.$('[data-type="storylines"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(storylines, function(value) {

                    return {

                        selected: (_.findWhere(this.editModel.get('storylines'), {title: value.title})),
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNewInput: 'New storyline'
            }));
        },

        _handleAuthorSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            var id = element.attr('data-id');
            var value = element.attr('data-value');

            this.editModel.set('author', {

                id: id,
                displayName: value
            });
            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Event editor - changed sidebar author', {

                model: this.editModel.id,
                data: value
            });

            this._updateAuthorText();
        },

        _handleCalendarSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            var id = element.attr('data-id');
            var value = element.attr('data-value');

            this.editModel.set('calendarId', id);
            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Event editor - changed sidebar calendar', {

                model: this.editModel.id,
                data: value
            });

            this._updateCalendarText();
        },

        _handleCoverImage: function(data) {

            var imageModel = data.imageModel;
            //var ogImageModel = data.ogImageModel;

            this.editModel.set('cover', imageModel ? imageModel.attributes : this.editModel.defaultsCover());
            //this.editModel.set('ogImage', ogImageModel ? ogImageModel.attributes : this.editModel.defaultsOGImage());

            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Event editor - changed sidebar cover image', {

                model: this.editModel.id
            });

            if (imageModel && !_.isEmpty(imageModel.getImageView()))
                this._updateCoverImage(imageModel, true);
            else
                this._renderCoverImage();
        },

        _handleCoverImageClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            Love.Helpers.Tracking.track('Event editor - clicked sidebar cover image', {

                model: this.editModel.id
            });

            this._showCoverImagePopup();
        },

        _handleImagesDropped: function(e) {

            if ($(e.target).closest('[data-type]').attr('data-type') === 'cover-image') {

                if (e.images[0]) {

                    this._handleCoverImage({

                        imageModel: e.images[0],
                        ogImageModel: null
                    });

                    Love.Helpers.Tracking.track('Event editor - dropped sidebar cover image', {

                        model: this.editModel.id
                    });
                }

                _.defer(_.bind(function() {

                    this._showCoverImagePopup(e.images);

                }, this));
            }
        },

        _handleEndDateClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var popup = new Love.Views.CommonPickDateTimePopupView({

                callbacks: {

                    onChange: _.bind(function(data) {

                        var newEndDate = data.value && data.value.isValid() ? data.value.clone() : null;

                        var startDate = this.editModel.get('startsOn').clone();
                        var endDate = this.editModel.get('endsOn').clone();

                        this.editModel.set('endsOn', newEndDate);

                        // Expected behavior: change start date as well, if the end date is no longer after the start date.

                        if (startDate && startDate.isAfter(newEndDate)) {

                            // Also update the start date.

                            this.editModel.set('startsOn', newEndDate.clone().subtract(1, 'hours'));
                        }

                        if (data.dateTimeRange.canLastAllDay)
                            this.editModel.set('lastsAllDay', data.dateTimeRange.lastsAllDay);

                        this.editModel.trigger('changedByUser', this.editModel);

                        Love.Helpers.Tracking.track('Event editor - changed sidebar end date', {

                            model: this.editModel.id,
                            data: newEndDate
                        });

                    }, this)
                },
                data: {

                    dateTimeRange: {

                        canLastAllDay: true,
                        isRange: false,
                        lastsAllDay: this.editModel.get('lastsAllDay'),
                        secondValue: this.editModel.get('startsOn').clone()
                    },
                    value: this.editModel.get('endsOn').clone()
                },
                positioning: {

                    attachToElement: $(e.target).closest('[data-action="end-date"]'),
                    centerX: true,
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _handleStartDateClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var popup = new Love.Views.CommonPickDateTimePopupView({

                callbacks: {

                    onChange: _.bind(function(data) {

                        var newStartDate = data.value && data.value.isValid() ? data.value.clone() : null;

                        var startDate = this.editModel.get('startsOn').clone();
                        var endDate = this.editModel.get('endsOn').clone();

                        this.editModel.set('startsOn', newStartDate);

                        // Expected behavior: change the end date as well, by the same amount as the start date has changed.

                        if (endDate && endDate.isAfter(startDate)) {

                            // Also update the end date.

                            var diff = endDate.diff(startDate);
                            this.editModel.set('endsOn', newStartDate.clone().add(diff, 'milliseconds'));
                        }

                        if (data.dateTimeRange.canLastAllDay)
                            this.editModel.set('lastsAllDay', data.dateTimeRange.lastsAllDay);

                        this.editModel.trigger('changedByUser', this.editModel);

                        Love.Helpers.Tracking.track('Event editor - changed sidebar start date', {

                            model: this.editModel.id,
                            data: newStartDate
                        });

                    }, this)
                },
                data: {

                    dateTimeRange: {

                        canLastAllDay: true,
                        isRange: false,
                        lastsAllDay: this.editModel.get('lastsAllDay'),
                        secondValue: this.editModel.get('endsOn').clone()
                    },
                    value: this.editModel.get('startsOn').clone()
                },
                positioning: {

                    attachToElement: $(e.target).closest('[data-action="start-date"]'),
                    centerX: true,
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        },

        _handleStorylineSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            var id = element.attr('data-id');
            var value = element.attr('data-value');

            var storyline = _.find(Love.session.get('currentSite').get('storylines'), function(obj) {return obj.id === id;});

            if (element.hasClass('selected')) {

                var existing = _.findIndex(this.editModel.get('storylines'), function(obj) { return obj.id === id; });

                if (existing === -1 && storyline) this.editModel.get('storylines').push(storyline);
            }
            else {

                this.editModel.set('storylines', _.filter(this.editModel.get('storylines'), function(obj) {return obj.title !== value;}));

                if (!storyline) {

                    // If we deselected a storyline that wasn't already saved to the database, we need to ensure its removal from the list.

                    this._renderStorylines();
                }
            }

            this.editModel.trigger('change', this.editModel);
            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Event editor - changed sidebar storylines', {

                model: this.editModel.id
            });

            this._updateStorylinesText();
        },

        _handleStorylineAdd: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var input = $(e.target).closest('.sidebar-list').find('input[name="new"]'); // This also works if entering via the keyup handler.
            var title = input.val();

            if (title) {

                var existing = _.findIndex(this.editModel.get('storylines'), function(obj) { return obj.title.toUpperCase() === title.toUpperCase(); });

                if (existing === -1) {

                    this.editModel.get('storylines').push({title: title});

                    this.editModel.trigger('change', this.editModel);
                    this.editModel.trigger('changedByUser', this.editModel);

                    this._renderStorylines();
                    this._updateStorylinesText();
                }
            }

            Love.Helpers.Tracking.track('Event editor - added sidebar storyline', {

                model: this.editModel.id,
                data: title
            });
        },

        _handleStorylineAddKeyUp: function(e) {

            if (e.keyCode === 13) { // Add on enter.

                e.preventDefault();
                this._handleStorylineAdd(e);
            }
        },

        _showCoverImagePopup: function(extraImages) {

            var currentImage = new Love.Models.ImageModel(this.editModel.get('cover'));
            var currentOgImage = new Love.Models.ImageModel(this.editModel.get('ogImage'));

            var images = this.editModel.getCoverImagesAvailable();

            // Concatenate and remove duplicate images.

            if (extraImages)
                images = images.concat(extraImages);

            images = _.compact(images);
            images = _.uniq(images, false, function(model) {return model.getImageView();});

            var popup = new Love.Views.CommonCoverImagePopupView({

                callbacks: {

                    onConfirm: _.bind(this._handleCoverImage, this)
                },
                data: {

                    canChangeCroppedArea: false,
                    canChangeFocusPoint: false,
                    imageModel: _.find(images, function(model) {

                        return (model.getImageView() === currentImage.getImageView());
                    }),
                    images: images,
                    ogImageModel: currentOgImage
                }
            });

            popup.showPopup();
        },

        _updateAuthorText: function() {

            if (this.editModel.get('author').displayName)
                this.$('[data-type="author"] .sidebar-row-content span').html("<em class='filled'>" + this.editModel.get('author').displayName + "</em> created this event.");
            else
                this.$('[data-type="author"] .sidebar-row-content span').html("<em>Who</em> created this event?");
        },

        _updateCalendarText: function() {

            var calendar = (this.editModel.get('calendarId')) ? _.findWhere(Love.session.get('currentSite').get('calendars'), {id: this.editModel.get('calendarId')}) : null;

            if (calendar)
                this.$('[data-type="calendar"] .sidebar-row-content span').html("It is scheduled on <em class='filled'>" + calendar.title + "</em>.");
            else
                this.$('[data-type="calendar"] .sidebar-row-content span').html("To which <em>calendar</em> does it belong?");
        },

        _updateCoverImage: function(coverModel, showFeedback) {

            if (this._imageUploadView) this._imageUploadView.close();

            var uploadContainer = this.$('[data-type="cover-image"].common-image-upload-container');
            var imageContainer = this.$('[data-type="cover-image"]:not(.common-image-upload-container)');

            uploadContainer.addClass('hidden');
            imageContainer.removeClass('hidden');

            Love.Helpers.Images.loadAnImage({

                element: imageContainer.find('.image'),
                focusPoint: coverModel.getFocusPoint(),
                loadImageOptions: {

                    orientation: true
                },
                onLoad: _.bind(function() {

                    if (showFeedback) {

                        // Show the cover image feedback for a few seconds.

                        imageContainer.find('.hover-feedback').css({'opacity': 1});
                        setTimeout(function() {imageContainer.find('.hover-feedback').css({'opacity': 0});}, 3000);
                    }

                }, this),
                url: coverModel.getImageView(),
                useProxy: true
            });
        },

        _updateDurationText: function() {

            var start = this.editModel.get('startsOn');
            var end = this.editModel.get('endsOn');
            var lastsAllDay = this.editModel.get('lastsAllDay');

            if (!lastsAllDay) {

                if (start.isSame(end, 'day'))
                    this.$('[data-type="duration"] .sidebar-row-content span').html("It starts on <em class='filled' data-action='start-date'>" + start.format('ddd, DD MMM YYYY, HH:mm') + "</em> and ends at <em class='filled' data-action='end-date'>" + end.format('HH:mm') + "</em>.");
                else
                    this.$('[data-type="duration"] .sidebar-row-content span').html("It starts on <em class='filled' data-action='start-date'>" + start.format('ddd, DD MMM YYYY, HH:mm') + "</em> and ends on <em class='filled' data-action='end-date'>" + end.format('ddd, DD MMM YYYY, HH:mm') + "</em>.");
            }
            else {

                if (start.isSame(end, 'day'))
                    this.$('[data-type="duration"] .sidebar-row-content span').html("It <em class='filled' data-action='start-date'>starts</em> and <em class='filled' data-action='end-date'>ends</em> on " + start.format('ddd, DD MMM YYYY') + ", lasting all day.");
                else
                    this.$('[data-type="duration"] .sidebar-row-content span').html("It starts on <em class='filled' data-action='start-date'>" + start.format('ddd, DD MMM') + "</em> and ends on <em class='filled' data-action='end-date'>" + end.format('ddd, DD MMM YYYY') + "</em>, lasting all day.");
            }
        },

        _updateSavedText: function() {

            var changedAttrs = this.editModel.changedAttributesJSON(this.editModel.serverAttributes);
            var isNew = this.editModel.isNew();

            if (!isNew && !changedAttrs)
                this.$('[data-type="saved"] .sidebar-row-content span').html("Your changes have been saved!");
            else
                this.$('[data-type="saved"] .sidebar-row-content span').html("Your changes have <em>not been saved</em>.");
        },

        _updateStorylinesText: function() {

            if (this.editModel.get('storylines').length > 0)
                this.$('[data-type="storylines"] .sidebar-row-content span').html("The event is part of <em class='filled'>" + this.editModel.get('storylines').length + "</em> storyline(s).");
            else
                this.$('[data-type="storylines"] .sidebar-row-content span').html("Do you want to add it to a <em>storyline</em>?");
        }
    });

})(Love);