(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'common': {

            'dialogs': {

                'before_unload_confirmation': 'There are unsaved changes on this page. Do you really want to go elsewhere?'
            },

            'dragdrop': {

                'feedback_drop': 'Drop your content here!',
                'feedback_drop_plural': 'Drop your contents here!',
                'feedback_drop_close': 'Okay, let it go!',
                'feedback_drop_close_plural': 'Okay, let them go!'
            },

            'image_upload': {

                'feedback_drop': 'Drop your image here!',
                'feedback_drop_plural': 'Drop your images here!',
                'feedback_drop_replace': 'Drop your image here to replace the current image!',
                'feedback_drop_replace_plural': 'Drop your images here to replace the current image!'
            },

            'key1': 'value of key test 1 in en',
            'key2': 'value of key test 2 in en',
            'key3': 'value of key test 3 in en'
        }
    });

})(Love);