(function (Love) {

    $(document).ready(function () {

        Backbone.$ = $;

        i18next.init({

            debug: false,
            defaultNS: 'common',
            fallbackLng: 'en',
            lng: 'en',
            ns: _.keys(Love.Locales.en)

        }, function () {

            // Resources have been loaded.

            Love.t = _.bind(i18next.t, i18next); // Shortcut to the translate function.

            Love.views = [];
            Love.viewBag = {};

            Love.appView = new Love.Views.AppView();
            Love.session = new Love.Models.SessionModel();

            Love.router = new Love.Router();
        });
    });

})(Love);