(function(Love) {

    Love.Views.CommonPickDateTimePopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonPickDateTimePopupView',

        className: 'base-popup popup-clean popup-size-auto',
        id: 'popup-common-pick-datetime',

        defaultsForPopupType: function() {

            return {

                data: {

                    canSelectInPast: true,
                    dateTimeRange: {

                        canLastAllDay: false,
                        isRange: false,
                        lastsAllDay: false,
                        secondValue: null
                    },
                    value: moment()
                },
                enableConfirmByEnter: true
            };
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_pick_datetime)());

            this.dateTimePickerView = this.addSubView(new Love.Views.CommonDateTimePickerView({

                callbacks: {

                    onChange: _.bind(function(date, dateTimeRange) {

                        this.options.data.value = date;
                        this.options.data.dateTimeRange = dateTimeRange; // This basically re-references the object, but the pointer might have been changed.

                        if (this.options.callbacks.onChange)
                            this.options.callbacks.onChange(this.options.data);

                    }, this),
                    onClose: _.bind(this.close, this)
                },
                canSelectInPast: this.options.data.canSelectInPast,
                date: this.options.data.value,
                dateTimeRange: this.options.data.dateTimeRange,
                showClose: true

            }));
            this.dateTimePickerView.setElement(this.$('.popup-content')).render();

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        isConsideredEqualTo: function(other) {

            if (Love.Views.BasePopupView.prototype.isConsideredEqualTo.call(this, other))
                return true;

            var isSameType = _.isEqual(this.objectClassName, other.objectClassName);
            if (!isSameType) return false;

            var data = this.options.data;
            var otherData = other.options.data;

            if (!data.value || !otherData.value) return false;

            if (!data.value.isValid() && !otherData.value.isValid()) return true;
            if (data.value.isSame(otherData.value, 'day') && (data.value.format('HH:mm') === otherData.value.format('HH:mm'))) return true;
        }
    });
})(Love);