(function (Love) {

    Love.Models.DestinationModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.DestinationModel',

        defaults: function () {

            return {

                accountName: '',
                avatarUrl: '',
                friendlyName: '',
                icon: '',
                id: '',
                maxCharacters: Infinity,
                type: ''
            };
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/destination/' + this.id + '.json';

            // Create url.

            else
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/destinations.json';
        },

        initialize: function (options) {

            if (options) this.set(options);
        },

        parse: function (response, options) {

            var contents = (response.response) ? response.response : response;

            if (!contents.avatarUrl) contents.avatarUrl = ''; // Replace false with ''.

            if (contents.type === 'facebook') {

                contents.icon = 'icon-facebook';
                contents.maxCharacters = 60000;
            } else if (contents.type === 'mobypicture') {
                contents.icon = 'ttl-moby';
			} else if (contents.type === 'instagram') {

                contents.icon = 'icon-instagram';
				contents.maxCharacters = 2200;
            }
            else if (contents.type === 'twitter') {

                contents.icon = 'icon-twitter';
                contents.maxCharacters = 140;
            }

            return contents;
        }
    });

    Love.Collections.DestinationCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.DestinationCollection',

        model: Love.Models.DestinationModel,

        url: function () {return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/destinations.json';},

        parse: function (response, options) {

            return response.response;
        }
    });

})(Love);