(function(Love) {

    Love.Views.BaseView = Backbone.View.extend({

        objectClassName: 'Love.Views.BaseView',

        viewOptions: {

            abortPendingAjaxRequests: true,
            removeElementOnClose: false
        },

        defaults: function() { return {}; },

        subViews: null,

        initialize: function(options) {

            this.subViews = [];

            this.performDotDotDotUpdate = _.debounce(_.bind(this.performDotDotDotUpdate, this), 50);
            this.performLazyLoadImagesResize = _.debounce(_.bind(this.performLazyLoadImagesResize, this), 50);

            this.options = _.defaults(_.clone(options || {}), this.defaults());

            this.listenTo(this, 'rendered', _.bind(function() {

                // Deferred execution ensures that in most cases, views have been placed in the DOM when below functions are called.
                // TODO RENS: dit wordt te ingewikkeld.. misschien een Marionette-achtig mechanisme voor subview rendering?

                _.defer(_.bind(function() {

                    this.performDotDotDot();
                    this.performLazyLoadImages();

                    // Once a popup has been rendered, it's size might have changed. We therefore need to call onResize() to ensure
                    // the popup is displayed correctly (eg., full screen when necessary). TODO RENS: is het niet slordig om dat hier te doen? De check is ook matig.

                    if (this.isPopupView && this.isPopupView()) this.onResize();

                }, this));

            }, this));

            Love.views.push(this);
        },

        render: function(dontTrigger) {

            this.$el.attr('data-is-view', true);
            this.$el.attr('data-client-id', this.cid);

            $(window).off('resize', this.performDotDotDotUpdate);
            $(window).on('resize', this.performDotDotDotUpdate);

            $(window).off('resize', this.performLazyLoadImagesResize);
            $(window).on('resize', this.performLazyLoadImagesResize);

            if (!dontTrigger)
                this.trigger('rendered');

            return this;
        },

        /**
         * The close function provides a graceful method to unbind any registered events and empty the view's element.
         * It also checks for existence of an onClose method on the view instance and calls it if there.
         * This method can be used to perform any cleanup when unrendering the view.
         */
        close: function() {

            _.each(this.subViews, function(view) {

                this.removeSubView(view);

            }, this);

            if (this.onClose) this.onClose();

            $(window).off('resize', this.performDotDotDotUpdate);
            $(window).off('resize', this.performLazyLoadImagesResize);

            if (_.result(this, 'viewOptions').abortPendingAjaxRequests)
                Love.Helpers.AjaxPool.abortPendingByIds([this.getAjaxAbortId()]);

            if (this.stopListening) this.stopListening();
            if (this.undelegateEvents) this.undelegateEvents();

            if (this.$el) {

                if (_.result(this, 'viewOptions').removeElementOnClose)
                    this.$el.remove();
                else
                    this.$el.empty();
            }

            Love.views = _.without(Love.views, this);
        },

        closeFade: function(duration) {

            this.$el.fadeOut(duration, _.bind(function() {

                this.close();

            }, this));
        },

        addSubView: function(view) {

            if (view)
                this.subViews.push(view);

            return view;
        },

        getAjaxAbortId: function() { return this.objectClassName + this.cid; },

        performDotDotDot: function(traverse) {

            // TODO RENS: onderstaande scan op parents is een hack! Er moet een beter systeem komen voor view rendering, en deze functie
            // mag pas worden aangeroepen als hij daadwerkelijk kan worden uitgevoerd (ie., images een hoogte hebben, tekst is gerenderd).

            var self = this;

            this.$('.truncate-multi:visible').not(function(index, element) {

                if (traverse) return false;
                return self._matchDirectChildrenOnly(index, element, self);

            }).dotdotdot();
        },

        performDotDotDotUpdate: function(traverse) {

            // TODO RENS: onderstaande scan op parents is een hack! Er moet een beter systeem komen voor view rendering, en deze functie
            // mag pas worden aangeroepen als hij daadwerkelijk kan worden uitgevoerd (ie., images een hoogte hebben, tekst is gerenderd).

            var self = this;

            this.$('.truncate-multi:visible').not(function(index, element) {

                if (traverse) return false;
                return self._matchDirectChildrenOnly(index, element, self);

            }).dotdotdot(); //.trigger('update.dot'); NOTE: the update function is usually also called to initiate previously non-visible .truncate-multi elements...
        },

        performLazyLoadImages: function(traverse) {

            // TODO RENS: onderstaande scan op parents is een hack! Er moet een beter systeem komen voor view rendering, en deze functie
            // mag pas worden aangeroepen als hij daadwerkelijk kan worden uitgevoerd (ie., images een hoogte hebben, tekst is gerenderd).

            var self = this;

            Love.Helpers.Images.lazyLoadImages(this.$('img[data-load-url], .image[data-load-url], canvas[data-load-url]').not(function(index, element) {

                if (traverse) return false;
                return self._matchDirectChildrenOnly(index, element, self);
            }));
        },

        performLazyLoadImagesResize: function(traverse) {

            // NOTE: this was necessary when doing resizing and exif handling client side. As this has been moved to the proxy, we don't have to manually resize anymore.

            return;

            // TODO RENS: onderstaande scan op parents is een hack! Er moet een beter systeem komen voor view rendering, en deze functie
            // mag pas worden aangeroepen als hij daadwerkelijk kan worden uitgevoerd (ie., images een hoogte hebben, tekst is gerenderd).

            var self = this;

            Love.Helpers.Images.lazyLoadImagesResize(this.$('.image[data-is-loaded="true"], canvas[data-is-loaded="true"], img[data-is-loaded="true"]').not(function(index, element) {

                if (traverse) return false;
                return self._matchDirectChildrenOnly(index, element, self);
            }));
        },

        removeSubView: function(view) {

            if (view) {

                view.close();
                this.subViews = _.without(this.subViews, view);
            }
        },

        _matchDirectChildrenOnly: function(index, element, view) {

            var closestView = $(element.closest('[data-is-view]'));
            if (closestView.length < 1) return false;

            return (closestView.attr('data-client-id') !== view.cid);
        }
    });

})(Love);