(function(Love) {

    Love.Views.PostSidebarView = Love.Views.BaseSidebarView.extend({

        baseClassName: 'Love.Views.BaseSidebarView',
        objectClassName: 'Love.Views.PostSidebarView',

        events: function() {

            return _.extend({}, Love.Views.BaseSidebarView.prototype.events, {

                'click [data-type="author"] [data-value]': '_handleAuthorSelect',
                'click [data-type="category"] [data-value]': '_handleCategorySelect',
                'click [data-type="cover-image"]:not(.common-image-upload-container)': '_handleCoverImageClick',
                'click [data-type="languages"] [data-value]': '_handleLanguageSelect',
                'click [data-type="languages"] [data-action="new"]': '_handleLanguageNew',
                'click [data-type="languages"] [data-action="remove"]': '_handleLanguageRemove',
                'click [data-type="social"] [data-action="edit"]': '_handleSocialEdit',
                'click [data-type="social"] [data-action="new"]': '_handleSocialNew',
                'click [data-type="social"] [data-action="remove"]': '_handleSocialRemove',
                'click [data-type="storylines"] [data-value]': '_handleStorylineSelect',
                'keyup [data-type="storylines"] input[name="new"]': '_handleStorylineAddKeyUp',
                'click [data-type="storylines"] [data-action="new"]': '_handleStorylineAdd',
                'keyup [data-type="tags"] input[name="tag"]': '_handleTagAddKeyUp',
                'input [data-type="tags"] input[name="tag"]': '_handleTagAddChange',
                'drop [data-type="tags"] input[name="tag"]': '_handleTagAddChange',
                'paste [data-type="tags"] input[name="tag"]': '_handleTagAddChange',
                'submit [data-type="tags"] form': '_handleTagAdd',
                'click [data-type="tags"] [data-action="add-tag"]': '_handleTagAdd',
                'click [data-type="tags"] [data-action="remove-tag"]': '_handleTagRemove'
            });
        },

        initialize: function(options) {

            Love.Views.BaseSidebarView.prototype.initialize.call(this, options);

            this.editModel = this.options.editModel;
            this.viewState = this.options.viewState;
        },

        render: function() {

            var context = _.extend(_.clone(this.editModel.attributes), this.viewState.attributes);
            this.$el.html(_.template(Love.Templates.posts_post_sidebar)(context));

            this.stopListening(this.viewState, 'change:activeRevision', this.render);
            this.stopListening(this.viewState, 'change:changedByUser', this._updateSavedText);

            this.stopListening(this.editModel, 'change', this._updateSavedText);
            this.stopListening(this.editModel, 'change:revisions');

            this.stopListening(this, 'imagesDropped', this._handleImagesDropped);
            this.stopListening(this, 'toggleDropdown', this._handleToggleDropdown);

            // Now the base layout has been rendered, we first allow some functions from the BaseSidebarView to be executed
            // before continuing the rendering process. This allows us (for example) to open the sidebar automatically before
            // rendering the cover image, which is necessary for measuring.

            _.defer(_.bind(function() {

                this._renderAuthors();
                this._renderCategories();
                this._renderCoverImage();
                this._renderLanguages();
                this._renderScheduled();
                this._renderSocial();
                this._renderStorylines();
                this._renderTags();

                this._updateAuthorText();
                this._updateCategoryText();
                this._updateLanguagesText();
                this._updateSavedText();
                this._updateScheduledText();
                this._updateSocialText();
                this._updateStorylinesText();
                this._updateTagsText();

                var self = this;

                this.listenTo(this.viewState, 'change:activeRevision', this.render);
                this.listenTo(this.viewState, 'change:changedByUser', this._updateSavedText);

                this.listenTo(this.editModel, 'change', this._updateSavedText);
                this.listenTo(this.editModel, 'change:revisions', function(model, value, options) {

                    options = options || {};

                    if (options.action === 'add') {

                        // Trigger a change event for the revision. This allows the post screen view to update the view state
                        // and re-render the sidebar and widget editor.

                        self.trigger('revisionChanged', {newLanguage: options.index});
                    }
                    else if (options.action === 'cover') {

                        var revision = self.editModel.get('revisions')[self.viewState.get('activeRevision')];
                        var coverModel = new Love.Models.ImageModel(revision.ogImage);

                        if (_.isEmpty(coverModel.getImageView()))
                            coverModel = new Love.Models.ImageModel(revision.cover);

                        if (!_.isEmpty(coverModel.getImageView()))
                            this._updateCoverImage(coverModel, true);
                        else
                            this._renderCoverImage();
                    }
                    else if (options.action === 'delete') {

                        // Check if the active language was deleted.

                        if (options.index === self.viewState.get('activeRevision')) {

                            // Trigger a change event for the revision. This allows the post screen view to update the view state
                            // and re-render the sidebar and widget editor.

                            self.trigger('revisionChanged', {newLanguage: self.editModel.getDefaultRevision()});
                        }
                        else
                            self._renderLanguages();
                    }
                });

                this.listenTo(this, 'imagesDropped', this._handleImagesDropped);
                this.listenTo(this, 'toggleDropdown', this._handleToggleDropdown);

                this.trigger('rendered');

            }, this));

            return Love.Views.BaseSidebarView.prototype.render.call(this, true); // We'll trigger the rendered event later.
        },

        openCategory: function() {this.openSidebarDropdown(this.$('[data-type="category"]'));},

        openSocial: function() {this.openSidebarDropdown(this.$('[data-type="social"]'));},

        openStorylines: function() {this.openSidebarDropdown(this.$('[data-type="storylines"]'));},

        _renderAuthors: function() {

            this.$('[data-type="author"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'images',
                items: _.map(Love.session.get('currentSite').get('authors'), function(value, index, list) {

                    var displayEmail = (_.indexOf(_.pluck(list.slice(0, index), 'name'), value.name) > -1);

                    return {

                        selected: (value.id === this.editModel.get('revisions')[this.viewState.get('activeRevision')].author.id),
                        id: value.id,
                        name: value.name + ((displayEmail) ? ' (' + value.id + ')' : ''),
                        imageSource: (value.imageUrl) ? value.imageUrl : "http://placekitten.com/g/50/50"
                    };

                }, this),
                displayNew: false,
                emptyText: 'No authors :('
            }));
        },

        _renderCategories: function() {

            this.$('[data-type="category"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'radio',
                items: _.map(Love.session.get('currentSite').get('blogs'), function(value) {

                    return {

                        selected: (value.id === this.editModel.get('groupId')),
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNew: 'New category'
            }));
        },

        _renderCoverImage: function() {

            var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];
            var coverModel = new Love.Models.ImageModel(revision.ogImage);

            if (_.isEmpty(coverModel.getImageView()))
                coverModel = new Love.Models.ImageModel(revision.cover);

            if (_.isEmpty(coverModel.getImageView())) {

                var uploadContainer = this.$('[data-type="cover-image"].common-image-upload-container');
                var imageContainer = this.$('[data-type="cover-image"]:not(.common-image-upload-container)');

                uploadContainer.removeClass('hidden');
                imageContainer.addClass('hidden');

                this._imageUploadView = this.addSubView(new Love.Views.CommonImageUploadView({

                    callbacks: {

                        onChange: _.bind(function(imageModel) {

                            this._handleCoverImage({

                                imageModel: imageModel,
                                ogImageModel: null
                            });

                        }, this),
                        onDropMultiple: function(file, index) {

                            // TODO RENS: eventueel dit afhandelen voor consistency met drop op bestaand image via base/sidebar.js.
                        },
                        onLoadingChange: _.bind(function(loading) {this.viewState.set('disableSaving', loading);}, this)
                    },
                    loadingFullScreen: false
                }));
                this._imageUploadView.setElement(uploadContainer).render();
            }
            else
                this._updateCoverImage(coverModel, false);
        },

        _renderLanguages: function() {

            var removable = _.keys(this.editModel.get('revisions')).length > 1;

            this.$('[data-type="languages"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'radio',
                items: _.sortBy(_.map(this.editModel.get('revisions'), function(value, lang) {

                    return {

                        selected: (lang === this.viewState.get('activeRevision')),
                        id: lang,
                        name: (lang === '') ? Love.t('languages:intl') : Love.t('languages:' + lang),
                        removable: removable
                    };

                }, this), function(item) {

                    return item.name;
                }),
                displayNew: 'Add language'
            }));
        },

        _renderScheduled: function() {

            var date = this.editModel.get('scheduledOn');

            if (this._scheduledDateTimePickerView)
                this._scheduledDateTimePickerView.close();

            this._scheduledDateTimePickerView = this.addSubView(new Love.Views.CommonDateTimePickerView({

                callbacks: {

                    onChange: _.bind(function(date) {

                        this.editModel.set('scheduledOn', date && date.isValid() && date.isAfter(moment()) ? date : null);
                        this._updateScheduledText();

                    }, this),
                    onClose: _.bind(function(date) {

                        this.editModel.set('scheduledOn', date && date.isValid() && date.isAfter(moment()) ? date : null);
                        this._updateScheduledText();

                        this.toggleDropdown(this.$('[data-type="scheduled"] .sidebar-dropdown'));

                    }, this),
                    onReset: _.bind(function() {

                        this.editModel.set('scheduledOn', null);
                        this._updateScheduledText();

                        this.toggleDropdown(this.$('[data-type="scheduled"] .sidebar-dropdown'));

                    }, this)
                },
                canSelectInPast: false,
                date: date,
                resetText: 'Don\'t schedule',
                showClose: true,
                showReset: true
            }));
            this._scheduledDateTimePickerView.setElement(this.$('[data-type="scheduled"] .common-datetime-picker-container')).render();
        },

        _renderSocial: function() {

            var updates = this.editModel.get('scheduledContent');
            var destinations = Love.session.get('currentSite').get('destinations');

            var immediate = _.filter(updates, function(update) {return !update.scheduledOnUtc;});
            var scheduled = _.filter(updates, function(update) {return update.scheduledOnUtc;});

            immediate = _.sortBy(immediate, function(update) { return update.message; });
            scheduled = _.sortBy(scheduled, function(update) { return update.scheduledOnUtc + update.message; });

            updates = immediate.concat(scheduled);

            this.editModel.set('scheduledContent', updates, {silent: true}); // We need to reorder the model array too, because it is matched by data-index.

            this.$('[data-type="social"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_social)({

                items: _.map(updates, function(value) {

                    return _.extend({

                        destination: _.findWhere(destinations, {id: value.destinationId})

                    }, value);

                }, this),
                displayNew: 'Add social update'
            }));

            this.performDotDotDot();
            this.performLazyLoadImages();
        },

        _renderStorylines: function() {

            var storylines = Love.session.get('currentSite').get('storylines');
            storylines = storylines.concat(this.editModel.get('storylines'));

            storylines = _.uniq(storylines, false, function(o) {return o.title;});
            storylines = _.sortBy(storylines, 'title');

            this.$('[data-type="storylines"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_list)({

                listMode: 'checkboxes',
                items: _.map(storylines, function(value) {

                    return {

                        selected: (_.findWhere(this.editModel.get('storylines'), {title: value.title})),
                        id: value.id,
                        name: value.title
                    };

                }, this),
                displayNewInput: 'New storyline'
            }));
        },

        _renderTags: function() {

            this.$('[data-type="tags"] .sidebar-dropdown').html(_.template(Love.Templates.common_sidebar_dropdown_tags)({

                items: this.editModel.get('revisions')[this.viewState.get('activeRevision')].tags.map(function(value) {

                    return {

                        name: value,
                        removable: true
                    };

                }, this),
                displayNew: true
            }));
        },

        _handleAuthorSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            var id = element.attr('data-id');
            var value = element.attr('data-value');

            this.editModel.get('revisions')[this.viewState.get('activeRevision')].author = {

                id: id,
                displayName: value
            };

            this.editModel.trigger('change:revisions', this.editModel, this.editModel.get('revisions'));
            this.editModel.trigger('change', this.editModel);
            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Post editor - changed sidebar author', {

                model: this.editModel.id,
                data: value
            });

            this._updateAuthorText();
        },

        _handleCategorySelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            var id = element.attr('data-id');
            var value = element.attr('data-value');

            this.editModel.set('groupId', id);
            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Post editor - changed sidebar category', {

                model: this.editModel.id,
                data: value
            });

            this._updateCategoryText();
        },

        _handleCoverImage: function(data) {

            var imageModel = data.imageModel;
            var ogImageModel = data.ogImageModel;

            /*
             // TODO RENS: images uploaden in background

             if (!imageModel.isUploaded()) {

             Love.Helpers.Loading.showLoading(true);

             imageModel.uploadUrl(imageModel.getImageView())

             .done(_.bind(function() {

             // TODO

             }, imageModel))
             .always(function() {

             Love.Helpers.Loading.showLoading(false);
             });
             }
             */

            var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];

            revision.cover = imageModel ? imageModel.attributes : this.editModel.defaultsCover();
            revision.ogImage = ogImageModel ? ogImageModel.attributes : this.editModel.defaultsOGImage();

            this.editModel.trigger('change:revisions', this.editModel, this.editModel.get('revisions'), {revision: revision});
            this.editModel.trigger('change', this.editModel);
            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Post editor - changed sidebar cover image', {

                model: this.editModel.id
            });

            var coverModel = ogImageModel;
            if (!coverModel || _.isEmpty(coverModel.getImageView())) coverModel = imageModel;

            if (coverModel && !_.isEmpty(coverModel.getImageView()))
                this._updateCoverImage(coverModel, true);
            else
                this._renderCoverImage();
        },

        _handleCoverImageClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            Love.Helpers.Tracking.track('Post editor - clicked sidebar cover image', {

                model: this.editModel.id
            });

            this._showCoverImagePopup();
        },

        _handleImagesDropped: function(e) {

            if ($(e.target).closest('[data-type]').attr('data-type') === 'cover-image') {

                if (e.images[0]) {

                    this._handleCoverImage({

                        imageModel: e.images[0],
                        ogImageModel: null
                    });

                    Love.Helpers.Tracking.track('Post editor - dropped sidebar cover image', {

                        model: this.editModel.id
                    });
                }

                _.defer(_.bind(function() {

                    this._showCoverImagePopup(e.images);

                }, this));
            }
        },

        _handleLanguageSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            var id = element.attr('data-id');
            var value = element.attr('data-value');

            // Trigger a change event for the revision. This allows the post screen view to update the view state
            // and re-render the sidebar and widget editor.

            this.trigger('revisionChanged', {newLanguage: id});

            Love.Helpers.Tracking.track('Post editor - changed sidebar language', {

                model: this.editModel.id,
                data: value
            });
        },

        _handleLanguageNew: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonContentNewLanguagePopupView({

                callbacks: {

                    onConfirm: function(data) {

                        if (data.language === 'intl') data.language = '';
                        if (data.copyFrom === 'intl') data.copyFrom = '';

                        self.editModel.addLanguage(data.language, data.copy, data.copyFrom);
                        self.editModel.trigger('changedByUser', self.editModel);

                        Love.Helpers.Tracking.track('Post editor - added sidebar language', {

                            model: self.editModel.id,
                            data: data.language
                        });
                    }
                },
                data: {

                    except: _.map(this.editModel.get('revisions'), function(value, lang) {

                        return _.isEmpty(lang) ? 'intl' : lang;
                    })
                }
            });

            popup.showPopup();
        },

        _handleLanguageRemove: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        var lang = $(e.target).closest('[data-id]').attr('data-id');

                        self.editModel.deleteLanguage(lang);
                        self.editModel.trigger('changedByUser', self.editModel);

                        Love.Helpers.Tracking.track('Post editor - removed sidebar language', {

                            model: self.editModel.id,
                            data: lang
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Remove language?',
                    text: 'Do you really want to remove this language? This action can\'t be undone.',
                    yesText: 'Remove'
                }
            });

            popup.showPopup();
        },

        _handleSocialEdit: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var index = $(e.target).closest('[data-index]').attr('data-index');
            var update = this.editModel.get('scheduledContent')[index];

            if (update) {

                var self = this;
                var popup = new Love.Views.CommonSocialMediaUpdatesPopupView({

                    callbacks: {

                        onConfirm: function(data) {

                            var updates = _.without(self.editModel.get('scheduledContent'), update).concat(data.updates);

                            _.each(updates, function(update) {

                                // Updates can only be scheduled 5 minutes or more after the post has been published.

                                if (update.scheduledOnUtc) {

                                    if (!update.scheduledOnUtc.isValid()) update.scheduledOnUtc = null;
                                    else if (update.scheduledOnUtc.isBefore(moment.utc().add(5, 'minutes'))) update.scheduledOnUtc = null;
                                    else if (self.editModel.get('scheduledOn')) {

                                        if (update.scheduledOnUtc.isBefore(self.editModel.get('scheduledOn').clone().utc().add(5, 'minutes'))) update.scheduledOnUtc = null;
                                    }
                                }
                            });

                            self.editModel.set('scheduledContent', updates);
                            self.editModel.trigger('changedByUser', self.editModel);

                            Love.Helpers.Tracking.track('Post editor - edited sidebar social updates', {

                                model: self.editModel.id
                            });

                            self._renderSocial();
                            self._updateSocialText();
                        },
                        onRemove: function() {

                            self.editModel.set('scheduledContent', _.without(self.editModel.get('scheduledContent'), update));
                            self.editModel.trigger('changedByUser', self.editModel);

                            Love.Helpers.Tracking.track('Post editor - removed sidebar social update through popup', {

                                model: self.editModel.id
                            });

                            self._renderSocial();
                            self._updateSocialText();
                        }
                    },
                    data: _.extend(_.clone(update), {

                        destination: _.findWhere(Love.session.get('currentSite').get('destinations'), {id: update.destinationId}),
                        isScheduled: update.scheduledOnUtc ? true : false,
                        originalModel: update,
                        scheduledOn: update.scheduledOnUtc ? update.scheduledOnUtc.clone().local() : null
                    })
                });

                popup.showPopup();
            }
        },

        _handleSocialNew: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonSocialMediaUpdatesPopupView({

                callbacks: {

                    onConfirm: function(data) {

                        var updates = self.editModel.get('scheduledContent').concat(data.updates);

                        _.each(updates, function(update) {

                            // Updates can only be scheduled 5 minutes or more after the post has been published.

                            if (update.scheduledOnUtc) {

                                if (!update.scheduledOnUtc.isValid()) update.scheduledOnUtc = null;
                                else if (update.scheduledOnUtc.isBefore(moment.utc().add(5, 'minutes'))) update.scheduledOnUtc = null;
                                else if (self.editModel.get('scheduledOn')) {

                                    if (update.scheduledOnUtc.isBefore(self.editModel.get('scheduledOn').clone().utc().add(5, 'minutes'))) update.scheduledOnUtc = null;
                                }
                            }
                        });

                        self.editModel.set('scheduledContent', updates);
                        self.editModel.trigger('changedByUser', self.editModel);

                        Love.Helpers.Tracking.track('Post editor - added sidebar social updates', {

                            model: self.editModel.id
                        });

                        self._renderSocial();
                        self._updateSocialText();
                    }
                },
                data: {

                    isScheduled: false,
                    scheduledOn: this.editModel.get('scheduledOn') ? this.editModel.get('scheduledOn').clone().add(1, 'hours') : null
                }
            });

            popup.showPopup();
        },

        _handleSocialRemove: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var index = $(e.target).closest('[data-index]').attr('data-index');
            var update = this.editModel.get('scheduledContent')[index];

            if (update) {

                var self = this;
                var popup = new Love.Views.CommonQuestionPopupView({

                    callbacks: {

                        onYes: function() {

                            self.editModel.set('scheduledContent', _.without(self.editModel.get('scheduledContent'), update));
                            self.editModel.trigger('changedByUser', self.editModel);

                            self._renderSocial();
                            self._updateSocialText();

                            Love.Helpers.Tracking.track('Post editor - removed sidebar social update', {

                                model: self.editModel.id
                            });
                        }
                    },
                    data: {

                        noText: 'Cancel',
                        title: 'Remove social update?',
                        text: 'Do you really want to remove the social update? This action can\'t be undone.',
                        yesText: 'Remove'
                    }
                });

                popup.showPopup();
            }
        },

        _handleStorylineSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var element = $(e.target).closest('li');

            var id = element.attr('data-id');
            var value = element.attr('data-value');

            var storyline = _.find(Love.session.get('currentSite').get('storylines'), function(obj) {return obj.id === id;});

            if (element.hasClass('selected')) {

                var existing = _.findIndex(this.editModel.get('storylines'), function(obj) { return obj.id === id; });

                if (existing === -1 && storyline) this.editModel.get('storylines').push(storyline);
            }
            else {

                this.editModel.set('storylines', _.filter(this.editModel.get('storylines'), function(obj) {return obj.title !== value;}));

                if (!storyline) {

                    // If we deselected a storyline that wasn't already saved to the database, we need to ensure its removal from the list.

                    this._renderStorylines();
                }
            }

            this.editModel.trigger('change', this.editModel);
            this.editModel.trigger('changedByUser', this.editModel);

            Love.Helpers.Tracking.track('Post editor - changed sidebar storylines', {

                model: this.editModel.id
            });

            this._updateStorylinesText();
        },

        _handleStorylineAdd: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var input = $(e.target).closest('.sidebar-list').find('input[name="new"]'); // This also works if entering via the keyup handler.
            var title = input.val();

            if (title) {

                var existing = _.findIndex(this.editModel.get('storylines'), function(obj) { return obj.title.toUpperCase() === title.toUpperCase(); });

                if (existing === -1) {

                    this.editModel.get('storylines').push({title: title});

                    this.editModel.trigger('change', this.editModel);
                    this.editModel.trigger('changedByUser', this.editModel);

                    this._renderStorylines();
                    this._updateStorylinesText();
                }
            }

            Love.Helpers.Tracking.track('Post editor - added sidebar storyline', {

                model: this.editModel.id,
                data: title
            });
        },

        _handleStorylineAddKeyUp: function(e) {

            if (e.keyCode === 13) { // Add on enter.

                e.preventDefault();
                this._handleStorylineAdd(e);
            }
        },

        _handleTagAdd: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var input = $(e.target).closest('.sidebar-tags').find('input[name="tag"]'); // This also works if entering via the keyup handler.
            var list = $(e.target).closest('.sidebar-tags').find('.common-tags-list');

            var name = input.val();

            if (name) {

                var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];
                var existing = _.findIndex(revision.tags, function(tag) { return tag.toUpperCase() === name.toUpperCase(); });

                if (existing === -1) {

                    revision.tags.push(name);

                    this.editModel.trigger('change:revisions', this.editModel, this.editModel.get('revisions'), {revision: revision});
                    this.editModel.trigger('change', this.editModel);
                    this.editModel.trigger('changedByUser', this.editModel);

                    list.append('<div class="tag removable" data-value="' + name + '"><span class="name truncate-single">' + name + '</span><a class="remove" data-action="remove-tag"><i class="icon ttl-cross-smaller"></i></a></div>');

                    this._updateTagsText();
                }
            }

            input.val('');
            this._handleTagAddChange(e);

            Love.Helpers.Tracking.track('Post editor - added sidebar tag', {

                model: this.editModel.id,
                data: name
            });
        },

        _handleTagAddChange: function(e) {

            // Due to the timing of the paste event, we have to defer execution.

            _.defer(function() {

                var input = $(e.target).closest('.sidebar-tags').find('input[name="tag"]'); // This also works if entering via the keyup handler.
                var button = $(e.target).closest('.sidebar-tags').find('[data-action="add-tag"]');

                if (!_.isEmpty(input.val()))
                    button.removeClass('disabled');
                else
                    button.addClass('disabled');
            });
        },

        _handleTagAddKeyUp: function(e) {

            if (e.keyCode === 13) { // Add on enter.

                e.preventDefault();
                this._handleTagAdd(e);
            }
        },

        _handleTagRemove: function(e) {

            var tag = $(e.target).closest('.tag');
            var name = tag.attr('data-value');

            var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];
            revision.tags = _.without(revision.tags, name);

            this.editModel.trigger('change:revisions', this.editModel, this.editModel.get('revisions'), {revision: revision});
            this.editModel.trigger('change', this.editModel);
            this.editModel.trigger('changedByUser', this.editModel);

            tag.remove();

            Love.Helpers.Tracking.track('Post editor - removed sidebar tag', {

                model: this.editModel.id,
                data: name
            });

            this._updateTagsText();
        },

        _handleToggleDropdown: function(e) {

            var type = $(e.element).closest('[data-type]').attr('data-type');

            if (type === 'social' && e.isOpen) {

                // Open the social updates popup immediately if no updates have been added to the post.

                if (this.editModel.get('scheduledContent').length < 1)
                    this._handleSocialNew();
            }
        },

        _showCoverImagePopup: function(extraImages) {

            var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];

            var currentImage = new Love.Models.ImageModel(revision.cover);
            var currentOgImage = new Love.Models.ImageModel(revision.ogImage);

            var images = this.editModel.getCoverImagesAvailable(revision);

            // Concatenate and remove duplicate images.

            if (extraImages)
                images = images.concat(extraImages);

            images = _.compact(images);
            images = _.uniq(images, false, function(model) {return model.getImageView();});

            var popup = new Love.Views.CommonCoverImagePopupView({

                callbacks: {

                    onConfirm: _.bind(this._handleCoverImage, this)
                },
                data: {

                    imageModel: _.find(images, function(model) {

                        return (model.getImageView() === currentImage.getImageView());
                    }),
                    images: images,
                    ogImageModel: currentOgImage
                }
            });

            popup.showPopup();
        },

        _updateAuthorText: function() {

            if (this.editModel.get('revisions')[this.viewState.get('activeRevision')].author.displayName)
                this.$('[data-type="author"] .sidebar-row-content span').html("<em class='filled'>" + this.editModel.get('revisions')[this.viewState.get('activeRevision')].author.displayName + "</em> will be credited as author.");
            else
                this.$('[data-type="author"] .sidebar-row-content span').html("<em>Who</em> wrote this article?");
        },

        _updateCategoryText: function() {

            var blog = (this.editModel.get('groupId')) ? _.findWhere(Love.session.get('currentSite').get('blogs'), {id: this.editModel.get('groupId')}) : null;

            if (blog)
                this.$('[data-type="category"] .sidebar-row-content span').html("Categorized as <em class='filled'>" + blog.title + "</em>.");
            else
                this.$('[data-type="category"] .sidebar-row-content span').html("This post is not part of any <em>category</em> yet.");
        },

        _updateCoverImage: function(coverModel, showFeedback) {

            if (this._imageUploadView) this._imageUploadView.close();

            var uploadContainer = this.$('[data-type="cover-image"].common-image-upload-container');
            var imageContainer = this.$('[data-type="cover-image"]:not(.common-image-upload-container)');

            uploadContainer.addClass('hidden');
            imageContainer.removeClass('hidden');

            Love.Helpers.Images.loadAnImage({

                element: imageContainer.find('.image'),
                focusPoint: coverModel.getFocusPoint(),
                loadImageOptions: {

                    orientation: true
                },
                onLoad: _.bind(function() {

                    if (showFeedback) {

                        // Show the cover image feedback for a few seconds.

                        imageContainer.find('.hover-feedback').css({'opacity': 1});
                        setTimeout(function() {imageContainer.find('.hover-feedback').css({'opacity': 0});}, 3000);
                    }

                    // Show the focus point, if applicable.

                    var revision = this.editModel.get('revisions')[this.viewState.get('activeRevision')];
                    var hasOgImage = revision.ogImage && !_.isEmpty((new Love.Models.ImageModel(revision.ogImage)).getImageView());

                    if (!hasOgImage)
                        imageContainer.find('.focuspoint').removeClass('hidden');
                    else
                        imageContainer.find('.focuspoint').addClass('hidden');

                }, this),
                url: coverModel.getImageView(),
                useProxy: true
            });
        },

        _updateLanguagesText: function() {

            var lang = this.viewState.get('activeRevision');
            var langName = (lang === '') ? Love.t('languages:intl') : Love.t('languages:' + lang);

            this.$('[data-type="languages"] .sidebar-row-content span').html("You are now editing the <em class='filled'>" + langName + "</em> version.");
        },

        _updateSavedText: function() {

            var changedAttrs = this.editModel.changedAttributesJSON(this.editModel.serverAttributes);
            var isNew = this.editModel.isNew();

            if (!isNew && !changedAttrs && this.editModel.get('revisions')[this.viewState.get('activeRevision')].updatedOnUtc) {

                var date = this.editModel.get('revisions')[this.viewState.get('activeRevision')].updatedOnUtc.clone().local();
                this.$('[data-type="saved"] .sidebar-row-content span').html("Last saved on <em class='filled'>" + date.format('ddd, DD MMM YYYY, HH:mm') + "</em>.");
            }
            else
                this.$('[data-type="saved"] .sidebar-row-content span').html("Your changes have <em>not been saved</em>.");
        },

        _updateScheduledText: function() {

            var date = this.editModel.get('scheduledOn');

            if (date && this.editModel.get('isScheduled'))
                this.$('[data-type="scheduled"] .sidebar-row-content span').html("Scheduled for <em class='filled'>" + date.format('ddd, DD MMM YYYY, HH:mm') + "</em>.");
            else if (date)
                this.$('[data-type="scheduled"] .sidebar-row-content span').html("This post will be scheduled for <em class='filled'>" + date.format('ddd, DD MMM YYYY, HH:mm') + "</em>.");
            else
                this.$('[data-type="scheduled"] .sidebar-row-content span').html("This post wil be published immediately. Do you want to <em>schedule</em> it?");
        },

        _updateSocialText: function() {

            if (this.editModel.get('scheduledContent').length > 0)
                this.$('[data-type="social"] .sidebar-row-content span').html("You have added <em class='filled'>" + this.editModel.get('scheduledContent').length + "</em> social media update(s).");
            else
                this.$('[data-type="social"] .sidebar-row-content span').html("Do you want to add a <em>social media</em> update?");
        },

        _updateStorylinesText: function() {

            if (this.editModel.get('storylines').length > 0)
                this.$('[data-type="storylines"] .sidebar-row-content span').html("The post is part of <em class='filled'>" + this.editModel.get('storylines').length + "</em> storyline(s).");
            else
                this.$('[data-type="storylines"] .sidebar-row-content span').html("Do you want to add it to a <em>storyline</em>?");
        },

        _updateTagsText: function() {

            if (this.editModel.get('revisions')[this.viewState.get('activeRevision')].tags.length > 0) {

                var joined = this.editModel.get('revisions')[this.viewState.get('activeRevision')].tags.join('</em>, <em class="filled">');
                var index = joined.lastIndexOf(',');

                if (index > -1)
                    joined = joined.substring(0, index) + ' and' + joined.substring(index + 1);

                this.$('[data-type="tags"] .sidebar-row-content span').html("The post is tagged as <em class='filled'>" + joined + "</em>.");
            }
            else
                this.$('[data-type="tags"] .sidebar-row-content span').html("You have not added any <em>tags</em>.");
        }
    });

})(Love);