(function(Love) {

    Love.Models.SocialUpdateModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.SocialUpdateModel',

        defaults: function() {

            return {

                id: '',
                contentFragment: null, // Added client side.
                contentType: 'statusUpdate', // shareLink (Facebook) or statusUpdate (Twitter) or uploadTtlFile (Mobypicture)
                contents: null, // Read-only.
                createdOnUtc: null,
                destination: {}, // Added client side.
                destinationId: '',
                external: {

                    // Only available if status === published.

                    id: '',
                    url: ''
                },
                processedOnUtc: null,
                scheduledOnUtc: null,
                scheduledOnUtcIsImmediately: false, // Added client side.
                settings: {

                    message: '',
                    ttlUploadId: null // Used when contentType equals uploadTtlFile.
                },
                status: 'idle' // idle, scheduled, queued, published, unpublished, error
            };
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/socialupdates/' + this.id + '.json';

            // Create url.

            else
                return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/socialupdates.json';
        },

        initialize: function(options) {

            if (options) this.set(options);

            _.bindAll(this, 'getClientBackup', 'removeClientBackup', 'saveClientBackup');
        },

        destroy: function(options) {

            this.removeClientBackup();
            return Love.Models.BaseModel.prototype.destroy.call(this, options);
        },

        parse: function(response, options) {

            var contents = (response.response) ? response.response : response;
            var defaults = this.defaults();

            contents = _.extend(contents, {

                createdOnUtc: contents.createdOnUtc && moment.utc(contents.createdOnUtc).isValid() ? moment.utc(contents.createdOnUtc) : null,
                processedOnUtc: contents.processedOnUtc && moment.utc(contents.processedOnUtc).isValid() ? moment.utc(contents.processedOnUtc) : null,
                scheduledOnUtc: contents.scheduledOnUtc && moment.utc(contents.scheduledOnUtc).isValid() ? moment.utc(contents.scheduledOnUtc) : null,
                settings: contents.settings || defaults.settings
            });

            var destination = _.find(Love.session.get('currentSite').get('destinations'), function(obj) {return obj.id === contents.destinationId;});
            contents.destination = destination || {};

            return contents;
        },

        save: function(attrs, options) {

            // NOTE by RENS: I've wrapped the save function to allow for uploading files before saving the model.

            // In order to maintain asynchronous operation, the new save function returns a Deferred object instead of a jqXHR.
            // The first part is copied from Backbone source files, to support returning false on validation errors.

            // Note that the code assumes that, after uploading the files, validation can't fail anymore!

            // BEGIN COPIED FROM BACKBONE SOURCE.

            options = _.extend({validate: true, parse: true}, options);
            var wait = options.wait;

            if (attrs && !wait) {

                if (!this.set(attrs, options)) return false;
            }
            else if (!this._validate(attrs, options))
                return false;

            // END COPIED FROM BACKBONE SOURCE.

            var resultPromise = $.Deferred();
            var deferredUpload = null;
            var imageModel, fileModel;

            if (attrs.contentType === 'uploadTtlFile') {

				console.log(attrs);

                // Image.

                if (attrs.contentFragment.settings.imageSource === 'upload') {
					
                    imageModel = new Love.Models.ImageModel(attrs.contentFragment.settings);
                    fileModel = new Love.Models.FileModel();

                    deferredUpload = $.Deferred();
					
					//

                    fileModel.uploadScheduleUrl(imageModel.getImageView())
                        .done(_.bind(function() {

                            attrs.settings.ttlUploadId = fileModel.id;
                            this.resolve();

                        }, deferredUpload))
                        .fail(_.bind(function() {this.reject();}, deferredUpload));
                }
                else if (attrs.contentFragment.settings.imageSource === 'url') {

					fileModel = new Love.Models.FileModel();
					deferredUpload = $.Deferred();

					if (attrs.contentFragment.settings.imageURL.substring(0, 5) == 'data:') {
						imageModel = new Love.Models.ImageModel(attrs.contentFragment.settings);
						
						var file = fileModel.dataURItoBlob(imageModel.getImageView());
						
						fileModel.uploadScheduleFile(file)
							.done(_.bind(function() {

								attrs.settings.ttlUploadId = fileModel.id;
								this.resolve();

							}, deferredUpload))
							.fail(_.bind(function() {this.reject();}, deferredUpload));
						
					} else {
						fileModel.uploadScheduleUrl(attrs.contentFragment.settings.imageURL)
							.done(_.bind(function() {

								attrs.settings.ttlUploadId = fileModel.id;
								this.resolve();

							}, deferredUpload))
							.fail(_.bind(function() {this.reject();}, deferredUpload));

					}
                }

                // TODO RENS: support video and audio.
            }

            $.when(deferredUpload).done(_.bind(function() {

                    var xhr = Love.Models.BaseModel.prototype.save.call(this, attrs, _.extend(_.clone(options), {validate: false}));

                    $.when(xhr)
                        .done(function() {resultPromise.resolve();})
                        .fail(function() {resultPromise.reject();});

                }, this))
                .fail(function() {resultPromise.reject();});

            return resultPromise;
        },

        toJSON: function(options) {

            // This model has some custom attributes that should not be sent to the server.
            // Other attributes might just not be necessary for saving and can save some network load.

            var result = Love.Models.BaseModel.prototype.toJSON.call(this, options);

            delete result.contentFragment;
            delete result.destination;
            delete result.scheduledOnUtcIsImmediately;

            return result;
        },

        validate: function(attributes, options) {

            var errors = {};

            if (!attributes.destinationId) {

                errors.destinationId = 'Destination required.';
            }
            else {

                switch (attributes.destination.type) {

                    case 'mobypicture': {

                        if (!attributes.contentFragment) errors.contentFragment = 'Content fragment required.';
                        break;
                    }
                }
            }

            if (!_.isEmpty(errors))
                return errors;
        },

        getClientBackup: function() {

            if (this.isNew()) return null;

            var key = 'social_update_' + this.id;

            var backup = Love.Helpers.LocalStorageSession.getObject(key);
            var date = moment(Love.Helpers.LocalStorageSession.getObject(key + '_updated_at'));

            if (!backup || !date) return null;

            return {

                backup: backup,
                updatedAt: date
            };
        },

        removeClientBackup: function() {

            var key = 'social_update_' + this.id;

            Love.Helpers.LocalStorageSession.removeObject(key);
            Love.Helpers.LocalStorageSession.removeObject(key + '_updated_at');
        },

        saveClientBackup: function() {

            if (this.isNew()) return null;

            // Store a copy of the edited model in the local cache.

            var key = 'social_update_' + this.id;

            Love.Helpers.LocalStorageSession.setObject(key, this.attributes); // Bypass the toJSON method.
            Love.Helpers.LocalStorageSession.setObject(key + '_updated_at', moment());
        }
    });

    Love.Collections.SocialUpdateCollection = Love.Collections.BasePageableCollection.extend({

        objectClassName: 'Love.Collections.SocialUpdateCollection',

        model: Love.Models.SocialUpdateModel,

        url: function() { return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/socialupdates.json'; },

        parseRecords: function(response, options) {

            return response.response;
        }
    });

})(Love);