(function(Love) {

    Love.Views.AppView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.AppView',

        el: 'body',

        defaults: function() {

            return {

                section: ''
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_handleBeforeUnload');

            Love.Helpers.Tracking.track('App initialized');

            $('body')[0].addEventListener('mousedown', Love.Helpers.DragDrop.dragDetectionHandler, true);
            $('body')[0].addEventListener('touchstart', Love.Helpers.DragDrop.dragDetectionHandler, true);

            $(window)[0].addEventListener('dragover', function(e) { e.preventDefault(); }, false);
            $(window)[0].addEventListener('drop', function(e) { e.preventDefault(); }, false);

            $(window).on('beforeunload', this._handleBeforeUnload);

            // NOTE: Konami disabled because it causes problems on mobile!
            //this._easter = new Konami(function() {$('body').toggleClass('rotated');});
        },

        onClose: function() {

            $(window).off('beforeunload', this._handleBeforeUnload);

            $('body')[0].removeEventListener('mousedown', Love.Helpers.DragDrop.dragDetectionHandler, true);
            $('body')[0].removeEventListener('touchstart', Love.Helpers.DragDrop.dragDetectionHandler, true);

            $(window)[0].removeEventListener('dragover');
            $(window)[0].removeEventListener('drop');

            this.removePopupViews(); // Popup views are currently not registered as subViews, so they should be closed manually.

            this.showLoading(false);
        },

        _handleBeforeUnload: function(e) {

            var status = this.raiseNavigate();

            if (status.unsavedChanges)
                return status.message;
        },

        getCurrentSection: function() {

            return this.options.section;
        },

        hasPopups: function() {

            return (Love.popupViews && Love.popupViews.length > 0);
        },

        raiseNavigate: function() {

            var status = {

                message: Love.t('common:dialogs.before_unload_confirmation'),
                unsavedChanges: false
            };

            this.trigger('navigate', status);

            return status;
        },

        removeContentView: function() {

            this.removeSubView(this.contentView);
            this.contentView = null;

            return this;
        },

        removeHeaderView: function() {

            this.removeSubView(this.headerView);
            this.headerView = null;

            return this;
        },

        removeMobyHeroView: function() {

            this.removeSubView(this.mobyHeroView);
            this.mobyHeroView = null;

            return this;
        },

        removePopupViews: function() {

            // Close any popups that were still open (e.g., when clicking the Previous button in the browser).

            if (Love.popupViews) {

                _.each(Love.popupViews, function(view) {view.close();});
                Love.popupViews = [];
            }
        },

        renderAppViewFor: function(view, siteChanged) {

            // Update the latest Moby background view.

            this.updateMobyHeroView(siteChanged);
            //this.teaseMobyHeroView(); NOTE: disabled omdat dit te traag is.

            // Set the new view and render it.

            if (view)
                this.setContentView(view);

            else {

                this.removeContentView();
                this.removePopupViews();
            }

            // Update the header view if necessary.

            this.updateHeaderView();
        },

        setContentView: function(view) {

            this.removeContentView();
            this.removePopupViews();

            this.contentView = this.addSubView(view);
            this.contentView.setElement(this.$('#layout-container')).render();

            if (!Modernizr.cssscrollbar) {

                var width = Love.Helpers.Scrolling.getScrollbarWidth();

                $('.scrollbar-outside').css('margin-right', -width);
                $('.scrollbar-outside-container').css('margin-right', width);
                $('.padding-for-scrollbar').css('padding-right', width);
            }
        },

        setCurrentSection: function(descriptor) {

            this.options.section = descriptor;
        },

        setHeaderView: function(view) {

            this.removeHeaderView();

            this.headerView = this.addSubView(view);
            this.headerView.setElement(this.$('#page-header')).render();
        },

        showLoading: function(show, element) {

            this.removeSubView(this.loadingView);

            if (show) {

                var onScreen = (!element || element.length < 1);

                if (onScreen)
                    element = this.$('#layout-container');

                this.loadingView = this.addSubView(new Love.Views.CommonLoadingOverlayView({parentElement: element}));
                this.loadingView.render();
            }
        },

        /*
         teaseMobyHeroView: function() {

         if (this.mobyHeroView && !this._hasTeased) {

         this.mobyHeroView.teaseMoby();
         _.delay(this.mobyHeroView.hideMoby, 1000);

         this._hasTeased = true;
         }
         },
         */

        updateHeaderView: function() {

            if (!Love.session.get('isLoggedIn')) {

                // Don't display the header.

                this.removeSubView(this.headerView);
                this.headerView = null;
            }
            else {

                // We're logged in and need a header. It might've already been displayed.
                // The header view can be any base header.
                // It needs to be re-rendered to reflect any (session) changes.

                if (!this.headerView)
                    this.headerView = this.addSubView(new Love.Views.CommonHeaderView());

                this.headerView.setElement(this.$('#page-header')).render();
            }
        },

        updateMobyHeroView: function(siteChanged) {

            var isMobile = $(window).width() <= 768; // Tablet or smaller.
            if (isMobile) return;

            // If a site has been selected, try to use it's latest Moby as background.

            if (Love.session.has('currentSite')) {

                if (!this.mobyHeroView) {

                    this.mobyHeroView = this.addSubView(new Love.Views.CommonMobyHeroView());
                    this.mobyHeroView.setElement(this.$('#moby-hero-container')).render();
                }
                else {

                    this.mobyHeroView.hideMoby(); // Since we're switching pages, the hero view should be hidden.

                    if (siteChanged)
                        this.mobyHeroView.updateCollection();
                }
            }
            else if (this.mobyHeroView)
                this.removeMobyHeroView();
        }
    });

})(Love);
