(function (Love) {

    Love.Helpers.AjaxPool = {

        _requests: [],

        abortPendingByIds: function (ids) {

            var removeRequests = [];

            _.each(this._requests, function (request) {

                if (_.indexOf(ids, request.requestId) > -1) {

                    if (request.xhr['readyState'] > 0 && request.xhr['readyState'] < 4) {

                        request.xhr.abort();
                        removeRequests.push(request);
                    }
                }
            });

            this.removeRequests(removeRequests);

            if (removeRequests.length > 0)
                console.log('Pending requests aborted: ' + removeRequests.length);
        },

        add: function (request) {

            this._requests.push(request);
        },

        cleanUp: function () {

            var removeRequests = _.filter(this._requests, function (request) {

                return request.xhr['readyState'] === 4;
            });

            this.removeRequests(removeRequests);
        },

        remove: function (request) {

            this._requests = _.without(this._requests, request);
        },

        removeRequests: function (requests) {

            _.each(requests, function (request) {Love.Helpers.AjaxPool.remove(request);});
        }
    };

})(Love);