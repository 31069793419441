(function(Love) {

    Love.Views.CalendarEventGooglePopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarEventGooglePopupView',

        className: 'base-popup popup-calendar popup-calendar-event popup-clean popup-size-auto',
        id: 'popup-calendar-event-google',

        defaultsForPopupType: function() {

            return {

                data: {

                    calendarEventModel: {}
                },
                enableClickInterception: false
            };
        },

        events: {

            'click [data-action="convert"]': '_handleConvertClick',
            'click [data-action="external"]': '_handleExternalClick'
        },

        initialize: function(options) {

            Love.Views.BasePopupView.prototype.initialize.call(this, options);
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.calendar_popups_event_google)(this.options.data.calendarEventModel.attributes));

            var location = this.options.data.calendarEventModel.get('content').location;

            if (!_.isEmpty(location)) {

                var locationPicker = this.addSubView(new Love.Views.CommonLocationPicker({

                    locationString: location,
                    enableControls: false,
                    useInput: false
                }));

                locationPicker.setElement(this.$('.popup-details-location-map .location-container')).render();

                this.$('.popup-details-location span').html('At ' + location);
            }

            var startTime = moment(this.options.data.calendarEventModel.get('date'));
            var endTime = moment(this.options.data.calendarEventModel.get('dateEnd'));

            var text = '';

            if (this.options.data.calendarEventModel.get('isWholeDay'))
                text = startTime.format('MMM Do');

            else {

                if (startTime.isSame(endTime, 'day'))
                    text = startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm');
                else
                    text = startTime.format('MMM Do, HH:mm') + ' - ' + endTime.format('MMM Do, HH:mm');
            }

            this.$('.popup-details-duration span').html(text);

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleConvertClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            Love.viewBag.eventFromGoogleCache = this.options.data.calendarEventModel.attributes;
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/event/new/google/' + this.options.data.calendarEventModel.get('content').id, {trigger: true});
        },

        _handleExternalClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var win = window.open('https://calendar.google.com/calendar/');

            /*if (win)
             win.focus();*/
        }
    });

})(Love);