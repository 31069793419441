'use strict';

// Perform feature detection for all required features.

var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
var isOldFirefox = false;

if (isFirefox) {

    var match = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    var version = match ? parseInt(match[1]) : 0;

    isOldFirefox = version < 53;
}

if (

    isOldFirefox || // Firefox < 53 is not supported because of https://bugzilla.mozilla.org/show_bug.cgi?id=1281137
    !Modernizr.audio ||
    !Modernizr.blobconstructor ||
    !Modernizr.canvas ||
    !Modernizr.flexbox ||
    !Modernizr.video

) {

    window.location = 'not_supported';
}

// JST object is created via Gruntfile.js in build/templates-compiled.js. It contains all compiled Underscore templates.

var Love = {

    Collections: {},
    Constants: {},
    Enums: {},
    Factories: {},
    Helpers: {},
    Locales: {},
    Models: {},
    Templates: JST,
    Views: {}
};

Love.Constants = {

    CSS_COMMON_MIXIN_TRANSITION_MILD: 200,
    IFRAMELY_API_KEY: '056febd974aa460411e807',
    MOBY_API_KEY: 'ekXjHm8sJdZSTO6D'
};

_.mixin({

    capitalize: function(string) { return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase(); },
    equals: function(args) { return _.isEqual(args); },
    slugify: function(text, id) {

        if (id)
            return (text) ? text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-').replace(/[-]+/g, '-').replace(/^-/g, '') + '-' + id : String(id);
        else
            return (text) ? text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-').replace(/[-]+/g, '-').replace(/^-/g, '') : '';
    },
    stringEndsWith: function(str, suffix) {return str.indexOf(suffix, str.length - suffix.length) !== -1;},
    threeStateValue: function(a, b) {

        if (a && !b) return 1;
        if (!a && b) return 0;
        else return void(0);
    },
    undefinedIfEmpty: function(obj) { return _.isEmpty(obj) ? void(0) : obj; }
});

_.template = _.wrap(_.template, function(func, templateStringOrFunc, settings) {

    // JST compiles Underscore templates as _.template() would do. Therefore, we don't have to compile them again
    // at runtime. However, it's nice to wrap all templates in a _.template() call for clarity and extensibility.

    // To allow for this, we override the _.template() function with a wrapper that checks if the template has already been compiled.

    return (_.isString(templateStringOrFunc)) ? func(templateStringOrFunc, settings) : templateStringOrFunc;
});

// Allow preventing hash navigation.

var originalFn = Backbone.history.loadUrl;

Backbone.history.loadUrl = function() {

    if (Love.appView) {

        var status = Love.appView.raiseNavigate();

        if (status.unsavedChanges) {

            var confirmed = confirm(status.message);

            if (!confirmed) {

                var previousFragment = Backbone.history.fragment;
                window.location.hash = '#' + previousFragment;

                return false;
            }
        }
    }

    return originalFn.apply(this, arguments);
};

// Enable using the proxy server with all jQuery AJAX (GET) requests.

$(document).ajaxSend(function(event, jqXHR, ajaxOptions) {

    if (ajaxOptions.useProxyServer)
        ajaxOptions.url = Love.Helpers.Proxy.getProxyUrl(ajaxOptions.url);
});

// Configure the internationalization engine.

var i18nextBundledBackend = {

    type: 'backend',

    init: function(services, backendOptions, i18nextOptions) { /* use services and options */ },

    read: function(language, namespace, callback) {

        /* return resources */

        if (!Love.Locales[language]) callback('Language ' + language + ' doesn\'t exist.', false);
        else if (!Love.Locales[language][namespace]) callback('Namespace ' + namespace + ' doesn\'t exist in language ' + language + '.', false);
        else {

            callback(null, Love.Locales[language][namespace]);
        }
    },

    // optional

    readMulti: function(languages, namespaces, callback) { /* return multiple resources - useful eg. for bundling loading in one xhr request */ },
    create: function(languages, namespace, key, fallbackValue) { /* save the missing translation */ }
};

i18next.use(i18nextBundledBackend);
i18next.use(window.i18nextIntervalPluralPostProcessor);

// All API dates are either in Amsterdam time or UTC. Timezone information is ignored, therefore we don't send it to the API.
// If dates need timezone information, they should be transformed to UTC both client and API side.

moment.fn.toJSON = function() { return this.format('YYYY-MM-DD HH:mm:ss'); };