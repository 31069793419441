(function(Love) {

    Love.Locales.nl = _.extend(Love.Locales.nl || {}, {

        'calendar': {

            'flairs': {

                // Based on IcoMoon free pack.

                'airplane': 'vlucht, vliegen, vliegtuig, vliegveld',
                'briefcase': 'werk, werken',
                'gift': 'cadeau, verjaardag, feest, jarig',
                'mug': 'drinken, drankje, drankjes, cafe',
                'spoon-knife': 'lunch, lunchen, ontbijt, ontbijten, diner, eten, restaurant'
            }
        }
    });

})(Love);