(function(Love) {

    Love.Collections.BasePageableCollection = Backbone.PageableCollection.extend({

        objectClassName: 'Love.Collections.BasePageableCollection',

        // You can configure the mapping from a `Backbone.PageableCollection#state`
        // key to the query string parameters accepted by your server API.

        queryParams: {

            // `Backbone.PageableCollection#queryParams` converts to ruby's
            // will_paginate keys by default.

            currentPage: 'page',
            pageSize: 'amount',
            totalPages: null,
            totalRecords: null
        },

        // Any `state` or `queryParam` you override in a subclass will be merged with
        // the defaults in `Backbone.PageableCollection` 's prototype.

        state: {

            // You can use 0-based or 1-based indices, the default is 1-based.
            // You can set to 0-based by setting ``firstPage`` to 0.

            firstPage: 1
        },

        parseLinks: function(response, options) {

            var link = _.result(this, 'url');
            var hasNext = response && response.meta && parseInt(response.meta.modeOffset) < parseInt(response.meta.totalPages);
            var hasPrev = response && response.meta && parseInt(response.meta.modeOffset) > this.state.firstPage;

            return {

                first: link,
                next: hasNext ? link : void(0),
                prev: hasPrev ? link : void(0)
            };
        },

        parseState: function(response, queryParams, state, options) {

            return {

                totalRecords: parseInt(response.meta.total)
            };
        }
    });

})(Love);