(function(Love) {

    Love.Views.DashboardAnalyticsViewsTotalModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardAnalyticsViewsTotalModuleView',

        defaults: function() {

            return {

                date: moment().hours(0).minutes(0).seconds(0).milliseconds(0),
                days: 14
            };
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this._eventCollection = new Love.Collections.CalendarEventCollection();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.dashboard_modules_analytics_views_total));

            Love.Helpers.Loading.startFadeInLoading(this.$('.module-content'));

            this.listenTo(this._eventCollection, 'sync', _.bind(function() {

                this._renderGraph();
                Love.Helpers.Loading.finishFadeInLoading(this.$('.module-content'));

            }, this));

            this._eventCollection.getEventsByRange(this.options.date.clone().add(-this.options.days + 1, 'days'), this.options.date);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _renderGraph: function() {

            var data = [];

            for (var i = 0; i < this.options.days; i++) {

                var offset = -(this.options.days - i) + 1;

                var start = this.options.date.clone().add(offset, 'days');
                var end = this.options.date.clone().add(offset + 1, 'days');

                data.push({

                    startDate: start,
                    endDate: end,
                    stats: this._eventCollection.getStatistics(start, end)
                });
            }

            data = _.map(data, function(value) { return value.stats; });
            data.reverse();

            var totalViewsMobys = [0, 0];
            var totalViewsPosts = [0, 0];

            _.each(data, function(dataSet, index) {

                var weekLength = 7;
                index = index < weekLength ? 0 : 1;

                totalViewsMobys[index] += dataSet.totalViewsMobys;
                totalViewsPosts[index] += dataSet.totalViewsPosts;
            });

            totalViewsMobys.reverse();
            totalViewsPosts.reverse();

            var labels = [

                moment().subtract(14, 'days').format('DD MMM') + ' - ' + moment().subtract(7, 'days').format('DD MMM'),
                moment().subtract(6, 'days').format('DD MMM') + ' - Today'
            ];

            var maxLastWeek = totalViewsMobys[0] + totalViewsPosts[0];
            var maxThisWeek = totalViewsMobys[1] + totalViewsPosts[1];
            var maxHeight = Math.max(maxLastWeek, maxThisWeek);

            // Start rendering the data.

            this.$('.last-week-date').text(labels[0]);
            this.$('.this-week-date').text(labels[1]);

            this.$('.label').removeClass('invisible');

            if (totalViewsMobys[0] === 0) this.$('.last-week-mobys').css('display', 'none');
            if (totalViewsPosts[0] === 0) this.$('.last-week-posts').css('display', 'none');
            if (totalViewsMobys[1] === 0) this.$('.this-week-mobys').css('display', 'none');
            if (totalViewsPosts[1] === 0) this.$('.this-week-posts').css('display', 'none');

            var lastWeekMobysPerc = totalViewsMobys[0] / maxHeight * 100;
            var lastWeekPostsPerc = totalViewsPosts[0] / maxHeight * 100;
            var thisWeekMobysPerc = totalViewsMobys[1] / maxHeight * 100;
            var thisWeekPostsPerc = totalViewsPosts[1] / maxHeight * 100;

            this.$('.last-week-mobys').css('height', lastWeekMobysPerc + '%');
            this.$('.last-week-posts').css('height', lastWeekPostsPerc + '%');
            this.$('.this-week-mobys').css('height', thisWeekMobysPerc + '%');
            this.$('.this-week-posts').css('height', thisWeekPostsPerc + '%');

            this.$('.last-week-mobys .amount').text(totalViewsMobys[0]);
            this.$('.last-week-posts .amount').text(totalViewsPosts[0]);
            this.$('.this-week-mobys .amount').text(totalViewsMobys[1]);
            this.$('.this-week-posts .amount').text(totalViewsPosts[1]);

            this.$('.last-week-mobys .tooltip').text('Moby views: ' + totalViewsMobys[0]);
            this.$('.last-week-posts .tooltip').text('Post views: ' + totalViewsPosts[0]);
            this.$('.this-week-mobys .tooltip').text('Moby views: ' + totalViewsMobys[1]);
            this.$('.this-week-posts .tooltip').text('Post views: ' + totalViewsPosts[1]);

            if (lastWeekMobysPerc <= 32) {

                this.$('.icon').css('display', 'block');
                this.$('.last-week-mobys').addClass('small');
            }
            if (lastWeekPostsPerc <= 32) {

                this.$('.icon').css('display', 'block');
                this.$('.last-week-posts').addClass('small');
            }
            if (thisWeekMobysPerc <= 32) {

                this.$('.icon').css('display', 'block');
                this.$('.this-week-mobys').addClass('small');
            }
            if (thisWeekPostsPerc <= 32) {

                this.$('.icon').css('display', 'block');
                this.$('.this-week-posts').addClass('small');
            }
        }
    });

})(Love);