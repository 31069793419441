(function (Love) {

    Love.Views.LoginScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.LoginScreenView',

        events: {

            'click [data-action="submit"]': '_handleSubmit'
        },

        render: function () {

            this.$el.html(_.template(Love.Templates.login_screen)());
            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        _handleSubmit: function (e) {

            if (e && e.preventDefault) e.preventDefault();

            Love.Helpers.Loading.showLoading(true);
            this.$('.error').addClass('hidden');

            // Retrieve the filled-in username and password.

            Love.session.set({

                username: this.$('input[name=email]').val(),
                password: this.$('input[name=password]').val(),
                rememberMe: this.$('input[name=remember]').prop('checked')
            });

            if (Love.session.isValid()) {

                Love.session.login(_.bind(function (result) {

                    Love.Helpers.Loading.showLoading(false);

                    if (result.error) {

                        console.log(result.error);
                        this.$('.error').removeClass('hidden');
                    }
                    else {

                        // Reload the requested page.

                        //var currentPage = Backbone.history.getHash(); // TODO RENS: volgens mij werkt dit niet goed.
                        //Love.router.navigate('f', {replace: true});

                        Love.router.navigate('sites', {trigger: true});
                    }

                }, this));
            }
            else
                Love.Helpers.Loading.showLoading(false);
        }
    });

})(Love);