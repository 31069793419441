(function(Love) {

    Love.Views.CalendarAddContentPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarAddContentPopupView',

        className: 'base-popup popup-list-menu',
        id: '',

        defaultsForPopupType: function() {

            return {

                data: {

                    date: moment(),
                    dayFormatting: false,
                    items: [

                        {icon: 'ttl-compose', name: 'POST', action: 'confirm', type: 'post'},
                        {icon: 'icon-share2', name: 'SOCIAL UPDATE', action: 'confirm', type: 'social'},
                        {icon: 'ttl-calendarevent', name: 'EVENT', action: 'confirm', type: 'event'},
                        {icon: 'ttl-page', name: 'PAGE', action: 'confirm', type: 'page'}
                    ],
                    mode: 'icons',
                    showTime: true,
                    title: '',
                    triangle: true
                }
            };
        },

        events: {

            'click .popup-content li': function(e) { if (e && e.preventDefault) e.preventDefault(); },
            'click [data-type="event"]': '_addContentEvent',
            'click [data-type="page"]': '_addContentPage',
            'click [data-type="post"]': '_addContentPost',
            'click [data-type="social"]': '_addContentSocialUpdate'
        },

        render: function() {

            var schedule = this.options.data.date.isAfter(moment().add(5, 'minutes'));
            var verb = schedule ? 'schedule' : 'create';

            if (!this.options.data.dayFormatting) {

                this.options.data.title = 'What do you want to ' + verb + ' on ' + this.options.data.date.format('DD MMM YYYY');

                if (this.options.data.showTime)
                    this.options.data.title += ', ' + this.options.data.date.format('HH:mm');

                this.options.data.title += '?';
            }
            else
                this.options.data.title = 'What do you want to ' + verb + ' at ' + this.options.data.date.format('HH:mm') + '?';

            this.$el.html(_.template(Love.Templates.common_popups_list_menu)(this.options.data));

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _addContentEvent: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            var date = this.options.data.date;
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/event/new/' + date.format('YYYY-MM-DD') + '/' + date.format('HH:mm'), {trigger: true});
        },

        _addContentPage: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            var date = this.options.data.date;
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/page/new/' + date.format('YYYY-MM-DD') + '/' + date.format('HH:mm'), {trigger: true});
        },

        _addContentPost: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            var date = this.options.data.date;
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/post/new/' + date.format('YYYY-MM-DD') + '/' + date.format('HH:mm'), {trigger: true});
        },

        _addContentSocialUpdate: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            var date = this.options.data.date;
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/social/new/' + date.format('YYYY-MM-DD') + '/' + date.format('HH:mm'), {trigger: true});
        }
    });

})(Love);