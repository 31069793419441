(function(Love) {

    Love.Views.CalendarEventMobyPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarEventMobyPopupView',

        className: 'base-popup popup-calendar popup-calendar-event popup-clean popup-size-auto',
        id: 'popup-calendar-event-moby',

        defaultsForPopupType: function() {

            return {

                data: {

                    calendarEventModel: {}
                },
                enableClickInterception: false
            };
        },

        events: {

            'click [data-action="view"]': '_handleViewClick'
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.calendar_popups_event_moby)(this.options.data.calendarEventModel.attributes));

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleViewClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var context = this.options.data.calendarEventModel.get('content');

            if (!_.isEmpty(context.tinyUrlCode)) {

                this.close();

                var win = window.open('http://moby.to/' + context.tinyUrlCode, '_blank');
                win.focus();
            }
        }

        /*
         _onImageLoad: function (e) {

         var imgDiv = this.$('.popup-calendar-media');

         imgDiv.find('a').prepend('<div class="image"></div>');
         imgDiv.find('a .image').html(e.target);

         this.resetPosition();
         this.makeVisible();
         },

         _onVideoLoad: function (e) {

         var imgDiv = this.$('.popup-calendar-media');

         imgDiv.find('a').prepend('<div class="video"></div>');
         imgDiv.prepend('<video height="298" width="298" src="' + this._image + '" controls="true"></video>');

         //setTimeout(function () {this.resetPosition();}, 200);

         this.makeVisible();
         }
         */
    });

})(Love);