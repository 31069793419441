(function(Love) {

    Love.Views.BasePopupView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.BasePopupView',

        className: 'base-popup',
        id: '',

        defaults: function() {

            return {

                callbacks: {},
                data: {},
                disableScrolling: true,
                enableClickInterception: true,
                enableConfirmByEnter: false,
                enableLightbox: false,
                parentView: null,
                positioning: {

                    attachToElement: null,
                    canBecomeFullScreen: true,
                    minWidth: null,
                    offsetX: 0,
                    offsetY: 0,
                    positionAt: 'screen'
                }
            };
        },

        defaultsForPopupType: function() { return {};},

        defaultsShowPopup: function() {

            return {

                keepOpen: [],
                keepOpenParents: false,
                toggleExisting: true
            };
        },

        viewOptions: function() {

            return _.extend({}, Love.Views.BaseView.prototype.viewOptions, {

                removeElementOnClose: true
            });
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_handleCloseByClickOutside', '_handleConfirmByEnter');

            this.onResize = _.debounce(_.bind(this.onResize, this), 50);

            var defaults = this.defaults();
            var defaultsForPopupType = this.defaultsForPopupType();

            //this.options = _.defaults(options || {}, _.extend(defaults, defaultsForPopupType));
            //this.options = $.extend(true, defaults, defaultsForPopupType, options); // Perform a deep merge of options.

            this.options = lodash.mergeWith(defaults, defaultsForPopupType, options, function (objValue, srcValue) {

                if (_.isArray(objValue))
                    return srcValue;

            }); // Perform a deep merge of options.

            // Create an array that holds references to all initiated popup views.

            if (!Love.popupViews) Love.popupViews = [];

            this._mouse = {touchIdentifier: null, x: 0, y: 0};
        },

        onClose: function() {

            if (this.options.callbacks.onClose)
                this.options.callbacks.onClose();

            // Remove any custom event handlers.

            this.$el.off('keydown', this._handleConfirmByEnter);

            $(window).off('resize', this.onResize);
            $(window).off('scroll', this.onResize);

            $('#page-content *').off('scroll', this.onResize);

            $('body')[0].removeEventListener('mouseup', this._handleCloseByClickOutside, true);

            // Remove the global reference to the popup.

            Love.popupViews = _.without(Love.popupViews, this);

            // Re-enable background scrolling if all popups have been closed.

            if (Love.popupViews.length === 0) {

                Love.Helpers.Scrolling.enableScroll($(window));
                Love.Helpers.Scrolling.enableScroll($('#page-content *'));
            }

            // Remove the lightbox if all popups that need it have been closed.

            var lightboxPopups = _.filter(Love.popupViews, function(view) { return (view.options.enableClickInterception || view.options.enableLightbox); }).length;
            if (lightboxPopups < 1) $('#popup-container .lightbox-background').remove();
        },

        onResize: function() {

            this.resetPosition();
        },

        getParents: function() {

            var current = this.options.parentView;
            var parents = [current];

            while (current && current.options.parentView) {

                parents.push(current.options.parentView);
                current = current.options.parentView;
            }

            return parents;
        },

        isConsideredEqualTo: function(other) {

            var isSameType = _.isEqual(this.objectClassName, other.objectClassName);
            var hasSameData = _.isEqual(other.options.data, this.options.data);

            return (isSameType && hasSameData);
        },

        isFullScreen: function() {

            return this.$el.hasClass('popup-full-screen');
        },

        isPopupView: function() { return true; },

        isPortrait: function() {

            return this.$('.popup-content').hasClass('portrait');
        },

        makeInvisible: function() {

            this.$el.css('opacity', 0);
        },

        makeVisible: function() {

            this.$el.animate({'opacity': 1}, 'fast');
        },

        resetPosition: function() {

            var popup = this.$el;

            this._setPortraitOrLandscape();
            this._setResponsiveSize();

            switch (this.options.positioning.positionAt) {

                case 'element': {
                    this._showAtElement(popup, this.options);
                    break;
                }
                case 'mouse': {
                    this._showAtMouse(popup, this.options);
                    break;
                }
                case 'text': {
                    this._showAtText(popup, this.options);
                    break;
                }
                case 'screen':
                default: {
                    this._showAtScreen(popup, this.options);
                    break;
                }
            }
        },

        showPopup: function(showOptions) {

            showOptions = _.defaults(_.clone(showOptions || {}), this.defaultsShowPopup());

            // Toggle mechanism.

            if (showOptions.toggleExisting && !this._closeExistingPopups(showOptions))
                return;

            // Add a global reference to the popup.

            if (_.indexOf(Love.popupViews, this) < 0)
                Love.popupViews.push(this);

            // Render the specific popup. Append the element first, to allow width measuring etc. inside render functions.

            $('#popup-container').append(this.$el);
            this.render();

            if (!_.isNull(this.options.positioning.minWidth))
                this.$el.css('min-width', this.options.positioning.minWidth);

            this.$el.attr('tabindex', 1); // Necessary for capturing keydown events on the popup.

            // Handle lightbox and click interception options.

            if (this.options.enableClickInterception || this.options.enableLightbox) {

                var hasLightbox = $('#popup-container .lightbox-background').length > 0;
                if (!hasLightbox) $('#popup-container').append('<div class="lightbox-background"></div>');
            }

            if (this.options.enableLightbox)
                $('#popup-container .lightbox-background').stop().animate({opacity: 1}, 300);

            // Position the popup.

            this.resetPosition();

            // Enable responsive sizing of the popup. Note that the anchor element might move as well.

            $(window).off('resize', this.onResize);
            $(window).on('resize', this.onResize);

            $(window).off('scroll', this.onResize);
            $(window).on('scroll', this.onResize);

            $('#page-content *').off('scroll', this.onResize);
            $('#page-content *').on('scroll', this.onResize);

            // Make it possible to click outside of the popup to close it.

            $('body')[0].removeEventListener('mouseup', this._handleCloseByClickOutside, true);
            $('body')[0].addEventListener('mouseup', this._handleCloseByClickOutside, true);

            // Enable confirm on enter / close on escape.

            this.$el.on('keydown', this._handleConfirmByEnter);

            // Disable background scrolling.

            if (this.options.disableScrolling) {

                Love.Helpers.Scrolling.disableScroll($(window));
                Love.Helpers.Scrolling.disableScroll($('#page-content *'));
            }
        },

        _closeExistingPopups: function(showOptions) {

            var showNewPopup = true;

            // Toggle mechanism: if the same popup is already open, close it and stop.
            // If another popup is open, close it and open the new one.

            _.each(Love.popupViews, function(view) {

                if (this.isConsideredEqualTo(view)) {

                    view.close();
                    showNewPopup = false;
                }
                else {

                    var keepOpenPopups = _.clone(showOptions.keepOpen);

                    if (showOptions.keepOpenParents) {

                        _.each(showOptions.keepOpen, function(popup) {

                            keepOpenPopups = keepOpenPopups.concat(popup.getParents());
                        });
                    }

                    var keepOpen = (_.indexOf(_.unique(keepOpenPopups), view) > -1);
                    if (!keepOpen) view.close();
                }

            }, this);

            return showNewPopup;
        },

        _handleCloseByClickOutside: function(e) {

            if (Love.Helpers.DragDrop.isDragOperation(e))
                return; // Don't handle the event if the user performed a dragging operation.

            // Check if the click was outside the current view.

            var shouldBeClosed = false;
            var closestViewWithId = $(e.target).closest('.base-popup[data-client-id]');

            if (closestViewWithId.length < 1) // If no popup was clicked.
                shouldBeClosed = true;

            else {

                if (closestViewWithId.attr('data-client-id') !== this.cid) {

                    // If another popup was clicked.

                    var other = _.findWhere(Love.popupViews, {cid: closestViewWithId.attr('data-client-id')});

                    // Check if the other popup was opened / is owned by the current one. If not, the current one should be closed.

                    if (!other || !this._isParentViewOf(other))
                        shouldBeClosed = true;
                }
            }

            if (shouldBeClosed) {

                // If the popup should be closed because of clicking outside, we defer this action until
                // after the current events chain has completed.

                // This allows the toggle mechanism to execute first (unless it has been deferred as well, in which
                // case this won't work) if the click outside was on an action that opens the current popup.

                _.defer(_.bind(this.close, this));
            }
        },

        _handleConfirmByEnter: function(e) {

            if (!this.options.enableConfirmByEnter) return;

            if (e.keyCode === 13) {

                e.preventDefault();
                this.trigger('confirmRequested', this);
            }
            else if (e.keyCode === 27) {

                e.preventDefault();
                this.trigger('closeRequested', true);
            }
        },

        _isParentViewOf: function(other) {

            if (this === other.options.parentView) return true;

            var parents = [];
            var current = other.options.parentView;

            while (current && current.options.parentView) {

                parents.push(current.options.parentView);
                current = current.options.parentView;
            }

            return (_.indexOf(parents, this) > -1);
        },

        _setPortraitOrLandscape: function() {

            var isPortrait = $(window).height() > $(window).width();

            if (isPortrait)
                this.$('.popup-content').addClass('portrait');
            else
                this.$('.popup-content').removeClass('portrait');
        },

        _setResponsiveSize: function() {

            // NOTE: height checks have been removed. Only screen width now determines full screen state.

            this.$el.removeClass('popup-full-screen');

            if (this.options.positioning.canBecomeFullScreen) {

                // Determine if the size of the popup exceeds the popup view area. If so, it should become full screen.

                // var height = this.$el.outerHeight(true);
                var width = this.$el.outerWidth(true);

                var layoutSpacing = $('#common-header').height(); // TODO RENS: this is only approximately true. It concerns the margin/padding for the visible layout.

                // var maxHeight = $(window).height() - (2 * $('#common-header').height());
                var maxWidth = $(window).width() - (2 * layoutSpacing);

                if (/* height > maxHeight || */ width > maxWidth || $(window).width() < 768) // 768 = common mobile layout breakpoint.
                    this.$el.addClass('popup-full-screen');
            }
        },

        _showAtElement: function(popup, options) {

            if (this.isFullScreen()) return;

            var preferences = options.positioning;

            var windowSize = {

                height: $(window).height() - $('#common-header').height(),
                width: $(window).width()
            };

            var element = preferences.attachToElement;
            var elementBounds = element[0].getBoundingClientRect();

            elementBounds = _.extend(elementBounds, {

                halfX: elementBounds.left + elementBounds.width / 2,
                halfY: elementBounds.top + elementBounds.height / 2
            });

            var offsetX = (typeof(preferences.offsetX) !== 'undefined') ? preferences.offsetX : 0;
            var offsetY = (typeof(preferences.offsetY) !== 'undefined') ? preferences.offsetY : 0;

            var triangleSize = popup.find('.popup-triangle').outerWidth(true) / 2; // Triangles are actually squares.

            var result = {

                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            };

            // TODO: als op een van de assen gecentreerd wordt heeft dat denk ik ook invloed op de beschikbare ruimte op de andere as.

            // Position on the X axis. First determine where there's enough space to show the popup.

            var canBeLeft = ((elementBounds.left + offsetX - triangleSize - popup.outerWidth(true)) >= 0);
            var canBeRight = ((elementBounds.right + offsetX + triangleSize + popup.outerWidth(true)) <= windowSize.width);

            var centerXLeft = elementBounds.halfX + offsetX - popup.outerWidth(true) / 2;
            var centerXRight = elementBounds.halfX + offsetX + popup.outerWidth(true) / 2;
            var canBeCenterX = (centerXLeft > 0 && centerXRight <= windowSize.width);

            var positionX = 'center';

            if (preferences.centerX && canBeCenterX) positionX = 'center';
            else if (preferences.left && canBeLeft) positionX = 'left';
            else if (preferences.right && canBeRight) positionX = 'right';
            else {

                // The preferred positioning mode could not be set. Pick the first one that can be, in order of expectancy.

                if (canBeRight) positionX = 'right';
                else if (canBeCenterX) positionX = 'center';
                else if (canBeLeft) positionX = 'left';
            }

            switch (positionX) {

                case 'center': {
                    result.left = centerXLeft;
                    result.right = result.left + popup.outerWidth(true);
                    break;
                }
                case 'left': {
                    result.left = elementBounds.left + offsetX - popup.outerWidth(true);
                    result.right = result.left + popup.outerWidth(true);
                    break;
                }
                case 'right': {
                    result.left = elementBounds.left + elementBounds.width + offsetX;
                    result.right = result.left + popup.outerWidth(true);
                    break;
                }
            }

            // Position on the Y axis. First determine where there's enough space to show the popup.

            var canBeTop = ((elementBounds.top + offsetY - triangleSize - popup.outerHeight(true)) >= $('#common-header').height());
            var canBeBottom = ((elementBounds.bottom + offsetY + triangleSize + popup.outerHeight(true)) <= windowSize.height);

            var centerYTop = elementBounds.halfY + offsetY - popup.outerHeight(true) / 2;
            var centerYBottom = elementBounds.halfY + offsetY + popup.outerHeight(true) / 2;
            var canBeCenterY = (centerYTop > 0 && centerYBottom <= windowSize.height);

            var positionY = 'center';

            if (preferences.centerY && canBeCenterY) positionY = 'center';
            else if (preferences.top && canBeTop) positionY = 'top';
            else if (preferences.bottom && canBeBottom) positionY = 'bottom';
            else {

                // The preferred positioning mode could not be set. Pick the first one that can be, in order of expectancy.

                if (canBeBottom) positionY = 'bottom';
                else if (canBeCenterY) positionY = 'center';
                else if (canBeTop) positionY = 'top';
            }

            switch (positionY) {

                case 'center': {
                    result.top = centerYTop;
                    result.bottom = result.top + popup.outerHeight(true);
                    break;
                }
                case 'top': {
                    result.top = elementBounds.top + offsetY - popup.outerHeight();
                    result.bottom = result.top + popup.outerHeight(true);
                    break;
                }
                case 'bottom': {
                    result.top = elementBounds.top + element.outerHeight() + offsetY;
                    result.bottom = result.top + popup.outerHeight(true);
                    break;
                }
            }

            // Add triangle depending on position.

            if (result.left >= elementBounds.right && Love.Helpers.General.getLineOverlap(result.top, result.bottom, elementBounds.top, elementBounds.bottom) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-left');
                result.left += triangleSize;
                result.right += triangleSize;
            }
            else if (result.right <= elementBounds.left && Love.Helpers.General.getLineOverlap(result.top, result.bottom, elementBounds.top, elementBounds.bottom) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-right');
                result.left -= triangleSize;
                result.right -= triangleSize;
            }
            else if (result.top >= elementBounds.bottom && Love.Helpers.General.getLineOverlap(result.left, result.right, elementBounds.left, elementBounds.right) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-top');
                result.top += triangleSize;
                result.bottom += triangleSize;
            }
            else if (result.bottom <= elementBounds.top && Love.Helpers.General.getLineOverlap(result.left, result.right, elementBounds.left, elementBounds.right) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-bottom');
                result.top -= triangleSize;
                result.bottom -= triangleSize;
            }
            else {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle hidden');
            }

            if (preferences.clampY) {

                switch (positionY) {

                    case 'top': {
                        result.top = preferences.clampY.min ? Math.min(result.top, preferences.clampY.min - popup.outerHeight() - triangleSize) : result.top;
                        result.top = preferences.clampY.max ? Math.max(result.top, preferences.clampY.max - popup.outerHeight() - triangleSize) : result.top;
                        break;
                    }
                    case 'bottom': {
                        result.top = preferences.clampY.min ? Math.min(result.top, preferences.clampY.min) : result.top;
                        result.top = preferences.clampY.max ? Math.max(result.top, preferences.clampY.max) : result.top;
                        break;
                    }
                }
            }

            popup.css({position: 'fixed', top: result.top, left: result.left});
        },

        _showAtMouse: function(popup, options) {

            if (this.isFullScreen()) return;

            popup.find('.popup-triangle').addClass('popup-triangle hidden');

            var x = options.positioning.offsetX;
            var y = options.positioning.offsetY;

            // Determine where there's enough space to show the popup. The default location is at the bottom right of the mouse position.

            var windowSize = {

                height: $(window).height() - $('#common-header').height(),
                width: $(window).width()
            };

            var right = ((x + popup.outerWidth()) <= windowSize.width);
            var bottom = ((y + popup.outerHeight()) <= windowSize.height);

            if (!right) x = x - popup.outerWidth();
            if (!bottom) y = y - popup.outerHeight();

            popup.css({position: 'fixed', top: y, left: x});
        },

        _showAtScreen: function(popup, options) {

            if (this.isFullScreen()) return;

            var preferences = options.positioning;

            switch (preferences.location) {

                case 'center':
                default: {

                    popup.addClass('popup-centered');
                    break;
                }
            }
        },

        _showAtText: function(popup, options) {

            if (this.isFullScreen()) return;

            var preferences = options.positioning;

            var windowSize = {

                height: $(window).height() - $('#common-header').height(),
                width: $(window).width()
            };

            var textRange = preferences.attachToElement;
            var elementBounds = textRange.getBoundingClientRect(); // TODO JEROEN: waardes lijken niet te kloppen, nog niks kunnen vinden over waarom.

            elementBounds = _.extend(elementBounds, {

                halfX: elementBounds.left + elementBounds.width / 2,
                halfY: elementBounds.top + elementBounds.height / 2
            });

            var offsetX = (typeof(preferences.offsetX) !== 'undefined') ? preferences.offsetX : 0;
            var offsetY = (typeof(preferences.offsetY) !== 'undefined') ? preferences.offsetY : 0;

            var triangleSize = popup.find('.popup-triangle').outerWidth(true) / 2; // Triangles are actually squares.

            var result = {

                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            };

            // TODO: als op een van de assen gecentreerd wordt heeft dat denk ik ook invloed op de beschikbare ruimte op de andere as.

            // Position on the X axis. First determine where there's enough space to show the popup.

            var canBeLeft = ((elementBounds.left + offsetX - triangleSize - popup.outerWidth(true)) >= 0);
            var canBeRight = ((elementBounds.right + offsetX + triangleSize + popup.outerWidth(true)) <= windowSize.width);

            var centerXLeft = elementBounds.halfX + offsetX - popup.outerWidth(true) / 2;
            var centerXRight = elementBounds.halfX + offsetX + popup.outerWidth(true) / 2;
            var canBeCenterX = (centerXLeft > 0 && centerXRight <= windowSize.width);

            var positionX = 'center';

            if (preferences.centerX && canBeCenterX) positionX = 'center';
            else if (preferences.left && canBeLeft) positionX = 'left';
            else if (preferences.right && canBeRight) positionX = 'right';
            else {

                // The preferred positioning mode could not be set. Pick the first one that can be, in order of expectancy.

                if (canBeRight) positionX = 'right';
                else if (canBeCenterX) positionX = 'center';
                else if (canBeLeft) positionX = 'left';
            }

            switch (positionX) {

                case 'center': {
                    result.left = centerXLeft;
                    result.right = result.left + popup.outerWidth(true);
                    break;
                }
                case 'left': {
                    result.left = elementBounds.left + offsetX - popup.outerWidth(true);
                    result.right = result.left + popup.outerWidth(true);
                    break;
                }
                case 'right': {
                    result.left = elementBounds.left + elementBounds.width + offsetX;
                    result.right = result.left + popup.outerWidth(true);
                    break;
                }
            }

            // Position on the Y axis. First determine where there's enough space to show the popup.

            var canBeTop = ((elementBounds.top + offsetY - triangleSize - popup.outerHeight(true)) >= $('#common-header').height());
            var canBeBottom = ((elementBounds.bottom + offsetY + triangleSize + popup.outerHeight(true)) <= windowSize.height);

            var centerYTop = elementBounds.halfY + offsetY - popup.outerHeight(true) / 2;
            var centerYBottom = elementBounds.halfY + offsetY + popup.outerHeight(true) / 2;
            var canBeCenterY = (centerYTop > 0 && centerYBottom <= windowSize.height);

            var positionY = 'center';

            if (preferences.centerY && canBeCenterY) positionY = 'center';
            else if (preferences.top && canBeTop) positionY = 'top';
            else if (preferences.bottom && canBeBottom) positionY = 'bottom';
            else {

                // The preferred positioning mode could not be set. Pick the first one that can be, in order of expectancy.

                if (canBeBottom) positionY = 'bottom';
                else if (canBeCenterY) positionY = 'center';
                else if (canBeTop) positionY = 'top';
            }

            switch (positionY) {

                case 'center': {
                    result.top = centerYTop;
                    result.bottom = result.top + popup.outerHeight(true);
                    break;
                }
                case 'top': {
                    result.top = elementBounds.top + offsetY - popup.outerHeight();
                    result.bottom = result.top + popup.outerHeight(true);
                    break;
                }
                case 'bottom': {
                    result.top = elementBounds.top + elementBounds.height + offsetY;
                    result.bottom = result.top + popup.outerHeight(true);
                    break;
                }
            }

            // Add triangle depending on position.

            if (result.left >= elementBounds.right && Love.Helpers.General.getLineOverlap(result.top, result.bottom, elementBounds.top, elementBounds.bottom) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-left');
                result.left += triangleSize;
                result.right += triangleSize;
            }
            else if (result.right <= elementBounds.left && Love.Helpers.General.getLineOverlap(result.top, result.bottom, elementBounds.top, elementBounds.bottom) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-right');
                result.left -= triangleSize;
                result.right -= triangleSize;
            }
            else if (result.top >= elementBounds.bottom && Love.Helpers.General.getLineOverlap(result.left, result.right, elementBounds.left, elementBounds.right) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-top');
                result.top += triangleSize;
                result.bottom += triangleSize;
            }
            else if (result.bottom <= elementBounds.top && Love.Helpers.General.getLineOverlap(result.left, result.right, elementBounds.left, elementBounds.right) >= triangleSize) {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle popup-triangle-bottom');
                result.top -= triangleSize;
                result.bottom -= triangleSize;
            }
            else {

                popup.find('.popup-triangle').removeClass().addClass('popup-triangle hidden');
            }

            if (preferences.clampY) {

                switch (positionY) {

                    case 'top': {
                        result.top = preferences.clampY.min ? Math.min(result.top, preferences.clampY.min - popup.outerHeight() - triangleSize) : result.top;
                        result.top = preferences.clampY.max ? Math.max(result.top, preferences.clampY.max - popup.outerHeight() - triangleSize) : result.top;
                        break;
                    }
                    case 'bottom': {
                        result.top = preferences.clampY.min ? Math.min(result.top, preferences.clampY.min) : result.top;
                        result.top = preferences.clampY.max ? Math.max(result.top, preferences.clampY.max) : result.top;
                        break;
                    }
                }
            }

            popup.css({position: 'fixed', top: result.top, left: result.left});
        }
    });

})(Love);