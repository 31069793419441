(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'remix': {

            'screen': {

                'common_content_list_actions_create_post_interval': '(0){Create post, newest first};(1-inf){Create post ({{count}}), newest first};',
                'common_content_list_actions_create_post_reversed_interval': '(0){Create post};(1-inf){Create post ({{count}})};',
                'common_content_list_actions_delete_interval': '(0){Delete bookmarks};(1){Delete 1 bookmark};(2-inf){Delete {{count}} bookmarks};'
            }
        }
    });

})(Love);