(function (Love) {

    Love.Views.CommonNotificationOverlayView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonNotificationOverlayView',

        className: 'common-notification-overlay',

        defaults: function() {

            return {

                dock: '',
                text: ''
            };
        },

        viewOptions: function () {

            return _.extend({}, Love.Views.BaseView.prototype.viewOptions, {

                removeElementOnClose: true
            });
        },

        render: function () {

            this.$el.html(this.options.text);

            if (!_.isEmpty(this.options.dock))
                this.$el.addClass('dock-' + this.options.dock);

            return Love.Views.BaseView.prototype.render.call(this);
        }
    });

})(Love);