(function(Love) {

    Love.Views.SocialUpdateRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.SocialUpdateRowView',

        defaults: function() {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true,
                selectable: true,
                selected: false
            });
        },

        renderItem: function() {

            // If any changes were saved to the client backup, display those instead of the server values.

            var entry = this.options.item.getClientBackup();
            if (entry) this.options.item.set(this.options.item.parse(entry.backup));

            var coverUrl = this.options.item.get('destination').avatarUrl;

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    externalUrl: this.options.item.get('external') ? this.options.item.get('external').url : '',
                    hasCoverImage: !_.isEmpty(coverUrl),
                    hasUnsavedChanges: (entry !== null),
                    isLoading: _.isEmpty(this.options.item.id)
                })
            });

            this.$el.html(_.template(Love.Templates.social_update_row)(context));

            if (!_.isEmpty(coverUrl)) {

                Love.Helpers.Images.loadAnImage({

                    element: this.$('.image'),
                    onLoad: _.bind(function() {

                        this.$('.image .placeholder').addClass('hidden');

                    }, this),
                    url: coverUrl
                });
            }
            else
                this.$('.image .placeholder').removeClass('hidden');
        }
    });

})(Love);