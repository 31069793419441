(function(Love) {

    Love.Views.BaseContentListRowView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.BaseContentListRowView',

        defaults: function() {

            return {

                collapsible: false,
                collapsed: true,
                deletable: false,
                item: null,
                layoutBlock: true,
                selectable: false,
                selected: false,
                type: 'item' // item, more
            };
        },

        viewOptions: function() {

            return _.extend({}, Love.Views.BaseView.prototype.viewOptions, {

                removeElementOnClose: true
            });
        },

        events: {

            'click': '_handleItemClick',
            'click [data-action="collapse"]': '_handleItemCollapse',
            'click [data-action="select"]': '_handleItemSelect',
            'click [data-action="delete"]': '_handleItemDelete',
            'click [data-action="edit"]': '_handleItemEdit',
            'click [data-action="external"]': '_handleItemExternal',
            'click [data-action="remix"]': '_handleItemRemix',
            'click [data-action="more"]': '_handleMore'
        },

        render: function(dontTrigger) {

            this.$el.addClass('list-row');

            if (this.options.layoutBlock)
                this.$el.addClass('layout-part-block');

            if (this.options.collapsible)
                this.$el.addClass('collapsible');

            if (this.options.type === 'item') {

                var id = this._getItemId();

                if (!id)
                    this.$el.addClass('loading');

                else {

                    this.$el.removeClass('loading');
                    this.$el.attr('data-id', id);
                }
            }
            else if (this.options.type === 'more') {

                this.$el.addClass('list-row-more');
            }

            this.renderItem();

            if (this.options.collapsible)
                this.setCollapsed(this.options.collapsed, true);

            if (this.options.selectable)
                this.setSelected(this.options.selected, true);

            return Love.Views.BaseView.prototype.render.call(this, dontTrigger);
        },

        renderItem: function() {

            // Override in specific class.
        },

        getItem: function() { return this.options.item; },

        isCollapsed: function() {

            return this.options.collapsible && this.$el.hasClass('collapsed');
        },

        isSelected: function() {

            return this.options.selectable && this.$el.hasClass('selected');
        },

        setItem: function(item) {

            this.options.item = item;
            this.render();
        },

        setCollapsed: function(collapsed, noTrigger) {

            if (!this.options.collapsible) return;

            if (collapsed) this.$el.addClass('collapsed');
            else {

                this.$el.closest('.common-content-list').find('.list-row').addClass('collapsed');
                this.$el.removeClass('collapsed');

                this.performDotDotDotUpdate();
                this.performLazyLoadImagesResize();
            }

            if (!noTrigger)
                this.trigger('collapsedChanged', {

                    view: this,
                    item: this.options.item,
                    collapsed: collapsed
                });
        },

        setSelected: function(selected, noTrigger) {

            if (!this.options.selectable) return;

            if (selected) this.$('.select .icon').removeClass('icon-checkbox-unchecked').addClass('icon-checkbox-checked');
            else this.$('.select .icon').removeClass('icon-checkbox-checked').addClass('icon-checkbox-unchecked');

            if (selected) this.$el.addClass('selected');
            else this.$el.removeClass('selected');

            if (!noTrigger)
                this.trigger('selectedChanged', {

                    view: this,
                    item: this.options.item,
                    selected: selected
                });
        },

        _getItemId: function() {

            if (!this.options.item) return null;
            return this.options.item.id; // Do not use get('id'), as the idAttribute will set a direct property on the model instead of the attributes hash.
        },

        _handleItemClick: function(e) {},

        _handleItemCollapse: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this.setCollapsed(!this.isCollapsed());
            return false;
        },

        _handleItemDelete: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this.trigger('delete', {view: this, item: this.options.item});
        },

        _handleItemEdit: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var link = $(e.target).closest('[href]').attr('href');

            if (!_.isEmpty(link)) {

                Love.router.navigate(link, {trigger: true});
            }
            else
                this.trigger('edit', {view: this, item: this.options.item});
        },

        _handleItemExternal: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var link = $(e.target).closest('[href]').attr('href');

            if (!_.isEmpty(link)) {

                var win = window.open(link, '_blank');
                win.focus();
            }
            else
                this.trigger('external', {view: this, item: this.options.item});
        },

        _handleItemRemix: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this.trigger('remix', {view: this, item: this.options.item});
        },

        _handleItemSelect: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this.setSelected(!this.isSelected());
            return false;
        },

        _handleMore: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this.trigger('more', {view: this});
        }
    });

})(Love);