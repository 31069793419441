(function(Love) {

    Love.Views.BaseHeaderView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.BaseHeaderView',

        events: {

            'click [data-menu-item="menu-sidebar-toggle"] a': '_handleMenuSidebarToggle'
        },

        render: function(dontTrigger) {

            // If the page has a sidebar, show the toggle sidebar icon.

            var hasSidebar = $('.base-sidebar').length > 0;

            if (hasSidebar)
                this.$('[data-menu-item="menu-sidebar-toggle"]').removeClass('hidden');
            else
                this.$('[data-menu-item="menu-sidebar-toggle"]').addClass('hidden');

            return Love.Views.BaseView.prototype.render.call(this, dontTrigger);
        },

        _handleMenuSidebarToggle: function(e) {

            e.preventDefault();

            //$('.base-sidebar-container').toggleClass('open');

            // Find the sidebar view and toggle it. This enables the normal chain of events for sidebar opening / closing.

            var sidebar = _.filter(Love.views, function(view) {

                return (view.baseClassName === 'Love.Views.BaseSidebarView');
            });

            if (sidebar.length < 1) return;
            else sidebar = sidebar[0];

            sidebar.toggle();

            if ($('.base-sidebar-container').hasClass('open')) {

                // Flip the toggle icon.

                this.$('[data-menu-item="menu-sidebar-toggle"] a').css('transform', 'scale(-1, 1)');
                this.$('[data-menu-item="menu-sidebar-toggle"]').addClass('open');
            }
            else {

                this.$('[data-menu-item="menu-sidebar-toggle"] a').css('transform', 'scale(1, 1)');
                this.$('[data-menu-item="menu-sidebar-toggle"]').removeClass('open');
            }

            // TODO RENS: disable scrolling voor alle elementen behalve sidebar. En weer enabled als sidebar wordt gesloten.
        }
    });

})(Love);