(function(Love) {

    Love.Views.CalendarMomentEventView = Love.Views.CalendarBaseEventView.extend({

        objectClassName: 'Love.Views.CalendarMomentEventView',

        render: function() {

            /*
             //TODO: Move to base_event render
             var calendar = "div.moby-calendar";
             if (this.calendarView.parentView.viewType === 'week') {
             calendar = "div.calendar-week-day[data-day='" + this.calendarView.dayOfView + "'] div.moby-calendar";
             }
             //split google cal // TODO: Jeroen doen


             //TODO: Improve template
             if (this.calendarView.parentView.viewType === 'day' && this.calendarView.parentView.separateGoogleEvents && this.model.get('isGoogleCal')) {
             calendar = "div.google-calendar";
             }

             var parameters = Love.Helpers.Calendar.getParametersForEvent(this.model);
             $(calendar + " .events-container").append(_.template(Love.Templates.calendar_event)(parameters));*/

            var parameters = Love.Helpers.Calendar.getParametersForEvent(this.model, this.displayDateStart, this.displayDateEnd);
            this.$el.html(_.template(Love.Templates.calendar_events_moment)(parameters));

            var viewsText = this._getViewsText(parameters);

            if (this.isPastEvent() && viewsText !== '') {

                this.$('.calendar-event-time').addClass('hidden');
                this.$('.calendar-event-views span').text(viewsText);
                this.$('.calendar-event-views').removeClass('hidden');

                this._hasViewsText = true;
            }

            this.$el.addClass('calendar-base-event');
            this.$el.addClass('calendar-moment-event');
            this.$el.addClass(parameters.color);
            this.$el.attr('data-id', this.model.id);
            this.$el.data('datetime', parameters.date.format());

            if (this.isDraggable) this.enableDragDrop();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        isPastEvent: function() {

            var now = moment();
            return (now.valueOf() > this.start.valueOf());
        },

        //Set the css 'top' of the event element
        eventPosition: function() {

            var hourblockSize = this.$el.closest('.base-day-calendar').find('.hourblock').outerHeight();
            var top = (this.eventTime * hourblockSize) - (this.height / 2); //Multiply by the amount of hourblocks the time spans

            this.$el.css({'top': top});
            this.top = top;
        },

        //Set height, and check how much space it occupies.
        eventSize: function() {

            var calendarEventHeight = 22;

            this.height = calendarEventHeight;
            this.$el.css('height', this.height);
        },

        eventStyling: function() {

            Love.Views.CalendarBaseEventView.prototype.eventStyling.call(this);

            var header = this.$('.calendar-event-header');
            var icon = this.$('.calendar-event-icon');
            var title = this.$('.calendar-event-title');
            var time = (this._hasViewsText) ? this.$('.calendar-event-views') : this.$('.calendar-event-time');

            // NOTE: hidden (display: none;) objects have no width. This function is called when resizing,
            // so we have to support both hiding and showing elements again.

            // Enable width measuring.

            title.removeClass('hidden');
            time.removeClass('hidden');

            var headerWidth = header.width();
            headerWidth += 1; // NOTE by Jeroen: offset for decimal error. A hack, but fault may be on Javascript side.

            var iconWidth = icon.outerWidth(true);
            var titleWidth = title.outerWidth(true);
            var timeWidth = time.outerWidth(true);

            // Check if the time / views element fits. This has priority over the title.

            if (iconWidth + timeWidth <= headerWidth) {

                time.removeClass('hidden');

                // If the time / views fit, check if there's also room to display the title.

                if (iconWidth + titleWidth + timeWidth <= headerWidth)
                    title.removeClass('hidden');
                else
                    title.addClass('hidden');
            }
            else {

                time.addClass('hidden');
                title.addClass('hidden');
            }
        },

        _getViewsText: function(parameters) {

            var result = '';

            if (!(_.isEmpty(parameters.additional))) {

                if ('views' in parameters.additional)
                    return Love.Helpers.Language.getViewsEtcText(parameters.additional.views);
            }

            return result;
        }
    });

})(Love);