(function(Love) {

    Love.Views.CommonSocialMediaUpdatesPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonSocialMediaUpdatesPopupView',

        className: 'base-popup popup-centered',
        id: 'popup-common-social-media-updates',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    activateFirstDestination: true,
                    availableDestinations: _.map(_.sortBy(Love.session.get('currentSite').get('destinations'), function(dest) { return dest.type + dest.accountName; }), function(model) {

                        return _.extend(_.clone(model), {

                            isScheduled: false,
                            message: '',
                            scheduledOn: null
                        });
                    }),
                    defaultMaxCharacters: 1000,
                    destination: null,
                    isScheduled: false,
                    nonScheduledText: 'Immediately',
                    originalModel: null,
                    scheduledOn: null,
                    settings: {

                        message: ''
                    },
                    useSameMessage: true
                },
                enableLightbox: true
            };
        },

        events: {

            'click .common-destinations .common-destination': '_handleDestinationClick',
            'click .common-destinations .common-destination-add': '_handleDestinationAddClick',
            'keyup textarea[name="message"]': '_handleMessageChange',
            'cut textarea[name="message"]': '_handleMessageChange',
            'paste textarea[name="message"]': '_handleMessageChange',
            'click [data-group="schedule"] [data-value]': '_handleScheduleChange',
            //'click [data-action="set-schedule-date"]': '_handleScheduleDate',
            //'click [data-action="set-schedule-time"]': '_handleScheduleTime',
            //'change input[name="schedule-date"]': '_handleScheduleDateChange',
            //'change input[name="schedule-time"]': '_handleScheduleDateChange',
            'change input[name="use-same-message"]': '_handleUseSameMessageChange',
            'click [data-action="remove"]': '_handleRemove',
            'click [data-action="confirm"]': '_handleConfirm',
            'click [data-action="close"]': '_handleClose'
        },

        render: function() {

            var context = this.options.data;
            this.$el.append(_.template(Love.Templates.common_popups_social_media_updates)(context));

            if (this.options.callbacks.onRemove)
                this.$('[data-action="remove"]').removeClass('hidden');

            // Set the default options for all destinations.

            _.each(this.options.data.availableDestinations, function(dest) {

                dest.isScheduled = this.options.data.isScheduled;
                dest.message = this.options.data.settings.message;
                dest.scheduledOn = (this.options.data.scheduledOn && this.options.data.scheduledOn.isValid()) ? this.options.data.scheduledOn.clone() : null;

            }, this);

            if (this.options.data.activateFirstDestination && !this.options.data.destination) {

                if (this.options.data.availableDestinations.length > 0)
                    this.options.data.destination = this.options.data.availableDestinations[0];
            }

            this._renderDestinations();
            this._renderMessageContainers();
            this._checkMaximumLength();

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _addMessageContainer: function(destination) {

            var useSame = this.options.data.useSameMessage;

            this.$('.message-containers').append(_.template(Love.Templates.common_popups_social_media_updates_message)({

                destination: destination,
                isScheduled: (useSame || !destination) ? this.options.data.isScheduled : destination.isScheduled,
                maxCharacters: (useSame || !destination) ? this._getMaximumCharacters() : destination.maxCharacters,
                maxCharactersIcon: (useSame || !destination) ? this._getMaximumCharactersIcon() : destination.icon,
                message: (useSame || !destination) ? this.options.data.settings.message : destination.message,
                nonScheduledText: this.options.data.nonScheduledText,
                scheduledOn: (useSame || !destination) ? this.options.data.scheduledOn : destination.scheduledOn
            }));

            var container = this.$('.message-containers .message-container:last');
            container.find('.controls').css('margin-left', container.find('.content .common-destination').outerWidth(true));

            this.performDotDotDot();
            this.performLazyLoadImages();
        },

        _checkMaximumLength: function(container) {

            container = container || this.$('.message-container');

            _.each(container, function(el) {

                var $container = $(el);
                var dest = _.findWhere(this.options.data.availableDestinations, {id: $container.find('.common-destination').attr('data-id')});

                var content = $container.find('textarea[name="message"]').val();
                var max = this.options.data.useSameMessage ? this._getMaximumCharacters() : dest.maxCharacters;

                if (content.length > max) {

                    content = content.substr(0, max);
                    $container.find('textarea[name="message"]').val(content);
                }

                var icon = this.options.data.useSameMessage ? this._getMaximumCharactersIcon() : dest.icon;

                if (icon)
                    $container.find('.message-length .icon').removeClass().addClass('icon m-r-s ' + icon);
                else
                    $container.find('.message-length .icon').addClass('hidden');

                if (isFinite(max)) {

                    $container.find('.message-length span').html(Math.max(max - content.length, 0));
                    $container.find('.message-length').removeClass('invisible');
                }
                else {

                    $container.find('.message-length span').html('0');
                    $container.find('.message-length').addClass('invisible');
                }

            }, this);
        },

        _getMaximumCharactersIcon: function() {

            var selectedDest = this._getSelectedDestinations();
            var max = Math.min.apply(Math, _.pluck(selectedDest, 'maxCharacters'));

            if (isFinite(max)) {

                var minDest = _.findWhere(selectedDest, {maxCharacters: max});
                if (minDest) return minDest.icon;
            }

            return '';
        },

        _getMaximumCharacters: function() {

            // If no destinations were selected, Math.min() returns Infinity.

            var selectedDest = this._getSelectedDestinations();
            return Math.min.apply(Math, _.pluck(selectedDest, 'maxCharacters'));
        },

        _getSelectedDestinations: function() {

            var available = this.options.data.availableDestinations;
            var selected = [];

            _.each(this.$('.common-destinations .common-destination.active'), function(el) {

                var id = $(el).attr('data-id');
                var dest = _.findWhere(available, {id: id});

                if (dest) selected.push(dest);
            });

            return selected;
        },

        _getSplitUpdates: function() {

            var updates = [];
            var useSame = this.options.data.useSameMessage;

            _.each(this._getSelectedDestinations(), function(dest) {

                var message = useSame ? this.options.data.settings.message : dest.message;
                var isScheduled = useSame ? this.options.data.isScheduled : dest.isScheduled;
                var scheduledOn = useSame ? this.options.data.scheduledOn : dest.scheduledOn;

                if (!_.isEmpty(message)) {

                    var contentType = '';

                    if (dest.type === 'facebook') contentType = 'shareLink';
                    else if (dest.type === 'twitter') contentType = 'statusUpdate';
                    else if (dest.type === 'mobypicture') contentType = 'uploadTtlFile';
                    else if (dest.type === 'instagram') contentType = 'uploadTtlFile';

                    var update = this.options.data.originalModel ? _.clone(this.options.data.originalModel) : {};

                    _.extend(update, {

                        contentType: contentType,
                        destinationId: dest.id,
                        scheduledOnUtc: isScheduled ? scheduledOn.clone().utc() : null,
                        settings: {

                            message: message
                        }
                    });

                    updates.push(update);
                }

            }, this);

            return updates;
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var result = {updates: this._getSplitUpdates()};

            this.close();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm(result);
        },

        _handleDestinationClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            $(e.target).closest('.common-destination').toggleClass('active');

            var numSelected = this.$('.common-destinations .common-destination.active').length;

            if (numSelected >= 2)
                this.$('input[name="use-same-message"]').closest('.form-group').removeClass('hidden');
            else
                this.$('input[name="use-same-message"]').closest('.form-group').addClass('hidden');

            if (this.options.data.useSameMessage) {

                // If the same message is used for all destinations, nothing changes in the number of message containers.
                // Only the max character limit and corresponding icon might need changing.

                this._checkMaximumLength();
            }
            else {

                // If different messages are used per destination, we need to add or remove a message container.

                var add = $(e.target).closest('.common-destination').hasClass('active');
                var dest = _.findWhere(this.options.data.availableDestinations, {id: $(e.target).closest('.common-destination').attr('data-id')});

                if (add) {

                    // If 0 or 1 destinations are selected, a message container without avatar is displayed.
                    // We remove it first and replace it with the new one.

                    if (numSelected < 2)
                        this._removeMessageContainer();

                    this._addMessageContainer(dest);
                }
                else
                    this._removeMessageContainer(dest);

                // Check if no message containers are left.

                if (numSelected === 0)
                    this._addMessageContainer();
            }
        },

        _handleDestinationAddClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/settings', {trigger: true});
        },

        _handleMessageChange: function(e) {

            // Defer for the paste and cut events.

            _.defer(_.bind(function() {

                var destination = $(e.target).closest('.message-container').find('.common-destination');
                var message = $(e.target).closest('.message-container').find('textarea[name="message"]').val();

                if (destination.length > 0) {

                    destination = _.findWhere(this.options.data.availableDestinations, {id: destination.attr('data-id')});
                    destination.message = message;
                }
                else
                    this.options.data.settings.message = message;

                this._checkMaximumLength($(e.target).closest('.message-container'));

            }, this));
        },

        _handleRemove: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            if (this.options.callbacks.onRemove)
                this.options.callbacks.onRemove();
        },

        _handleScheduleChange: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var schedule = $(e.target).closest('[data-value]').attr('data-value') === 'true';
            var destination = $(e.target).closest('.message-container').find('.common-destination');

            if (destination.length > 0)
                destination = _.findWhere(this.options.data.availableDestinations, {id: destination.attr('data-id')});
            else
                destination = { // Create a fake destination that serves to edit all at once.

                    isScheduled: this.options.data.isScheduled,
                    scheduledOn: (this.options.data.scheduledOn && this.options.data.scheduledOn.isValid) ? this.options.data.scheduledOn.clone() : null
                };

            if (schedule) {

                destination.isScheduled = true;

                var button = $(e.target).closest('.button');
                var updateElement = button.find('span');

                $(e.target).closest('[data-group="schedule"]').removeClass('left');
                $(e.target).closest('[data-group="schedule"]').addClass('right');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="true"]').addClass('active');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="false"]').removeClass('active');

                if (!destination.scheduledOn) {

                    destination.scheduledOn = moment().add(1, 'hours');
                    updateElement.html('<em>' + destination.scheduledOn.format('ddd, MMM Do YYYY, HH:mm') + '</em>');

                    //this.$('input[name="schedule-date"]').val(this.options.data.scheduledOn.format('YYYY-MM-DD'));
                    //this.$('input[name="schedule-time"]').val(this.options.data.scheduledOn.format('HH:mm'));
                }

                //this.$('[data-group="schedule-date"]').removeClass('hidden');
                //this.$('.popup-content').scrollTo(this.$('[data-group="schedule-date"]'), 'normal');

                var popup = new Love.Views.CommonPickDateTimePopupView({

                    callbacks: {

                        onChange: _.bind(function(data) {

                            destination.isScheduled = data.value && data.value.isValid() ? true : false;
                            destination.scheduledOn = data.value && data.value.isValid() ? data.value : null;

                            updateElement.html('<em>' + data.value.format('ddd, MMM Do YYYY, HH:mm') + '</em>');

                            // Edit the global dates, in case no destination was chosen.

                            this.options.data.isScheduled = destination.isScheduled;
                            this.options.data.scheduledOn = destination.scheduledOn.clone();

                        }, this)
                    },
                    data: {

                        canSelectInPast: false,
                        value: destination.scheduledOn.clone()
                    },
                    parentView: this,
                    positioning: {

                        attachToElement: button,
                        positionAt: 'element'
                    }
                });

                popup.showPopup({keepOpen: [this]});
            }
            else {

                destination.isScheduled = false;

                $(e.target).closest('[data-group="schedule"]').removeClass('right');
                $(e.target).closest('[data-group="schedule"]').addClass('left');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="true"]').removeClass('active');
                $(e.target).closest('[data-group="schedule"]').find('[data-value="false"]').addClass('active');

                //this.$('[data-group="schedule-date"]').addClass('hidden');
            }

            // Edit the global dates, in case no destination was chosen.

            this.options.data.isScheduled = destination.isScheduled;
            this.options.data.scheduledOn = destination.scheduledOn ? destination.scheduledOn.clone() : null;
        },

        /*
         _handleScheduleDate: function (e) {

         if (e && e.preventDefault) e.preventDefault();

         var self = this;
         var popup = new Love.Views.CommonPickDatePopupView({

         callbacks: {

         onChange: function (data) {

         self.$('input[name="schedule-date"]').val(data.value.format('YYYY-MM-DD'));
         self._handleScheduleDateChange();
         }
         },
         data: {value: moment(this.$('input[name="schedule-date"]').val(), 'YYYY-MM-DD')},
         parentView: this,
         positioning: {

         attachToElement: $(e.target).closest('.button'),
         positionAt: 'element'
         }
         });

         popup.showPopup({keepOpen: [this]});
         },

         _handleScheduleDateChange: function (e) {

         if (e && e.preventDefault) e.preventDefault();

         var date = this.$('input[name="schedule-date"]').val();
         var time = this.$('input[name="schedule-time"]').val();

         var value = null;

         if (time && !date) {

         date = moment().format('YYYY-MM-DD');
         this.$('input[name="schedule-date"]').val(date);
         }

         if (date && time)
         value = moment(date + " " + time, "YYYY-MM-DD HH:mm");

         this.options.data.isScheduled = value && value.isValid() ? true : false;
         this.options.data.scheduledOn = value && value.isValid() ? value : null;
         },

         _handleScheduleTime: function (e) {

         if (e && e.preventDefault) e.preventDefault();

         var self = this;
         var popup = new Love.Views.CommonPickTimePopupView({

         callbacks: {

         onChange: function (data) {

         self.$('input[name="schedule-time"]').val(data.value.format('HH:mm'));
         self._handleScheduleDateChange();
         }
         },
         data: {value: moment(this.$('input[name="schedule-time"]').val(), 'HH:mm')},
         parentView: this,
         positioning: {

         attachToElement: $(e.target).closest('.button'),
         positionAt: 'element'
         }
         });

         popup.showPopup({keepOpen: [this]});
         },
         */

        _handleUseSameMessageChange: function(e) {

            var selected = this._getSelectedDestinations();

            if (!this.options.data.useSameMessage) {

                // Copy the data from the first destination to the shared message.

                if (selected.length > 0) {

                    this.options.data.settings.message = selected[0].message;
                    this.options.data.isScheduled = selected[0].isScheduled;
                    this.options.data.scheduledOn = (selected[0].scheduledOn && selected[0].scheduledOn.isValid()) ? selected[0].scheduledOn.clone() : null;
                }
            }
            else {

                // Copy the shared data to all destinations.

                _.each(selected, function(dest) {

                    dest.message = this.options.data.settings.message;
                    dest.isScheduled = this.options.data.isScheduled;
                    dest.scheduledOn = (this.options.data.scheduledOn && this.options.data.scheduledOn.isValid()) ? this.options.data.scheduledOn.clone() : null;

                }, this);
            }

            this.options.data.useSameMessage = this.$('input[name="use-same-message"]').prop('checked');

            this._renderMessageContainers();
        },

        _removeMessageContainer: function(destination) {

            if (!destination)
                this.$('.message-container').remove(); // Remove all message containers.
            else
                this.$('.message-container .common-destination[data-id="' + destination.id + '"]').closest('.message-container').remove();
        },

        _renderDestinations: function() {

            var isMini = (this.options.data.availableDestinations.length > 4);

            if (isMini)
                this.$('.common-destinations').addClass('mini');
            else
                this.$('.common-destinations').removeClass('mini');

            _.each(_.reject(this.options.data.availableDestinations, function(model) {

                // We can't post social media updates to Mobypicture as the interface doesn't (yet) support file uploads.

                return model.type === 'mobypicture';

            }), function(model) {

                var context = _.extend({

                    active: this.options.data.destination && (model.id === this.options.data.destination.id),
                    isMini: isMini

                }, model);

                this.$('.common-destinations .common-destination-add').before(_.template(Love.Templates.common_popups_social_media_updates_destination(context)));

            }, this);
        },

        _renderMessageContainers: function() {

            this.$('.message-containers').empty();

            if (this.options.data.useSameMessage)
                this._addMessageContainer();

            else {

                var selectedDest = this._getSelectedDestinations();

                if (selectedDest.length < 1)
                    this._addMessageContainer();
                else
                    _.each(selectedDest, function(dest) { this._addMessageContainer(dest); }, this);
            }
        }
    });

})(Love);