(function(Love) {

    Love.Views.DashboardCalendarModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardCalendarModuleView',

        defaults: function() {

            return {

                date: moment()
            };
        },

        events: {

            'click .calendar-description': '_handleGoToDate',
            'click .calendar-create-content': '_handleCreateContent',
            'click .calendar-previous': '_handlePrevious',
            'click .calendar-next': '_handleNext',
            'click .calendar-current': '_handleCurrent',
            'click .calendar-mode-week': '_handleWeek',
            'click .calendar-mode-month': '_handleMonth'
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            this.selectedDay = this.options.date;
            this.calendarView = this.addSubView(new Love.Views.CalendarDayView({parentView: this}));

            this.eventCollection = new Love.Collections.CalendarEventCollection();
        },

        render: function() {

            var context = _.extend({

                isMobile: this.isMobile()

            }, this.options);

            this.$el.html(_.template(Love.Templates.dashboard_modules_calendar)(context));

            this._updateHeaderDate();

            this.calendarView.setElement(this.$('.calendar-view-container')).render();

            var self = this;

            this.stopListening(this.eventCollection, 'sync');
            this.listenTo(this.eventCollection, 'sync', function() {

                // We need to empty the DOM before populating it, even if this was done already, in case of racing calls.

                self.calendarView.emptyDOM();
                self.calendarView.populateDomFromCollection(self.eventCollection);
            });
            this.listenToOnce(this.eventCollection, 'sync', function() {_.defer(function() { self.calendarView.scrollToTime(true); });});

            this._doFetchEvents();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        isMobile: function() {

            return $(window).width() < 880; // $calendar-mobile-breakpoint
        },

        updateDayViewSelectors: function() {

            this._updateHeaderDate();
        },

        _doFetchEvents: function() {

            this.eventCollection.getEventsForDay(this.options.date, _.uniq(
                Love.session.get('googleCalendars').concat(Love.session.get('currentSite').get('googleCalendars')),
                false,
                function(cal) { return cal.id; }
            ), {

                requestId: this.getAjaxAbortId(),
                abortPendingByIds: [this.getAjaxAbortId()]
            });
        },

        _handleCreateContent: function(e) {

            this.trigger('createContent', {fromHeader: true, originalEvent: e});
        },

        _handleCurrent: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.options.date = moment();
            this.selectedDate = this.options.date;

            this._updateHeaderDate();

            this.calendarView.emptyDOM();
            this.calendarView.updateRenderedView();
            this._doFetchEvents();

            this.trigger('dateChanged', {newDate: this.options.date});
        },

        _handleGoToDate: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/calendar/day/' + this.options.date.format('YYYY-MM-DD'), {trigger: true});
        },

        _handleWeek: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/calendar/week/' + this.options.date.startOf('isoweek').format('YYYY-MM-DD'), {trigger: true});
        },

        _handleMonth: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/calendar/month/' + this.options.date.format('YYYY-MM-DD'), {trigger: true});
        },

        _handlePrevious: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.options.date.add(-1, 'days');
            this._updateHeaderDate();

            this.calendarView.emptyDOM();
            this.calendarView.updateRenderedView();
            this._doFetchEvents();

            this.trigger('dateChanged', {newDate: this.options.date});
        },

        _handleNext: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.options.date.add(1, 'days');
            this._updateHeaderDate();

            this.calendarView.emptyDOM();
            this.calendarView.updateRenderedView();
            this._doFetchEvents();

            this.trigger('dateChanged', {newDate: this.options.date});
        },

        _updateHeaderDate: function() {

            var date = this.options.date;
            var format = '';

            if (date.isSame(moment(), 'day')) { format = 'Today'; }
            else if (date.isSame(moment().add(-1, 'days'), 'day')) { format = 'Yesterday'; }
            else if (date.isSame(moment().add(1, 'days'), 'day')) { format = 'Tomorrow'; }
            else {
                var descWidth = this.$('.calendar-general-controls').width();
                if (descWidth < 650) {format = date.format('dddd, MMM Do YYYY');}
                else format = date.format('dddd, MMMM Do YYYY');
            }

            this.$('.calendar-description').text(format);
        }
    });

})(Love);