(function(Love) {

    Love.Views.RemixSocialRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.RemixSocialRowView',

        defaults: function() {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true,
                selectable: true,
                selected: false
            });
        },

        renderItem: function() {

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    externalUrl: this.options.item.getExternalUrl(),
                    isLoading: _.isEmpty(this.options.item.id)
                })
            });

            this.$el.html(_.template(Love.Templates.remix_social_row)(context));
        }
    });

})(Love);