(function(Love) {

    Love.Views.CommonNotificationsPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonNotificationsPopupView',

        className: 'base-popup popup-list-menu',
        id: '',

        defaultsForPopupType: function() {

            return {

                data: {

                    items: null
                }
            };
        },

        events: {

            'click [data-action="click"]': '_handleClick',
            'click [data-action="more"]': '_handleMore'
        },

        initialize: function(options) {

            Love.Views.BasePopupView.prototype.initialize.call(this, options);

            if (!this.options.data.items)
                this.options.data.items = Love.Helpers.Notifications.getLocalNotifications();
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_notifications)({

                items: this.options.data.items.map(function(item) { return item.attributes; })
            }));

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.close();
        },

        _handleMore: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            /*
             var popup = new Love.Views.CommonContentNewWidgetRemixPopupView({

             callbacks: {

             onConfirm: this.options.callbacks.onConfirm
             }
             });

             popup.showPopup();
             */

            this.close();
        }
    });

})(Love);