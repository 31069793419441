(function(Love) {

    Love.Views.CommonImageUploadView = Love.Views.BaseHeaderView.extend({

        objectClassName: 'Love.Views.CommonImageUploadView',

        defaults: function() {

            return {

                addImageMode: false,
                callbacks: {},
                disabled: false,
                file: null,
                imageModel: new Love.Models.ImageModel(),
                loadingFullScreen: true,
                mini: false,
                parentPopupView: null,
                uploadAutomatically: true
            };
        },

        events: {

            'click [data-action="upload"]': '_handleClick',
            'click input[name="media"]': '_handleMediaClick',
            'change input[name="media"]': '_handleMediaChange',
            'click [data-action="remix"]': '_handleRemix',
            'click [data-action="remove"]': '_handleRemove',
            'click [data-action="url"]': '_handleUrl',
            'cut [data-action="url"] input[type="text"]': '_handleUrlInputChange',
            'paste [data-action="url"] input[type="text"]': '_handleUrlInputChange',
            'keyup [data-action="url"] input[type="text"]': '_handleUrlKeyUp',
            'click [data-action="url"] input[type="text"]': function(e) {e.stopPropagation();},
            'click [data-feedback="invalid"]': '_clearInput',
            'dragover .common-image-upload': function(e) {e.preventDefault();},
            'drop .common-image-upload': '_handleDrop'
        },

        initialize: function(options) {

            Love.Views.BaseHeaderView.prototype.initialize.call(this, options);

            _.bindAll(this, '_handleImage', '_handleWindowResize');
        },

        render: function() {

            var url = this._getCurrentImageView();

            if (this.options.mini)
                this.$el.html(_.template(Love.Templates.common_image_upload_mini)({source: url, disabled: this.options.disabled}));
            else
                this.$el.html(_.template(Love.Templates.common_image_upload)({source: url, disabled: this.options.disabled}));

            this._unregisterCustomEvents();
            this._registerCustomEvents();

            this._handleWindowResize();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this._unregisterCustomEvents();
            $(window).off('resize', this._handleWindowResize);
        },

        setImage: function(imageModel, dontRender) {

            this.options.imageModel = imageModel;

            if (!dontRender)
                this.render();
        },

        showPopup: function() {

            var popup = new Love.Views.CommonEditImagePopupView({

                callbacks: {

                    onConfirm: _.bind(function(imageModel) {

                        // TODO RENS: save the model title and link, hier of ergens anders?

                        this._handleImage(imageModel);

                    }, this)
                },
                data: {

                    addImageMode: this.options.addImageMode,
                    imageModel: _.clone(this.options.imageModel)
                },
                parentView: this.options.parentPopupView
            });

            popup.showPopup({keepOpen: [this.options.parentPopupView], keepOpenParents: true});
        },

        _clearInput: function(e) {

            e.stopPropagation();
            this.$('input[name="url"]').val('');
        },

        _confirmReplace: function() {

            var promise = $.Deferred();

            if (!this._hasImage())
                promise.resolve();

            else {

                var popup = new Love.Views.CommonQuestionPopupView({

                    callbacks: {

                        onNo: function() {promise.reject(); },
                        onYes: function() {promise.resolve();}
                    },
                    data: {

                        title: 'Replace existing image?',
                        text: 'You\'re about to replace an existing image. Do you want to continue?'
                    }
                });

                popup.showPopup();
            }

            return promise;
        },

        _getCurrentImageView: function() {

            return !this.options.addImageMode ? this.options.imageModel.getImageView() : '';
        },

        _handleClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            if (this.options.disabled) return;

            if (this.options.mini)
                this.showPopup();
            else
                this.$('input[name="media"]').click();
        },

        _handleDragEnter: function(e) {

            this.$('.controls').addClass('hidden');
            this.$('.common-image-upload-overlay > .icon').addClass('hidden');
            this.$('.drop-feedback').removeClass('hidden');

            this.$('.common-image-upload').addClass('hover'); // A little dirty, but it makes it easier to show the overlay both on hover and on dragging anywhere.

            var amount = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);
            var display = _.isEmpty(this._getCurrentImageView()) ? Love.t('common:image_upload.feedback_drop', {count: amount}) : Love.t('common:image_upload.feedback_drop_replace', {count: amount});

            this.$('.drop-feedback .icon').removeClass(Love.Helpers.DragDrop.getHeartsIconClasses().join(' '));
            this.$('.drop-feedback .icon').addClass(Love.Helpers.DragDrop.getHeartsIconClass(amount));

            this.$('.drop-feedback .text').text(display);
        },

        _handleDragEnterContainer: function(e) {

            var amount = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);
            var display = Love.t('common:dragdrop.feedback_drop_close', {count: amount});

            this.$('.drop-feedback .text').text(display);
        },

        _handleDragLeave: function(e) {

            this.$('.drop-feedback').addClass('hidden');
            this.$('.common-image-upload-overlay > .icon').removeClass('hidden');
            this.$('.controls').removeClass('hidden');

            this.$('.common-image-upload').removeClass('hover');
        },

        _handleDragLeaveContainer: function(e) {

            var amount = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);
            var display = _.isEmpty(this._getCurrentImageView()) ? Love.t('common:image_upload.feedback_drop', {count: amount}) : Love.t('common:image_upload.feedback_drop_replace', {count: amount});

            this.$('.drop-feedback .text').text(display);
        },

        _handleDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault(); // Default is to open as link on drop.

            var count = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            if (count > 0) {

                //$.when(this._confirmReplace()).then(_.bind(function() {

                var self = this;
                var promises = [];

                this._showLoading(true);

                _.each(Love.Helpers.DragDrop.getDataItems(e.originalEvent), function(item, index) {

                    var dataType = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent, index);

                    if (dataType === 'image') {

                        // Only the first dropped file is handled here. Others are passed to onDropMultiple.

                        if (index > 0) {

                            if (this.options.callbacks.onDropMultiple)
                                promises[index] = this.options.callbacks.onDropMultiple(item.getAsFile(), index);
                        }
                        else {

                            var failed, previewDisplayed = false;
                            var file = item.getAsFile();

                            Love.Helpers.General.blobToDataURL(file, function(url) {

                                // Display a preview

                                if (!failed) {

                                    self._handleImage(new Love.Models.ImageModel({

                                        imageSource: 'url',
                                        imageURL: url
                                    }));

                                    if (self.options.uploadAutomatically)
                                        self._showLoading(true); // Show again since we've rerendered.

                                    previewDisplayed = true;
                                }
                            });

                            if (this.options.uploadAutomatically) {

                                // Upload the image.

                                var model = new Love.Models.ImageModel();

                                promises[index] = model.uploadFile(file)

                                    .done(_.bind(function() {

                                        this.set({

                                            // Keep the existing link and title, as they weren't sent to the server now.

                                            imageLink: self.options.imageModel.get('imageLink'),
                                            imageTitle: self.options.imageModel.get('imageTitle')
                                        });

                                        self._handleImage(this, true);

                                    }, model))
                                    .fail(function() {

                                        failed = true;
                                        self._handleImage(new Love.Models.ImageModel());

                                    }).always(function() {

                                        if (previewDisplayed) self._showLoading(false); // Decrement reference counter.
                                    });
                            }
                        }
                    }

                }, this);

                $.whenAll.apply($, promises).always(_.bind(function() { this._showLoading(false);}, this));

                //}, this));
            }
        },

        _handleImage: function(model, dontRender) {

            this.setImage(model, dontRender);

            if (this.options.callbacks.onChange)
                this.options.callbacks.onChange(model);
        },

        _handleMediaChange: function() {

            var count = this.$('input[name="media"]')[0].files ? this.$('input[name="media"]')[0].files.length : 0;

            if (count > 0) {

                $.when(this._confirmReplace()).then(_.bind(function() {

                    var self = this;
                    var promises = [];

                    this._showLoading(true);

                    _.each(this.$('input[name="media"]')[0].files, function(file, index) {

                        // Only the first file is handled here. Others are passed to onDropMultiple.

                        if (index > 0) {

                            if (this.options.callbacks.onDropMultiple)
                                promises[index] = this.options.callbacks.onDropMultiple(file, index);
                        }
                        else {

                            var failed, previewDisplayed = false;

                            Love.Helpers.General.blobToDataURL(file, function(url) {

                                // Display a preview

                                if (!failed) {

                                    self._handleImage(new Love.Models.ImageModel({

                                        imageSource: 'url',
                                        imageURL: url
                                    }));

                                    if (self.options.uploadAutomatically)
                                        self._showLoading(true); // Show again since we've rerendered.

                                    previewDisplayed = true;
                                }
                            });

                            if (this.options.uploadAutomatically) {

                                // Upload the image.

                                var model = new Love.Models.ImageModel();

                                promises[index] = model.uploadFile(file)

                                    .done(_.bind(function() {

                                        this.set({

                                            // Keep the existing link and title, as they weren't sent to the server now.

                                            imageLink: self.options.imageModel.get('imageLink'),
                                            imageTitle: self.options.imageModel.get('imageTitle')
                                        });

                                        self._handleImage(this, true);

                                    }, model))
                                    .fail(function() {

                                        failed = true;
                                        self._handleImage(new Love.Models.ImageModel());

                                    }).always(function() {

                                        if (previewDisplayed) self._showLoading(false); // Decrement reference counter.
                                    });
                            }
                        }

                    }, this);

                    $.whenAll.apply($, promises).always(_.bind(function() { this._showLoading(false);}, this));

                }, this));
            }
        },

        _handleMediaClick: function(e) {

            e.stopPropagation();
        },

        _handleRemix: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            $.when(Love.session.get('currentSite').getData('bookmarksLatestImages', true)).done(_.bind(function(items) {

                var popup = new Love.Views.CommonNewRemixTopPopupView({

                    callbacks: {

                        onConfirm: _.bind(function(data) {

                            if (data.items[0] && data.items[0].type === 'bookmark') {

                                // We use the fragment factory here, as it contains an useful function.

                                var fragmentOrPromise = Love.Factories.FragmentFactory.createImageFragmentFromBookmark(data.items[0].model);

                                $.when(fragmentOrPromise).always(_.bind(function(fragment) {

                                    if (fragment) {

                                        $.when(this._confirmReplace()).then(_.bind(function() {

                                            // The fragment holds an image model.

                                            var imageModel = new Love.Models.ImageModel(fragment.settings);
                                            this._handleImage(imageModel);

                                        }, this));
                                    }

                                }, this));
                            }

                        }, this)
                    },
                    data: {items: items, showMore: false, type: 'images'},
                    parentView: this.options.parentPopupView,
                    positioning: {

                        attachToElement: $(e.target).closest('.button'),
                        centerX: true,
                        positionAt: 'element'
                    }
                });

                popup.showPopup({keepOpen: [this.options.parentPopupView], keepOpenParents: true});

            }, this));
        },

        _handleRemove: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            this._handleImage(new Love.Models.ImageModel());
        },

        _handleUrl: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var element = $(e.target).closest('[data-action]');
            var url = element.find('input[name="url"]').val();

            if (!_.isEmpty(url)) {

                $.when(this._confirmReplace()).then(_.bind(function() {

                    this._showLoading(true);

                    element.find('input[name="url"]').val('');
                    element.find('[data-feedback]').addClass('hidden');

                    // We use the fragment factory here, as it contains an image validate function.

                    var fragmentOrPromise = Love.Factories.FragmentFactory.createImageFragmentFromString(url);

                    $.when(fragmentOrPromise).always(_.bind(function(fragment) {

                        if (fragment) {

                            // The fragment holds an image model.

                            var self = this;
                            var model = new Love.Models.ImageModel(fragment.settings);

                            // Display a preview

                            this._handleImage(model);
                            this._showLoading(false);

                            if (this.options.uploadAutomatically) {

                                this._showLoading(true); // Show again since we've rerendered.

                                // Upload the image.

                                model.uploadUrl(model.getImageView())

                                    .done(_.bind(function() {

                                        this.set({

                                            // Keep the existing link and title, as they weren't sent to the server now.

                                            imageLink: self.options.imageModel.get('imageLink'),
                                            imageTitle: self.options.imageModel.get('imageTitle')
                                        });

                                        self._handleImage(this, true);

                                    }, model))
                                    .always(function() {

                                        self._showLoading(false);
                                    });
                            }
                        }
                        else
                            this._showLoading(false);

                    }, this));

                }, this));
            }
        },

        _handleUrlInputChange: function(e) {

            if (e && e.stopPropagation) e.stopPropagation();

            var element = $(e.target).closest('[data-action]');

            // We need to defer execution in case of the paste event, as the event occurs before the input value has been updated.
            // Deferred execution might also work nice for other change events anyway, as parsing the value could take some time.

            _.defer(function() {

                var inputVal = $(e.target).val();

                // Validate the input url and display feedback to the user.

                if (!inputVal) {

                    element.find('[data-feedback]').addClass('hidden');
                    return;
                }

                // We use the fragment factory here, as it contains an image validate function.

                var fragmentOrPromise = Love.Factories.FragmentFactory.createImageFragmentFromString(inputVal);

                $.when(fragmentOrPromise).always(function(fragment) {

                    if (fragment) {

                        element.find('[data-feedback="invalid"]').addClass('hidden');
                        element.find('[data-feedback="valid"]').removeClass('hidden');
                    }
                    else {

                        element.find('[data-feedback="valid"]').addClass('hidden');
                        element.find('[data-feedback="invalid"]').removeClass('hidden');
                    }
                });
            });
        },

        _handleUrlKeyUp: function(e) {

            if (e.keyCode === 13)
                this._handleUrl(e); // Handle the enter key.
            else
                this._handleUrlInputChange(e);
        },

        _handleWindowResize: function() {

            var isMinimal = this.$('.common-image-upload').outerWidth() < 256;

            if (isMinimal)
                this.$('.common-image-upload').addClass('minimal-controls');
            else
                this.$('.common-image-upload').removeClass('minimal-controls');
        },

        _hasImage: function() {

            return !_.isEmpty(this._getCurrentImageView());
        },

        _registerCustomEvents: function() {

            _.bindAll(this, '_handleDragEnter');
            _.bindAll(this, '_handleDragEnterContainer');
            _.bindAll(this, '_handleDragLeave');
            _.bindAll(this, '_handleDragLeaveContainer');

            Love.Helpers.DragDrop.registerEnterLeaveEvents($('body'), this.cid, this._handleDragEnter, this._handleDragLeave, true);
            Love.Helpers.DragDrop.registerEnterLeaveEvents(this.$('.common-image-upload'), this.cid, this._handleDragEnterContainer, this._handleDragLeaveContainer, true);

            $(window).on('resize', this._handleWindowResize);
        },

        _showLoading: function(loading) {

            if (loading) {

                if (this.options.loadingFullScreen)
                    Love.Helpers.Loading.showLoading(true);
                else
                    Love.Helpers.Loading.showLoading(true, this.cid, this.$('.common-image-upload'));
            }
            else {

                if (this.options.loadingFullScreen)
                    Love.Helpers.Loading.showLoading(false);
                else
                    Love.Helpers.Loading.showLoading(false, this.cid);
            }

            if (this.options.callbacks.onLoadingChange)
                this.options.callbacks.onLoadingChange(loading);
        },

        _unregisterCustomEvents: function() {

            $(window).off('resize', this._handleWindowResize);

            Love.Helpers.DragDrop.unregisterEnterLeaveEvents($('body'), this.cid);
            Love.Helpers.DragDrop.unregisterEnterLeaveEvents(this.$('.common-image-upload'), this.cid);
        }
    });

})(Love);