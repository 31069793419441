(function(Love) {

    Love.Views.CalendarScreenView = Love.Views.BaseScreenView.extend({

        objectClassName: 'Love.Views.CalendarScreenView',

        events: {

            'click .calendar-general-controls .calendar-previous': 'previousWeek',
            'click .calendar-general-controls .calendar-current': '_selectToday',
            'click .calendar-general-controls .calendar-next': 'nextWeek',
            'click .calendar-general-controls .calendar-select-date': '_showDatePicker',
            'click .calendar-general-controls .calendar-create-content': '_createContent',

            'click .calendar-general-controls .calendar-mode-day': function(e) {

                if (e && e.preventDefault) e.preventDefault();
                this.setView('day');
            },

            'click .calendar-general-controls .calendar-mode-week': function(e) {

                if (e && e.preventDefault) e.preventDefault();
                this.setView('week');
            },

            'click .calendar-general-controls .calendar-mode-month': function(e) {

                if (e && e.preventDefault) e.preventDefault();
                this.setView('month');
            },

            'click .calendar-general-controls .calendar-mode-switcher .menu-title': function(e) {

                if (e && e.preventDefault) e.preventDefault();
                this.$('.calendar-general-controls .calendar-mode-switcher .menu-dropdown').toggleClass('hidden');
            },

            'click .calendar-general-controls .calendar-mode-switcher [data-value]': function(e) {

                if (e && e.preventDefault) e.preventDefault();
                this.setView($(e.target).closest('[data-value]').attr('data-value'));
            }
        },

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            // RENS: this view automatically replaces the current url hash with the active date.
            // If this is done for the first time, we don't create a record in the browser history
            // to allow to user to continue using the back button. If it isn't the first time, we do
            // create a record in the history to allow switching between calendar views through history.

            this.isFirstUrlReplace = true;

            this.siteId = Love.session.get('currentSiteId');
            this.viewType = (this.options.mode) ? this.options.mode : 'week';

            this.selectedDay = (this.options.date) ? this.options.date : moment();

            this.separateGoogleEvents = true;
            this.curCalendarView = null;

            this.sidebarView = this.addSubView(new Love.Views.CalendarSidebarView({parentView: this}));
            this.eventCollection = new Love.Collections.CalendarEventCollection();

            // Both user Google Calendars and platform Google Calendars are shown in the schedule.

            this.googleCalendars = Love.session.get('googleCalendars');
            this.googleCalendars = this.googleCalendars.concat(Love.session.get('currentSite').get('googleCalendars'));
            this.googleCalendars = _.uniq(this.googleCalendars, false, function(cal) {return cal.id;});

            // Filters

            // TODO RENS: combinatie van client en server side filtering.

            this._setSidebarFilters(this.sidebarView.getFilterModel());

            var self = this;

            this.listenTo(this.sidebarView, 'filtersChanged', function(e) {

                self._setSidebarFilters(e.filters);

                self._emptyDOM();
                self._populateDom();
            });

            this.listenTo(this.sidebarView, 'toggle', function(e) {

                // Rens: deferred to allow a smooth sidebar opening before adjusting the calendar.

                _.defer(function() {

                    if (self.curCalendarView)
                        self.curCalendarView.viewSizeReset();
                });
            });

            this.listenTo(this.eventCollection, 'sync', function() {

                // We need to empty the DOM before populating it, even if this was done already, in case of racing calls.

                self._emptyDOM();
                self._populateDom();

                this.eventCollection.each(function(model) {

                    // Update the view when a model is destroyed.

                    self.listenTo(model, 'destroy', function() {

                        // We need to defer updating, because the destroy event is triggered before the model is actually removed from the collection.

                        _.defer(function() {

                            self._emptyDOM();
                            self._populateDom();
                        });
                    });
                });

                self.trigger('fetchEventsComplete');
            });
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.calendar_screen)({

                isMobile: this.isMobile()
            }));

            this.listenTo(this.eventCollection, 'syncAborted', _.bind(function() {

                if (this.viewType === 'month')
                    Love.Helpers.Loading.showLoading(false, this.cid);

            }, this));

            this.listenTo(this, 'fetchEventsComplete', _.bind(function(e) {

                if (this.viewType === 'month')
                    Love.Helpers.Loading.showLoading(false, this.cid);

            }, this));

            this.listenTo(this, 'fetchEventsStart', _.bind(function(e) {

                if (this.viewType === 'month')
                    Love.Helpers.Loading.showLoading(true, this.cid, this.$('.calendar-view-content'));

            }, this));

            this.setView(this.viewType);

            this.sidebarView.setElement(this.$('.base-sidebar-container')).render();
            this.sidebarView.listenTo(this, 'eventsChanged', _.bind(function(e) {

                this.sidebarView.renderStats(!e.eventsFetched, e.events);

            }, this));

            return Love.Views.BaseScreenView.prototype.render.call(this);
        },

        isMobile: function() {

            return $(window).width() < 880; // $calendar-mobile-breakpoint
        },

        goToDate: function(newDate) {

            var oldDate = this.selectedDay.clone();

            if (this.viewType === 'day') {

                if (!this.selectedDay.isSame(newDate, 'day')) {

                    this._emptyDOM();
                    this.selectedDay = newDate;
                    this.curCalendarView.updateRenderedView();
                    this.trigger('fetchEventsStart');
                    this.eventCollection.getEventsForDay(this.selectedDay, this.googleCalendars, {

                        requestId: this.getAjaxAbortId(),
                        abortPendingByIds: [this.getAjaxAbortId()]
                    });
                    Love.router.navigate('site/' + this.siteId + '/calendar/day/' + this.selectedDay.format('YYYY-MM-DD'), {trigger: false});
                }
            }
            else if (this.viewType === 'week') {

                newDate.startOf('isoweek');

                if (!this.selectedDay.isSame(newDate, 'day')) {

                    this._emptyDOM();
                    this.selectedDay = newDate;
                    this.curCalendarView.updateRenderedView();
                    this.trigger('fetchEventsStart');
                    this.eventCollection.getEventsForWeek(this.selectedDay, this.googleCalendars, {

                        requestId: this.getAjaxAbortId(),
                        abortPendingByIds: [this.getAjaxAbortId()]
                    });
                    Love.router.navigate('site/' + this.siteId + '/calendar/week/' + this.selectedDay.format('YYYY-MM-DD'), {trigger: false});
                }
            }
            else if (this.viewType === 'month') {

                newDate.startOf('month');

                if (!this.selectedDay.isSame(newDate, 'day')) {

                    this._emptyDOM();
                    this.selectedDay = newDate;
                    this.curCalendarView.updateRenderedView();
                    this.trigger('fetchEventsStart');
                    this.eventCollection.getEventsForMonth(this.selectedDay, this.googleCalendars, {

                        requestId: this.getAjaxAbortId(),
                        abortPendingByIds: [this.getAjaxAbortId()]
                    });
                    Love.router.navigate('site/' + this.siteId + '/calendar/month/' + this.selectedDay.format('YYYY-MM-DD'), {trigger: false});
                }
            }

            this._animateDaySelectors(oldDate.diff(newDate, 'days'));
        },

        nextWeek: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var targetDate;

            if (this.viewType === 'day')
                targetDate = this.selectedDay.clone().add(1, 'days');

            else if (this.viewType === 'week')
                targetDate = this.selectedDay.clone().add(1, 'weeks');

            else if (this.viewType === 'month')
                targetDate = this.selectedDay.clone().add(1, 'months');

            this.goToDate(targetDate);
        },

        previousWeek: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var targetDate;

            if (this.viewType === 'day')
                targetDate = this.selectedDay.clone().add(-1, 'days');

            else if (this.viewType === 'week')
                targetDate = this.selectedDay.clone().add(-1, 'weeks');

            else if (this.viewType === 'month')
                targetDate = this.selectedDay.clone().add(-1, 'months');

            this.goToDate(targetDate);
        },

        setView: function(view) {

            Love.Helpers.Tracking.track('Calendar - change view to ' + view);

            window.calendarScrollPosition = 0;

            this.viewType = view;

            if (this.curCalendarView)
                this.curCalendarView.close();

            this.trigger('eventsChanged', {eventsFetched: false, events: null});

            switch (this.viewType) {

                case 'day':
                    this.separateGoogleEvents = true;
                    this.curCalendarView = this.addSubView(new Love.Views.CalendarDayView({parentView: this}));
                    this.curCalendarView.setElement(this.$('.calendar-view-container')).render();
                    _.defer(_.bind(function() { this.curCalendarView.scrollToTime(true); }, this));
                    this.trigger('fetchEventsStart');
                    this.eventCollection.getEventsForDay(this.selectedDay, this.googleCalendars, {

                        requestId: this.getAjaxAbortId(),
                        abortPendingByIds: [this.getAjaxAbortId()]
                    });
                    Love.router.navigate('site/' + this.siteId + '/calendar/day/' + this.selectedDay.format('YYYY-MM-DD'), {
                        trigger: false,
                        replace: this.isFirstUrlReplace
                    });
                    this.isFirstUrlReplace = false;
                    this.$('.calendar-general-controls .calendar-mode-switcher .text').text('Day');
                    break;

                case 'week':
                    this.separateGoogleEvents = false;
                    this.curCalendarView = this.addSubView(new Love.Views.CalendarWeekView({parentView: this}));
                    this.curCalendarView.setElement(this.$('.calendar-view-container')).render();
                    _.defer(_.bind(function() { this.curCalendarView.scrollToTime(true); }, this));
                    this.trigger('fetchEventsStart');
                    this.eventCollection.getEventsForWeek(this.selectedDay.clone().startOf('isoweek'), this.googleCalendars, {

                        requestId: this.getAjaxAbortId(),
                        abortPendingByIds: [this.getAjaxAbortId()]
                    });
                    Love.router.navigate('site/' + this.siteId + '/calendar/week/' + this.selectedDay.format('YYYY-MM-DD'), {
                        trigger: false,
                        replace: this.isFirstUrlReplace
                    });
                    this.isFirstUrlReplace = false;
                    this.$('.calendar-general-controls .calendar-mode-switcher .text').text('Week');
                    break;

                case 'month':
                    this.separateGoogleEvents = false;
                    this.curCalendarView = this.addSubView(new Love.Views.CalendarMonthView({parentView: this}));
                    this.curCalendarView.setElement(this.$('.calendar-view-container')).render();
                    this.trigger('fetchEventsStart');
                    this.eventCollection.getEventsForMonth(this.selectedDay.clone().startOf('month'), this.googleCalendars, {

                        requestId: this.getAjaxAbortId(),
                        abortPendingByIds: [this.getAjaxAbortId()]
                    });
                    Love.router.navigate('site/' + this.siteId + '/calendar/month/' + this.selectedDay.clone().startOf('month').format('YYYY-MM-DD'), {
                        trigger: false,
                        replace: this.isFirstUrlReplace
                    });
                    this.isFirstUrlReplace = false;
                    this.$('.calendar-general-controls .calendar-mode-switcher .text').text('Month');
                    break;
            }
        },

        updateDayViewSelectors: function() {

            var description = '';

            if (this.selectedDay.isSame(moment(), 'day'))
                description = 'Today';

            else if (this.selectedDay.isSame(moment().add(-1, 'days'), 'day'))
                description = 'Yesterday';

            else if (this.selectedDay.isSame(moment().add(1, 'days'), 'day'))
                description = 'Tomorrow';

            else
                description = this.selectedDay.format('dddd, MMMM Do YYYY');

            this.$('.calendar-general-controls .calendar-description').text(description);
            this.$('.calendar-general-controls .calendar-current').text('This day');
            this.$('.calendar-general-controls .calendar-mode').removeClass('active');
            this.$('.calendar-general-controls .calendar-mode-day').addClass('active');

            this.sidebarView.updateStatsText(description);
        },

        updateMonthViewSelectors: function() {

            this.$('.calendar-general-controls .calendar-description').text(this.selectedDay.format('MMMM YYYY'));
            this.$('.calendar-general-controls .calendar-current').text('This month');
            this.$('.calendar-general-controls .calendar-mode').removeClass('active');
            this.$('.calendar-general-controls .calendar-mode-month').addClass('active');

            this.sidebarView.updateStatsText(this.selectedDay.format('MMMM YYYY'));
        },

        updateWeekViewSelectors: function() {

            var firstDayOfWeek = this.selectedDay.clone().startOf('isoweek');
            var isMobile = this.isMobile();

            this.$('.calendar-view-header ul li').each(function(index) {

                $(this).removeClass('current-day');

                var targetDate = firstDayOfWeek.clone().add(index, 'days');
                var text = targetDate.format('ddd D MMM');

                if (targetDate.isSame(moment(), 'day')) { // isSame(x, 'day') checks for day, month and year.

                    text = 'Today';
                    $(this).addClass('current-day');
                }

                else if (targetDate.isSame(moment().add(-1, 'days'), 'day'))
                    text = 'Yesterday';

                else if (targetDate.isSame(moment().add(1, 'days'), 'day'))
                    text = 'Tomorrow';

                if (!isMobile)
                    $(this).find('a').text(text);

                $(this).find('a').attr('data-date', targetDate.format('YYYY-MM-DD'));
            });

            var from = this.selectedDay.clone().startOf('isoweek');
            var to = this.selectedDay.clone().endOf('isoweek');

            /*
             Cases:
             September 12 - 18, 2016 (week 38)              [general-controls > 685] Same month
             September 26 - October 2, 2016 (week 40)       [general-controls > 765] Same year
             December 26, 2016 - January 2, 2017 (week 40)  [general-controls > 785] Different both

             September 12 - 18, 2016                        [general-controls > 615]
             September 26 - October 2, 2016                 [general-controls > 695]
             December 26, 2016 - January 2, 2017            [general-controls > 715]

             Sep 12 - 18, 2016                              [general-controls > 575]
             Sep 26 - Oct 2, 2016.                          [general-controls > 655]
             Dec 26, 2016 - Jan 2, 2017.                    [general-controls > 675]

             Sep 12-18 '16
             Sep 26-Oct 2 '16
             Dec 26 '16-Jan 2 '16 -> 26/12/16 - 2/1/16
             */

            var fromFormat, toFormat;
            var isSameYear = (from.isSame(to, 'year'));
            var isSameMonth = (from.isSame(to, 'month'));

            var weekStringThreshold = isSameYear ? 765 : 785;
            weekStringThreshold = isSameMonth ? 685 : weekStringThreshold;

            var weekString = this.$('.calendar-general-controls').width() > weekStringThreshold ? ' (week ' + this.selectedDay.format('w') + ')' : '';

            var longStringThreshold = isSameYear ? 695 : 715;
            longStringThreshold = isSameMonth ? 615 : longStringThreshold;

            var shortStringThreshold = isSameYear ? 655 : 675;
            shortStringThreshold = isSameMonth ? 575 : shortStringThreshold;

            if (this.$('.calendar-general-controls').width() > longStringThreshold) {

                fromFormat = (isSameYear ? from.format('MMMM D') : from.format('MMMM D, YYYY')) + ' - ';
                toFormat = isSameMonth ? to.format('D, YYYY') : to.format('MMMM D, YYYY');
            }
            else if (this.$('.calendar-general-controls').width() > shortStringThreshold) {

                fromFormat = (isSameYear ? from.format('MMM D') : from.format('MMM D, YYYY')) + ' - ';
                toFormat = isSameMonth ? to.format('D, YYYY') : to.format('MMM D, YYYY');
            }
            else {

                fromFormat = (isSameYear ? from.format('DD/MM') : from.format('DD/MM/YY')) + ' - ';
                toFormat = isSameMonth ? to.format('DD/MM/YY') : to.format('DD/MM/YY');
            }

            this.$('.calendar-general-controls .calendar-description').text(fromFormat + toFormat + weekString);
            this.$('.calendar-general-controls .calendar-current').text('This week');
            this.$('.calendar-general-controls .calendar-mode').removeClass('active');
            this.$('.calendar-general-controls .calendar-mode-week').addClass('active');

            this.sidebarView.updateStatsText(fromFormat + toFormat + weekString);
        },

        _animateDaySelectors: function(diff) {

            var width = this.$('.calendar-view-header ul li').outerWidth();
            var amount = (width * diff) + 'px';

            var self = this;
            this.$('.calendar-view-header').stop(true).css({'left': 0}).animate({'left': amount}, {

                duration: 300, complete: function() {

                    self.$('.calendar-view-header').css({'left': 0});
                }
            });
        },

        _createContent: function(e) {

            this.trigger('createContent', {fromHeader: true, originalEvent: e});
        },

        _emptyDOM: function() {

            if (this.curCalendarView) {

                this.curCalendarView.emptyDOM();
                this.trigger('eventsChanged', {eventsFetched: false, events: null});
            }
        },

        _enableDragDrop: function() {

            var handleDrop = function(dateTime, hasHour, model) {

                console.log('Calendar event dropped at:');
                console.log(dateTime.format('YYYY-MM-DD HH:mm'));
                //console.log(model);
            };

            // TODO RENS: remove handler on view close.

            this.$('.whole-day-events .base-day-calendar, .hourblock, .calendar-view-month .calendar-week-day').on('dragover', function(e) {

                var type = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent);

                if (type === 'ttl/calendar-event')
                    e.originalEvent.preventDefault();
            });

            // TODO RENS: remove handler on view close.

            this.$('.whole-day-events .base-day-calendar, .hourblock, .calendar-view-month .calendar-week-day').on('drop', function(e) {

                e.originalEvent.preventDefault();

                var items = Love.Helpers.DragDrop.getDataItems(e.originalEvent);

                _.each(items, function(item, index) {

                    var type = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent, index);

                    if (type === 'ttl/calendar-event') {

                        var json = JSON.parse(e.originalEvent.dataTransfer.getData('ttl/calendar-event'));
                        var model = new Love.Models.CalendarEventModel(json, {parse: true});

                        var date = $(e.target).closest('[data-date]').attr('data-date');

                        var hasHour = false;
                        var hour = $(e.target).closest('[data-hour]');

                        if (hour.length > 0) {

                            hasHour = true;
                            hour = parseInt(hour.attr('data-hour'));
                        }
                        else
                            hour = 0;

                        var dateTime = moment(date).add(hour, 'hours');

                        handleDrop(dateTime, hasHour, model);
                    }
                });
            });
        },

        _filterCollection: function(collection) {

            collection = collection.filterByQuery(this.filterSearch);
            collection = collection.filterByAuthors(this.filterAuthors || []);
            collection = collection.filterByCalendars(this.filterCalendars || []);
            collection = collection.filterByCategories(this.filterCategories || []);
            collection = collection.filterByContentTypes(this.filterContentTypes || []);
            collection = collection.filterByStorylines(this.filterStorylines || []);

            return collection;
        },

        _populateDom: function() {

            if (this.curCalendarView) {

                var filtered = this._filterCollection(this.eventCollection);

                this.curCalendarView.populateDomFromCollection(filtered);
                this.trigger('eventsChanged', {eventsFetched: true, events: filtered});

                this._enableDragDrop();
            }
        },

        _selectToday: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.goToDate(moment());
        },

        _setSidebarFilters: function(filters) {

            this.filterSearch = filters.search;
            this.filterAuthors = filters.authors;
            this.filterCalendars = filters.calendars;
            this.filterCategories = filters.categories;
            this.filterContentTypes = filters.types;
            this.filterStorylines = filters.storylines;
        },

        _showDatePicker: function() {

            var self = this;
            var popup = new Love.Views.CommonPickDatePopupView({

                callbacks: {

                    onChange: function(data) {self.goToDate(data.value);}
                },
                data: {value: this.selectedDay.clone()},
                positioning: {

                    attachToElement: this.$('.calendar-general-controls .calendar-select-date'),
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        }
    });

})(Love);