(function(Love) {

    Love.Models.CalendarEventModel = Love.Models.BaseModel.extend({

        objectClassName: 'Love.Models.CalendarEventModel',

        defaults: function() {

            return {

                id: '',
                content: {},
                contentSubType: '',
                contentType: '',
                date: moment(),
                dateEnd: moment().add(1, 'hours'),
                isGoogleCal: false,
                isWholeDay: false,
                type: null
            };
        },

        initialize: function(options) {

            if (options) this.set(options);
        },

        parse: function(response, options) {

            var event = (response.response) ? response.response : response;

            var isGoogleCal = (event.contentType === 'googlecalendar');
            var isWholeDay = (event.content.type === 'allday');

            var date, dateEnd;

            date = isWholeDay ? moment(event.dateTimeUtc) : moment.utc(event.dateTimeUtc).local();

            if (isGoogleCal) {

                dateEnd = isWholeDay ? moment(event.endDateTimeUtc) : moment.utc(event.endDateTimeUtc).local(); // Google events.
            }
            else {

                if (event.content.endsOn)
                    dateEnd = moment(event.content.endsOn); // TTL calendar events.
                else
                    dateEnd = date.clone(); // Moment events.
            }

            // Sometimes the API gives back calendarID instead of calendarId.
            // Also, for items without a calendarId, we use -1 to be able to filter them in a consistent way.

            if (event.content.calendarID && !event.content.calendarId) {

                event.content.calendarId = event.content.calendarID;
                delete event.content.calendarID;
            }

            if (!event.content.calendarId && event.content.calendarId !== '0')
                event.content.calendarId = '-1';

            // Sometimes the API gives back groupID instead of groupId.

            if (event.content.groupID && !event.content.groupId) {

                event.content.groupId = event.content.groupID;
                delete event.content.groupID;
            }

            // if (!isWholeDay) {
            //
            //     var zeroDate = date.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
            //     var zeroDateEnd = dateEnd.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
            //
            //     isWholeDay = (date.isSame(zeroDate, 'second') && dateEnd.isSame(zeroDateEnd, 'second'));
            // }

            if (event.type === 'scheduledcontent') {

                var destination = _.find(Love.session.get('currentSite').get('destinations'), function(obj) {return obj.id === event.content.destinationId;});

                event.content.destination = destination || {};
                event.content.settings = event.content.settings || {message: ''};
            }

            return _.extend(event, {

                date: date,
                dateEnd: dateEnd,
                isGoogleCal: isGoogleCal,
                isWholeDay: isWholeDay
            });
        },

        hasDuration: function() {

            if (this.get('isGoogleCal')) return true;
            if (this.get('type') === 'event') return true;
            if (this.get('contentType') === 'poll') return true;

            return false;
        }
    });

    Love.Collections.CalendarEventCollection = Love.Collections.BaseCollection.extend({

        objectClassName: 'Love.Collections.CalendarEventCollection',

        model: Love.Models.CalendarEventModel,

        url: function() { return 'api/1.0/site/' + Love.session.get('currentSiteId') + '/contentcalendar.json'; },

        comparator: function(model1, model2) {

            return model1.get('date').valueOf() - model2.get('date').valueOf();
        },

        parse: function(response, options) {

            var contents = [];

            _.each(response.response, function(value, index) {

                if (value.content) {

                    value.id = index + 1;
                    contents.push(value);
                }
            });

            return contents;
        },

        filterByAuthors: function(filters) {

            return new Love.Collections.CalendarEventCollection(this.filter(function(model) {

                if (filters.length === 0) return true;

                var result = false;

                _.each(filters, function(filter) {

                    // Currently, author filters by id only work for blog posts.

                    var isBlogPost = (model.get('type') === 'cmscontent' && model.get('content').contentType === 'blog');

                    var postModel = isBlogPost ? new Love.Models.BlogPostModel(model.get('content'), {parse: true}) : null;
                    var revision = postModel ? postModel.get('revisions')[postModel.getDefaultRevision()] : null;

                    result = (result || (!isBlogPost || !revision.author || !revision.author.id));
                    result = (result || (revision.author.id === filter.id));
                });

                return result;
            }));
        },

        filterByCalendars: function(filters) {

            return new Love.Collections.CalendarEventCollection(this.filter(function(model) {

                if (filters.length === 0) return true;

                var result = false;

                _.each(filters, function(filter) {

                    var isGoogleCal = false;

                    if (filter.id.length > 5) {

                        var tag = filter.id.substr(0, 5);

                        if (tag === 'gcal_') {

                            isGoogleCal = true;
                            filter = _.extend(_.clone(filter), {id: filter.id.slice(5)});
                        }
                    }

                    result = (result || (model.get('content').calendarId === filter.id && (model.get('isGoogleCal') === isGoogleCal)));
                });

                return result;
            }));
        },

        filterByCategories: function(filters) {

            return new Love.Collections.CalendarEventCollection(this.filter(function(model) {

                if (filters.length === 0) return true;

                var result = false;

                _.each(filters, function(filter) {

                    result = (result || (!model.get('content').groupId));
                    result = (result || (model.get('content').groupId === filter.id));
                });

                return result;
            }));
        },

        filterByContentTypes: function(filters) {

            // TODO RENS / JEROEN: dit moet meer solide, in overleg.

            return new Love.Collections.CalendarEventCollection(this.filter(function(model) {

                if (filters.length === 0) return true;

                var result = false;

                if (_.findWhere(filters, {id: '1'})) result = (result || model.get('contentType') === 'twitter');
                if (_.findWhere(filters, {id: '2'})) result = (result || model.get('contentType') === 'mobypicture');
                if (_.findWhere(filters, {id: '3'})) result = (result || model.get('contentType') === 'facebook');
                if (_.findWhere(filters, {id: '4'})) result = (result || (model.get('type') === 'event') || (model.get('type') === 'googlecalendar'));
                if (_.findWhere(filters, {id: '5'})) result = (result || (model.get('type') === 'cmscontent' && model.get('content').contentType === 'blog'));
                if (_.findWhere(filters, {id: '6'})) result = (result || model.get('contentType') === 'poll');
                if (_.findWhere(filters, {id: '7'})) result = (result || model.get('contentType') === 'instagram');

                return result;
            }));
        },

        filterByStorylines: function(filters) {

            return new Love.Collections.CalendarEventCollection(this.filter(function(model) {

                if (filters.length === 0) return true;

                var result = false;

                _.each(filters, function(filter) {

                    result = (result || (!model.get('content').storylines || !model.get('content').storylines.length === 0));
                    result = (result || (_.findWhere(model.get('content').storylines, {id: filter.id})));
                });

                return result;
            }));
        },

        filterByQuery: function(query) {

            return new Love.Collections.CalendarEventCollection(this.filter(function(model) {

                if (!query) return true;

                var result = false;

                // TODO RENS / JEROEN: zou mooi zijn als dit generalized model in de parse functie van de events zit, dan hoeft het niet steeds opnieuw gemaakt te worden
                // met een helper.

                var generalizedModel = Love.Helpers.Calendar.getParametersForEvent(model);

                result = (result || (generalizedModel.text.toLowerCase().indexOf(query.toLowerCase()) > -1));

                return result;
            }));
        },

        getEventsByRange: function(startDate, endDate, googleCalendars, options) {

            var stringFirstDay = startDate.format('YYYY-MM-DD');
            var stringLastDay = endDate.format('YYYY-MM-DD');
            var stringGoogleCals = googleCalendars ? Love.Helpers.Calendar.stringifyGoogleCalendars(googleCalendars) : '';

            return this.fetch(_.extend(options || {}, {

                data: {

                    by: 'range',
                    dateStart: stringFirstDay,
                    dateEnd: stringLastDay,
                    googleCalendarIds: stringGoogleCals
                }
            }));
        },

        getEventsForDay: function(day, googleCalendars, options) {

            var stringDay = day.format('YYYY-MM-DD');
            var stringGoogleCals = googleCalendars ? Love.Helpers.Calendar.stringifyGoogleCalendars(googleCalendars) : '';

            return this.fetch(_.extend(options || {}, {

                data: {

                    by: 'day',
                    date: stringDay,
                    googleCalendarIds: stringGoogleCals
                }
            }));
        },

        getEventsForMonth: function(day, googleCalendars, options) {

            var start = day.clone().startOf('isoweek'); // Include events of the first week of the month, even if they were in the previous month.
            var end = day.clone().add(1, 'months').endOf('isoweek'); // Do the same for the last month.

            return this.getEventsByRange(start, end, googleCalendars, options);
        },

        getEventsForWeek: function(firstDay, googleCalendars, options) {

            return this.getEventsByRange(firstDay, firstDay.clone().add(6, 'days'), googleCalendars, options);
        },

        getEventsUpcoming: function(fromDay, amount, options) {

            // TODO: implement upcoming events api call.

            return this.getEventsByRange(fromDay, fromDay.clone().add(10, 'years'), null, options);
        },

        getStatistics: function(startDate, endDate) {

            var collection = this;

            if (startDate) {

                if (!endDate) endDate = startDate.clone().add(1, 'days');

                // Only return statistics between the given dates.

                collection = new Love.Collections.CalendarEventCollection(collection.filter(function(model) {

                    return Love.Helpers.General.isOverlappingRange(model.get('date'), model.get('dateEnd'), startDate, endDate);
                }));
            }

            // Split the collection by content types.

            var events = collection.where({type: 'event'});
            var eventsGoogle = collection.where({contentType: 'googlecalendar'});
            var mobys = collection.where({contentType: 'mobypicture'});
            var posts = collection.filter(function(model) { return (model.get('type') === 'cmscontent' && model.get('content').contentType === 'blog'); });
            var tweets = collection.where({contentType: 'twitter'});

            var totalPostViews = 0;
            var totalMobyViews = 0;

            if (mobys.length > 0) {

                var mobyViews = _.map(mobys, function(value) {return parseInt(value.get('content').info.media.views);});
                totalMobyViews = mobyViews.reduce(function(a, b) {return a + b;});
            }

            if (posts.length > 0) {

                var postViews = _.map(posts, function(value) {return parseInt(value.get('content').views);});
                totalPostViews = postViews.reduce(function(a, b) {return a + b;});
            }

            return {

                // Total created per content type per period.

                totalEvents: events.length,
                totalGoogleEvents: eventsGoogle.length,
                totalMobys: mobys.length,
                totalPosts: posts.length,
                totalTweets: tweets.length,

                // Total views per content type created in period.

                totalViewsMobys: totalMobyViews,
                totalViewsPosts: totalPostViews
            };
        }
    });

})(Love);