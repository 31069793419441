(function(Love) {

    Love.Locales.en = _.extend(Love.Locales.en || {}, {

        'pages': {

            'screen': {

                'common_content_list_actions_delete_interval': '(0){Delete pages};(1){Delete 1 page};(2-inf){Delete {{count}} pages};'
            }
        }
    });

})(Love);