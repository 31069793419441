(function(Love) {

    Love.Views.PagesConfirmationPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.PagesConfirmationPopupView',

        className: 'base-popup popup-centered popup-size-auto',
        id: 'popup-pages-confirmation',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    action: 'publish',
                    editModel: null
                },
                enableLightbox: true
            };
        },

        events: {

            'click [data-action="close"]': '_handleClose',
            'click [data-action="confirm"]': '_handleConfirm'
        },

        render: function() {

            var languages = _.map(_.keys(this.options.data.editModel.get('revisions')), function(lang) {

                return (lang === '') ? Love.t('languages:intl') : Love.t('languages:' + lang);
            });

            var joined = languages.join('</em>, <em>');
            var index = joined.lastIndexOf(',');

            if (index > -1)
                joined = joined.substring(0, index) + ' and' + joined.substring(index + 1);

            var langText = 'A(n) <em>' + joined + '</em> version of the page will be created.';

            var tags = this.options.data.editModel.get('revisions')[this.options.data.editModel.getDefaultRevision()].tags;
            var tagsText = 'You have not added any <em>tags</em>.';

            if (tags.length > 0) {

                joined = tags.join('</em>, <em>');
                index = joined.lastIndexOf(',');

                if (index > -1)
                    joined = joined.substring(0, index) + ' and' + joined.substring(index + 1);

                tagsText = 'The page will be tagged as <em>' + joined + '</em>.';
            }

            this.$el.html(_.template(Love.Templates.pages_popups_confirmation)(_.extend({

                action: this.options.data.action,
                activeRevision: this.options.data.editModel.getDefaultRevision(),
                langText: langText,
                tagsText: tagsText

            }, this.options.data.editModel.attributes)));

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm();

            this.close();
        }
    });

})(Love);