(function(Love) {

    // TODO RENS: veel van onderstaande code staat hetzelfde in widget_toolbar_line.js. Kunnen we dit niet samenvoegen?

    Love.Views.CommonContentWidgetNewView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.CommonContentWidgetNewView',

        className: 'widget-new',
        id: '',

        defaults: function() {

            return {

                enableEmbed: true,
                enableImage: true,
                enableQuote: true,
                enableRemix: true,
                enableText: true,
                enableTextImage: false, // Op verzoek van Mathys.
                enableTextImageInMenu: true
            };
        },

        events: {

            'click [data-action="menu"]': '_showPopup',
            'click [data-action="new"]': '_handleNew',
            'cut [data-action="new"] input[type="text"]': '_handleNewInputChange',
            'paste [data-action="new"] input[type="text"]': '_handleNewInputChange',
            'keyup [data-action="new"] input[type="text"]': '_handleNewKeyUp',
            'click [data-action="new"] input[type="text"]': function(e) {e.stopPropagation();},
            'click [data-feedback="invalid"]': '_clearInput',
            'dragover': function(e) {e.preventDefault();},
            'drop': '_handleDrop'
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_widgets_widget_new)(this.options));

            this._unregisterCustomEvents();
            this._registerCustomEvents();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        onClose: function() {

            this._unregisterCustomEvents();
        },

        _clearInput: function(e) {

            e.stopPropagation();
            $(e.target).closest('[data-action="new"]').find('input[type="text"]').val('');
        },

        _handleDragEnter: function(e) {

            this.$('.controls').addClass('hidden');
            this.$('.drop-feedback').removeClass('hidden');

            var dataType = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent);
            var amount = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            this.$('.drop-feedback .icon').removeClass(Love.Helpers.DragDrop.getHeartsIconClasses().join(' '));
            this.$('.drop-feedback .icon').addClass(Love.Helpers.DragDrop.getHeartsIconClass(amount));

            var display;

            switch (dataType) {

                case 'image': {
                    display = Love.t('posts:post_editor.feedback_drop_image', {count: amount});
                    break;
                }
                default: {
                    display = Love.t('posts:post_editor.feedback_drop', {count: amount});
                    break;
                }
            }

            this.$('.drop-feedback .text').text(display);
        },

        _handleDragEnterContainer: function(e) {

            var display = Love.t('common:dragdrop.feedback_drop_close', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.drop-feedback .text').text(display);
        },

        _handleDragLeave: function(e) {

            this.$('.drop-feedback').addClass('hidden');
            this.$('.controls').removeClass('hidden');
        },

        _handleDragLeaveContainer: function(e) {

            var display = Love.t('posts:post_editor.feedback_drop', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.drop-feedback .text').text(display);
        },

        _handleDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault(); // Default is to open as link on drop.

            var count = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            if (count > 0) {

                var self = this;
                var promises = [];

                Love.Helpers.Loading.showLoading(true);

                _.every(Love.Helpers.DragDrop.getDataItems(e.originalEvent), function(item, index) {

                    // Determine the dropped content and create new widgets based on data type.

                    var dataType = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent, index);

                    if (dataType === 'image') {

                        var promise = $.Deferred();
                        promises[index] = promise;

                        var model = new Love.Models.ImageModel();

                        model.uploadFile(item.getAsFile())

                            .done(_.bind(function() {

                                var fragmentOrPromise = Love.Factories.FragmentFactory.createImageFragment(this);

                                $.when(fragmentOrPromise).always(function(fragment) {

                                    if (fragment) {

                                        self.trigger('addExtraWidget', {

                                            settings: fragment.settings,
                                            type: fragment.type
                                        });
                                    }

                                    promise.resolve();
                                });

                            }, model)).fail(function() { promise.reject(); });

                        return true;
                    }
                    else if (dataType === 'text') {

                        var content = Love.Helpers.General.htmlSanitize(e.originalEvent.dataTransfer.getData('text/html'));

                        if (!_.isEmpty(content)) {

                            var fragmentOrPromise = Love.Factories.FragmentFactory.createTextFragment(content);
                            promises.push(fragmentOrPromise);

                            $.when(fragmentOrPromise).always(function(fragment) {

                                self.trigger('addExtraWidget', {

                                    settings: fragment.settings,
                                    type: fragment.type
                                });
                            });

                            return false; // When dropping text, we don't support handling multiple dropped items.
                        }
                    }

                }, this);

                $.whenAll.apply($, promises).always(function() { Love.Helpers.Loading.showLoading(false);});
            }
        },

        _handleNew: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            if (e && e.stopPropagation) e.stopPropagation();

            var element = $(e.target).closest('[data-type]');
            var type = element.attr('data-type');
            var self = this;

            if (type === 'remix') {

                $.when(Love.session.get('currentSite').getData('bookmarksLatest', true)).done(_.bind(function(items) {

                    var popup = new Love.Views.CommonNewRemixTopPopupView({

                        callbacks: {

                            onConfirm: _.bind(function(data) {

                                Love.Helpers.Loading.showLoading(true);

                                data.items.reverse();
                                var promises = [];

                                _.each(data.items, function(item) {

                                    var fragmentOrPromise;
                                    var promise = $.Deferred();

                                    promises.push(promise);

                                    if (item.type === 'bookmark')
                                        fragmentOrPromise = Love.Factories.FragmentFactory.createFragmentFromBookmark(item.model);
                                    else if (item.type === 'media')
                                        fragmentOrPromise = Love.Factories.FragmentFactory.createFragmentFromMedia(item.model);

                                    $.when(fragmentOrPromise).always(function(fragment) {

                                        if (fragment)
                                            promise.resolve(fragment);
                                        else
                                            promise.reject();
                                    });
                                });

                                $.whenAll.apply($, promises).always(_.bind(function(promises) {

                                    promises.reverse(); // Reversing and inserting provides a nicer UI flow.

                                    _.each(promises, function(resultArray) {

                                        var state = resultArray[0];
                                        var fragment = resultArray[1];

                                        if (state === 'resolved' && fragment) {

                                            this.trigger('addExtraWidget', {

                                                settings: fragment.settings,
                                                type: fragment.type
                                            });
                                        }

                                    }, this);

                                    Love.Helpers.Loading.showLoading(false);

                                }, this));

                            }, this)
                        },
                        data: {items: items, showMore: true, type: 'widgets'},
                        positioning: {

                            attachToElement: $(e.target).closest('.button'),
                            centerX: true,
                            positionAt: 'element'
                        }
                    });

                    popup.showPopup();

                }, this));
            }
            else {

                Love.Helpers.Loading.showLoading(true);

                var fragmentOrPromise = {

                    settings: {},
                    type: type
                };

                var handleFragment = function(fragment) {

                    if (fragment) {

                        self.trigger('addExtraWidget', {

                            settings: fragment.settings,
                            type: fragment.type
                        });
                    }

                    Love.Helpers.Loading.showLoading(false);
                };

                if (type === 'CMSTextImageWidget')
                    fragmentOrPromise.settings.imageAlignment = element.attr('data-align');

                else if (type === 'CMSEmbedWidget') {

                    var url = element.find('input[name="url"]').val();

                    if (_.isEmpty(url)) {

                        fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedFragmentFromString(url, true);
                    }
                    else {

                        element.find('input[name="url"]').val('');
                        element.find('[data-feedback]').addClass('hidden');

                        fragmentOrPromise = Love.Factories.FragmentFactory.createFragmentFromString(url);
                    }
                }

                $.when(fragmentOrPromise).always(handleFragment);
            }
        },

        _handleNewInputChange: function(e) {

            if (e && e.stopPropagation) e.stopPropagation();

            var element = $(e.target).closest('[data-type]');
            var type = element.attr('data-type');

            if (type === 'CMSEmbedWidget') {

                // We need to defer execution in case of the paste event, as the event occurs before the input value has been updated.
                // Deferred execution might also work nice for other change events anyway, as parsing the value could take some time.

                _.defer(function() {

                    var inputVal = $(e.target).val();

                    // Validate the input url and display feedback to the user.

                    if (!inputVal) {

                        element.find('[data-feedback]').addClass('hidden');
                        return;
                    }

                    var fragmentOrPromise = Love.Factories.FragmentFactory.createEmbedFragmentFromString(inputVal);

                    $.when(fragmentOrPromise).always(function(fragment) {

                        if (fragment) {

                            element.find('[data-feedback="invalid"]').addClass('hidden');
                            element.find('[data-feedback="valid"]').removeClass('hidden');
                        }
                        else {

                            element.find('[data-feedback="valid"]').addClass('hidden');
                            element.find('[data-feedback="invalid"]').removeClass('hidden');
                        }
                    });
                });
            }
        },

        _handleNewKeyUp: function(e) {

            if (e.keyCode === 13)
                this._handleNew(e); // Handle the enter key.
            else
                this._handleNewInputChange(e);
        },

        _registerCustomEvents: function() {

            _.bindAll(this, '_handleDragEnter', '_handleDragEnterContainer');
            _.bindAll(this, '_handleDragLeave', '_handleDragLeaveContainer');

            Love.Helpers.DragDrop.registerEnterLeaveEvents($('body'), this.cid, this._handleDragEnter, this._handleDragLeave);
            Love.Helpers.DragDrop.registerEnterLeaveEvents(this.$el, this.cid, this._handleDragEnterContainer, this._handleDragLeaveContainer);
        },

        _unregisterCustomEvents: function() {

            Love.Helpers.DragDrop.unregisterEnterLeaveEvents($('body'), this.cid);
            Love.Helpers.DragDrop.unregisterEnterLeaveEvents(this.$el, this.cid);
        },

        _showPopup: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var self = this;
            var popup = new Love.Views.CommonContentNewWidgetPopupView({

                callbacks: {

                    onConfirm: function(data) {

                        data.fragments.reverse();

                        _.each(data.fragments, function(fragment) {

                            self.trigger('addExtraWidget', {

                                settings: fragment.settings,
                                type: fragment.type
                            });
                        });
                    }
                },
                data: {

                    items: _.without([

                        this.options.enableTextImageInMenu ? {

                            icon: 'icon-image',
                            icon2: 'icon-paragraph-left',
                            name: 'Image & Text',
                            action: 'confirm',
                            type: 'CMSTextImageWidget',
                            extra: 'left'

                        } : null,
                        this.options.enableTextImageInMenu ? {

                            icon: 'icon-paragraph-left',
                            icon2: 'icon-image',
                            name: 'Text & Image',
                            action: 'confirm',
                            type: 'CMSTextImageWidget',
                            extra: 'right'

                        } : null,
                        this.options.enableText ? {icon: 'icon-paragraph-left', name: 'Text', action: 'confirm', type: 'CMSTextWidget'} : null,
                        this.options.enableImage ? {icon: 'icon-image', name: 'Image', action: 'confirm', type: 'CMSImageWidget'} : null,
                        this.options.enableQuote ? {icon: 'icon-quotes-left', name: 'Quote', action: 'confirm', type: 'CMSQuoteWidget'} : null,
                        this.options.enableRemix ? {icon: 'ttl-remix', name: 'Remix content', action: 'confirm', type: 'remix'} : null,
                        this.options.enableEmbed ? {icon: 'ttl-embedcontent2', name: 'Embedded Media', action: 'confirm', type: 'CMSEmbedWidget'} : null

                    ], null)
                },
                positioning: {

                    attachToElement: $(e.target).closest('.button'),
                    centerY: true,
                    positionAt: 'element'
                }
            });

            popup.showPopup();
        }
    });

})(Love);