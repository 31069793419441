(function(Love) {

    Love.Views.CommonCoverImagePopupViewNew = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonCoverImagePopupView',

        className: 'base-popup popup-centered',
        id: 'popup-common-cover-image',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    canChangeCroppedArea: true, // TODO RENS: implement
                    imageModel: null,
                    images: [],
                    ogImageModel: null
                },
                enableLightbox: true
            };
        },

        // TODO RENS: keep data in memory by listening to crop events. Maar werkte dat uberhaupt nog wel nu data van ogImage werd uitgelezen?

        events: {

            'click .cover-available-images .image': '_handleThumbnailClick',
            'dragover .popup-content': function(e) {e.preventDefault();},
            'drop .cover-cropper': '_handleDrop',
            'click [data-action="toggle-description"]': '_handleToggleDescription',
            'click [data-action="remove"]': '_handleRemove',
            'click [data-action="confirm"]': '_handleConfirm',
            'click [data-action="close"]': '_handleClose'
        },

        initialize: function(options) {

            Love.Views.BasePopupView.prototype.initialize.call(this, options);

            // We deep clone all image models here to ensure predictable Cancel / OK behavior:

            // - in the popup, focus point data should be kept while open.
            // - on Cancel, data should not be kept.
            // - on OK, data should be kept for the active imageModel.

            _.each(this.options.data.images, function(image, index) {

                this.options.data.images[index] = Love.Helpers.General.deepClone(image);

            }, this);

            if (this.options.data.imageModel)
                this.options.data.imageModel = Love.Helpers.General.deepClone(this.options.data.imageModel);

            else if (this.options.data.images[0])
                this.options.data.imageModel = this.options.data.images[0];

            if (this.options.data.ogImageModel)
                this.options.data.ogImageModel = Love.Helpers.General.deepClone(this.options.data.ogImageModel);
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_popups_cover_image)());

            this.$cropper = this.$('.cover-cropper > .cropper-image');

            this._grid = this.addSubView(new Love.Views.CommonContentGridView({type: 'image'}));
            this._grid.setElement(this.$('.common-content-grid-container')).render();

            _.each(this.options.data.images, function(image, index) {

                var fp = image.getFocusPoint();
                this._grid.addItem('<div class="image" data-load-url="' + image.getImageView() + '" data-focus-x="' + fp.x + '" data-focus-y="' + fp.y + '"></div>', index);

            }, this);

            this._grid.addImageUploader();
            this._grid.init();

            this._imageUploadView = this.addSubView(new Love.Views.CommonImageUploadView({

                addImageMode: true,
                callbacks: {

                    onChange: _.bind(function(imageModel) {

                        if (imageModel && !_.isEmpty(imageModel.getImageView())) {

                            this._addNewImage(imageModel, true);
                            this._imageUploadView.setImage(new Love.Models.ImageModel());
                        }

                    }, this),
                    onDropMultiple: _.bind(this._handleImageDropMultiple, this)
                },
                mini: true,
                parentPopupView: this
            }));
            this._imageUploadView.setElement(this.$('.common-image-upload-container')).render();

            this._updateDisplayedControls();

            _.defer(_.bind(function() {

                if (this.options.data.imageModel)
                    this._updateDisplayedImage();

            }, this));

            if (Love.Helpers.LocalStorageSession.getObject('common_popups_cover_image_description_hidden'))
                _.defer(_.bind(function() { this.$('.toggle-description').removeClass('open'); }, this));

            this._unregisterCustomEvents();
            this._registerCustomEvents();

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        onClose: function() {

            this._unregisterCustomEvents();
            Love.Views.BasePopupView.prototype.onClose.call(this);
        },

        _addNewImage: function(imageModel, activate) {

            imageModel = Love.Helpers.General.deepClone(imageModel);

            this.options.data.images.push(imageModel);

            var fp = imageModel.getFocusPoint();

            this._grid.addItem('<div class="image" data-load-url="' + imageModel.getImageView() + '" data-focus-x="' + fp.x + '" data-focus-y="' + fp.y + '"></div>', this.options.data.images.length - 1);
            this._grid.init();

            this._updateDisplayedControls();

            if (activate) {

                this.options.data.imageModel = imageModel;
                this.options.data.ogImageModel = null;

                this._updateDisplayedImage();
            }

            this.performLazyLoadImages();

            // NOTE: updating the content slider isn't necessary here, as it performs a resize after adding a new slide.
        },

        _createCropper: function() {

            if (!this.cropper)
                this.cropper = new Cropper(this.$cropper[0], {

                    aspectRatio: 1.91, // 1.91:1 is the approximate ratio Facebook etc. use.
                    autoCropArea: 1,
                    background: false,
                    center: false,
                    dragMode: 'move',
                    guides: false,
                    highlight: false,
                    minContainerHeight: 0,
                    minContainerWidth: 0,
                    rotatable: false,
                    scalable: false,
                    toggleDragModeOnDblclick: false,
                    viewMode: 2,

                    ready: _.bind(function() {

                        var croppedArea = (this.options.data.ogImageModel && !this.options.data.ogImageModel.hasDefaultCropRegion()) ? this.options.data.ogImageModel.getCroppedArea() : null;

                        if (croppedArea) {

                            this.cropper.setCropBoxData(croppedArea.cropBox); // This must be called first.
                            this.cropper.setCanvasData(croppedArea.canvas);
                        }

                    }, this)
                });
        },

        _getCanvasCroppedData: function(callback) {

            // Though we don't have to use a callback, this allows easily replacing the data URL by a blob.

            var canvas = this.cropper.getCroppedCanvas();
            var url = (canvas && canvas.toDataURL) ? canvas.toDataURL() : null;

            callback(url);
        },

        _getCroppedAreaData: function() {

            var canvasData = this.cropper.getCanvasData();
            var cropBoxData = this.cropper.getCropBoxData();

            return {

                canvas: canvasData,
                cropBox: cropBoxData
            };
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.cropper) {

                this.cropper.destroy(); // The cropper doesn't survive a closing.
                this.cropper = null;
            }

            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            // TODO RENS: implement focus point zoals hieronder (Kinder)

            // if (this.cropper) {
            //
            //     const canvas = this.cropper.getCanvasData();
            //     const cropBox = this.cropper.getCropBoxData();
            //
            //     // Store the canvas and cropBox data to enable restoring the cropper state on editing.
            //
            //     value.focus = _.extend(value.focus, {canvas: canvas, cropBox: cropBox});
            //
            //     // Calculate the focus region relative to the original image.
            //
            //     const zoom = {
            //
            //         x: canvas.naturalWidth / canvas.width,
            //         y: canvas.naturalHeight / canvas.height
            //     };
            //
            //     value.focus.region = {
            //
            //         left: (cropBox.left - canvas.left) * zoom.x,
            //         top: (cropBox.top - canvas.top) * zoom.y,
            //         width: cropBox.width * zoom.x,
            //         height: cropBox.height * zoom.y
            //     }
            // }

            if (this.options.callbacks.onConfirm) {

                var ogImageModel = this.options.data.ogImageModel ? this.options.data.ogImageModel : new Love.Models.ImageModel();

                if (this.options.data.canChangeCroppedArea) {

                    // Though it would be best for performance to create the ogImage in the background or server side,
                    // we have an instance of the cropper plugin available here that can do the job.

                    Love.Helpers.Loading.showLoading(true);

                    // If the crop handler wasn't executed yet / after cropping, we might have to create a new ogImageModel and store the data.
                    // We'll do it here just in case.

                    ogImageModel.setCroppedArea(this._getCroppedAreaData());

                    this._getCanvasCroppedData(_.bind(function(blob) {

                        Love.Helpers.Loading.showLoading(false);

                        if (blob) {

                            // Storing the image data as an URI allows us to upload it later.

                            ogImageModel.set('imageSource', 'url');
                            ogImageModel.set('imageURL', blob);

                            this.options.callbacks.onConfirm({

                                imageModel: this.options.data.imageModel,
                                ogImageModel: ogImageModel
                            });
                        }

                    }, this));
                }
                else
                    this.options.callbacks.onConfirm({

                        imageModel: this.options.data.imageModel,
                        ogImageModel: ogImageModel
                    });
            }

            this.close();
        },

        _handleDragEnter: function(e) {

            this.$('.cover-cropper-drop-overlay .icon').removeClass('icon-image');
            this.$('.cover-cropper-drop-overlay .icon').removeClass(Love.Helpers.DragDrop.getHeartsIconClasses().join(' '));

            var amount = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            this.$('.cover-cropper-drop-overlay .icon').addClass(Love.Helpers.DragDrop.getHeartsIconClass(amount));

            var display = Love.t('common:dragdrop.feedback_drop', {count: amount});
            this.$('.cover-cropper-drop-overlay .drop-feedback').text(display);

            this.$('.cover-cropper-drop-overlay').removeClass('hidden');
        },

        _handleDragEnterCropper: function(e) {

            this.$('.cover-cropper').addClass('hover');

            var display = Love.t('common:dragdrop.feedback_drop_close', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.cover-cropper-drop-overlay .drop-feedback').text(display);
        },

        _handleDragLeave: function(e) {

            this.$('.cover-cropper-drop-overlay').addClass('hidden');
        },

        _handleDragLeaveCropper: function(e) {

            this.$('.cover-cropper').removeClass('hover');

            var display = Love.t('common:dragdrop.feedback_drop', {count: Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent)});
            this.$('.cover-cropper-drop-overlay .drop-feedback').text(display);
        },

        _handleDrop: function(e) {

            if (e && e.preventDefault) e.preventDefault(); // Default is to open as link on drop.

            var count = Love.Helpers.DragDrop.getAmountForEvent(e.originalEvent);

            if (count > 0) {

                var self = this;

                _.each(Love.Helpers.DragDrop.getDataItems(e.originalEvent), function(item, index) {

                    var dataType = Love.Helpers.DragDrop.getDataTypeForEvent(e.originalEvent, index);

                    if (dataType === 'image') {

                        var reader = new FileReader();

                        reader.addEventListener('load', function() {

                            var imageModel = new Love.Models.ImageModel({

                                imageSource: 'url',
                                imageURL: reader.result
                            });

                            self._addNewImage(imageModel, true);

                        }, false);

                        reader.readAsDataURL(item.getAsFile());
                    }

                }, this);
            }
        },

        _handleImageDropMultiple: function(file, index) {

            // Add any extra dropped images to the grid.

            var model = new Love.Models.ImageModel();
            var self = this;

            return model.uploadFile(file)

                .done(function() {

                    self._addNewImage(model);
                });
        },

        _handleRemove: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm({

                    imageModel: new Love.Models.ImageModel(),
                    ogImageModel: new Love.Models.ImageModel()
                });

            this.close();
        },

        _handleThumbnailClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var index = parseInt($(e.target).closest('[data-index]').attr('data-index'));

            this.options.data.imageModel = this.options.data.images[index];
            this.options.data.ogImageModel = null;

            this._updateDisplayedImage();
        },

        _handleToggleDescription: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this._setDescriptionArrow();

            Love.Helpers.LocalStorageSession.setObject('common_popups_cover_image_description_hidden', !this.$('.toggle-description').hasClass('open'));
        },

        _registerCustomEvents: function() {

            Love.Helpers.DragDrop.registerEnterLeaveEvents($('body'), this.cid, this._handleDragEnter, this._handleDragLeave, true);
            Love.Helpers.DragDrop.registerEnterLeaveEvents(this.$('.cover-cropper'), this.cid, this._handleDragEnterCropper, this._handleDragLeaveCropper, true);
        },

        _setDescriptionArrow: function() {

            var isOpen = this.$('.toggle-description').toggleClass('open').hasClass('open');

            if (isOpen) {

                var button = this.$('.toggle-description-button');
                var left = button.offset().left + button.width() / 2 - 1; // With a small correction

                var arrowLeft = left - this.$('.toggle-description').offset().left;

                this.$('.toggle-arrow').css('left', arrowLeft);
            }
        },

        _unregisterCustomEvents: function() {

            Love.Helpers.DragDrop.unregisterEnterLeaveEvents($('body'), this.cid);
            Love.Helpers.DragDrop.unregisterEnterLeaveEvents(this.$('.cover-cropper'), this.cid);
        },

        _updateDisplayedControls: function() {

            if (this.options.data.images.length < 1) {

                this.$('.common-content-slider-container').addClass('hidden');
                this.$('.common-image-upload-container').addClass('full');
                this.$('.cover-cropper-container').addClass('hidden');
            }
            else {

                this.$('.common-content-slider-container').removeClass('hidden');
                this.$('.common-image-upload-container').removeClass('full');
                this.$('.cover-cropper-container').removeClass('hidden');
            }
        },

        _updateDisplayedImage: function() {

            var imageModel = this.options.data.imageModel;
            var imageIndex = _.findIndex(this.options.data.images, function(model) {

                // We can't match on reference here, as the image models are all deep cloned.

                return model.getImageView() === imageModel.getImageView();
            });

            // Mark the active image in the available images list.

            this.$('.cover-available-images .grid-item').removeClass('active');
            this.$('.cover-available-images .grid-item[data-index="' + imageIndex + '"]').addClass('active');

            // Display the active image in the cropper.

            var parameters = [];

            parameters.push({name: 'image_correct_orientation', value: true});

            if (this.cropper) {

                this.cropper.replace(Love.Helpers.Proxy.getProxyUrl(imageModel.getImageView(), parameters));
            }
            else {

                this.$cropper.attr('src', Love.Helpers.Proxy.getProxyUrl(imageModel.getImageView(), parameters));
                this._createCropper();
            }
        }
    });

})(Love);