(function(Love) {

    Love.Models.BlogPostModel = Love.Models.BaseContentModel.extend({

        objectClassName: 'Love.Models.BlogPostModel',

        defaults: function() {

            return _.extend(Love.Models.BaseContentModel.prototype.defaults.call(this), {

                comments: {total: 0, last: []},
                contentType: 'blog',
                groupId: '',
                scheduledContent: []
            });
        },

        url: function() {

            // Get / update / delete url.

            if (this.id)
                return 'api/1.0/blogpost/' + this.id + '.json?sid=' + Love.session.get('currentSiteId');

            // Create url. A group id is required.

            else
                return 'api/1.0/blog/' + this.get('groupId') + '/posts.json?sid=' + Love.session.get('currentSiteId');
        },

        parse: function(response, options) {

            var contents = Love.Models.BaseContentModel.prototype.parse.call(this, response, options);

            contents = _.extend(contents, {

                scheduledContent: contents.scheduledContent ? _.each(contents.scheduledContent, function(update) {

                        update.createdOnUtc = update.createdOnUtc && moment.utc(update.createdOnUtc).isValid() ? moment.utc(update.createdOnUtc) : null;
                        update.scheduledOnUtc = update.scheduledOnUtc && moment.utc(update.scheduledOnUtc).isValid() ? moment.utc(update.scheduledOnUtc) : null;

                    }, this) : this.defaults().scheduledContent
            });

            return contents;
        },

        validate: function(attributes, options) {

            var errors = {};

            if (!attributes.groupId) errors.groupId = 'Group id required.';

            // TODO: attributes valideren voor save.

            if (!_.isEmpty(errors))
                return errors;
        },

        getClientBackupKey: function() {

            return 'posts_post_' + this.id;
        }
    });

    Love.Collections.BlogPostCollection = Love.Collections.BasePageableCollection.extend({

        objectClassName: 'Love.Collections.BlogPostCollection',

        model: Love.Models.BlogPostModel,

        url: function() { return 'api/1.0/search/blogposts.json?sid=' + Love.session.get('currentSiteId'); },

        parseRecords: function(response, options) {

            return response.response;
        }
    });

})(Love);