(function(Love) {

    Love.Helpers.DragDrop = {

        _mouse: {touchIdentifier: null, x: 0, y: 0},

        dragDetectionHandler: function(e) {

            // e: mousedown / touchstart / pointerdown

            Love.Helpers.DragDrop._mouseStartPosition = Love.Helpers.DragDrop.getMousePositionForEvent(e);
        },

        getAmountForEvent: function(e) {

            if (!e.dataTransfer) return 0;

            if (e.dataTransfer.items) {

                // New D&D interface, only supported by Chrome.

                return e.dataTransfer.items.length;
            }
            else {

                return e.dataTransfer.files.length;
            }
        },

        getDataItems: function(e) {

            if (!e.dataTransfer) return [];

            if (e.dataTransfer.items) {

                // New D&D interface, only supported by Chrome.

                return e.dataTransfer.items;
            }
            else {

                return _.map(_.clone(e.dataTransfer.files), function(file) {

                    return _.extend(file, {

                        // Expose as much as possible of the new D&D interface.

                        getAsFile: function() { return file; }
                    });
                });
            }
        },

        getDataTypeForEvent: function(e, itemIndex) {

            var type = '';

            if (!itemIndex) itemIndex = 0;

            if (e.dataTransfer) {

                if (e.dataTransfer.files[itemIndex])
                    type = e.dataTransfer.files[itemIndex].type;

                else if (e.dataTransfer.items && e.dataTransfer.items[itemIndex])
                    type = e.dataTransfer.items[itemIndex].type;
            }

            if (type && type.substring(0, 6) === 'image/')
                type = 'image';

            else if (type && type.substring(0, 5) === 'text/')
                type = 'text';

            return type;
        },

        getHeartsIconClass: function(amount) {

            if (amount > 3)
                return 'ttl-more-hearts';

            else if (amount > 2)
                return 'ttl-triple-hearts';

            else if (amount > 1)
                return 'ttl-hearts-4';

            else
                return 'icon-heart';
        },

        getHeartsIconClasses: function() {

            return ['icon-heart', 'ttl-hearts-4', 'ttl-triple-hearts', 'ttl-more-hearts'];
        },

        getMousePositionForDragStart: function() { return Love.Helpers.DragDrop._mouseStartPosition; },

        getMousePositionForEvent: function(e) {

            // e: mousedown / mousemove / mouseup / touchstart / touchmove / touchend / pointerdown / pointermove / pointerup

            var isTouchEvent = e.type.startsWith('touch');

            if (isTouchEvent) {

                e = (e.originalEvent && e.originalEvent.touches) ? e.originalEvent : e;
                e = e.touches[0];
            }

            return {

                touchIdentifier: isTouchEvent ? e.identifier : null,
                x: e.pageX,
                y: e.pageY
            };
        },

        isDragOperation: function(e) {

            // e: mouseup / mousemove / touchend / touchmove / pointerup / pointermove

            var position = Love.Helpers.DragDrop.getMousePositionForDragStart();

            if (e.type === 'touchend' || e.type === 'touchmove') {

                e = (e.originalEvent && e.originalEvent.changedTouches) ? e.originalEvent : e;
                e = _.findWhere(e.changedTouches, {identifier: position.touchIdentifier});

                if (!e) return;
            }

            if (position.x !== e.pageX || position.y !== e.pageY)
                return true;

            return false;
        },

        registerEnterLeaveEvents: function(elements, identifier, enterHandler, leaveHandler, isPopup) {

            // Register our custom dragenter / dragleave / drop / dragend events.

            $.each($(elements), function() {

                var element = this;
                var $element = $(this);
                var key = identifier;

                var handlers = {

                    handleDragEnter: function(e) {

                        // If this is not a popup and there are popups open don't do anything.

                        if (!isPopup && Love.appView.hasPopups())
                            return;

                        var references = $.data(element, 'loveDragDropReferences');

                        if (references[key].length === 0)
                            enterHandler(e);

                        references[key].push(e.target);
                    },

                    handleDragLeave: function(e) {

                        // If this is not a popup and there are popups open don't do anything.

                        if (!isPopup && Love.appView.hasPopups())
                            return;

                        var references = $.data(element, 'loveDragDropReferences');

                        // Timeout is needed to ensure that the leave event on the previous element
                        // fires AFTER the enter event on the next element.

                        setTimeout(function() {

                            var currentElementIndex = references[key].indexOf(e.target);

                            if (currentElementIndex > -1)
                                references[key].splice(currentElementIndex, 1);

                            if (references[key].length === 0)
                                leaveHandler(e);

                        }, 1);
                    },

                    handleDrop: function(e) {

                        // If this is not a popup and there are popups open don't do anything.

                        if (!isPopup && Love.appView.hasPopups())
                            return;

                        var references = $.data(element, 'loveDragDropReferences');

                        references[key] = [];
                        leaveHandler(e);
                    }
                };

                if (!$.data(element, 'loveDragDropHandlers'))
                    $.data(element, 'loveDragDropHandlers', []);

                $.data(element, 'loveDragDropHandlers')[key] = handlers;

                if (!$.data(element, 'loveDragDropReferences'))
                    $.data(element, 'loveDragDropReferences', []);

                $.data(element, 'loveDragDropReferences')[key] = [];

                $element.on('dragenter.lovedragdrop', handlers.handleDragEnter);
                $element.on('dragleave.lovedragdrop', handlers.handleDragLeave);
                $element.on('drop.lovedragdrop', handlers.handleDrop);
                $element.on('dragend.lovedragdrop', handlers.handleDrop);
            });
        },

        unregisterEnterLeaveEvents: function(elements, identifier) {

            $.each($(elements), function() {

                var element = this;
                var $element = $(this);
                var key = identifier;

                if (element.loveDragDropHandlers && element.loveDragDropHandlers[key]) {

                    var handlers = element.loveDragDropHandlers[key];

                    $element.off('dragenter.lovedragdrop', handlers.handleDragEnter);
                    $element.off('dragleave.lovedragdrop', handlers.handleDragLeave);
                    $element.off('drop.lovedragdrop', handlers.handleDrop);
                    $element.off('dragend.lovedragdrop', handlers.handleDrop);

                    delete element.loveDragDropHandlers[key];
                    delete element.loveDragDropReferences[key];
                }
            });
        }
    };

})(Love);