(function(Love) {

    Love.Views.DashboardNewsModuleView = Love.Views.BaseView.extend({

        objectClassName: 'Love.Views.DashboardNewsModuleView',

        initialize: function(options) {

            Love.Views.BaseView.prototype.initialize.call(this, options);

            _.bindAll(this, '_renderItems');

            this._items = new Love.Collections.NewsItemCollection();
            this._itemViews = [];
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.dashboard_modules_news));

            this.listenTo(this._items, 'sync', this._renderItems);
            this._items.fetch();

            return Love.Views.BaseView.prototype.render.call(this);
        },

        _renderItems: function() {

            var addElements = [];
            var items = this._items.where({isPublished: true});

            if (items.length < 1) {

                this.$('.common-content-list').html(_.template(Love.Templates.dashboard_modules_news_row_none)());
                return;
            }

            _.each(items, function(model) {

                try {

                    var itemView = this.addSubView(new Love.Views.DashboardNewsRowView({item: model}));

                    this._itemViews.push(itemView);
                    addElements.push(itemView.render().$el);
                }
                catch (ex) {

                    console.log(ex); // TODO: proper error handling.
                }

            }, this);

            /*
             this._itemViews.push(this.addSubView(new Love.Views.DashboardNewsRowView({item: this._items.at(0)})));
             this.$('.common-content-list').append(_.last(this._itemViews).render().$el);

             var containerHeight = this.$('.common-content-list').height();
             var itemHeight = this.$('.list-row').height();

             var maxItems = containerHeight / itemHeight;
             maxItems = Math.max(3, Math.floor(maxItems));

             for (var i = 1; i < this._items.length; i++) {

             if (i === maxItems) break;

             this._itemViews.push(this.addSubView(new Love.Views.DashboardNewsRowView({item: this._items.at(i)})));
             this.$('.common-content-list').append(_.last(this._itemViews).render().$el);
             }
             */

            this._itemViews.push(this.addSubView(new Love.Views.DashboardNewsRowView({type: 'more'})));
            addElements.push(_.last(this._itemViews).render().$el);

            this.$('.common-content-list').append(addElements);

            this.trigger('rendered');
        }
    });

})(Love);