(function(Love) {

    Love.Views.PageRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.PageRowView',

        defaults: function() {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true,
                selectable: true,
                selected: false
            });
        },

        renderItem: function() {

            // If any changes were saved to the client backup, display those instead of the server values.

            var entry = this.options.item.getClientBackup();
            if (entry)this.options.item.set(this.options.item.parse(entry.backup));

            var externalUrl = '';

            if (this.options.item.get('isPublished')) {

                var siteUrl = Love.session.get('currentSite').get('url');
                externalUrl = siteUrl + '/page' + this.options.item.get('path');
            }

            var revision = this.options.item.get('revisions')[this.options.item.getDefaultRevision()];
            var coverModel = new Love.Models.ImageModel(revision.cover);
            var coverUrl = coverModel.getImageView();

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    activeRevision: this.options.item.getDefaultRevision(),
                    externalUrl: externalUrl,
                    hasCoverImage: !_.isEmpty(coverUrl),
                    hasUnsavedChanges: (entry !== null),
                    isLoading: _.isEmpty(this.options.item.id)
                })
            });

            this.$el.html(_.template(Love.Templates.pages_page_row)(context));

            if (!_.isEmpty(coverUrl)) {

                Love.Helpers.Images.loadAnImage({

                    element: this.$('.image'),
                    focusPoint: coverModel.getFocusPoint(),
                    onLoad: _.bind(function() {

                        this.$('.image .placeholder').addClass('hidden');

                    }, this),
                    url: coverUrl
                });
            }
            else
                this.$('.image .placeholder').removeClass('hidden');
        }
    });

})(Love);