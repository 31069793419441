(function(Love) {

    Love.Factories.PageFactory = {

        createFromBlogPostModel: function(blogPostModel) {

            return new Love.Models.PageModel({

                revisions: _.each(_.clone(blogPostModel.get('revisions')), function(revision, lang) {

                    revision.id = '';
                }),
                storylines: _.clone(blogPostModel.get('storylines'))
            });
        }
    };

})(Love);