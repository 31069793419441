(function(Love) {

    Love.Models.BaseModel = Backbone.Model.extend({

        objectClassName: 'Love.Models.BaseModel',

        /**
         * This function mimics the behaviour of changedAttributes().
         *
         * If diff is not supplied, the tracked changed attributes by the set() function will be returned.
         * If diff is supplied, a JSON serialized comparison is used to compare the attributes of the current
         * model to the diff attributes.
         *
         * Using JSON serialization allows for a more functional comparison of what will actually be sent to the API.
         *
         * @param diff
         * @returns {*}
         */
        changedAttributesJSON: function(diff) {

            if (!diff) return this.hasChanged() ? _.clone(this.changed) : false;

            var old = this._changing ? this._previousAttributes : this.attributes;
            var changed = {};

            old = JSON.parse(JSON.stringify(old));
            diff = JSON.parse(JSON.stringify(diff));

            for (var attr in diff) {

                var val = diff[attr];
                if (_.isEqual(old[attr], val)) continue;

                changed[attr] = val;
            }

            return _.size(changed) ? changed : false;
        },

        isNew: function() {

            // Backbone only checks if the model has an id. We also check for empty id's, as we consider them non-existent on the server.

            return !this.has(this.idAttribute) || _.isEmpty(this.get(this.idAttribute));
        },

        lastSyncedBeforeMinutes: function(minutes) {

            if (!this.lastSynced || !this.lastSynced.isValid()) return true;
            return (this.lastSynced.isBefore(moment().subtract(minutes, 'minutes')));
        },

        sync: function(method, model, options) {

            options = options || {};

            // This function provides a wrapper around Backbone.sync. When we retrieve data from the server, we keep a collection of
            // attributes as they were sent. This allows easy client side change tracking.

            if (method === 'update' || method === 'create' || method === 'patch' || method === 'read') {

                options.success = _.wrap(options.success, _.bind(function(oldSuccess, data, textStatus, jqXHR) {

                    // We need to be the first callback to happen on success, to allow setting the server attributes hash
                    // before any change event is raised.

                    model.serverAttributes = model.parse(Love.Helpers.General.deepCloneJSON(data));

                    // We also keep a record of when the model was last synced successfully with the server.

                    if (method === 'read')
                        model.lastSynced = moment();

                    if (oldSuccess) return oldSuccess.call(this, data, textStatus, jqXHR);

                }, this));
            }

            return Backbone.Model.prototype.sync.call(this, method, model, options);
        },

        toJSON: function(options) {

            var result = Backbone.Model.prototype.toJSON.call(this, options);

            // We use empty id's on the client side to indicate non-saved models. However, we don't want to send
            // these to the API.

            if (_.isEmpty(result.id)) delete result.id;

            return result;
        }
    });

})(Love);