(function(Love) {

    Love.Router = Backbone.Router.extend({

        _routesWithoutLogin: [

            // These routes are authorized without a logged in session.

            '_routeLogin',
            '_routeLogout',
            '_routeRecover'
        ],

        /**
         * This method is called internally within the router, whenever a route matches and its corresponding
         * callback is about to be executed. Return false from execute to cancel the current transition. Override
         * it to perform custom parsing or wrapping of your routes, for example, to parse query strings before
         * handing them to your route callback.
         *
         * @param callback
         * @param args
         * @param name
         * @returns {boolean}
         */
        execute: function(callback, args, name) {

            if (this._handleQueryParameters()) return false;

            var isRouteAlwaysAuthorized = _.indexOf(this._routesWithoutLogin, name) > -1;

            if (!Love.session.get('isLoggedIn')) {

                // We're not logged in. This occurs either if the user really isn't logged in, or after a hard page refresh.
                // In the latter case, there might still be a session on the server. We'll try to retrieve it now.

                Love.session.authorize(_.bind(function() {

                    // If the session was retrieved successfully, continue router execution.

                    if ((Love.session.get('isLoggedIn') || isRouteAlwaysAuthorized) && callback)
                        callback.apply(this, args);

                    // If not, navigate to the login page.

                    else
                        this.navigate('login', {trigger: true});

                }, this));

                return false;
            }

            else if (callback) {

                Love.Helpers.Tracking.track('Navigating to ' + name);
                callback.apply(this, args);
            }
        },

        /**
         * When creating a new router, you may pass its routes hash directly as an option, if you choose.
         * All options will also be passed to your initialize function, if defined.
         *
         * @param options
         */
        initialize: function(options) {

            this._setupRoutes();

            // From the Backbone documentation:

            // During page load, after your application has finished creating all of its routers, be sure to call
            // Backbone.history.start() or Backbone.history.start({pushState: true}) to route the initial URL.

            Backbone.history.start();
        },

        /**
         * Whenever you reach a point in your application that you'd like to save as a URL, call navigate in order to update the URL.
         * If you also wish to call the route function, set the trigger option to true. To update the URL without creating an entry
         * in the browser's history, set the replace option to true.
         *
         * @param fragment
         * @param options
         */
        navigate: function(fragment, options) {

            //console.log('navigate: ' + fragment);

            return Backbone.Router.prototype.navigate.call(this, fragment, options);
        },

        /**
         * Navigates to a route. If the route is already active, it is reloaded.
         *
         * @param fragment
         * @param options
         */
        navigateOrReload: function(fragment, options) {

            // `Backbone.history.navigate` is sufficient for all Routers and will trigger the
            // correct events. The Router's internal `navigate` method calls this anyways.

            var ret = Backbone.history.navigate(fragment, options);

            // Typically Backbone's history/router will do nothing when trying to load the same URL.
            // But since we want it to re-fire the same route, we can detect
            // when Backbone.history.navigate did nothing, and force the route.

            if (!ret)
                Backbone.history.loadUrl(fragment);
        },

        _handleQueryParameters: function() {

            var query = Love.Helpers.General.getQueryParameters();

            if (query.authToken && !_.isEmpty(query.authToken[0])) {

                // Try to login the user based on the authentication token. TODO RENS: alternatief is om in te loggen en te redirecten naar url.

                var token = query.authToken[0];
            }

            if (query.from && !_.isEmpty(query.from[0])) {

                var url = query.from[0].substring(1); // Skip the leading slash.
                var siteId = Love.session.get('currentSiteId');

                // Handle /?from=%2remix

                if (url.startsWith('remix')) {

                    if (siteId)
                        location.assign('/#site/' + siteId + '/remix');
                    else
                        location.assign('http://www.tagthelove.com/remix');

                    return true;
                }
            }

            return false;
        },

        _handleUnmatchedRoute: function(path) {

            if (_.isEmpty(path)) {

                this.navigate('sites', {trigger: true, replace: true});
                return;
            }

            var siteChanged = Love.session.setCurrentSite();

            Love.appView.setCurrentSection('error');
            Love.appView.renderAppViewFor(new Love.Views.Errors404View({query: path}), siteChanged);
        },

        _handleUnmatchedRouteWithSite: function(siteId, path) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('error');
            Love.appView.renderAppViewFor(new Love.Views.Errors404View({query: path}), siteChanged);
        },

        _routeAccount: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('account');
            Love.appView.renderAppViewFor(new Love.Views.AccountScreenView(), siteChanged);
        },

        _routeCalendar: function(siteId, mode, date) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('calendar');
            Love.appView.renderAppViewFor(new Love.Views.CalendarScreenView({

                mode: mode,
                date: (date) ? moment(date) : moment()

            }), siteChanged);
        },

        _routeDashboard: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('dashboard');
            Love.appView.renderAppViewFor(new Love.Views.DashboardScreenView(), siteChanged);
        },

        _routeError: function(code) {

            var siteChanged = Love.session.setCurrentSite();
            Love.appView.setCurrentSection('error');

            switch (code) {

                case 'session': {

                    Love.appView.renderAppViewFor(new Love.Views.ErrorsSessionView(), siteChanged);
                    break;
                }
                default: {

                    Love.appView.renderAppViewFor(new Love.Views.Errors404View(), siteChanged);
                    break;
                }
            }
        },

        _routeEventCreate: function(siteId, date, time) {

            var start = (date && time) ? moment(date + ' ' + time + ':00') : moment();
            if (!start.isValid()) start = moment();

            var end = start.clone().add(1, 'hours');

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('events');
            Love.appView.renderAppViewFor(new Love.Views.EventScreenView({

                startDate: start,
                endDate: end

            }), siteChanged);
        },

        _routeEventCreateFromGoogle: function(siteId, eventId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('events');
            Love.appView.renderAppViewFor(new Love.Views.EventScreenView({

                fromGoogleEventId: eventId

            }), siteChanged);
        },

        _routeEventEdit: function(siteId, calendarId, eventId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('events');
            Love.appView.renderAppViewFor(new Love.Views.EventScreenView({

                calendarId: calendarId,
                eventId: eventId

            }), siteChanged);
        },

        _routeEvents: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('events');
            Love.appView.renderAppViewFor(new Love.Views.EventsScreenView(), siteChanged);
        },

        _routeFAQ: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('faq');
            Love.appView.renderAppViewFor(new Love.Views.FAQScreenView(), siteChanged);
        },

        _routeHighlighted: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('highlighted');
            Love.appView.renderAppViewFor(new Love.Views.HighlightedScreenView(), siteChanged);
        },

        _routeHighlightedCreate: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('highlighted');
            Love.appView.renderAppViewFor(new Love.Views.HighlightedContentSetScreenView(), siteChanged);
        },

        _routeHighlightedEdit: function(siteId, setId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('highlighted');
            Love.appView.renderAppViewFor(new Love.Views.HighlightedContentSetScreenView({

                contentSetId: setId

            }), siteChanged);
        },

        _routeLogin: function() {

            if (Love.session.get('isLoggedIn')) {

                Love.router.navigate('sites', {trigger: true});
                return;
            }

            var siteChanged = Love.session.setCurrentSite();

            Love.appView.setCurrentSection('login');
            Love.appView.renderAppViewFor(new Love.Views.LoginScreenView(), siteChanged);
        },

        _routeLogout: function() {

            Love.session.logout(function() {Love.router.navigate('login', {trigger: true});});
        },

        _routePages: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('pages');
            Love.appView.renderAppViewFor(new Love.Views.PagesScreenView(), siteChanged);
        },

        _routePageCreate: function(siteId, date, time) {

            var dateObj = (date && time) ? moment(date + ' ' + time + ':00') : null;

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('pages');
            Love.appView.renderAppViewFor(new Love.Views.PageScreenView({

                date: dateObj && dateObj.isValid() ? dateObj : null

            }), siteChanged);
        },

        _routePageCreateFromPostCache: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('pages');
            Love.appView.renderAppViewFor(new Love.Views.PageScreenView({

                fromPostCache: true

            }), siteChanged);
        },

        _routePageEdit: function(siteId, pageId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('pages');
            Love.appView.renderAppViewFor(new Love.Views.PageScreenView({

                pageId: pageId

            }), siteChanged);
        },

        _routePlaylists: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('playlists');
            Love.appView.renderAppViewFor(new Love.Views.PlaylistsScreenView(), siteChanged);
        },

        _routePlaylistCreate: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('playlists');
            Love.appView.renderAppViewFor(new Love.Views.PlaylistScreenView(), siteChanged);
        },

        _routePlaylistEdit: function(siteId, playlistId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('playlists');
            Love.appView.renderAppViewFor(new Love.Views.PlaylistScreenView({

                playlistId: playlistId

            }), siteChanged);
        },

        _routePostCreate: function(siteId, date, time) {

            var dateObj = (date && time) ? moment(date + ' ' + time + ':00') : null;

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostScreenView({

                date: dateObj && dateObj.isValid() ? dateObj : null

            }), siteChanged);
        },

        _routePostCreateFromBookmarks: function(siteId, ids) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            ids = _.without(ids.split(','), '', null, void(0));

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostScreenView({

                fromBookmarkIds: ids

            }), siteChanged);
        },

        _routePostCreateFromCache: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostScreenView({

                fromCache: true

            }), siteChanged);
        },

        _routePostCreateFromMedia: function(siteId, ids) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            ids = _.without(ids.split(','), '', null, void(0));

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostScreenView({

                fromMediaIds: ids

            }), siteChanged);
        },

        _routePostCreateFromPageCache: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostScreenView({

                fromPageCache: true

            }), siteChanged);
        },

        _routePostCreateFromUrl: function(siteId, url) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostScreenView({

                fromUrl: url

            }), siteChanged);
        },

        _routePostEdit: function(siteId, postId, edit) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostScreenView({

                edit: edit,
                postId: postId

            }), siteChanged);
        },

        _routePosts: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('posts');
            Love.appView.renderAppViewFor(new Love.Views.PostsScreenView(), siteChanged);
        },

        _routeRecover: function() {

            var siteChanged = Love.session.setCurrentSite();

            Love.appView.setCurrentSection('login');
            Love.appView.renderAppViewFor(new Love.Views.LoginRecoverScreenView(), siteChanged);
        },

        _routeRemix: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('remix');
            Love.appView.renderAppViewFor(new Love.Views.RemixScreenView(), siteChanged);
        },

        _routeSettings: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('settings');
            Love.appView.renderAppViewFor(new Love.Views.SettingsScreenView(), siteChanged);
        },

        _routeSites: function() {

            var siteChanged = Love.session.setCurrentSite();

            Love.appView.setCurrentSection('sites');
            Love.appView.renderAppViewFor(new Love.Views.SitesScreenView(), siteChanged);
        },

        _routeSocial: function(siteId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('social');
            Love.appView.renderAppViewFor(new Love.Views.SocialScreenView(), siteChanged);
        },

        _routeSocialCreate: function(siteId, date, time) {

            var dateObj = (date && time) ? moment(date + ' ' + time + ':00') : null;

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('social');
            Love.appView.renderAppViewFor(new Love.Views.SocialUpdateScreenView({

                date: dateObj && dateObj.isValid() ? dateObj : null

            }), siteChanged);
        },

        _routeSocialEdit: function(siteId, updateId) {

            var siteChanged = Love.session.setCurrentSite(siteId);

            Love.appView.setCurrentSection('social');
            Love.appView.renderAppViewFor(new Love.Views.SocialUpdateScreenView({

                socialUpdateId: updateId

            }), siteChanged);
        },

        _setupRoutes: function() {

            // NOTE: we define the routes hash as a local variable and process all routes using the route() function.
            // This ensures that routes are added in correct order, especially the last route for unmatched paths.

            var processed = [];

            /*
             The routes hash maps URLs with parameters to functions on your router (or just direct function
             definitions, if you prefer), similar to the View's events hash. Routes can contain parameter parts,
             :param, which match a single URL component between slashes; and splat parts *splat, which can match
             any number of URL components. Part of a route can be made optional by surrounding it in parentheses
             (/:optional).
             */

            var routesToProcess = {

                'account': '_routeAccount',
                'error/:code': '_routeError',
                'faq': '_routeFAQ',
                'login': '_routeLogin',
                'logout': '_routeLogout',
                'recover': '_routeRecover',
                'site/:id': '_routeDashboard',
                'site/:id/bookmarks': '_routeRemix', // TODO RENS: deze oude route moet weg als Erik 'm uit de iOS app heeft gehaald.
                'site/:id/calendar': '_routeCalendar',
                'site/:id/calendar/:view/:date': '_routeCalendar',
                'site/:id/dashboard': '_routeDashboard',
                'site/:id/event/new/google/:eventid': '_routeEventCreateFromGoogle',
                'site/:id/event/new': '_routeEventCreate',
                'site/:id/event/new/:date/:time': '_routeEventCreate',
                'site/:id/event/:calendar/:event': '_routeEventEdit',
                'site/:id/events': '_routeEvents',
                'site/:id/faq': '_routeFAQ',
                'site/:id/highlighted': '_routeHighlighted',
                'site/:id/highlighted/new': '_routeHighlightedCreate',
                'site/:id/highlighted/:set': '_routeHighlightedEdit',
                'site/:id/pages': '_routePages',
                'site/:id/page/new/post/cache': '_routePageCreateFromPostCache',
                'site/:id/page/new': '_routePageCreate',
                'site/:id/page/new/:date/:time': '_routePageCreate',
                'site/:id/page/:page': '_routePageEdit',
                'site/:id/playlists': '_routePlaylists',
                'site/:id/playlist/new': '_routePlaylistCreate',
                'site/:id/playlist/:page': '_routePlaylistEdit',
                'site/:id/post/new/page/cache': '_routePostCreateFromPageCache',
                'site/:id/post/new/remix/media/:ids': '_routePostCreateFromMedia',
                'site/:id/post/new/remix/url/:url': '_routePostCreateFromUrl',
                'site/:id/post/new/remix/cache': '_routePostCreateFromCache',
                'site/:id/post/new/remix/:ids': '_routePostCreateFromBookmarks',
                'site/:id/post/new': '_routePostCreate',
                'site/:id/post/new/:date/:time': '_routePostCreate',
                'site/:id/post/:post': '_routePostEdit',
                'site/:id/post/:post/edit/:type': '_routePostEdit',
                'site/:id/posts': '_routePosts',
                'site/:id/remix': '_routeRemix',
                'site/:id/settings': '_routeSettings',
                'site/:id/social/new': '_routeSocialCreate',
                'site/:id/social/new/:date/:time': '_routeSocialCreate',
                'site/:id/social/:update': '_routeSocialEdit',
                'site/:id/social': '_routeSocial',
                'sites': '_routeSites'
            };

            _.each(routesToProcess, function(value, key, list) {

                // Backbone correctly distinguishes between routes with a trailing slash / and routes without one.
                // Therefore, sites points to a different route than sites/.
                // For usability, we don't want this and therefore add an optional trailing slash to all routes.

                key = key + '(/)';

                processed.push({

                    route: key + '(/)',
                    name: value,
                    callback: null
                });

            }, this);

            processed.push({

                route: 'site/:id/*path',
                name: '_handleUnmatchedRouteWithSite',
                callback: null
            });

            processed.push({

                route: '*path',
                name: '_handleUnmatchedRoute',
                callback: null
            });

            processed.reverse();
            _.each(processed, function(r) {this.route(r.route, r.name, r.callback);}, this);
        }
    });

})(Love);