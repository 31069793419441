(function(Love) {

    Love.Views.CommonContentNewWidgetRemixPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CommonContentNewWidgetRemixPopupView',

        className: 'base-popup popup-centered popup-tabbed',
        id: 'popup-common-content-new-widget-remix',

        defaultsForPopupType: function() {

            return {

                callbacks: {

                    onConfirm: null
                },
                data: {

                    itemsToLoad: 5,
                    view: 'bookmarks',
                    views: ['bookmarks', 'social']
                },
                enableLightbox: true
            };
        },

        events: {

            'click [data-action="close"]': '_handleClose',
            'click [data-action="confirm"]': '_handleConfirm'
        },

        initialize: function(options) {

            Love.Views.BasePopupView.prototype.initialize.call(this, options);

            this._bookmarks = {

                fetchOptions: {requestId: this.getAjaxAbortId()},
                finalNotificationShown: false,
                items: new Love.Collections.BookmarkCollection(null, {

                    mode: 'infinite',
                    state: {pageSize: this.options.data.itemsToLoad}
                }),
                itemViews: [],
                notification: null,
                scrollPosition: 0,
                selectedItems: [],
                waypoints: []
            };

            this._social = {

                fetchOptions: {

                    data: {

                        mediaType: '',
                        streamIDs: _.pluck(_.where(Love.session.get('currentSite').get('streams'), {isBrandStream: true}), 'id')
                    },
                    traditional: true,
                    requestId: this.getAjaxAbortId()
                },
                finalNotificationShown: false,
                items: new Love.Collections.MediaCollection(null, {

                    mode: 'infinite',
                    state: {pageSize: this.options.data.itemsToLoad}
                }),
                itemViews: [],
                notification: null,
                scrollPosition: 0,
                selectedItems: [],
                waypoints: []
            };
        },

        render: function() {

            this.$el.html(_.template(Love.Templates.common_content_popups_new_widget_remix)(this.options.data));

            Love.Helpers.Tabs.initTabs(this.$('.tabs'), _.bind(function(e) {

                this._activateView(e.newTab);

            }, this));

            this._updateSelectedItems();

            this.listenTo(this._bookmarks.items, 'sync', _.bind(function() {this._renderItems('bookmarks'); }, this));
            this.listenTo(this._social.items, 'sync', _.bind(function() {this._renderItems('social'); }, this));

            this._bookmarks.items.getFirstPage(this._bookmarks.fetchOptions);
            this._social.items.getFirstPage(this._social.fetchOptions);

            this.listenTo(this, 'confirmRequested', this._handleConfirm);
            this.listenTo(this, 'closeRequested', this._handleClose);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        onClose: function() {

            Love.Helpers.Tabs.unloadTabs(this.$('.tabs'));

            this._clearWaypoints();

            return Love.Views.BasePopupView.prototype.onClose.call(this);
        },

        _activateView: function(view) {

            this._clearWaypoints(this.options.data.view);
            this._closeNotification(this.options.data.view, true);

            this.options.data.view = view;

            this._registerWaypoints(view);
        },

        _clearWaypoints: function(view) {

            var doClear = function(viewObj) {

                _.each(viewObj.waypoints, function(point) { point.destroy(); });
                viewObj.waypoints = [];
            };

            switch (view) {

                case 'bookmarks':
                    doClear(this._bookmarks);
                    break;
                case 'social':
                    doClear(this._social);
                    break;
                default:
                    doClear(this._bookmarks);
                    doClear(this._social);
                    break;
            }
        },

        _closeNotification: function(view, noFade) {

            var doClose = function(viewObj) {

                if (viewObj.notification) {

                    if (!noFade)
                        viewObj.notification.closeFade('slow');
                    else
                        viewObj.notification.close();
                }
            };

            switch (view) {

                case 'bookmarks':
                    doClose(this._bookmarks);
                    break;
                case 'social':
                    doClose(this._social);
                    break;
                default:
                    doClose(this._bookmarks);
                    doClose(this._social);
                    break;
            }
        },

        _getSelectedItems: function() {

            var selectedItems = _.map(_.filter(this._bookmarks.itemViews, _.bind(function(view) {

                return (_.indexOf(this._bookmarks.selectedItems, view.getItem().id) > -1);

            }, this)), function(view) {return {type: 'bookmark', view: 'bookmarks', model: view.getItem()};});

            selectedItems = selectedItems.concat(_.map(_.filter(this._social.itemViews, _.bind(function(view) {

                return (_.indexOf(this._social.selectedItems, view.getItem().id) > -1);

            }, this)), function(view) {return {type: 'media', view: 'social', model: view.getItem()};}));

            return selectedItems;
        },

        _getViewObject: function(view) {

            switch (view) {

                case 'bookmarks':
                    return this._bookmarks;
                case 'social':
                    return this._social;
            }
        },

        _handleClose: function(e) {

            if (e && e.preventDefault) e.preventDefault();
            this.close();
        },

        _handleConfirm: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            if (this.options.callbacks.onConfirm)
                this.options.callbacks.onConfirm({items: this._getSelectedItems()});

            this.close();
        },

        _registerWaypoints: function(view) {

            if (view !== this.options.data.view) return;

            var viewObj = this._getViewObject(view);

            if (viewObj.items.hasNextPage()) {

                var triggerView = _.first(_.last(viewObj.itemViews, this.options.data.itemsToLoad));

                if (triggerView)
                    viewObj.waypoints = triggerView.$el.waypoint({

                        context: this.$('[data-tab="' + view + '"] .common-content-list'),
                        handler: _.bind(function(direction) {

                            if (direction === 'down') {

                                viewObj.notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                                    dock: 'bottom',
                                    text: 'Fetching more items...'
                                }));
                                this.$('[data-tab="' + view + '"] .common-content-list-container').append(viewObj.notification.render().$el);

                                this._clearWaypoints(view);
                                viewObj.items.getNextPage(viewObj.fetchOptions);
                            }

                        }, this),
                        offset: '100%' // When the top of the element has passed the bottom of the context.
                    });
            }
        },

        _renderItems: function(view) {

            var viewObj = this._getViewObject(view);
            var fullCollection = viewObj.items.fullCollection ? viewObj.items.fullCollection : viewObj.items; // fullCollection is only used when mode is infinite.

            var container = this.$('[data-tab="' + view + '"] .common-content-list');
            var addElements = [];

            if (fullCollection.length < 1) {

                switch (view) {

                    case 'bookmarks':
                        container.html(_.template(Love.Templates.remix_bookmark_row_none)());
                        return;
                    case 'social':
                        container.html(_.template(Love.Templates.remix_social_row_none)());
                        return;
                }
            }

            viewObj.items.each(function(model) {

                try {

                    var itemView;

                    switch (view) {

                        case 'bookmarks':
                            itemView = this.addSubView(new Love.Views.RemixBookmarkRowView({item: model, deletable: false, remixable: false}));
                            break;
                        case 'social':
                            itemView = this.addSubView(new Love.Views.RemixSocialRowView({item: model, selectable: true}));
                            break;
                    }

                    this.listenTo(itemView, 'selectedChanged', _.bind(function(e) {

                        if (e.selected) {

                            if (_.indexOf(viewObj.selectedItems, e.item.id) === -1)
                                viewObj.selectedItems.push(e.item.id);
                        }
                        else
                            viewObj.selectedItems = _.without(viewObj.selectedItems, e.item.id);

                        this._updateSelectedItems();

                    }, this));

                    viewObj.itemViews.push(itemView);
                    addElements.push(itemView.render().$el);
                }
                catch (ex) {

                    console.log(ex); // TODO: proper error handling.
                }

            }, this);

            container.append(addElements);

            this._closeNotification(view);
            this._registerWaypoints(view);

            if (!viewObj.items.hasNextPage() && !viewObj.finalNotificationShown) {

                this._closeNotification(view, true);

                viewObj.notification = this.addSubView(new Love.Views.CommonNotificationOverlayView({

                    dock: 'bottom',
                    text: 'That\'s it for now!'
                }));
                this.$('[data-tab="' + view + '"] .common-content-list-container').append(viewObj.notification.render().$el);

                _.delay(_.bind(function() {

                    this._closeNotification(view);

                }, this), 4000);

                viewObj.finalNotificationShown = true;
            }

            //this.trigger('rendered');
        },

        _updateSelectedItems: function() {

            // Update the number of selected items.

            this.$('[data-open-tab="bookmarks"]').text(Love.t('posts:popups.new_widget_remix.bookmarks_title_interval', {

                count: this._bookmarks.selectedItems.length,
                postProcess: 'interval'
            }));

            this.$('[data-open-tab="social"]').text(Love.t('posts:popups.new_widget_remix.social_title_interval', {

                count: this._social.selectedItems.length,
                postProcess: 'interval'
            }));

            this.$('.button[data-action="confirm"]').text(Love.t('posts:popups.new_widget_remix.add', {

                count: this._getSelectedItems().length
            }));
        }
    });

})(Love);