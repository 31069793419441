(function(Love) {

    Love.Views.CalendarEventSocialPopupView = Love.Views.BasePopupView.extend({

        objectClassName: 'Love.Views.CalendarEventSocialPopupView',

        className: 'base-popup popup-calendar popup-calendar-event popup-clean popup-size-auto',
        id: 'popup-calendar-event-social',

        defaultsForPopupType: function() {

            return {

                data: {

                    calendarEventModel: {}
                },
                enableClickInterception: false
            };
        },

        events: {

            'click [data-action="delete"]': '_handleDeleteClick',
            'click [data-action="edit"]': '_handleEditClick',
            'click [data-action="view"]': '_handleViewClick'
        },

        render: function() {

            this.$el.append(_.template(Love.Templates.calendar_popups_event_social)(this.options.data.calendarEventModel.get('content')));

            var socialUpdate = this.options.data.calendarEventModel.get('content');
            var coverUrl = socialUpdate.destination.avatarUrl;

            if (socialUpdate.contents && socialUpdate.contents.ttlUpload && socialUpdate.contents.ttlUpload.mimeType.startsWith('image/')) {

                coverUrl = socialUpdate.contents.ttlUpload.url;
            }

            if (!_.isEmpty(coverUrl)) {

                Love.Helpers.Images.loadAnImage({

                    element: this.$('.popup-details-media .image'),
                    loadImageOptions: {

                      maxHeight: 240, // 15rem
                      maxWidth: 240 // 15rem
                    },
                    onLoad: _.bind(function() {

                        this.$('.image .placeholder').addClass('hidden');

                    }, this),
                    resize: true,
                    url: coverUrl
                });
            }

            this.listenTo(this, 'confirmRequested', this.close);
            this.listenTo(this, 'closeRequested', this.close);

            return Love.Views.BasePopupView.prototype.render.call(this);
        },

        _handleDeleteClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();

            var model = new Love.Models.SocialUpdateModel(this.options.data.calendarEventModel.get('content'));

            var self = this;
            var popup = new Love.Views.CommonQuestionPopupView({

                callbacks: {

                    onYes: function() {

                        model.destroy({

                            wait: true,
                            success: function(model, response, options) {

                                self.options.data.calendarEventModel.destroy();
                            }
                        });
                    }
                },
                data: {

                    noText: 'Cancel',
                    title: 'Delete social update?',
                    text: 'Do you really want to delete this social update? This action can\'t be undone.',
                    yesText: 'Delete'
                }
            });

            popup.showPopup();
        },

        _handleEditClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            this.close();
            Love.router.navigate('site/' + Love.session.get('currentSiteId') + '/social/' + this.options.data.calendarEventModel.get('content').id, {trigger: true});
        },

        _handleViewClick: function(e) {

            if (e && e.preventDefault) e.preventDefault();

            var externalUrl = this.options.data.calendarEventModel.get('content').external ? this.options.data.calendarEventModel.get('content').external.url : '';

            if (!_.isEmpty(externalUrl)) {

                this.close();

                var win = window.open(externalUrl, '_blank');
                win.focus();
            }
        }
    });

})(Love);