(function(Love) {

    Love.Views.HighlightedContentSetRowView = Love.Views.BaseContentListRowView.extend({

        objectClassName: 'Love.Views.HighlightedContentSetRowView',

        defaults: function() {

            return _.extend({}, Love.Views.BaseContentListRowView.prototype.defaults.call(this), {

                collapsible: true,
                selectable: true,
                selected: false
            });
        },

        renderItem: function() {

            var context = _.extend(_.clone(this.options.item.attributes), {

                viewOptions: _.extend(_.clone(this.options), {

                    isLoading: _.isEmpty(this.options.item.id)
                })
            });

            this.$el.html(_.template(Love.Templates.highlighted_content_set_row)(context));
        }
    });

})(Love);